import React, { useEffect, useRef, useState } from "react";
import { getTepars } from "../../Utils/helpers";
import { post_video_thumb_url } from "../../ServerConfiguration/awsConfiguration";
import { IMAGE, VIDEO } from "../../Utils/enums";

function MediaSliderVideo({
  index,
  sound,
  post,
  Media,
  mediaIndexFinal,
  handlePlayPauseToggleClick,
  disableRightClick,
  setIsPaused,
}) {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleLoadedMetadata = (event) => {
    setVideoDimensions({
      width: event.target.videoWidth,
      height: event.target.videoHeight,
    });
  };

  const handleLoadedData = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (videoRef.current && videoRef.current.readyState === 4) {
      setIsLoading(false);
    }
  }, []);

  // const getObjectFit = () => {
  //     return videoDimensions.height < videoDimensions.width ? 'contain' : 'cover';
  // };

  const getObjectFit = () => {
    var allVideo = post?.media?.every((item) => item?.type === "Video")
      ? VIDEO
      : IMAGE;
    var allImage = post?.media?.every((item) => item?.type === "Image")
      ? IMAGE
      : VIDEO;
    if (post.media.length === 1 && post.media[0].type === VIDEO) {
      return videoDimensions.height > videoDimensions.width
        ? "cover"
        : "contain";
    } else if (post.media.length === 1 && post.media[0].type === IMAGE) {
      return videoDimensions.height > videoDimensions.width
        ? "cover"
        : "contain";
    } else if (post.media.length > 1 && allVideo === VIDEO) {
      return videoDimensions.height > videoDimensions.width
        ? "cover"
        : "contain";
    } else if (post.media.length > 1 && allImage === IMAGE) {
      return "contain";
    } else {
      return videoDimensions.height > videoDimensions.width
        ? "cover"
        : "contain";
    }
  };

  return (
    <>
      {/* {!isLoading && (
                <img
                    src={post_video_thumb_url + Media?.thumbnail}
                    alt="Thumbnail"
                    style={{ width: '100%', height: 'auto', display: 'block', position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
                />
            )} */}
      <video
        ref={videoRef}
        loop={true}
        muted={sound}
        className="view-video"
        poster={post_video_thumb_url + Media?.thumbnail}
        src={getTepars(Media, post)}
        autoPlay={index === mediaIndexFinal}
        controls={false}
        name={`${post?._id}-${index}`}
        id={`${post?._id}-${index}`}
        onLoadedMetadata={handleLoadedMetadata}
        onLoadedData={handleLoadedData}
        onPlay={() => setIsPaused((prev) => ({ ...prev, [Media?._id]: false }))}
        onPause={() => setIsPaused((prev) => ({ ...prev, [Media?._id]: true }))}
        onClick={() => handlePlayPauseToggleClick(Media?._id, index)}
        onContextMenu={disableRightClick}
        style={{
          opacity: isLoading ? 0 : 1,
          transition: "opacity 150ms ease-in-out",
          objectFit: getObjectFit(),
        }}
      />
    </>
  );
}
export default MediaSliderVideo;
