import React from "react";
import "../../assets/css/settingsMenu.scss";

function SettingsMenu({
  ddref,
  type,
  selectedTepars,
  setSettingMenu,
  settingMenuOption,
  setSettingMenuOption,
  handleSubmit,
  setDeleteArchive,
}) {
  const handleSelectMenu = () => {
    setSettingMenuOption("selected");
    setSettingMenu(false);
  };

  return (
    <div ref={ddref} className="settingsMenu">
      <ul className="setting-menu-list">
        {settingMenuOption === "select" && selectedTepars?.length === 0 && (
          <li onClick={() => handleSelectMenu()}>Select...</li>
        )}
        {settingMenuOption !== "select" &&
          type === "archive" &&
          selectedTepars?.length !== 0 && (
            <>
              <li onClick={() => handleSubmit("unarchive")}>Show on profile</li>
              <li onClick={() => setDeleteArchive(true)}>Delete</li>
            </>
          )}
        {settingMenuOption !== "select" &&
          type === "saved" &&
          selectedTepars?.length !== 0 && (
            <li onClick={() => handleSubmit()}>Unsaved</li>
          )}
      </ul>
    </div>
  );
}

export default SettingsMenu;
