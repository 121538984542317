import React from "react";
import "../../assets/css/camera_alert.scss";
import "../../assets/css/camera_not_alert.scss";

export const CameraAlertAllow = ({ handleCloseAlert }) => {
  const handleCloseAllowAlert = () => {
    handleCloseAlert();
  };

  return (
    <div className="allowCamera">
      <div className="dialog">
        <div className="dialog-info">
          <div className="allow-image">
            <svg width="40" height="40" viewBox="0 0 46 46" fill="none">
              <rect
                width="46"
                height="46"
                rx="23"
                transform="matrix(-1 0 0 1 46 0)"
                fill="#1891A2"
              />
              <path
                d="M31.75 31.75L15.5 15.5M15.5 15.5V31.1M15.5 15.5H31.1"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <h4>Allow Camera</h4>
        </div>
        <p className="description">
          To Take photos, click “Allow” above to give tepnot access to your
          computer’s camera.
        </p>

        <button className="btn btn-allow" onClick={handleCloseAllowAlert}>
          Ok, got it
        </button>
      </div>
    </div>
  );
};

export const CameraAlertDenied = ({ handleCloseAlert }) => {
  const handleCloseAllowAlert = () => {
    handleCloseAlert();
  };
  
  return (
    <div className="notallowCamera">
      <div className="dialog">
        <div className="dialog-info">
          <h4>Camera Not Found</h4>
        </div>
        <p className="description">
          You can't take a photo because it looks like your computer doesn't
          have a camera. Try connecting one or if you have one connected, try
          restarting your browser.
        </p>

        <button className="btn btn-allow" onClick={handleCloseAllowAlert}>
          Ok, got it
        </button>
      </div>
    </div>
  );
};
