import React from "react";
import "../../assets/css/discardPost.scss";

function DiscardPost({ setOpenLastBack, handleDiscart }) {
  return (
    <div className="discardPost">
      <div className="dialog">
        <div className="dialog-info">
          <h4 className="title">Discard post?</h4>
        </div>
        <p className="description">If you leave, your edits won’t be saved.</p>
        <div className="discardPost-cta">
          <button
            className="btn btn-cancel active"
            onClick={() => setOpenLastBack(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-discard"
            onClick={() => handleDiscart()}
          >
            Discard
          </button>
        </div>
      </div>
    </div>
  );
}

export default DiscardPost;
