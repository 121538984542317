import { useState } from "react";

const Links = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="links-wrapper">
      <div className="links">
        <div className="links-details">
          <div className="link-userinfo">
            <div className="profile-image" />
            <span className="user">You</span>
            <span className="triangle">
              <svg width="6" height="6" viewBox="0 0 6 6" fill="none">
                <path
                  d="M0.962491 5.10459L0.962491 0.895409C0.962251 0.729235 1.00905 0.566391 1.09747 0.425724C1.1859 0.285058 1.31233 0.172321 1.46213 0.100564C1.63988 0.0165642 1.83763 -0.0157891 2.03286 0.00719092C2.22809 0.0301709 2.41294 0.10756 2.56635 0.230539L5.11453 2.33513C5.20978 2.41772 5.28617 2.51983 5.33852 2.63455C5.39087 2.74926 5.41797 2.87389 5.41797 3C5.41797 3.12611 5.39087 3.25074 5.33852 3.36545C5.28617 3.48017 5.20978 3.58228 5.11453 3.66487L2.56635 5.76946C2.41294 5.89244 2.22809 5.96983 2.03286 5.99281C1.83763 6.01579 1.63988 5.98343 1.46213 5.89943C1.31233 5.82768 1.1859 5.71494 1.09747 5.57428C1.00905 5.43361 0.96225 5.27076 0.962491 5.10459Z"
                  fill="#999999"
                />
              </svg>
            </span>
            <span className="username">Jigar Dioneapp</span>
          </div>

          <div className="link-date">
            <span className="date">26/06/2023</span>
            <div className="arrow">
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                <title>More info</title>
                <path
                  id="Vector"
                  d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                  fill="#C5C5C5"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="links-preview">
          <div className="group-check">
            <input
              type="checkbox"
              id="myCheckbox"
              aria-label="Check this box"
              checked={isChecked}
            />
            <span className="checkmark" onClick={handleCheckmarkClick} />
          </div>

          <div className="link-preview-info">
            <div className="link-preview-details">
              <div className="preview-image">
                <div className="preview-link-image">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path
                      d="M10.6386 18.9215H7.69333C6.36985 18.9215 5.10057 18.3519 4.16472 17.3381C3.22888 16.3243 2.70313 14.9492 2.70312 13.5154C2.70312 12.0817 3.22888 10.7066 4.16472 9.69277C5.10057 8.67894 6.36985 8.10938 7.69333 8.10938H10.5736M16.4569 8.10938H19.3372C20.6606 8.10938 21.9299 8.67894 22.8658 9.69277C23.8016 10.7066 24.3274 12.0817 24.3274 13.5154C24.3274 14.9492 23.8016 16.3243 22.8658 17.3381C21.9299 18.3519 20.6606 18.9215 19.3372 18.9215H16.3919M8.99651 13.5154H18.1379"
                      stroke="#999999"
                      strokeWidth="1.52045"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="details">
                <p className="details-link">
                  https://play.google.com/store/apps/details? is=
                  com.com.luckykat.panzerdogs
                </p>
                <span className="name">Play google.com</span>
              </div>
            </div>

            <div className="go-toLink">
              <a href="/">
                https://pay.google.com/store/apps/details?id=com.luckykat.pa...
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="links">
        <div className="links-details">
          <div className="link-userinfo">
            <div className="profile-image" />
            <span className="user">You</span>
            <span className="triangle">
              <svg width="6" height="6" viewBox="0 0 6 6" fill="none">
                <path
                  d="M0.962491 5.10459L0.962491 0.895409C0.962251 0.729235 1.00905 0.566391 1.09747 0.425724C1.1859 0.285058 1.31233 0.172321 1.46213 0.100564C1.63988 0.0165642 1.83763 -0.0157891 2.03286 0.00719092C2.22809 0.0301709 2.41294 0.10756 2.56635 0.230539L5.11453 2.33513C5.20978 2.41772 5.28617 2.51983 5.33852 2.63455C5.39087 2.74926 5.41797 2.87389 5.41797 3C5.41797 3.12611 5.39087 3.25074 5.33852 3.36545C5.28617 3.48017 5.20978 3.58228 5.11453 3.66487L2.56635 5.76946C2.41294 5.89244 2.22809 5.96983 2.03286 5.99281C1.83763 6.01579 1.63988 5.98343 1.46213 5.89943C1.31233 5.82768 1.1859 5.71494 1.09747 5.57428C1.00905 5.43361 0.96225 5.27076 0.962491 5.10459Z"
                  fill="#999999"
                />
              </svg>
            </span>
            <span className="username">Jigar Dioneapp</span>
          </div>

          <div className="link-date">
            <span className="date">26/06/2023</span>
            <div className="arrow">
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                <title>More info</title>
                <path
                  id="Vector"
                  d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                  fill="#C5C5C5"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="links-preview">
          <div className="group-check">
            <input
              type="checkbox"
              id="myCheckbox"
              aria-label="Check this box"
              checked={isChecked}
            />
            <span className="checkmark" onClick={handleCheckmarkClick} />
          </div>

          <div className="link-preview-info youtube">
            <div className="link-preview-details">
              <div className="preview-image">
                <div className="preview-link-image play">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path
                      d="M10.6386 18.9215H7.69333C6.36985 18.9215 5.10057 18.3519 4.16472 17.3381C3.22888 16.3243 2.70313 14.9492 2.70312 13.5154C2.70312 12.0817 3.22888 10.7066 4.16472 9.69277C5.10057 8.67894 6.36985 8.10938 7.69333 8.10938H10.5736M16.4569 8.10938H19.3372C20.6606 8.10938 21.9299 8.67894 22.8658 9.69277C23.8016 10.7066 24.3274 12.0817 24.3274 13.5154C24.3274 14.9492 23.8016 16.3243 22.8658 17.3381C21.9299 18.3519 20.6606 18.9215 19.3372 18.9215H16.3919M8.99651 13.5154H18.1379"
                      stroke="#999999"
                      strokeWidth="1.52045"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="details">
                <p className="details-link">
                  https://play.google.com/store/apps/details? is=
                  com.com.luckykat.panzerdogs
                </p>
                <span className="name">Play google.com</span>
              </div>
            </div>

            <div className="go-toLink">
              <a href="/">
                https://pay.google.com/store/apps/details?id=com.luckykat.pa...
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="links">
        <div className="links-details">
          <div className="link-userinfo">
            <div className="profile-image" />
            <span className="user">You</span>
            <span className="triangle">
              <svg width="6" height="6" viewBox="0 0 6 6" fill="none">
                <path
                  d="M0.962491 5.10459L0.962491 0.895409C0.962251 0.729235 1.00905 0.566391 1.09747 0.425724C1.1859 0.285058 1.31233 0.172321 1.46213 0.100564C1.63988 0.0165642 1.83763 -0.0157891 2.03286 0.00719092C2.22809 0.0301709 2.41294 0.10756 2.56635 0.230539L5.11453 2.33513C5.20978 2.41772 5.28617 2.51983 5.33852 2.63455C5.39087 2.74926 5.41797 2.87389 5.41797 3C5.41797 3.12611 5.39087 3.25074 5.33852 3.36545C5.28617 3.48017 5.20978 3.58228 5.11453 3.66487L2.56635 5.76946C2.41294 5.89244 2.22809 5.96983 2.03286 5.99281C1.83763 6.01579 1.63988 5.98343 1.46213 5.89943C1.31233 5.82768 1.1859 5.71494 1.09747 5.57428C1.00905 5.43361 0.96225 5.27076 0.962491 5.10459Z"
                  fill="#999999"
                />
              </svg>
            </span>
            <span className="username">Jigar Dioneapp</span>
          </div>

          <div className="link-date">
            <span className="date">26/06/2023</span>
            <div className="arrow">
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                <title>More info</title>
                <path
                  id="Vector"
                  d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                  fill="#C5C5C5"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="links-preview">
          <div className="group-check">
            <input
              type="checkbox"
              id="myCheckbox"
              aria-label="Check this box"
              checked={isChecked}
            />
            <span className="checkmark" onClick={handleCheckmarkClick} />
          </div>

          <div className="link-preview-info youtube">
            <div className="link-preview-details">
              <div className="preview-image">
                <div className="preview-link-image play">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path
                      d="M10.6386 18.9215H7.69333C6.36985 18.9215 5.10057 18.3519 4.16472 17.3381C3.22888 16.3243 2.70313 14.9492 2.70312 13.5154C2.70312 12.0817 3.22888 10.7066 4.16472 9.69277C5.10057 8.67894 6.36985 8.10938 7.69333 8.10938H10.5736M16.4569 8.10938H19.3372C20.6606 8.10938 21.9299 8.67894 22.8658 9.69277C23.8016 10.7066 24.3274 12.0817 24.3274 13.5154C24.3274 14.9492 23.8016 16.3243 22.8658 17.3381C21.9299 18.3519 20.6606 18.9215 19.3372 18.9215H16.3919M8.99651 13.5154H18.1379"
                      stroke="#999999"
                      strokeWidth="1.52045"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="details">
                <p className="details-link">
                  https://play.google.com/store/apps/details? is=
                  com.com.luckykat.panzerdogs
                </p>
                <span className="name">Play google.com</span>
              </div>
            </div>

            <div className="go-toLink">
              <a href="/">
                https://pay.google.com/store/apps/details?id=com.luckykat.pa...
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { Links };
