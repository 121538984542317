import { useState } from "react";

const Docs = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="docs-wrapper">
      <div className="docs">
        <div className="docs-preview">
          <div className="group-check">
            <input
              type="checkbox"
              id="myCheckbox"
              aria-label="Check this box"
              checked={isChecked}
            />
            <span className="checkmark" onClick={handleCheckmarkClick} />
          </div>

          <div className="group-preview-info" />
        </div>
      </div>

      <div className="docs">
        <div className="docs-preview">
          <div className="group-check">
            <input
              type="checkbox"
              id="myCheckbox"
              aria-label="Check this box"
              checked={isChecked}
            />
            <span className="checkmark" onClick={handleCheckmarkClick} />
          </div>

          <div className="group-preview-info" />
        </div>
      </div>

      <div className="docs">
        <div className="docs-preview">
          <div className="group-check">
            <input
              type="checkbox"
              id="myCheckbox"
              aria-label="Check this box"
              checked={isChecked}
            />
            <span className="checkmark" onClick={handleCheckmarkClick} />
          </div>

          <div className="group-preview-info" />
        </div>
      </div>

      <div className="docs">
        <div className="docs-preview">
          <div className="group-check">
            <input
              type="checkbox"
              id="myCheckbox"
              aria-label="Check this box"
              checked={isChecked}
            />
            <span className="checkmark" onClick={handleCheckmarkClick} />
          </div>

          <div className="group-preview-info" />
        </div>
      </div>

      <div className="docs">
        <div className="docs-preview">
          <div className="group-check">
            <input
              type="checkbox"
              id="myCheckbox"
              aria-label="Check this box"
              checked={isChecked}
            />
            <span className="checkmark" onClick={handleCheckmarkClick} />
          </div>

          <div className="group-preview-info" />
        </div>
      </div>

      <div className="docs">
        <div className="docs-preview">
          <div className="group-check">
            <input
              type="checkbox"
              id="myCheckbox"
              aria-label="Check this box"
              checked={isChecked}
            />
            <span className="checkmark" onClick={handleCheckmarkClick} />
          </div>

          <div className="group-preview-info" />
        </div>
      </div>

      <div className="docs">
        <div className="docs-preview">
          <div className="group-check">
            <input
              type="checkbox"
              id="myCheckbox"
              aria-label="Check this box"
              checked={isChecked}
            />
            <span className="checkmark" onClick={handleCheckmarkClick} />
          </div>

          <div className="group-preview-info" />
        </div>
      </div>
    </div>
  );
};
export { Docs };
