import React, { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";
import Play from "../../assets/image/SVG_Images/Play.svg";
import pause from "../../assets/image/SVG_Images/Pause.svg";
import muted from "../../assets/image/SVG_Images/muted.svg";
import dragVideo from "../../assets/image/SVG_Images/Drag.svg";
import volumes from "../../assets/image/SVG_Images/volume.svg";
import close from "../../assets/image/close-white.png";
import maxscreen from "../../assets/image/SVG_Images/fullscreen.svg";

function VideoPlayer({ video, poster, getPIPVideoId, onClose }) {
  const videoRef = useRef(null);
  const [volume, setVolume] = useState(1);
  const [duration, setDuration] = useState(0);
  const [videoMute, setVideoMute] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoRatio, setVideoRation] = useState(null);
  const [pipVideoPlay, setPipVideoPlay] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setPipVideoPlay(true);
      } else {
        videoRef.current.pause();
        setPipVideoPlay(false);
      }
    }
  };

  useEffect(() => {
    const handleLoadedMetadata = () => {
      setDuration(videoRef.current ? videoRef.current.duration : 0);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(videoRef.current ? videoRef.current.currentTime : 0);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
      videoRef.current.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
        videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [videoRef]);

  const handleSliderChange = (e) => {
    if (videoRef.current) {
      const newTime = e.target.value;
      videoRef.current.currentTime = newTime;
    }
  };

  const handleVolumeButtonClick = () => {
    if (videoRef.current) {
      if (videoRef.current.volume === 0) {
        // Set it to the previous volume
        videoRef.current.volume = volume;
      } else {
        // Mute the volume
        videoRef.current.volume = 0;
      }
    }
  };

  const handleFullScreenButtonClick = () => {
    if (videoRef.current) {
      if (!isFullScreen) {
        if (videoRef.current.requestFullscreen) {
          videoRef.current.requestFullscreen();
        } else if (videoRef.current.mozRequestFullScreen) {
          videoRef.current.mozRequestFullScreen();
        } else if (videoRef.current.webkitRequestFullscreen) {
          videoRef.current.webkitRequestFullscreen();
        }
        setIsFullScreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        setIsFullScreen(false);
      }
    }
  };

  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.error("Speech recognition is not supported in this browser");
      return;
    }

    const recognition = new SpeechRecognition();

    recognition.onresult = (event) => {
      const last = event.results.length - 1;
      const voiceInput = event.results[last][0].transcript.trim().toLowerCase();

      // Assuming the voice input is a number between 0 and 1
      const volumeValue = parseFloat(voiceInput);

      if (!isNaN(volumeValue) && volumeValue >= 0 && volumeValue <= 1) {
        setVolume(volumeValue);
      }
    };

    recognition.start();

    return () => {
      recognition.stop();
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      const videoWidth = videoRef?.current?.videoWidth;
      const videoHeight = videoRef?.current?.videoHeight;

      if (videoWidth > videoHeight) {
        setVideoRation("horizontal");
      } else if (videoHeight > videoWidth) {
        setVideoRation("vertical");
      }
    }
  }, [videoRef?.current?.videoHeight, videoRef?.current?.videoWidth]);

  return (
    <Draggable handle="#videoPlayerheader">
      <div
        id="videoPlayer"
        className={`videoPlayer ${
          videoRatio === "horizontal" && "horizontal"
        } ${videoRatio === "vertical" && "vertical"}`}
      >
        <div className="header">
          <div id="videoPlayerheader" className="dragable">
            <img src={dragVideo} alt="drag" />
          </div>
          <button className="btn close">
            <img
              src={close}
              alt="close"
              onClick={() => {
                onClose(false);
                getPIPVideoId(null);
              }}
            />
          </button>
        </div>
        <video
          ref={videoRef}
          src={video}
          poster={poster}
          autoPlay={true}
          alt=""
        />
        <div className="videoPlayer-cta">
          <button className="btn play" onClick={handlePlayButtonClick}>
            {pipVideoPlay ? (
              <img src={pause} alt="play" />
            ) : (
              <img src={Play} alt="pause" />
            )}
          </button>
          <div className="duration-slider">
            <input
              className="slider"
              type="range"
              min={0}
              max={duration}
              step="any"
              value={currentTime}
              onChange={handleSliderChange}
            />
          </div>
          <button className="btn volume" onClick={handleVolumeButtonClick}>
            {videoMute && (
              <input
                className="slider vol"
                type="range"
                min={0}
                max={1}
                step="any"
                value={volume}
                onChange={(e) => setVolume(e.target.value)}
              />
            )}
            <img
              src={videoMute ? volumes : muted}
              alt="volume"
              onClick={() => setVideoMute(!videoMute)}
            />
          </button>
          <button
            className="btn fullscreen"
            onClick={handleFullScreenButtonClick}
          >
            <img src={maxscreen} alt="fullscreen" />
          </button>
        </div>
      </div>
    </Draggable>
  );
}

export default VideoPlayer;
