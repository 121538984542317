// import React, { useRef, useEffect } from "react";

// const FocusTrapping = React.forwardRef(({ children, onClose }, ref) => {
//   const captureRef = useRef(null);

//   useEffect(() => {
//     const element = captureRef.current;
//     if (element) {
//       element.focus();
//     }
//     return () => {
//       captureRef.current = null; // Clear the reference on unmount
//     };
//   }, []);

//   const handleKeydown = (event) => {
//     if (event.key.toLowerCase() === "escape") {
//       onClose();
//       return;
//     }


//     if (event.key.toLowerCase() !== "tab") {
//       return;
//     }

//     const tabbableElements = captureRef.current.querySelectorAll(
//       'button, input, select, textarea, [href], [tabindex]:not([tabindex="-1"])'
//     );
//     const firstTabbable = tabbableElements[0];
//     const lastTabbable = tabbableElements[tabbableElements.length - 1];
//     const target = event.target;

//     if (event.shiftKey) {
//       if (target === captureRef.current || target === firstTabbable) {
//         event.preventDefault();
//         lastTabbable.focus();
//       }
      
//     } else {
//       if (target === lastTabbable) {
//         event.preventDefault();
//         firstTabbable.focus();
//       }
//     }
//   };

//   return React.cloneElement(children, {
//     ref: (node) => {
//       captureRef.current = node;
//       if (typeof ref === "function") {
//         ref(node);
//       } else if (ref) {
//         ref.current = node;
//       }
//     },
//     onKeyDown: handleKeydown,
//   });
// });

// export default FocusTrapping;




// import React, { useEffect, useRef } from 'react';

// const FocusTrapping = ({ children, onClose, focusElementRef }) => {
//   const containerRef = useRef(null);
//   const focusableElements = useRef([]);

//   useEffect(() => {
//     focusableElements.current = Array.from(
//       containerRef.current.querySelectorAll('button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])')
//     );
//     const initialElement = focusElementRef?.current || focusableElements.current[0];
//     if (initialElement) {
//       initialElement.focus(); // Set initial focus
//     }

//     const handleKeyDown = (event) => {
//       const { key, target } = event;

//       if (key.toLowerCase() === 'escape') {
//         onClose();
//         return;
//       }

//       if (key.toLowerCase() === 'enter') {
//         event.preventDefault();
//         if (document.activeElement && typeof document.activeElement.click === 'function') {
//           document.activeElement.click();
//         }
//         return;
//       }

//       if (key.toLowerCase() === 'tab') {
//         event.preventDefault();
//         const currentIndex = focusableElements.current.indexOf(document.activeElement);
//         if (event.shiftKey) {
//           // Shift + Tab
//           const prevIndex = (currentIndex - 1 + focusableElements.current.length) % focusableElements.current.length;
//           focusableElements.current[prevIndex].focus();
//         } else {
//           // Tab
//           const nextIndex = (currentIndex + 1) % focusableElements.current.length;
//           focusableElements.current[nextIndex].focus();
//         }
//       }

//       if (key.toLowerCase() === 'arrowdown') {
//         event.preventDefault();
//         const currentIndex = focusableElements.current.indexOf(document.activeElement);
//         const nextIndex = (currentIndex + 1) % focusableElements.current.length;
//         focusableElements.current[nextIndex].focus();
//       }

//       if (key.toLowerCase() === 'arrowup') {
//         event.preventDefault();
//         const currentIndex = focusableElements.current.indexOf(document.activeElement);
//         const prevIndex = (currentIndex - 1 + focusableElements.current.length) % focusableElements.current.length;
//         focusableElements.current[prevIndex].focus();
//       }
//     };

//     const container = containerRef.current;
//     container.addEventListener('keydown', handleKeyDown);

//     return () => {
//       container.removeEventListener('keydown', handleKeyDown);
//     };
//   }, [focusElementRef, onClose]);

//   return (
//     <div ref={containerRef} tabIndex="-1">
//       {children}
//     </div>
//   );
// };

// export default FocusTrapping;



import React, { useRef, useEffect, forwardRef } from 'react';

// Use forwardRef to allow refs to be passed down
const FocusTrapping = forwardRef(({ children, onClose }, ref) => {
  const containerRef = useRef(null);
  const focusableElements = useRef([]);

  useEffect(() => {
    // Populate focusable elements whenever the container changes
    focusableElements.current = Array.from(
      containerRef.current.querySelectorAll('button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])')
    );

    const initialElement = focusableElements.current[0];
    if (initialElement) {
      initialElement.focus(); // Set initial focus
    }

    const handleKeyDown = (event) => {
      const { key } = event;
      const currentIndex = focusableElements.current.indexOf(document.activeElement);

      if (key.toLowerCase() === 'escape') {
        onClose();
        return;
      }

      if (key.toLowerCase() === 'enter') {
        event.preventDefault();
        if (document.activeElement && typeof document.activeElement.click === 'function') {
          document.activeElement.click();
        }
        return;
      }

      if (key.toLowerCase() === 'tab') {
        event.preventDefault();
        if (event.shiftKey) {
          // Shift + Tab
          const prevIndex = (currentIndex - 1 + focusableElements.current.length) % focusableElements.current.length;
          focusableElements.current[prevIndex].focus();
        } else {
          // Tab
          const nextIndex = (currentIndex + 1) % focusableElements.current.length;
          focusableElements.current[nextIndex].focus();
        }
        return;
      }

      if (key.toLowerCase() === 'arrowdown') {
        event.preventDefault();
        const nextIndex = (currentIndex + 1) % focusableElements.current.length;
        focusableElements.current[nextIndex].focus();
        return;
      }

      if (key.toLowerCase() === 'arrowup') {
        event.preventDefault();
        const prevIndex = (currentIndex - 1 + focusableElements.current.length) % focusableElements.current.length;
        focusableElements.current[prevIndex].focus();
        return;
      }
    };

    const container = containerRef.current;
    container.addEventListener('keydown', handleKeyDown);

    return () => {
      container.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  // Use the ref forwarding to attach the ref to the container
  return React.cloneElement(children, {
    ref: (node) => {
      containerRef.current = node;
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    },
    tabIndex: -1, // Ensure the element is focusable
  });
});

export default FocusTrapping;

