import { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./Crop";

const EasyCrop = ({
  image,
  aspect,
  type,
  setCropImages,
  index,
  zooms,
  setZoomValue,
}) => {
  const [rotation, setRotation] = useState(0);
  const [reCrop, setReCrop] = useState(aspect);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      setCropImages(croppedImage, index);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image]);

  useEffect(() => {
    if (
      !isNaN(croppedAreaPixels?.width) &&
      !isNaN(croppedAreaPixels?.height) &&
      !isNaN(croppedAreaPixels?.x) &&
      !isNaN(croppedAreaPixels?.y)
    ) {
      showCroppedImage();
    }
    return () => {};
  }, [croppedAreaPixels]);

  // this time give for crop border set proper angle
  const handleAspectChange = (newAspect) => {
    var timer = setTimeout(() => {
      setReCrop(newAspect);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  };

  handleAspectChange(aspect);

  return (
    <div
      className="container"
      style={{
        display: image === null || croppedImage !== null ? "none" : "block",
      }}
    >
      <div className="crop-container">
        {type === "image" ? (
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zooms}
            zoomSpeed={1}
            maxZoom={3}
            zoomWithScroll={true}
            showGrid={true}
            // aspect={aspect}
            aspect={reCrop}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoomValue}
            onRotationChange={setRotation}
            objectFit={"contain"}
            cropShape={"rect"}
          />
        ) : type === "video" ? (
          <Cropper
            video={image}
            crop={crop}
            rotation={rotation}
            zoom={zooms}
            zoomSpeed={4}
            maxZoom={3}
            zoomWithScroll={true}
            showGrid={true}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoomValue}
            onRotationChange={setRotation}
          />
        ) : null}
      </div>
    </div>
  );
};

export default EasyCrop;
