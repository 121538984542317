import React, { useState } from "react";
import { getDeleteMessageType } from "../../Utils/helpers";
import {
  SENDER_TEXT,
  RECEIVER_TEXT,
  SENDER_CONTACT,
  SEND_MESSAGE_TYPE,
} from "../../Utils/enums";
import "../../assets/css/deleteMessage.scss";

function DeleteMessage({ message, setDeleteMessage, deleteMessage }) {
  const messageType = SEND_MESSAGE_TYPE.find((type) => message?.type === type);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const handleDescriptionClick = () => {
    setIsChecked(!isChecked);
  };

  const onDeleteForMe = () => {
    // setDeleteMessage(false);
    // realm.write(() => {
    //   realm.delete(message);
    // });
  };

  const onDeleteForEveryone = () => {
    const type = getDeleteMessageType(message);
    // realm.write(() => {
    //   message.type = type;
    // });
    setDeleteMessage(false);
    deleteMessage(message);
  };

  return (
    <div className="deleteMessage">
      <div className="dialog">
        <div className="dialog-info">
          <h4>Delete message?</h4>
        </div>
        {message?.type === SENDER_TEXT ||
        message?.type === SENDER_CONTACT ||
        message?.type === RECEIVER_TEXT ? (
          <></>
        ) : (
          <div className="dialog-info-wrapper">
            {/* <div className="group-check">
              <input
                type="radio"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div> */}

            <div className="group-check">
              <input
                type="radio"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />

              <div
                className="checkmark"
                role="radio"
                tabIndex="0"
                onClick={handleCheckmarkClick}
              >
                <div className="offRadio radio-cmn"></div>
                <div className="onRadio radio-cmn"></div>
              </div>
            </div>

            <p className="description" onClick={handleDescriptionClick}>
              Delete file from your phone
            </p>
          </div>
        )}
        <div className="cta">
          <button
            className="btn btn-cancel active"
            onClick={() => setDeleteMessage(false)}
          >
            Cancel
          </button>
          <button className="btn btn-for-me" onClick={() => onDeleteForMe()}>
            Delete for me
          </button>
          {message?.type === messageType && !message?.SenderDeleted && (
            <button
              className="btn btn-for-everyone"
              onClick={() => onDeleteForEveryone()}
            >
              Delete for everyone
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DeleteMessage;
