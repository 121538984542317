import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Sharemenu from "./Sharemenu";
import star from "../../assets/image/star.png";
import DeleteMessage from "../Group/DeleteMessage";
import { MessageAction } from "../Common/MessageAction";
import downArrow from "../../assets/image/downArrow.png";
import { getMessageStatus, getTimeFromDate } from "../../Utils/helpers";
import {
  MAP_UI,
  MAP_API,
  MAP_API_KEY,
  SENDER_LOCATION,
  SENDER_TEXT_REPLY,
  RECEIVER_LOCATION,
} from "../../Utils/enums";
import "../../assets/css/map.scss";
import "../../assets/css/location-alert.scss";

function Map({
  message,
  tapped,
  getIds,
  getSenderMessageType,
  forward,
  deleteMessage,
}) {
  const dropdownRef = useRef(null);
  const [mapImage, setMapImage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [deletedMessage, setDeletedMessage] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const toggleShareMenu = (event) => {
    event.preventDefault();
    const { right, top, left } = event.target.getBoundingClientRect();
    const isFromReceiver = message?.type === RECEIVER_LOCATION;
    const position = isFromReceiver
      ? { x: right, y: top }
      : { x: left, y: top };
    setMenuPosition(position);
    setIsShareMenuOpen(true);
    setScrollEnabled(false);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [scrollEnabled]);

  const closeDropdown = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event.target)) {
      setIsShareMenuOpen(false);
      setScrollEnabled(true);
    }
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isShareMenuOpen) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isShareMenuOpen]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  });

  useEffect(() => {
    const fetchMapImage = async () => {
      try {
        const URL = `${MAP_API}staticmap?center=${message?.Location?.latitude},${message?.location?.longitude}${MAP_UI}${message?.location?.latitude},${message?.location?.longitude}${MAP_API_KEY}`;
        const response = await axios.get(URL);
        setMapImage(response?.request?.responseURL);
      } catch (error) {
        console.error("Error fetching map image:", error);
      }
    };
    fetchMapImage();
  }, [message]);

  const openGoogleMaps = () => {
    const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${message?.location?.latitude},${message?.location?.longitude}`;
    window.open(googleMapsURL, "_blank");
  };

  const onDoubleTapped = () => {
    tapped(true);
    getIds({ userId: message.userId, messageId: message.messageId });
    getSenderMessageType(SENDER_TEXT_REPLY);
    handleClickOutside();
  };

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const onDelete = () => {
    setDeletedMessage(true);
  };

  const onStar = async () => {
    // Realm.write(() => {
    //   Realm.create(
    //     "RMessageModel",
    //     {
    //       _id: message._id,
    //       isStarMessage: !message?.isStarMessage,
    //     },
    //     "modified"
    //   );
    // });
    setIsShareMenuOpen(false);
    setScrollEnabled(true);
  };

  return (
    <>
      {message?.type === RECEIVER_LOCATION && (
        <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="mapRecieved">
              <div className="wrapper">
                <div className="map">
                  <img
                    className="map-thumbnail"
                    src={mapImage}
                    alt="Map Thumbnail"
                    onClick={openGoogleMaps}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="map-details">
                  <div className="detail">
                    {/* <p className="text">Live Location not available</p> */}
                    <p className="text">
                      {message?.location?.address}
                      <span className="space" />
                    </p>
                  </div>
                </div>
                <div className="message-status">
                  {message?.isStarMessage && (
                    <img className="important" src={star} alt="important" />
                  )}
                  <span className="date">{getTimeFromDate(message)}</span>
                </div>
                <div className="down-arrow">
                  <img
                    src={downArrow}
                    alt="down"
                    role="button"
                    onClick={toggleShareMenu}
                  />
                </div>
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {message?.type === SENDER_LOCATION && (
        <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="mapSend">
              <div className="wrapper">
                <div className="map">
                  <img
                    className="map-thumbnail"
                    src={mapImage}
                    alt="Map Thumbnail"
                    onClick={openGoogleMaps}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="map-details">
                  <div className="detail">
                    {/* <p className="text">Live Location not available</p> */}
                    <p className="text">
                      {message?.Location[0]?.address}
                      <span className="space" />
                    </p>
                  </div>
                </div>
                <div className="message-status">
                  {message?.isStarMessage && (
                    <img className="important" src={star} alt="important" />
                  )}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="map-status"
                    src={getMessageStatus(message)}
                    alt="img-status"
                  />
                </div>
                <div className="down-arrow">
                  <img
                    src={downArrow}
                    alt="down"
                    role="button"
                    onClick={toggleShareMenu}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {isShareMenuOpen && (
        <Sharemenu
          ref={dropdownRef}
          menuPosition={menuPosition}
          isFromReceiver={message?.type === RECEIVER_LOCATION}
          onReplyClick={onDoubleTapped}
          onDeleteClick={onDelete}
          onStarClick={onStar}
          message={message}
        />
      )}
      {deletedMessage && (
        <DeleteMessage
          message={message}
          setDeleteMessage={setDeletedMessage}
          deleteMessage={deleteMessage}
        />
      )}
      {/* map unnnamed*/}

      {/* <div className="mapRecieved unnamed">
        <div className="wrapper">
        <div className="map">
        <img className="map-thumbnail" src={mapImage} alt="Map Thumbnail" />
        </div>
        <div className="map-details">
            <div className="detail">
            <p className="name">Live location ended</p>
            <p className="location">Laxmi Inclave, Katargam</p>
            </div>
            </div>
            <div className="message-status">
            <span className="date">11:10 PM</span>
            </div>
            </div>
            <div className="share" role="button">
            <img src={share} alt="share" />
            </div>
          </div> */}

      {/* Without location name */}
      {/* <div className="mapSend unnamed"> */}
      {/* <div className="wrapper"> */}
      {/* <div className="map">
            <img className="map-thumbnail" src={mapImage} alt="Map Thumbnail" />
          </div> */}
      {/* <div className="map-details">
            <div className="down-arrow">
              {isHovered && (
                <img
                  src={downArrow}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              )} */}

      {/* <img src={downArrow} alt="down"/> */}
      {/* </div>
          </div> */}

      {/* <div className="message-status">
            <span className="date">11:10 PM</span>
          </div> */}
      {/* <div className="shadow-background"/> */}
      {/* <div className="down-arrow">
            {isHovered && (
              <img
                src={downArrow}
                alt="down"
                role="button"
                onClick={toggleShareMenu}
              />
            )} */}

      {/* <img src={downArrow} alt="down"/> */}
      {/* </div> */}
      {/* </div> */}
      {/* <div className="share" role="button">
          <img src={share} alt="share" />
        </div> */}
      {/* </div> */}
    </>
  );
}

// {/* Location Not Found */}
// function LocationAlert() {
//   return (
//     <>
//       <div className="notFound">
//         <div className="dialog">
//           <p className="description">
//             Live location is not available on this device. view location on your
//             phone.
//           </p>

//           <button className="btn ok">Ok</button>
//         </div>
//       </div>
//     </>
//   );
// }

export default Map;
