import React from "react";
import { SENDER_TEXT } from "../../../Utils/enums";
import { useDispatch } from "react-redux";
import { setChatBoxContact } from "../../../Redux/actions";
import {
  getGroupProfilePic,
  getMessageStatus,
  getTimetoDaytoDate,
  getUserProfilePic,
} from "../../../Utils/helpers";
import NetworkError from "../../Errors/NetworkError";
import BackgroundSyncMessage from "../../Errors/BackgroundSyncMessage";

function ChatContact({
  inboxContacts,
  setOpenChatContact,
  onCloseProfile,
  onCloseMediaLinkDocs,
}) {
  const dispatch = useDispatch();

  const newChatOpen = () => {
    setOpenChatContact(true);
  };

  const handleClick = (inbox) => {
    dispatch(setChatBoxContact(inbox));
    // Realm.write(() => {
    //   inbox.unreadCount = 0;
    // });
  };

  return (
    <div role="button" className="profiles">
      {!navigator.onLine && <NetworkError />}
      {inboxContacts && inboxContacts?.length > 0 ? (
        inboxContacts?.map((inbox, i) => {
          return (
            <>
              {inbox?.type === "user" ? (
                <div
                  key={i}
                  className="profile"
                  onClick={() => {
                    onCloseProfile(false);
                    onCloseMediaLinkDocs(false);
                  }}
                >
                  <div className="profile-image">
                    <img
                      className="profile-avatar"
                      src={getUserProfilePic(inbox?.contact?.user?.profilePic)}
                      alt="profile"
                    />
                  </div>
                  <div
                    className="profile-info"
                    onClick={() => handleClick(inbox)}
                  >
                    <div className="profile-info-name">
                      <p>
                        {inbox?.contact?.name
                          ? inbox?.contact?.name
                          : inbox?.contact?.countryCode +
                            " " +
                            inbox?.contact?.user?.phone}
                      </p>
                      <div className="status">
                        {inbox?.lastMessage?.type === SENDER_TEXT && (
                          <img
                            className="status_seen"
                            src={getMessageStatus(inbox?.lastMessage)}
                            alt="status_seen"
                          />
                        )}
                        <p className="profile-info-message">
                          {inbox?.lastMessage?.message ? (
                            inbox?.lastMessage?.message?.length > 7 ? (
                              `${inbox?.lastMessage?.message.substring(
                                0,
                                16
                              )}...`
                            ) : (
                              inbox?.lastMessage?.message
                            )
                          ) : (
                            <span />
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="profile-details">
                      <p className="profile-details-date">
                        {inbox?.lastMessage?.message
                          ? getTimetoDaytoDate(inbox?.lastMessageTimeStamp)
                          : ""}
                      </p>
                      <div className="cta">
                        {inbox?.unreadCount > 0 && (
                          <div className="notifications">
                            {inbox?.unreadCount}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  key={i}
                  className="profile"
                  onClick={() => {
                    onCloseProfile(false);
                    onCloseMediaLinkDocs(false);
                  }}
                >
                  <div className="profile-image">
                    <img
                      className="profile-avatar"
                      src={getGroupProfilePic(inbox?.group?.image)}
                      alt="profile"
                    />
                  </div>
                  <div
                    className="profile-info"
                    onClick={() => handleClick(inbox?.contact)}
                  >
                    <div className="profile-info-name">
                      <p>
                        {inbox?.group?.name
                          ? inbox?.group?.name
                          : inbox?.contact?.countryCode +
                            " " +
                            inbox?.contact?.user?.phone}
                      </p>
                      <div className="status">
                        {inbox?.lastMessage?.type === SENDER_TEXT && (
                          <img
                            className="status_seen"
                            src={getMessageStatus(inbox?.lastMessage)}
                            alt="status_seen"
                          />
                        )}
                        <p className="profile-info-message">
                          {inbox?.lastMessage?.message ? (
                            inbox?.lastMessage?.message?.length > 7 ? (
                              `${inbox?.lastMessage?.message.substring(
                                0,
                                16
                              )}...`
                            ) : (
                              inbox?.lastMessage?.message
                            )
                          ) : (
                            <span />
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="profile-details">
                      <p className="profile-details-date">
                        {inbox?.lastMessage?.message
                          ? getTimetoDaytoDate(inbox?.lastMessageTimeStamp)
                          : ""}
                      </p>
                      <div className="cta">
                        {inbox?.unreadCount > 0 && (
                          <div className="notifications">
                            {inbox?.unreadCount}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          );
        })
      ) : (
        // {inboxContacts && inboxContacts?.length > 0 ? (
        //   inboxContacts?.map((contact, i) => {
        //     const lastMessage = getLastMessage(contact);
        //     return (
        //       <div
        //         key={i}
        //         className="profile"
        //         onClick={() => {
        //           onCloseProfile(false);
        //           onCloseMediaLinkDocs(false);
        //         }}
        //       >
        //         <div className="profile-image">
        //           <img
        //             className="profile-avatar"
        //             src={getUserProfilePic(contact?.User?.profilePic)}
        //             alt="profile"
        //           />
        //         </div>
        //         <div
        //           className="profile-info"
        //           onClick={() => handleClick(contact)}
        //         >
        //           <div className="profile-info-name">
        //             <p>
        //               {contact?.Contact?.name
        //                 ? contact?.Contact?.name
        //                 : contact?.Contact?.countryCode +
        //                   " " +
        //                   contact?.User?.phone}
        //             </p>
        //             <div className="status">
        //               {lastMessage?.type === SENDER_TEXT && (
        //                 <img
        //                   className="status_seen"
        //                   src={getMessageStatus(lastMessage)}
        //                   alt="status_seen"
        //                 />
        //               )}
        //               <p className="profile-info-message">
        //                 {lastMessage?.message ? (
        //                   lastMessage?.message?.length > 7 ? (
        //                     `${lastMessage?.message.substring(0, 16)}...`
        //                   ) : (
        //                     lastMessage?.message
        //                   )
        //                 ) : (
        //                   <span />
        //                 )}
        //               </p>
        //             </div>
        //           </div>
        //           <div className="profile-details">
        //             <p className="profile-details-date">
        //               {lastMessage?.message
        //                 ? getTimetoDaytoDate(lastMessage?.timestamp)
        //                 : ""}
        //             </p>
        //             <div className="cta">
        //               {contact?.unreadCount > 0 && (
        //                 <div className="notifications">
        //                   {contact?.unreadCount}
        //                 </div>
        //               )}
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     );
        //   })
        <div className="empty">
          <div className="empty-message">
            <p>New Conversation</p>
          </div>
        </div>
        // <NetworkError />
        // <BackgroundSyncMessage />
      )}
      <div className="newChat" onClick={() => newChatOpen()}>
        <svg width="30" height="29" viewBox="0 0 30 29" fill="none">
          <title>New Chat</title>
          <g id="Frame 11" clipPath="url(#clip0_8217_717)">
            <path
              id="Vector 9"
              d="M18.0206 3.94727H6.83721C4.6573 3.94727 2.89014 5.71443 2.89014 7.89434V22.3669C2.89014 24.5468 4.6573 26.314 6.83721 26.314H21.3098C23.4897 26.314 25.2569 24.5468 25.2569 22.3669V12.7465"
              stroke="white"
              strokeWidth="1.97353"
              strokeLinecap="round"
            />
            <path
              id="Vector 10"
              d="M24.1255 2.8231C20.3201 6.36869 14.6977 11.637 12.9108 13.5882C12.4937 14.0436 12.1992 14.611 11.9209 15.1624L10.1592 18.6541C9.8873 19.193 10.4241 19.783 10.9863 19.563L14.8932 18.0342C15.3817 17.843 15.8272 17.5564 16.2037 17.1911L27.4274 6.29975C28.3747 5.38054 28.4009 3.86858 27.486 2.91711C26.5788 1.97359 25.0832 1.93081 24.1255 2.8231Z"
              stroke="white"
              strokeWidth="1.8913"
            />
          </g>
          <defs>
            <clipPath id="clip0_8217_717">
              <rect
                width="29.5"
                height="29"
                fill="white"
                transform="translate(0.258789)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>

      {/* <----------------Archived---------------> */}
      {/* <div
          className="archived-menu"
          tabIndex="0"
          role="button"
          aria-label="archived"
        >
          <div className="archived-image">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M3.4375 6.53125V17.5312C3.43886 17.9867 3.62038 18.4231 3.94241 18.7451C4.26445 19.0671 4.70083 19.2486 5.15625 19.25H16.8438C17.2992 19.2486 17.7356 19.0671 18.0576 18.7451C18.3796 18.4231 18.5611 17.9867 18.5625 17.5312V6.53125"
                stroke="#1590A1"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.7344 2.75H3.26562C2.60116 2.75 2.0625 3.28866 2.0625 3.95312V4.98438C2.0625 5.64884 2.60116 6.1875 3.26562 6.1875H18.7344C19.3988 6.1875 19.9375 5.64884 19.9375 4.98438V3.95312C19.9375 3.28866 19.3988 2.75 18.7344 2.75Z"
                stroke="#1590A1"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M13.75 13.0625L11 15.8125L8.25 13.0625M11 14.8625V9.625"
                stroke="#1590A1"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <span className="title">Archived</span>
        </div> */}
    </div>
  );
}

export default ChatContact;
