import React from "react";
import { useDispatch } from "react-redux";
import { setSelectedSetting } from "../../Redux/actions";
import "../../assets/css/keyboardShortcuts.scss";

function KeyboardShortcuts() {
  const dispatch = useDispatch();
  return (
    <div className="keyboardShortcuts">
      <div className="dialog">
        <div className="dialog-info-wrapper">
          <div className="dialog-heading">
            <h4>Keyboard Shortcuts</h4>
          </div>

          <div className="dialog-info">
            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">
                  Decrease Speed of Selected Voice Message
                </span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>

            <div className="keys-for-wrapper">
              <div className="keys-for">
                <span className="key-for-info">Mark as Unread</span>
              </div>

              <div className="key-names">
                <div className="key-name">Ctrl</div>
                <div className="key-name">Alt</div>
                <div className="key-name">Shift</div>
                <div className="key-name">U</div>
              </div>
            </div>
          </div>
        </div>

        <div className="dialog-cta">
          <button
            className="btn btn-ok"
            onClick={() => dispatch(setSelectedSetting(""))}
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default KeyboardShortcuts;
