import React, { useEffect, useState, useRef } from "react";
import Sharemenu from "./Sharemenu";
import play from "../../assets/image/play.png";
import DeleteMessage from "../Group/DeleteMessage";
import loading from "../../assets/video/loader.gif";
import fullscreen from "../../assets/image/pip.png";
import { MessageAction } from "../Common/MessageAction";
import downArrow from "../../assets/image/white-down.png";
import maxscreen from "../../assets/image/SVG_Images/fullscreen.svg";
import star from "../../assets/image/SVG_Images/white-star.svg";
import {
  SENDER_VIDEO,
  RECEIVER_VIDEO,
  SENDER_TEXT_REPLY,
} from "../../Utils/enums";
import {
  getTimeFromDate,
  getMessageStatus,
  convertSecondsToTimeFormat,
  TN_THUMB_VIDEO_IMAGE_BASE_URL,
  TN_CLOUD_FRONT_BUCKET_VIDEO_BASE_URL,
} from "../../Utils/helpers";
import "../../assets/css/video.scss";

function Videos({
  message,
  setShowViewMedia,
  setcloseViewMedia,
  tapped,
  getTappedMessage,
  showForward,
  setForwardMessage,
  handlePIP,
  getPIPVideo,
  getPIPVideoPoster,
  getPIPVideoId,
  pipId,
  getIds,
  getSenderMessageType,
  forward,
  deleteMessage,
}) {
  const playerRef = useRef(null);
  const dropdownRef = useRef(null);
  const downArrowRef = useRef(null);

  const [isChecked, setIsChecked] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [deletedMessage, setDeletedMessage] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const handleViewMedia = () => {
    setShowViewMedia({ show: true, url: message?.media?.mediaUrl });
    setcloseViewMedia(false);
  };

  const toggleShareMenu = (event) => {
    event.preventDefault();
    const arrowRect = downArrowRef.current.getBoundingClientRect();
    const { right, top, left } = arrowRect;
    const isReceiverText = message?.type === RECEIVER_VIDEO;
    const position = isReceiverText
      ? { x: left, y: top }
      : { x: right, y: top };
    setMenuPosition(position);
    setIsShareMenuOpen(true);
    setScrollEnabled(false);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [scrollEnabled]);

  const closeDropdown = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event.target)) {
      setIsShareMenuOpen(false);
      setScrollEnabled(true);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isShareMenuOpen) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isShareMenuOpen]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  }, []);
  //endHere

  const handleEnablePIP = () => {
    if (playerRef.current && document.pictureInPictureEnabled) {
      getPIPVideo(
        TN_CLOUD_FRONT_BUCKET_VIDEO_BASE_URL + message?.media?.mediaUrl
      );
      getPIPVideoPoster(
        TN_THUMB_VIDEO_IMAGE_BASE_URL + message?.media?.thumbUrl
      );
      getPIPVideoId(message.messageId);
      handlePIP(true);
    }
  };

  const onDoubleTapped = () => {
    tapped(true);
    getTappedMessage(message);
    getIds(message?._id);
    getSenderMessageType(SENDER_TEXT_REPLY);
    handleClickOutside();
  };

  const onForwardMessage = (message) => {
    showForward(true);
    setForwardMessage(message);
  };

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const onDelete = () => {
    setDeletedMessage(true);
  };

  const onStar = async () => {
    // Realm.write(() => {
    //   Realm.create(
    //     "RMessageModel",
    //     {
    //       _id: message._id,
    //       isStarMessage: !message?.isStarMessage,
    //     },
    //     "modified"
    //   );
    // });
    setIsShareMenuOpen(false);
    setScrollEnabled(true);
  };

  return (
    <>
      {message?.type === RECEIVER_VIDEO && (
        <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="videoRecieved">
              <div className="wrapper">
                <div className="send-video" onClick={() => handleViewMedia()}>
                  <video
                    ref={playerRef}
                    src={
                      TN_CLOUD_FRONT_BUCKET_VIDEO_BASE_URL +
                      message?.media?.mediaUrl
                    }
                    poster={
                      TN_THUMB_VIDEO_IMAGE_BASE_URL + message?.media?.thumbUrl
                    }
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </div>
                <div className="message-status">
                  {message?.isStarMessage && (
                    <img className="important" src={star} alt="important" />
                  )}
                  <span className="date">{getTimeFromDate(message)}</span>
                </div>
                <span className="duration">
                  {convertSecondsToTimeFormat(message?.media)}
                </span>
                {message.messageId !== pipId ? (
                  <div role="button" className="play">
                    <img src={play} alt="play" />
                  </div>
                ) : (
                  <div className="pipOverlay">
                    <div className="pipOverlay-container">
                      <button className="btn pips">
                        <img src={maxscreen} alt="fullscreen" />
                      </button>
                      <p className="pipOverlay-info">
                        This video is playing in picture in picture.
                      </p>
                    </div>
                  </div>
                )}
                <div className="shadow-background" />
                {message.messageId !== pipId && (
                  <div role="button" className="fullscreen">
                    <img
                      src={fullscreen}
                      alt="fullscreen"
                      onClick={handleEnablePIP}
                    />
                  </div>
                )}
                <div className="down-arrow">
                  <img
                    src={downArrow}
                    ref={downArrowRef}
                    alt="down"
                    role="button"
                    onClick={toggleShareMenu}
                  />
                </div>
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {message?.type === SENDER_VIDEO && (
        <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="videoSend">
              <div className="wrapper">
                <div className="send-video" onClick={() => handleViewMedia()}>
                  <video
                    ref={playerRef}
                    src={
                      TN_CLOUD_FRONT_BUCKET_VIDEO_BASE_URL +
                      message?.media?.mediaUrl
                    }
                    poster={
                      TN_THUMB_VIDEO_IMAGE_BASE_URL + message?.media?.thumbUrl
                    }
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </div>
                <div className="message-status">
                  {message?.isStarMessage && (
                    <img className="important" src={star} alt="important" />
                  )}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="video-status"
                    src={getMessageStatus(message)}
                    alt="seen"
                  />
                </div>
                <span className="duration">
                  {convertSecondsToTimeFormat(message?.media)}
                </span>
                {message.messageId !== pipId ? (
                  <div role="button" className="play">
                    <img src={play} alt="play" />
                  </div>
                ) : (
                  <div className="pipOverlay">
                    <div className="pipOverlay-container">
                      <button className="btn pips">
                        <img src={maxscreen} alt="fullscreen" />
                      </button>
                      <p className="pipOverlay-info">
                        This video is playing in picture in picture.
                      </p>
                    </div>
                  </div>
                )}
                <div className="shadow-background" />
                {message.messageId !== pipId && (
                  <div role="button" className="fullscreen">
                    <img
                      src={fullscreen}
                      alt="fullscreen"
                      onClick={handleEnablePIP}
                    />
                  </div>
                )}
                <div className="down-arrow">
                  <img
                    src={downArrow}
                    ref={downArrowRef}
                    alt="down"
                    role="button"
                    onClick={toggleShareMenu}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {isShareMenuOpen && (
        <Sharemenu
          ddref={dropdownRef}
          menuPosition={menuPosition}
          onReplyClick={onDoubleTapped}
          onDeleteClick={onDelete}
          onStarClick={onStar}
          message={message}
        />
      )}
      {deletedMessage && (
        <DeleteMessage
          message={message}
          setDeleteMessage={setDeletedMessage}
          deleteMessage={deleteMessage}
        />
      )}
    </>
  );
}

export default Videos;
