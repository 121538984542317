import React, { useEffect, useRef, useState } from "react";
import { pdfjs } from "react-pdf";
import AddMedia from "./AddMedia";
import MediaMenu from "./MediaMenu";
import FooterText from "./FooterText";
import { processImage } from "../../Utils/helpers";
import loading from "../../assets/video/loader.gif";
import docImg from "../../assets/image/document.png";
import { DOCUMENT, IMAGE, PDF, VIDEO } from "../../Utils/enums";
import { calculateFileSize, getMediaType } from "../../Utils/helpers";
import "../../assets/css/uploadMedia.scss";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function UploadMedia({ media, setPdfPagesCount, onClose, onSendMedia }) {
  const videoRef = useRef(null);
  const fileInputRef = useRef(null);
  const [numPages, setNumPages] = useState(0);
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [pdfPage, setPdfPage] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [imagesList, setImagesList] = useState(media);
  const [selectedImage, setSelectedImage] = useState(imagesList[0]);
  var blobImage = selectedImage ? URL.createObjectURL(selectedImage) : "";

  const img = new Image();
  img.src = blobImage;
  img.onload = () => {
    setImgHeight(img.height);
    setImgWidth(img.width);
  };

  useEffect(() => {
    const filetype = getMediaType(media);
    setFileType(filetype);
  }, [media]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelected = async (e) => {
    const selectedFiles = e.target.files;
    var singletype = getMediaType(selectedFiles);
    if (singletype === "Image") {
      if (selectedFiles?.length) {
        // if (selectedFiles?.length === 1) {
        //   var data = [...imagesList, selectedFiles[0]];
        //   const processedImages = await Promise.all(data.map(async (file) => await processImage(file)));
        //   setImagesList(processedImages);
        // } else {
        const images = Object.values(selectedFiles);
        const processedImages = await Promise.all(
          images.map(async (file) => await processImage(file))
        );
        const concatImages = [...imagesList, ...processedImages];
        setImagesList(concatImages);
      }
    } else {
      if (selectedFiles?.length) {
        // if (selectedFiles?.length === 1) {
        //   var data = [];
        //   data.push(...imagesList, selectedFiles[0]);
        //   setImagesList(data);
        // } else {
        var multipleData = [];
        const images = Object.values(selectedFiles);
        for (var i = 0; i < images.length; i++) {
          multipleData.push(images[i]);
        }
        var concatImages = [];
        concatImages.push(...imagesList, ...multipleData);
        setImagesList(concatImages);
      }
    }
  };

  const getPdfPageForPreview = () => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const url = e.target.result;
        const loadingTask = pdfjs.getDocument({ url });
        const pdf = await loadingTask.promise;
        setNumPages(pdf.numPages); // Set the total number of pages
        setPdfPagesCount(pdf.numPages);
        const pdfPage = await pdf.getPage(1);
        const scale = 1.5;
        const viewport = pdfPage.getViewport({ scale });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await pdfPage.render(renderContext).promise;
        setPdfPage(canvas.toDataURL("image/png"));
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  useEffect(() => {
    if (fileType && fileType[1] === PDF) {
      getPdfPageForPreview();
    }
  }, [fileType]);

  return (
    <>
      {!fileType ? (
        <div className="loader-wrapper">
          <div className="loader">
            <img src={loading} alt="" />
          </div>
        </div>
      ) : (
        <div className="uploadMedia">
          {/* Video Heading */}
          {fileType && fileType === VIDEO && (
            <>
              <div className="video-heading">
                <button
                  className="btn btn-close"
                  onClick={() => {
                    onClose(null);
                    setPdfPagesCount(0);
                  }}
                >
                  <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
                    <title>Close</title>
                    <g id="Frame 2595">
                      <path
                        id="Shape"
                        d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                        fill="#1891A2"
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <div className="uploadMedia-container">
                <div className="uploadedMedia-wrapper">
                  <div className="uploadedMedia">
                    <video
                      ref={videoRef}
                      src={blobImage}
                      controls
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {/* Image Heading */}
          {fileType && fileType === IMAGE && (
            <div className="uploadMedia-container">
              <div className="heading">
                <button
                  className="btn btn-close"
                  onClick={() => {
                    onClose(null);
                    setPdfPagesCount(0);
                  }}
                >
                  <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
                    <title>Close</title>
                    <g id="Frame 2595">
                      <path
                        id="Shape"
                        d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                        fill="#1891A2"
                      />
                    </g>
                  </svg>
                </button>
                <MediaMenu />
              </div>
              <div
                className="uploadedMedia-wrapper"
                style={{ overflow: "hidden" }}
              >
                <div
                  className={`uploadedMedia${
                    imgHeight < 450
                      ? "SmallHeight"
                      : imgWidth < 350
                      ? "SmallWidth"
                      : ""
                  } setImageInSection`}
                >
                  <img src={blobImage} alt="uploadedMedia" />
                </div>
              </div>
            </div>
          )}
          {/* Document Heading */}
          {fileType && fileType[0] === DOCUMENT && (
            <div className="uploadMedia-container">
              <div className="document-heading">
                <button
                  className="btn btn-close"
                  onClick={() => {
                    onClose(null);
                    setPdfPagesCount(0);
                  }}
                >
                  <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
                    <title>Close</title>
                    <g id="Frame 2595">
                      <path
                        id="Shape"
                        d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                        fill="#1891A2"
                      />
                    </g>
                  </svg>
                </button>
                {fileType && fileType[1] !== PDF && (
                  <div className="text">
                    <p className="name">{selectedImage?.name}</p>
                  </div>
                )}
                {fileType && fileType[1] === PDF && (
                  <div className="text">
                    <p className="name">{selectedImage?.name}</p>
                    <div className="details">
                      <span className="length">{numPages} pages</span>
                      <span className="dot" />
                      <span className="size">
                        {calculateFileSize(media[0]?.size)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`uploadedMedia-wrapper ${
                  fileType && fileType[1] === PDF ? "pdf" : "document"
                }`}
              >
                <div className="uploadedMedia">
                  {fileType && fileType[1] === PDF && (
                    <img alt="pdf" src={pdfPage} className="pdf" />
                  )}
                  {fileType && fileType[1] !== PDF && (
                    <img src={docImg} alt="uploadededMedia" />
                  )}
                </div>
                <div className="text">
                  {fileType && fileType[1] !== PDF && (
                    <>
                      <p className="name">No preview available</p>
                      <div className="details">
                        <span className="size">
                          {calculateFileSize(media[0]?.size)}
                        </span>
                        <span className="dot" />
                        <span className="length">
                          {fileType && fileType[1].toLocaleUpperCase()}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Footer */}
          <FooterText media={imagesList} onSendMedia={onSendMedia} />
          <div className="select-media">
            <AddMedia
              media={imagesList}
              getSelectedFileType={setFileType}
              updateMedia={setImagesList}
              getSelectedImage={setSelectedImage}
              pdfPage={pdfPage}
              setNumPages={setNumPages}
              setPdfPagesCount={setPdfPagesCount}
              onClose={onClose}
            />
            <button
              className="btn btn-upload"
              onClick={() => handleImageClick()}
            >
              <svg width="18" height="18" viewBox="0 0 26 26" fill="none">
                <path
                  d="M12.8463 0.73429L12.9993 0.726562C13.7742 0.726562 14.4116 1.3155 14.4882 2.07021L14.496 2.22324V11.5026H23.7753C24.5503 11.5026 25.1876 12.0916 25.2643 12.8463L25.272 12.9993C25.272 13.7742 24.6831 14.4116 23.9284 14.4882L23.7753 14.496H14.496V23.7753C14.496 24.5503 13.907 25.1876 13.1523 25.2643L12.9993 25.272C12.2244 25.272 11.587 24.6831 11.5103 23.9284L11.5026 23.7753V14.496H2.22324C1.44831 14.496 0.810934 13.907 0.73429 13.1523L0.726562 12.9993C0.726562 12.2244 1.3155 11.587 2.07021 11.5103L2.22324 11.5026H11.5026V2.22324C11.5026 1.44831 12.0916 0.810934 12.8463 0.73429L12.9993 0.726562L12.8463 0.73429Z"
                  fill="#1891A2"
                />
              </svg>

              <input
                accept="*"
                multiple
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelected}
              />
            </button>
          </div>

          {/* <div className="discard-popup">
        <div className="popup">
        <p className="text">Discard Your Changes?</p>
        <div className="cta-btn">
        <button className="btn cancel">Cancel</button>
            <button className="btn discard">Discard</button>
          </div>
        </div>
      </div> */}
        </div>
      )}
    </>
  );
}

export default UploadMedia;
