import {
  MESSAGE_TYPE,
  RECEIVER_TEXT,
  RECEIVER_TEXT_REPLY,
  SEND_MESSAGE_TYPE,
  SENDER_TEXT,
  SENDER_TEXT_REPLY,
} from "../../Utils/enums";
import { useSelector } from "react-redux";
import "../../assets/css/messageAction.scss";

const MessageAction = ({ message }) => {
  const messageType = SEND_MESSAGE_TYPE.find((type) => message?.type === type);
  const type = MESSAGE_TYPE.find((type) => message?.type === type);
  const theme = useSelector((state) => state.theme);

  return (
    <>
      <div
        className="messageGroup-cta"
        style={{
          flexDirection: message?.type === messageType && "row-reverse",
        }}
      >
        <div
          className="share"
          role="button"
          style={{
            display:
              (type === SENDER_TEXT ||
                type === RECEIVER_TEXT ||
                type === SENDER_TEXT_REPLY ||
                type === RECEIVER_TEXT_REPLY ||
                message?.contact?.length > 1) &&
              "none",
          }}
          onClick={() => alert("share")}
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
            <path
              d="M14.2088 4.00462L19.8856 8.7996C20.0503 8.93882 20.1819 9.10817 20.272 9.2969C20.3622 9.48563 20.4089 9.68963 20.4092 9.89595C20.4094 10.1023 20.3633 10.3064 20.2737 10.4954C20.1841 10.6844 20.053 10.8541 19.8886 10.9937L14.2253 15.8053C13.1932 16.682 11.5258 16.007 11.524 14.7112L11.5205 12.1412C7.49344 12.1468 4.68489 19.5936 4.68489 19.5936C4.68489 19.5936 3.46021 7.6867 11.5142 7.67548L11.5106 5.10471C11.5088 3.80965 13.1743 3.13077 14.2088 4.00462Z"
              fill={
                theme === "Light"
                  ? "#0c0c0c"
                  : theme === "Dark"
                  ? "#ffffff"
                  : window.matchMedia("(prefers-color-scheme: light)").matches
                  ? "#0c0c0c"
                  : "#ffffff"
              }
            />
          </svg>
        </div>
        <div
          className="btn-reactToMessage"
          role="button"
          onClick={() => alert("emoji")}
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
            <g id="mingcute:emoji-fill" clipPath="url(#clip0_9122_2368)">
              <g id="Group">
                <path
                  id="Vector"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM14.8 13.857C14.0532 14.5912 13.0473 15.0018 12 15C10.9527 15.0018 9.94682 14.5912 9.2 13.857C9.1067 13.7629 8.99563 13.6882 8.87323 13.6374C8.75084 13.5866 8.61957 13.5606 8.48704 13.561C8.35452 13.5614 8.22339 13.5881 8.10128 13.6396C7.97916 13.6911 7.8685 13.7663 7.77573 13.8609C7.68295 13.9556 7.60991 14.0677 7.56085 14.1908C7.51179 14.3139 7.48769 14.4455 7.48995 14.578C7.4922 14.7105 7.52078 14.8413 7.57401 14.9626C7.62723 15.084 7.70405 15.1936 7.8 15.285C8.92064 16.3858 10.4292 17.0018 12 17C13.5708 17.0018 15.0794 16.3858 16.2 15.285C16.385 15.0984 16.4891 14.8465 16.4898 14.5838C16.4905 14.321 16.3878 14.0685 16.2039 13.8809C16.0199 13.6933 15.7695 13.5856 15.5068 13.5811C15.2441 13.5767 14.9902 13.6758 14.8 13.857ZM8.5 8C8.10218 8 7.72064 8.15804 7.43934 8.43934C7.15804 8.72064 7 9.10218 7 9.5C7 9.89782 7.15804 10.2794 7.43934 10.5607C7.72064 10.842 8.10218 11 8.5 11C8.89782 11 9.27936 10.842 9.56066 10.5607C9.84196 10.2794 10 9.89782 10 9.5C10 9.10218 9.84196 8.72064 9.56066 8.43934C9.27936 8.15804 8.89782 8 8.5 8ZM15.5 8C15.1022 8 14.7206 8.15804 14.4393 8.43934C14.158 8.72064 14 9.10218 14 9.5C14 9.89782 14.158 10.2794 14.4393 10.5607C14.7206 10.842 15.1022 11 15.5 11C15.8978 11 16.2794 10.842 16.5607 10.5607C16.842 10.2794 17 9.89782 17 9.5C17 9.10218 16.842 8.72064 16.5607 8.43934C16.2794 8.15804 15.8978 8 15.5 8Z"
                  fill={
                    theme === "Light"
                      ? "#0c0c0c"
                      : theme === "Dark"
                      ? "#ffffff"
                      : window.matchMedia("(prefers-color-scheme: light)")
                          .matches
                      ? "#0c0c0c"
                      : "#ffffff"
                  }
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_9122_2368">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="btn-deleteMessage" role="button">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
            <g id="ic:round-error">
              <path
                id="Vector"
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 13C11.45 13 11 12.55 11 12V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V12C13 12.55 12.55 13 12 13ZM13 17H11V15H13V17Z"
                fill="#E72915"
              />
            </g>
          </svg>
        </div>
      </div>
    </>
  );
};

export { MessageAction };
