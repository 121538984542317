import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { IMAGE, VIDEO } from "../../Utils/enums";
import { getTepars } from "../../Utils/helpers";
import { useSwiperSlider } from "../../Utils/helpers";
import SliderPopupVideo from "./SliderPopupVideo";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "29px" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: 0, zIndex: 9 }}
      onClick={onClick}
    />
  );
}

function SliderPopup({
  sliderWrapperRef,
  post,
  imagesList,
  mediaIndexFinal,
  setMediaIndexFinal,
}) {
  const sliderRef = useRef(null);

  const toggleViewOtherProfile = useSelector(
    (state) => state.toggleViewOtherProfile
  );

  const [isLoading, setIsLoading] = useState(true);
  const [activeDimensions, setActiveDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isPaused, setIsPaused] = useState({});

  useEffect(() => {
    if (toggleViewOtherProfile) {
      pauseAllVideos();
    } else {
      playCurrentVideo();
    }
  }, [toggleViewOtherProfile]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        pauseAllVideos();
      } else {
        playCurrentVideo();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [mediaIndexFinal]);

  useEffect(() => {
    if (imagesList && imagesList.length > 0) {
      updateDimensions(imagesList[mediaIndexFinal], mediaIndexFinal);
    }
  }, [imagesList, mediaIndexFinal]);

  const updateDimensions = (media, index) => {
    const mediaUrl = getTepars(media);
    if (media.type === VIDEO) {
      const video = document.getElementById(`${media._id}-${index}`);
      if (video) {
        setActiveDimensions({
          width: video.videoWidth,
          height: video.videoHeight,
        });
      } else {
        const tempVideo = document.createElement("video");
        tempVideo.src = mediaUrl;
        tempVideo.onloadedmetadata = () => {
          setActiveDimensions({
            width: tempVideo.videoWidth,
            height: tempVideo.videoHeight,
          });
        };
      }
    } else {
      const img = new Image();
      img.src = mediaUrl;
      img.onload = () => {
        setActiveDimensions({ width: img.width, height: img.height });
      };
    }
  };

  const handleSlideChange = (currentSlide) => {
    pauseAllVideos();
    setMediaIndexFinal(currentSlide);
    updateDimensions(imagesList[currentSlide], currentSlide);
    playCurrentVideo(currentSlide);
  };

  const pauseAllVideos = () => {
    imagesList.forEach((media, index) => {
      if (media.type === VIDEO) {
        const video = document.getElementById(`${media._id}-${index}`);
        if (video) {
          video.pause();
        }
      }
    });
  };

  const playCurrentVideo = (currentSlide = mediaIndexFinal) => {
    const currentMedia = imagesList[currentSlide];
    if (currentMedia?.type === VIDEO) {
      const video = document.getElementById(
        `${currentMedia._id}-${currentSlide}`
      );
      if (video) {
        video.play();
      }
    }
  };

  const handlePlayPauseToggle = (mediaId, index) => {
    const video = document.getElementById(`${mediaId}-${index}`);
    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const handleLoadedMetadata = (event, media, index) => {
    setActiveDimensions({
      width: event.target.videoWidth,
      height: event.target.videoHeight,
    });
  };

  const handleLoadedData = () => {
    setIsLoading(false);
  };

  const getObjectFit = () => {
    var allVideo = post?.media?.every((item) => item?.type === "Video")
      ? VIDEO
      : IMAGE;
    var allImage = post?.media?.every((item) => item?.type === "Image")
      ? IMAGE
      : VIDEO;
    if (post.media.length === 1 && imagesList[0].type === VIDEO) {
      return activeDimensions.height > activeDimensions.width
        ? "cover"
        : "contain";
    } else if (post.media.length === 1 && imagesList[0].type === IMAGE) {
      return activeDimensions.height > activeDimensions.width
        ? "cover"
        : "contain";
    } else if (post.media.length > 1 && allVideo === VIDEO) {
      return activeDimensions.height > activeDimensions.width
        ? "cover"
        : "contain";
    } else if (post.media.length > 1 && allImage === IMAGE) {
      return "contain";
    } else {
      return activeDimensions.height > activeDimensions.width
        ? "cover"
        : "contain";
    }
  };

  useSwiperSlider(sliderRef, sliderWrapperRef, imagesList?.length);

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  return (
    <Slider
      ref={sliderRef}
      dots={true}
      infinite={false}
      speed={500}
      slidesToShow={1}
      nextArrow={<SampleNextArrow />}
      prevArrow={<SamplePrevArrow />}
      swipe={false}
      afterChange={handleSlideChange}
    >
      {imagesList?.map((media, index) => (
        <div
          key={index}
          className={media.type === IMAGE ? "next-img" : "upload-image"}
        >
          {media.type === IMAGE ? (
            <>
              <div
                className="upload-image"
                style={{
                  backgroundImage: `url(${getTepars(media)})`,
                  transition: "all 150ms ease-in-out",
                  backgroundSize: "contain",
                }}
              />
            </>
          ) : media.type === VIDEO ? (
            <div style={{ position: "relative" }}>
              <SliderPopupVideo
                index={index}
                mediaIndexFinal={mediaIndexFinal}
                media={media}
                handleLoadedMetadata={handleLoadedMetadata}
                handlePlayPauseToggle={handlePlayPauseToggle}
                disableRightClick={disableRightClick}
                setIsPaused={setIsPaused}
              />

              {isPaused[media._id] && index === mediaIndexFinal && (
                <div
                  className="video"
                  onClick={() => handlePlayPauseToggle(media._id, index)}
                  title="Play"
                >
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <title>Play</title>
                    <path
                      d="M1.83399 11.0104L1.83398 17.8482C1.83398 19.6325 3.97888 20.7407 5.69234 19.8541L12.2873 16.4297L18.8822 13.0053C20.5957 12.1187 20.5957 9.89112 18.8822 8.99345L12.2873 5.56901L5.69234 2.14456C3.97889 1.25798 1.83399 2.36621 1.83399 4.15047L1.83399 10.9883L1.83399 11.0104Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
          ) : null}
        </div>
      ))}
    </Slider>
  );
}

export default SliderPopup;
