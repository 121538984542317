import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getSelfUser,
  getCommonGroupList,
  getUserProfilePic,
  getGroupMemberList,
} from "../../Utils/helpers";
import "../../assets/css/groupInfo.scss";

function GroupInfo({ onClose, openMediaLinksDocs, userProfileData }) {
  const inboxContacts = useSelector((state) => state.inboxContacts);

  const [isMounted, setIsMounted] = useState(true);
  const [groupMemberList, setGroupMemberList] = useState([]);
  const [displayedMembers, setDisplayedMembers] = useState(5);
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [selectedUserProfileData, setSelectedUserProfileData] =
    useState(userProfileData);

  useEffect(() => {
    const fetchData = async () => {
      const membersList =
        selectedUserProfileData?.type === "group"
          ? getGroupMemberList(
              selectedUserProfileData?.groupMembersList,
              selectedUserProfileData?.groupMemberContacts,
              selectedUserProfileData?.groupMemberuserList
            )
          : getCommonGroupList(inboxContacts, selectedUserProfileData?._id);

      setGroupMemberList(membersList);
    };

    fetchData();
  }, [selectedUserProfileData, inboxContacts]);

  const handleProfileClick = (seletedMember) => {
    setSelectedUserProfileData(seletedMember);
  };

  const handleViewOtherClick = () => {
    setDisplayedMembers(groupMemberList.length);
  };

  const setAdmin =
    groupMemberList?.length > 0 ? groupMemberList[0].isAdmin : false;
  const toggleReadMore = () => {
    setShowFullMessage((prevState) => !prevState);
  };

  const isDescriptionLong = (description) => {
    const words = description.split(/\s+/);
    return words.length > 25;
  };

  const handleMediaClick = () => {
    openMediaLinksDocs(true);
    onClose(false);
  };

  useEffect(() => {
    const element = document.querySelector(".groupInfo");

    const handleAnimationEnd = () => {
      if (!isMounted) {
        onClose(false);
      }
    };

    if (isMounted) {
      element.classList.add("open");
      element.classList.remove("close");
      element.removeEventListener("animationend", handleAnimationEnd);
    } else {
      element.classList.add("close");
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      element.classList.remove("open", "close");
      element.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [isMounted, onClose]);

  return (
    <div className="groupInfo">
      <header className="header">
        <div className="main-cta">
          <div
            className="btn btn-back"
            role="button"
            aria-label="back"
            tabIndex="0"
            onClick={() => onClose(false)}
          >
            <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
              <title>Back</title>
              <g id="Group 18522">
                <g id="Group 18522_2">
                  <path
                    id="Vector"
                    d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div
            className="btn btn-settings"
            role="button"
            aria-label="settings"
            tabIndex="0"
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
              <title>Profile settings</title>
              <path
                id="Vector"
                d="M9.41612 0.255132C11.1197 -0.0842338 12.8787 -0.0850509 14.5826 0.252733C14.6961 0.964524 14.977 1.64286 15.4045 2.23732C15.8319 2.83178 16.3949 3.32708 17.0516 3.68639C17.7077 4.04695 18.441 4.26234 19.1968 4.31656C19.9527 4.37077 20.7117 4.26241 21.4174 3.99952C22.5771 5.23293 23.4555 6.68099 23.9975 8.25261C23.4056 8.702 22.928 9.27233 22.6 9.9212C22.272 10.5701 22.1022 11.2808 22.1031 12.0006C22.1031 13.5171 22.8432 14.8692 24 15.7486C23.4548 17.3189 22.5753 18.7658 21.4161 19.9993C20.7106 19.7366 19.9518 19.6283 19.1962 19.6825C18.4406 19.7367 17.7076 19.952 17.0516 20.3124C16.3954 20.6716 15.8329 21.1666 15.4057 21.7606C14.9785 22.3546 14.6976 23.0324 14.5839 23.7437C12.8805 24.0838 11.1215 24.0855 9.41738 23.7485C9.30441 23.036 9.02375 22.357 8.59628 21.7619C8.16881 21.1667 7.60555 20.6709 6.94843 20.3112C6.29219 19.9508 5.55887 19.7357 4.80302 19.6816C4.04716 19.6276 3.28821 19.7362 2.58261 19.9993C1.42277 18.7655 0.544313 17.317 0.0025259 15.745C0.594056 15.2958 1.07151 14.7259 1.39945 14.0775C1.7274 13.4291 1.89741 12.7188 1.89686 11.9994C1.89748 11.2794 1.72727 10.5686 1.39886 9.91967C1.07046 9.27078 0.592326 8.70057 0 8.25141C0.545151 6.68109 1.42469 5.23418 2.58388 4.00072C3.28939 4.26342 4.04816 4.37168 4.80378 4.31747C5.5594 4.26326 6.29244 4.04797 6.94843 3.68759C7.60456 3.32839 8.1671 2.83341 8.59431 2.23939C9.02151 1.64538 9.3024 0.966389 9.41612 0.255132ZM12 15.5998C13.0048 15.5998 13.9685 15.2206 14.679 14.5456C15.3895 13.8706 15.7887 12.9552 15.7887 12.0006C15.7887 11.046 15.3895 10.1305 14.679 9.45556C13.9685 8.78058 13.0048 8.40137 12 8.40137C10.9952 8.40137 10.0315 8.78058 9.321 9.45556C8.61049 10.1305 8.21132 11.046 8.21132 12.0006C8.21132 12.9552 8.61049 13.8706 9.321 14.5456C10.0315 15.2206 10.9952 15.5998 12 15.5998Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <div className="group-image">
          <img
            src={getUserProfilePic(selectedUserProfileData?.profilePic)}
            alt="groupImg"
          />
        </div>

        <div className="group-info">
          <span className="name">
            {getSelfUser(selectedUserProfileData) ?? "not found"}
          </span>
          <p className="status">
            {selectedUserProfileData?.groupDescription &&
            isDescriptionLong(selectedUserProfileData?.groupDescription) ? (
              <>
                {showFullMessage
                  ? selectedUserProfileData.groupDescription
                  : selectedUserProfileData.groupDescription.slice(0, 25) || ""}
                <span onClick={toggleReadMore} className="more">
                  {showFullMessage ? "See Less" : "  ...See More"}
                </span>
              </>
            ) : (
              selectedUserProfileData?.groupDescription ||
              selectedUserProfileData?.phone ||
              "not found"
            )}
          </p>
          <div className="sub-cta">
            <div className="call" tabIndex="0">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <g id="Frame" clipPath="url(#clip0_7387_18304)">
                  <path
                    id="Vector"
                    d="M28 21.8933V26.608C28.0002 26.9456 27.8723 27.2706 27.6421 27.5176C27.412 27.7646 27.0967 27.915 26.76 27.9387C26.1773 27.9787 25.7013 28 25.3333 28C13.5507 28 4 18.4493 4 6.66667C4 6.29867 4.02 5.82267 4.06133 5.24C4.08496 4.90326 4.23544 4.58801 4.4824 4.35788C4.72937 4.12774 5.05443 3.99985 5.392 4H10.1067C10.2721 3.99983 10.4316 4.06115 10.5543 4.17203C10.677 4.28291 10.7541 4.43544 10.7707 4.6C10.8013 4.90667 10.8293 5.15067 10.856 5.336C11.121 7.18523 11.664 8.98378 12.4667 10.6707C12.5933 10.9373 12.5107 11.256 12.2707 11.4267L9.39333 13.4827C11.1526 17.5819 14.4194 20.8487 18.5187 22.608L20.572 19.736C20.6559 19.6187 20.7784 19.5345 20.918 19.4982C21.0576 19.4619 21.2055 19.4757 21.336 19.5373C23.0227 20.3385 24.8208 20.8802 26.6693 21.144C26.8547 21.1707 27.0987 21.2 27.4027 21.2293C27.567 21.2462 27.7192 21.3234 27.8298 21.4461C27.9404 21.5688 28.0002 21.7282 28 21.8933Z"
                    fill="white"
                  />
                  <path
                    id="Vector_2"
                    d="M20 4.80078V8.80078H16V11.4674H20V15.4674H22.6667V11.4674H26.6667V8.80078H22.6667V4.80078H20Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7387_18304">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <span>Call</span>
            </div>

            <div className="unmute" tabIndex="0">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <g id="wpf:mute">
                  <path
                    id="Vector"
                    d="M4.31237 2.46138C4.08714 2.50214 3.87773 2.6049 3.70768 2.75812C3.53764 2.91134 3.41369 3.10894 3.34976 3.32873C3.28583 3.54851 3.28446 3.78177 3.3458 4.00229C3.40713 4.22281 3.52875 4.42186 3.69699 4.57707L28.3124 29.1925C28.421 29.3264 28.5565 29.4361 28.7102 29.5143C28.8639 29.5925 29.0323 29.6375 29.2046 29.6465C29.3768 29.6554 29.549 29.6281 29.71 29.5662C29.8709 29.5043 30.0171 29.4093 30.139 29.2873C30.2609 29.1653 30.3558 29.0191 30.4176 28.8581C30.4793 28.6971 30.5065 28.5249 30.4975 28.3526C30.4884 28.1804 30.4433 28.012 30.3649 27.8584C30.2866 27.7047 30.1769 27.5693 30.0428 27.4608L20.9278 18.3469V4.07615C20.9278 2.51922 19.5678 2.12907 18.5044 3.19245L12.081 9.50015L5.42868 2.84538C5.30164 2.71007 5.14564 2.60524 4.97236 2.53874C4.79908 2.47224 4.61301 2.44579 4.42806 2.46138C4.38952 2.45956 4.35091 2.45956 4.31237 2.46138ZM6.15852 11.1543C5.48037 11.1543 4.92776 11.7057 4.92776 12.3851V19.7697C4.92776 20.4466 5.48037 21.0005 6.15852 21.0005H10.3899L18.4662 28.8835C19.697 30.1143 20.9278 29.4841 20.9278 27.8066V24.6521L7.42745 11.1543H6.15852Z"
                    fill="white"
                  />
                </g>
              </svg>

              <span>Unmute</span>
            </div>

            <div className="search" tabIndex="0">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <g id="Frame" clipPath="url(#clip0_7387_18312)">
                  <path
                    id="Vector"
                    d="M24.0426 22.1534L29.7533 27.8627L27.8666 29.7494L22.1573 24.0387C20.033 25.7417 17.3906 26.6679 14.668 26.6641C8.04397 26.6641 2.66797 21.2881 2.66797 14.6641C2.66797 8.04006 8.04397 2.66406 14.668 2.66406C21.292 2.66406 26.668 8.04006 26.668 14.6641C26.6718 17.3867 25.7456 20.029 24.0426 22.1534ZM21.368 21.1641C23.0601 19.4239 24.0051 17.0913 24.0013 14.6641C24.0013 9.50673 19.824 5.33073 14.668 5.33073C9.51064 5.33073 5.33464 9.50673 5.33464 14.6641C5.33464 19.8201 9.51064 23.9974 14.668 23.9974C17.0952 24.0012 19.4278 23.0562 21.168 21.3641L21.368 21.1641Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7387_18312">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <span>Search</span>
            </div>
          </div>
        </div>
      </header>

      <div className="group-description">
        <p className="description">
          Which is better? Whichever makes the code easier to{" "}
          <span className="learnMore">...See More</span>
        </p>
      </div>

      <div className="group-links">
        {/* group */}
        {setAdmin && (
          <>
            <div className="group-chat-settings">
              <div className="settings">
                <div className="icon">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <rect width="30" height="30" rx="5" fill="#1891A2" />
                    <g clipPath="url(#clip0_7387_18349)">
                      <path
                        d="M24.259 8.00619C24.259 11.1922 24.259 14.3692 24.259 17.5552C24.2501 17.5732 24.2325 17.5822 24.2325 17.6002C24.0028 18.7072 23.2521 19.3282 22.1394 19.3282C18.2005 19.3282 14.2616 19.3282 10.3227 19.3282C10.1726 19.3282 10.0224 19.3282 9.87229 19.3012C8.85666 19.1122 8.20312 18.2842 8.20312 17.1862C8.20312 14.2612 8.20312 11.3272 8.20312 8.40219C8.20313 7.12419 9.05979 6.24219 10.3139 6.24219C11.7799 6.24219 13.246 6.24219 14.712 6.24219C17.2113 6.24219 19.7019 6.24219 22.2012 6.24219C23.1197 6.24219 23.8792 6.79119 24.1618 7.66419C24.1971 7.77219 24.2236 7.88919 24.259 8.00619ZM22.6074 14.0452C22.6251 14.0362 22.6339 14.0362 22.6516 14.0272C22.6516 13.9732 22.6516 13.9102 22.6516 13.8562C22.6516 12.0382 22.6516 10.2292 22.6516 8.41119C22.6516 8.00619 22.528 7.88019 22.1305 7.88019C18.2005 7.88019 14.2704 7.88019 10.3315 7.88019C9.92528 7.88019 9.80164 8.00619 9.80164 8.41119C9.80164 10.8682 9.80164 13.3162 9.80164 15.7732C9.80164 15.8362 9.78398 15.8992 9.82814 15.9712C9.88113 15.9172 9.93412 15.8722 9.97827 15.8272C10.6406 15.1522 11.303 14.4772 11.9565 13.8112C12.5924 13.1722 13.4226 13.1632 14.0585 13.8112C14.3146 14.0722 14.5795 14.3242 14.8268 14.5942C14.9416 14.7112 14.9946 14.7292 15.1094 14.5852C16.0367 13.4332 16.9729 12.2902 17.909 11.1562C18.5449 10.3912 19.5076 10.3822 20.1523 11.1382C20.585 11.6422 21.0001 12.1462 21.424 12.6502C21.8303 13.1182 22.2189 13.5772 22.6074 14.0452Z"
                        fill="white"
                      />
                      <path
                        d="M6.99262 12.0793C6.99262 12.1513 6.99262 12.2143 6.99262 12.2863C6.99262 13.9963 6.98379 15.6973 6.99262 17.4073C7.00146 18.6313 7.53135 19.5763 8.58231 20.1883C9.05922 20.4583 9.57145 20.5573 10.119 20.5573C13.8724 20.5573 17.6347 20.5573 21.3881 20.5573C21.6531 20.5573 21.6531 20.5573 21.5736 20.8183C21.3528 21.5203 21.1408 22.2313 20.92 22.9333C20.6198 23.9053 19.6836 24.4453 18.7033 24.1753C14.5878 23.0593 10.4634 21.9343 6.34792 20.8003C5.29696 20.5123 4.7494 19.4773 5.05851 18.4243C5.66789 16.3633 6.27727 14.2933 6.87781 12.2323C6.92197 12.1693 6.93963 12.1243 6.9573 12.0703C6.96613 12.0703 6.98379 12.0703 6.99262 12.0793Z"
                        fill="white"
                      />
                      <path
                        d="M12.2178 11.9623C11.3347 11.9623 10.6105 11.2423 10.6016 10.3423C10.5928 9.44231 11.3258 8.69531 12.209 8.69531C13.0921 8.69531 13.8075 9.43331 13.8163 10.3333C13.8252 11.2243 13.101 11.9623 12.2178 11.9623Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7387_18349">
                        <rect
                          width="19.8"
                          height="18"
                          fill="white"
                          transform="translate(5 6.24219)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <p className="group-setting-chat">Group Chat Settings</p>
              </div>
              <div className="more">
                <svg width="7" height="13" viewBox="0 0 9 15" fill="none">
                  <title>More info</title>
                  <g id="Group 18424">
                    <path
                      id="Vector"
                      d="M8.99998 7.5C8.99998 7.67937 8.96562 7.85336 8.89692 8.02197C8.82822 8.19058 8.73662 8.33049 8.62211 8.4417L2.30152 14.63C2.04961 14.8767 1.729 15 1.33969 15C0.950378 15 0.629769 14.8767 0.377861 14.63C0.125953 14.3834 -4.1454e-08 14.0695 -5.84351e-08 13.6883C-7.54163e-08 13.3072 0.125953 12.9933 0.377861 12.7466L5.73663 7.5L0.377861 2.25336C0.125953 2.00673 -5.92842e-07 1.69283 -6.09823e-07 1.31166C-6.26804e-07 0.930494 0.125953 0.616594 0.37786 0.369958C0.629768 0.123321 0.950378 -4.07599e-08 1.33969 -5.74567e-08C1.729 -7.41535e-08 2.04961 0.12332 2.30152 0.369958L8.62211 6.5583C8.75952 6.69283 8.85708 6.83857 8.91479 6.99552C8.9725 7.15247 9.00089 7.32063 8.99998 7.5Z"
                      fill="#C5C5C5"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </>
        )}

        {/* media */}
        <div className="group-media-settings">
          <div className="wrapper" onClick={handleMediaClick}>
            <div className="media">
              <div className="icon">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <rect width="30" height="30" rx="5" fill="#1891A2" />
                  <g clipPath="url(#clip0_7387_18349)">
                    <path
                      d="M24.259 8.00619C24.259 11.1922 24.259 14.3692 24.259 17.5552C24.2501 17.5732 24.2325 17.5822 24.2325 17.6002C24.0028 18.7072 23.2521 19.3282 22.1394 19.3282C18.2005 19.3282 14.2616 19.3282 10.3227 19.3282C10.1726 19.3282 10.0224 19.3282 9.87229 19.3012C8.85666 19.1122 8.20312 18.2842 8.20312 17.1862C8.20312 14.2612 8.20312 11.3272 8.20312 8.40219C8.20313 7.12419 9.05979 6.24219 10.3139 6.24219C11.7799 6.24219 13.246 6.24219 14.712 6.24219C17.2113 6.24219 19.7019 6.24219 22.2012 6.24219C23.1197 6.24219 23.8792 6.79119 24.1618 7.66419C24.1971 7.77219 24.2236 7.88919 24.259 8.00619ZM22.6074 14.0452C22.6251 14.0362 22.6339 14.0362 22.6516 14.0272C22.6516 13.9732 22.6516 13.9102 22.6516 13.8562C22.6516 12.0382 22.6516 10.2292 22.6516 8.41119C22.6516 8.00619 22.528 7.88019 22.1305 7.88019C18.2005 7.88019 14.2704 7.88019 10.3315 7.88019C9.92528 7.88019 9.80164 8.00619 9.80164 8.41119C9.80164 10.8682 9.80164 13.3162 9.80164 15.7732C9.80164 15.8362 9.78398 15.8992 9.82814 15.9712C9.88113 15.9172 9.93412 15.8722 9.97827 15.8272C10.6406 15.1522 11.303 14.4772 11.9565 13.8112C12.5924 13.1722 13.4226 13.1632 14.0585 13.8112C14.3146 14.0722 14.5795 14.3242 14.8268 14.5942C14.9416 14.7112 14.9946 14.7292 15.1094 14.5852C16.0367 13.4332 16.9729 12.2902 17.909 11.1562C18.5449 10.3912 19.5076 10.3822 20.1523 11.1382C20.585 11.6422 21.0001 12.1462 21.424 12.6502C21.8303 13.1182 22.2189 13.5772 22.6074 14.0452Z"
                      fill="white"
                    />
                    <path
                      d="M6.99262 12.0793C6.99262 12.1513 6.99262 12.2143 6.99262 12.2863C6.99262 13.9963 6.98379 15.6973 6.99262 17.4073C7.00146 18.6313 7.53135 19.5763 8.58231 20.1883C9.05922 20.4583 9.57145 20.5573 10.119 20.5573C13.8724 20.5573 17.6347 20.5573 21.3881 20.5573C21.6531 20.5573 21.6531 20.5573 21.5736 20.8183C21.3528 21.5203 21.1408 22.2313 20.92 22.9333C20.6198 23.9053 19.6836 24.4453 18.7033 24.1753C14.5878 23.0593 10.4634 21.9343 6.34792 20.8003C5.29696 20.5123 4.7494 19.4773 5.05851 18.4243C5.66789 16.3633 6.27727 14.2933 6.87781 12.2323C6.92197 12.1693 6.93963 12.1243 6.9573 12.0703C6.96613 12.0703 6.98379 12.0703 6.99262 12.0793Z"
                      fill="white"
                    />
                    <path
                      d="M12.2178 11.9623C11.3347 11.9623 10.6105 11.2423 10.6016 10.3423C10.5928 9.44231 11.3258 8.69531 12.209 8.69531C13.0921 8.69531 13.8075 9.43331 13.8163 10.3333C13.8252 11.2243 13.101 11.9623 12.2178 11.9623Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7387_18349">
                      <rect
                        width="19.8"
                        height="18"
                        fill="white"
                        transform="translate(5 6.24219)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p className="group-setting-chat">Media, Links, and Docs</p>
            </div>
            <div className="more">
              <svg width="7" height="13" viewBox="0 0 9 15" fill="none">
                <title>More info</title>
                <g id="Group 18424">
                  <path
                    id="Vector"
                    d="M8.99998 7.5C8.99998 7.67937 8.96562 7.85336 8.89692 8.02197C8.82822 8.19058 8.73662 8.33049 8.62211 8.4417L2.30152 14.63C2.04961 14.8767 1.729 15 1.33969 15C0.950378 15 0.629769 14.8767 0.377861 14.63C0.125953 14.3834 -4.1454e-08 14.0695 -5.84351e-08 13.6883C-7.54163e-08 13.3072 0.125953 12.9933 0.377861 12.7466L5.73663 7.5L0.377861 2.25336C0.125953 2.00673 -5.92842e-07 1.69283 -6.09823e-07 1.31166C-6.26804e-07 0.930494 0.125953 0.616594 0.37786 0.369958C0.629768 0.123321 0.950378 -4.07599e-08 1.33969 -5.74567e-08C1.729 -7.41535e-08 2.04961 0.12332 2.30152 0.369958L8.62211 6.5583C8.75952 6.69283 8.85708 6.83857 8.91479 6.99552C8.9725 7.15247 9.00089 7.32063 8.99998 7.5Z"
                    fill="#C5C5C5"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div className="media-content">
            <div className="icon" />
          </div>
        </div>
      </div>

      {/* Members */}
      {selectedUserProfileData && groupMemberList.length > 0 ? (
        <div className="members">
          <div className="members-number">
            {selectedUserProfileData?.type === "group" ? (
              <span className="number">{`${selectedUserProfileData?.groupMemberuserList?.length} Participants`}</span>
            ) : (
              <span className="number">{`${groupMemberList.length} Groups in Common`}</span>
            )}
            <div className="search-member">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <title>Search</title>
                <path
                  id="Vector"
                  d="M14.2049 12.952L18 16.7462L16.7462 18L12.952 14.2049C11.5402 15.3366 9.78419 15.9522 7.9748 15.9496C3.57271 15.9496 0 12.3769 0 7.9748C0 3.57271 3.57271 0 7.9748 0C12.3769 0 15.9496 3.57271 15.9496 7.9748C15.9522 9.78419 15.3366 11.5402 14.2049 12.952ZM12.4274 12.2945C13.5519 11.138 14.18 9.58786 14.1774 7.9748C14.1774 4.54741 11.4013 1.77218 7.9748 1.77218C4.54741 1.77218 1.77218 4.54741 1.77218 7.9748C1.77218 11.4013 4.54741 14.1774 7.9748 14.1774C9.58786 14.18 11.138 13.5519 12.2945 12.4274L12.4274 12.2945Z"
                  fill="#999999"
                />
              </svg>
            </div>
          </div>

          <div className="members-info">
            {setAdmin && (
              <>
                {/* Add Participants */}
                <div className="add-participants">
                  <div className="add-image">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
                      <title>Add Participants</title>
                      <path
                        d="M10.2868 0.00655855L10.4167 0C11.0744 0 11.6154 0.499873 11.6804 1.14044L11.687 1.27033V9.14634H19.563C20.2207 9.14634 20.7617 9.64621 20.8268 10.2868L20.8333 10.4167C20.8333 11.0744 20.3335 11.6154 19.6929 11.6804L19.563 11.687H11.687V19.563C11.687 20.2207 11.1871 20.7617 10.5466 20.8268L10.4167 20.8333C9.75893 20.8333 9.21795 20.3335 9.1529 19.6929L9.14634 19.563V11.687H1.27033C0.612593 11.687 0.0716119 11.1871 0.00655855 10.5466L0 10.4167C0 9.75893 0.499873 9.21795 1.14044 9.1529L1.27033 9.14634H9.14634V1.27033C9.14634 0.612593 9.64621 0.0716119 10.2868 0.00655855L10.4167 0L10.2868 0.00655855Z"
                        fill="#F0F0F0"
                      />
                    </svg>
                  </div>
                  <div className="participant-text">
                    <span className="text">Add Participants</span>
                  </div>
                </div>

                {/* Invite via Link */}
                <div className="invite-link">
                  <div className="link-image">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <title>Invite via Link</title>
                      <g id="Frame" clipPath="url(#clip0_7387_18397)">
                        <path
                          id="Vector"
                          d="M18.3 16.2776L16.7893 14.7649L18.3 13.2542C18.7996 12.759 19.1964 12.17 19.4677 11.521C19.7391 10.872 19.8795 10.1757 19.8811 9.47229C19.8826 8.76885 19.7452 8.07202 19.4767 7.42183C19.2082 6.77163 18.814 6.18087 18.3165 5.68345C17.8191 5.18604 17.2284 4.79177 16.5782 4.52329C15.928 4.2548 15.2312 4.11738 14.5277 4.11892C13.8243 4.12047 13.128 4.26093 12.479 4.53226C11.83 4.8036 11.241 5.20045 10.7458 5.70003L9.23512 7.21173L7.72343 5.7011L9.23619 4.19047C10.6387 2.78794 12.541 2 14.5245 2C16.5079 2 18.4102 2.78794 19.8127 4.19047C21.2153 5.59301 22.0032 7.49526 22.0032 9.47874C22.0032 11.4622 21.2153 13.3645 19.8127 14.767L18.3 16.2776ZM15.2787 19.2989L13.767 20.8095C12.3645 22.2121 10.4622 23 8.47874 23C6.49526 23 4.59301 22.2121 3.19047 20.8095C1.78794 19.407 1 17.5047 1 15.5213C1 13.5378 1.78794 11.6355 3.19047 10.233L4.70217 8.72236L6.2128 10.2351L4.70217 11.7458C4.20258 12.241 3.80573 12.83 3.5344 13.479C3.26307 14.128 3.1226 14.8243 3.12106 15.5277C3.11952 16.2312 3.25694 16.928 3.52542 17.5782C3.79391 18.2284 4.18818 18.8191 4.68559 19.3165C5.18301 19.814 5.77377 20.2082 6.42396 20.4767C7.07416 20.7452 7.77098 20.8826 8.47443 20.8811C9.17788 20.8795 9.87409 20.7391 10.5231 20.4677C11.1721 20.1964 11.7612 19.7996 12.2564 19.3L13.767 17.7893L15.2787 19.2989ZM14.5223 7.96704L16.034 9.47874L8.47981 17.0319L6.96811 15.5213L14.5223 7.96704Z"
                          fill="#F0F0F0"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7387_18397">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="link-text">
                    <span className="text">Invite via Link</span>
                  </div>
                </div>

                {/* Pending Requests */}
                <div className="pending-request">
                  <div className="pending-image">
                    <svg width="25" height="22" viewBox="0 0 25 22" fill="none">
                      <path
                        d="M10.5145 11.2737C10.927 10.3674 11.5139 9.55117 12.2419 8.87172C12.9698 8.19227 13.8245 7.66288 14.7571 7.3138C15.6896 6.96471 16.6818 6.80277 17.677 6.83721C18.6722 6.87165 19.6508 7.10181 20.557 7.51453C21.4634 7.92698 22.2796 8.51394 22.959 9.24188C23.6385 9.96983 24.1679 10.8245 24.5169 11.7571C24.866 12.6896 25.028 13.6818 24.9935 14.677C24.9591 15.6722 24.7289 16.6508 24.3162 17.557C23.7156 18.8831 22.7449 20.0076 21.5208 20.7955C20.2967 21.5834 18.8711 22.0012 17.4154 21.9987C14.4904 21.9987 11.8145 20.3087 10.5579 17.6654H0.0820312V15.4987C0.147031 14.2637 0.992031 13.2562 2.61703 12.4437C4.24203 11.6312 6.2787 11.2087 8.7487 11.1654C9.3662 11.1654 9.9512 11.2195 10.5145 11.2737ZM8.7487 0.332031C9.96203 0.364531 10.9804 0.787031 11.7929 1.59953C12.6054 2.41203 13.0062 3.43036 13.0062 4.66536C13.0062 5.90037 12.6054 6.9187 11.7929 7.7312C10.9804 8.5437 9.96203 8.94453 8.7487 8.94453C7.53537 8.94453 6.51703 8.5437 5.70453 7.7312C4.89203 6.9187 4.4912 5.90037 4.4912 4.66536C4.4912 3.43036 4.89203 2.41203 5.70453 1.59953C6.51703 0.787031 7.53537 0.364531 8.7487 0.332031ZM17.4154 19.832C18.852 19.832 20.2297 19.2614 21.2455 18.2455C22.2614 17.2297 22.832 15.852 22.832 14.4154C22.832 12.9788 22.2614 11.601 21.2455 10.5852C20.2297 9.56938 18.852 8.9987 17.4154 8.9987C15.9788 8.9987 14.601 9.56938 13.5852 10.5852C12.5694 11.601 11.9987 12.9788 11.9987 14.4154C11.9987 15.852 12.5694 17.2297 13.5852 18.2455C14.601 19.2614 15.9788 19.832 17.4154 19.832ZM16.332 11.1654H17.957V14.2204L20.6004 15.7479L19.7879 17.1562L16.332 15.1629V11.1654Z"
                        fill="#617782"
                      />
                    </svg>
                  </div>
                  <div className="pending-text">
                    <span className="text">Pending Requests</span>
                    {/* <div className="pending-number"><span>01</span></div> */}
                  </div>
                </div>
              </>
            )}

            {/* Add Participants */}

            {/* Profiles */}
            {groupMemberList
              .slice(0, displayedMembers)
              .map((members, index) => {
                return (
                  <>
                    <div
                      className="profiles"
                      key={members.id}
                      onClick={() => handleProfileClick(members)}
                    >
                      <div className="profile">
                        <div className="profile-image">
                          <img
                            className="profile-avatar"
                            src={getUserProfilePic(members?.profilePic)}
                            alt="Profile Avatar"
                          />
                        </div>
                        <div className="profile-info">
                          <div className="profile-info-name">
                            {selectedUserProfileData?.type === "group" ? (
                              <p>
                                {members?.contactname
                                  ? members?.contactname
                                  : members?.countryCode + members?.phone}
                              </p>
                            ) : (
                              <p>{members?.name}</p>
                            )}
                            <div className="status">
                              {/* <p className="profile-info-message">
                      I've arrived at the office...
                    </p> */}
                            </div>
                          </div>
                          <div className="profile-details">
                            {members?.isAdmin && (
                              <div className="profile-details-status">
                                <span>Admin</span>
                              </div>
                            )}
                            {selectedUserProfileData?.type === "group" && (
                              <div className="profile-number">
                                {members?.status === "ADD" ? (
                                  <span className="number">
                                    {members?.countryCode + members?.phone}
                                  </span>
                                ) : (
                                  <span className="number">
                                    {"~" + members?.name}
                                  </span>
                                )}
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <title>Options</title>
                                  <g id="material-symbols:keyboard-arrow-down-rounded">
                                    <path
                                      id="Vector"
                                      d="M6.99922 10.5008C6.84855 10.5008 6.70239 10.4714 6.56076 10.4126C6.41913 10.3538 6.3016 10.2754 6.20819 10.1775L1.00998 4.76986C0.802806 4.55434 0.699219 4.28004 0.699219 3.94696C0.699219 3.61388 0.802806 3.33958 1.00998 3.12406C1.21716 2.90854 1.48083 2.80078 1.80101 2.80078C2.12119 2.80078 2.38487 2.90854 2.59204 3.12406L6.99922 7.70879L11.4064 3.12406C11.6136 2.90854 11.8772 2.80078 12.1974 2.80078C12.5176 2.80078 12.7813 2.90854 12.9885 3.12406C13.1956 3.33958 13.2992 3.61388 13.2992 3.94696C13.2992 4.28004 13.1956 4.55434 12.9885 4.76986L7.79025 10.1775C7.67725 10.295 7.55482 10.3785 7.42299 10.4279C7.29115 10.4773 7.14989 10.5015 6.99922 10.5008Z"
                                      fill="#838383"
                                    />
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

            {/* Others */}
            {displayedMembers < groupMemberList?.length && (
              <span
                className="other-members"
                onClick={() => handleViewOtherClick()}
              >
                View Other ({groupMemberList?.length - displayedMembers})
              </span>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* encryption */}
      <div className="encryption">
        <div className="encryption-image">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
            <rect width="30" height="30" rx="5.35714" fill="#1891A2" />
            <g clipPath="url(#clip0_7387_18501)">
              <path
                d="M13.8461 8.4375L15.3045 9.89583H21.5651C21.7585 9.89583 21.944 9.97266 22.0807 10.1094C22.2174 10.2461 22.2943 10.4316 22.2943 10.625V20.8333C22.2943 21.0267 22.2174 21.2122 22.0807 21.3489C21.944 21.4857 21.7585 21.5625 21.5651 21.5625H8.4401C8.24672 21.5625 8.06125 21.4857 7.92451 21.3489C7.78776 21.2122 7.71094 21.0267 7.71094 20.8333V9.16667C7.71094 8.97328 7.78776 8.78781 7.92451 8.65107C8.06125 8.51432 8.24672 8.4375 8.4401 8.4375H13.8461ZM15.0026 12.8125C14.6816 12.8125 14.3695 12.9184 14.1149 13.1139C13.8602 13.3093 13.6771 13.5833 13.594 13.8934C13.5109 14.2035 13.5325 14.5323 13.6553 14.8289C13.7782 15.1255 13.9954 15.3732 14.2734 15.5338V18.6458H15.7318L15.7325 15.5338C16.0106 15.3732 16.2279 15.1254 16.3507 14.8287C16.4735 14.5321 16.495 14.2032 16.4118 13.893C16.3286 13.5829 16.1454 13.3089 15.8906 13.1135C15.6358 12.9182 15.3237 12.8124 15.0026 12.8125Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_7387_18501">
                <rect
                  width="17.5"
                  height="17.5"
                  fill="white"
                  transform="translate(6.25391 6.24609)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div className="encryption-details">
          <div className="info">
            <span className="info-title">Encryption</span>
            <p className="info-subtitle">
              Messages and calls are end-to-end encrypted.
            </p>
            <span className="info-more">Tap to learn more.</span>
          </div>

          <div className="cta">
            <span>off</span>
            <svg width="7" height="13" viewBox="0 0 9 15" fill="none">
              <title>More info</title>
              <g id="Group 18424">
                <path
                  id="Vector"
                  d="M8.99998 7.5C8.99998 7.67937 8.96562 7.85336 8.89692 8.02197C8.82822 8.19058 8.73662 8.33049 8.62211 8.4417L2.30152 14.63C2.04961 14.8767 1.729 15 1.33969 15C0.950378 15 0.629769 14.8767 0.377861 14.63C0.125953 14.3834 -4.1454e-08 14.0695 -5.84351e-08 13.6883C-7.54163e-08 13.3072 0.125953 12.9933 0.377861 12.7466L5.73663 7.5L0.377861 2.25336C0.125953 2.00673 -5.92842e-07 1.69283 -6.09823e-07 1.31166C-6.26804e-07 0.930494 0.125953 0.616594 0.37786 0.369958C0.629768 0.123321 0.950378 -4.07599e-08 1.33969 -5.74567e-08C1.729 -7.41535e-08 2.04961 0.12332 2.30152 0.369958L8.62211 6.5583C8.75952 6.69283 8.85708 6.83857 8.91479 6.99552C8.9725 7.15247 9.00089 7.32063 8.99998 7.5Z"
                  fill="#C5C5C5"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>

      {/* report exit */}
      <div className="group-cta">
        {/* Report Group */}
        <div className="report">
          <div className="report-image">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
              <g id="mdi:thumb-down">
                <path
                  id="Vector"
                  d="M17.418 13.75H21.0846V2.75H17.418M13.7513 2.75H5.5013C4.74047 2.75 4.08964 3.20833 3.81464 3.86833L1.0463 10.3308C0.963802 10.5417 0.917969 10.7617 0.917969 11V12.8333C0.917969 13.3196 1.11112 13.7859 1.45494 14.1297C1.79876 14.4735 2.26507 14.6667 2.7513 14.6667H8.53547L7.66463 18.8558C7.6463 18.9475 7.63714 19.0392 7.63714 19.14C7.63714 19.525 7.79297 19.8642 8.04047 20.1117L9.01213 21.0833L15.0438 15.0425C15.383 14.7125 15.5846 14.2542 15.5846 13.75V4.58333C15.5846 4.0971 15.3915 3.63079 15.0477 3.28697C14.7038 2.94315 14.2375 2.75 13.7513 2.75Z"
                  fill="#E72915"
                />
              </g>
            </svg>
          </div>
          <span className="text">Report Group</span>
        </div>

        {/* Exit Group */}
        <div className="exit">
          <div className="exit-image">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
              <g id="ion:exit">
                <path
                  id="Vector"
                  d="M14.4375 16.1562V11.6875H8.20703C8.02469 11.6875 7.84983 11.6151 7.7209 11.4861C7.59196 11.3572 7.51953 11.1823 7.51953 11C7.51953 10.8177 7.59196 10.6428 7.7209 10.5139C7.84983 10.3849 8.02469 10.3125 8.20703 10.3125H14.4375V5.84375C14.4368 5.20578 14.1831 4.59414 13.732 4.14303C13.2809 3.69192 12.6692 3.43818 12.0312 3.4375H3.78125C3.14328 3.43818 2.53164 3.69192 2.08053 4.14303C1.62942 4.59414 1.37568 5.20578 1.375 5.84375V16.1562C1.37568 16.7942 1.62942 17.4059 2.08053 17.857C2.53164 18.3081 3.14328 18.5618 3.78125 18.5625H12.0312C12.6692 18.5618 13.2809 18.3081 13.732 17.857C14.1831 17.4059 14.4368 16.7942 14.4375 16.1562ZM18.2776 11.6875L16.014 13.9515C15.8905 14.0815 15.8227 14.2546 15.825 14.4339C15.8273 14.6131 15.8995 14.7844 16.0263 14.9112C16.1531 15.038 16.3244 15.1102 16.5036 15.1125C16.6829 15.1148 16.856 15.047 16.986 14.9235L20.4235 11.486C20.5523 11.3571 20.6247 11.1823 20.6247 11C20.6247 10.8177 20.5523 10.6429 20.4235 10.514L16.986 7.07652C16.856 6.95303 16.6829 6.88521 16.5036 6.8875C16.3244 6.8898 16.1531 6.96203 16.0263 7.08881C15.8995 7.21559 15.8273 7.38687 15.825 7.56614C15.8227 7.74542 15.8905 7.9185 16.014 8.04848L18.2776 10.3125H14.4375V11.6875H18.2776Z"
                  fill="#E72915"
                />
              </g>
            </svg>
          </div>
          <span className="text">Exit Group</span>
        </div>
      </div>

      {/* group creation */}
      <div className="group-creation-info">
        <span className="name">Created by You</span>
        <span className="date">Created 30/04/2023</span>
      </div>
    </div>
  );
}

export default GroupInfo;
