import { React, useState, useEffect, useRef } from "react";
import NoMediaYet from "./NoMediaYet";
import Spinner from "../Common/Spinner";
import SettingsMenu from "./SettingsMenu";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import { getGridTepars } from "../../Utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import ArchiveDelete from "./ArchiveDelete";
import FocusTrapping from "./FocusTrapping";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  setTepars,
  handleToast,
  setSelectedSetting,
  setAnimationDirection,
  setTeparsPage,
  setTeparsLoad,
  setTeparsTotalCount,
} from "../../Redux/actions";
import "../../assets/css/archiveSettings.scss";

function ArchiveSettings({ setSelectedMenu, handlePostSelect }) {
  const dispatch = useDispatch();

  const dropdownRef = useRef(null);
  const archiveContainerRef = useRef(null);

  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const teparsPage = useSelector((state) => state.teparsPage);
  const totalCount = useSelector((state) => state.totalCount);
  const teparsLoad = useSelector((state) => state.teparsLoad);

  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [settingMenu, setSettingMenu] = useState(false);
  const [selectedTepars, setSelectedTepars] = useState([]);
  const [deleteArchive, setDeleteArchive] = useState(false);
  const [settingMenuOption, setSettingMenuOption] = useState("select");
  const [imageErrors, setImageErrors] = useState({});
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  const handleImageError = (index) => {
    setImageErrors((prev) => ({ ...prev, [index]: true }));
  };

  const handleBackButton = () => {
    dispatch(setAnimationDirection("right"));
    setIsMounted(false);
  };

  useEffect(() => {
    const element = document.querySelector(".archiveSettings");

    const handleAnimationEnd = () => {
      if (!isMounted) {
        dispatch(setSelectedSetting(""));
      }
    };

    if (isMounted) {
      element.classList.add("open");
      element.classList.remove("close");
      element.removeEventListener("animationend", handleAnimationEnd);
    } else {
      element.classList.add("close");
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      element.classList.remove("open", "close");
      element.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [isMounted]);

  const getArchiveTepars = (pageNumber) => {
    if (allDataLoaded) return;
    setLoading(true);
    if (pageNumber === 1) {
      dispatch(setTeparsLoad(true));
    }
    axiosRequest
      .get(`post/archive?page=${pageNumber}`)
      .then((res) => {
        if (res?.data?.data?.length) {
          let updatedArchivedTepars = [...Tepars, ...res?.data?.data];
          if (pageNumber === 1) {
            dispatch(setTepars(res.data.data));
          } else {
            dispatch(setTepars(updatedArchivedTepars));
          }
          dispatch(setTeparsPage(pageNumber + 1));
        } else {
          if (pageNumber === 1) {
            dispatch(setTepars([]));
          } else {
            setAllDataLoaded(true);
          }
        }
        setLoading(false);
        dispatch(setTeparsLoad(false));
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
        dispatch(setTeparsLoad(false));
      });
  };

  // useEffect(() => {
  //   getArchiveTepars(1);
  // }, []);

  const handleSelectMedia = (e, tepars) => {
    e.stopPropagation();
    if (selectedTepars.includes(tepars)) {
      setSelectedTepars(selectedTepars.filter((item) => item !== tepars));
      if (selectedTepars?.length === 1) {
        setSettingMenuOption("select");
      }
    } else {
      setSelectedTepars([...selectedTepars, tepars]);
    }
  };

  const handleUnarchiveOrDelete = (type) => {
    const data = {
      type: type,
      postId: selectedTepars,
    };

    axiosRequest
      .post(`post/functionality`, data)
      .then((res) => {
        const archivedTepars = Tepars.filter(
          (tepars) => !res?.data?.data.some((item) => item._id === tepars._id)
        );
        dispatch(setTepars(archivedTepars));
        dispatch(setTeparsTotalCount(totalCount - 1));
        setSettingMenu(false);
        setSelectedMenu("profile");
        setSettingMenuOption("select");
        setSelectedTepars([]);
        setDeleteArchive(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setSelectedTepars([]);
        setDeleteArchive(false);
      });
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      archiveContainerRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      totalCount !== Tepars?.length &&
      !allDataLoaded
    ) {
      getArchiveTepars(teparsPage);
    }
  };

  const closeDropdown = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event?.target)) {
      setSettingMenu(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event?.target)) {
      setSettingMenu(false);
      closeDropdown();
    }
  };

  useEffect(() => {
    if (settingMenu) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [settingMenu]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  }, []);

  const keyDown = (e) => {
    if (e.key === "Escape") {
      setSettingMenu(false);
    }
  };

  return (
    <>
      <FocusTrapping onClose={handleBackButton}>
        <div className="archiveSettings">
          <div className="archiveSettings-wrapper">
            <header className="header">
              <div className="back">
                <div
                  className="btn btn-back"
                  aria-label="Back"
                  role="button"
                  tabIndex="0"
                  onClick={() => handleBackButton()}
                >
                  <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                    <title>Back</title>
                    <g id="Group 18522">
                      <g id="Group 18522_2">
                        <path
                          id="Vector"
                          d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                          stroke="white"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="text">Archive</span>
              </div>
              {Tepars?.length > 0 && (
                <button
                  className="btn btn-archive-menu"
                  onClick={() => setSettingMenu(true)}
                  onKeyDown={keyDown}
                >
                  <svg width="5" height="20" viewBox="0 0 5 18" fill="none">
                    <title>Menu</title>
                    <path
                      d="M2.25 13.5C2.84674 13.5 3.41903 13.7371 3.84099 14.159C4.26295 14.581 4.5 15.1533 4.5 15.75C4.5 16.3467 4.26295 16.919 3.84099 17.341C3.41903 17.7629 2.84674 18 2.25 18C1.65326 18 1.08097 17.7629 0.65901 17.341C0.237053 16.919 0 16.3467 0 15.75C0 15.1533 0.237053 14.581 0.65901 14.159C1.08097 13.7371 1.65326 13.5 2.25 13.5ZM2.25 6.75C2.84674 6.75 3.41903 6.98705 3.84099 7.40901C4.26295 7.83097 4.5 8.40326 4.5 9C4.5 9.59674 4.26295 10.169 3.84099 10.591C3.41903 11.0129 2.84674 11.25 2.25 11.25C1.65326 11.25 1.08097 11.0129 0.65901 10.591C0.237053 10.169 0 9.59674 0 9C0 8.40326 0.237053 7.83097 0.65901 7.40901C1.08097 6.98705 1.65326 6.75 2.25 6.75ZM2.25 0C2.84674 0 3.41903 0.237053 3.84099 0.65901C4.26295 1.08097 4.5 1.65326 4.5 2.25C4.5 2.84674 4.26295 3.41903 3.84099 3.84099C3.41903 4.26295 2.84674 4.5 2.25 4.5C1.65326 4.5 1.08097 4.26295 0.65901 3.84099C0.237053 3.41903 0 2.84674 0 2.25C0 1.65326 0.237053 1.08097 0.65901 0.65901C1.08097 0.237053 1.65326 0 2.25 0Z"
                      fill="white"
                    />
                  </svg>
                </button>
              )}
            </header>

            {teparsLoad ? (
              <div className="archived-posts">
                <div className="media-container" style={{ rowGap: "0px" }}>
                  {Array.from({ length: 33 }).map((posts, index) => (
                    <SkeletonTheme
                      baseColor={
                        theme === "Light"
                          ? ""
                          : theme === "Dark"
                          ? "#202020"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? ""
                          : "#202020"
                      }
                      highlightColor={
                        theme === "Light"
                          ? ""
                          : theme === "Dark"
                          ? "#2A2A2A"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? ""
                          : "#2A2A2A"
                      }
                    >
                      <div className="media">
                        <Skeleton width={130} height={130} />
                      </div>
                    </SkeletonTheme>
                  ))}
                </div>
              </div>
            ) : (
              <>
                {Tepars?.length > 0 ? (
                  <div
                    ref={archiveContainerRef}
                    onScroll={handleScroll}
                    className="archived-posts"
                  >
                    <div className="media-container">
                      {Tepars?.map((tepars, i) => (
                        <div
                          tabIndex="0"
                          className="media"
                          role="listitem"
                          onClick={() => handlePostSelect(tepars?._id)}
                        >
                          {getGridTepars(tepars?.media[0]) &&
                          !imageErrors[i] ? (
                            <img
                              src={getGridTepars(tepars?.media[0])}
                              alt="post media"
                              onError={() => handleImageError(i)} // Ensure the index is passed here
                            />
                          ) : (
                            <div className="blank-image"></div>
                          )}
                          {tepars?.media?.length > 1 && (
                            <div className="media-type">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M12.7 1.66406H9.455C7.985 1.66406 6.82 1.66406 5.90917 1.7874C4.97083 1.91406 4.21167 2.18073 3.61333 2.78156C3.01417 3.3824 2.74833 4.1449 2.6225 5.08656C2.5 6.00156 2.5 7.17073 2.5 8.64656V13.5116C2.5 14.7682 3.26667 15.8449 4.35583 16.2966C4.3 15.5382 4.3 14.4757 4.3 13.5907V9.41573C4.3 8.34823 4.3 7.4274 4.39833 6.69073C4.50417 5.90073 4.7425 5.14406 5.35417 4.5299C5.96583 3.91573 6.72 3.67656 7.50667 3.5699C8.24 3.47156 9.15667 3.47156 10.2208 3.47156H12.7792C13.8425 3.47156 14.7575 3.47156 15.4917 3.5699C15.2717 3.00854 14.8877 2.52647 14.3897 2.18653C13.8917 1.84658 13.3029 1.66451 12.7 1.66406Z"
                                  fill="white"
                                />
                                <path
                                  d="M5.5 9.49495C5.5 7.22328 5.5 6.08745 6.20333 5.38161C6.90583 4.67578 8.03667 4.67578 10.3 4.67578H12.7C14.9625 4.67578 16.0942 4.67578 16.7975 5.38161C17.5 6.08745 17.5 7.22328 17.5 9.49495V13.5116C17.5 15.7833 17.5 16.9191 16.7975 17.6249C16.0942 18.3308 14.9625 18.3308 12.7 18.3308H10.3C8.0375 18.3308 6.90583 18.3308 6.20333 17.6249C5.5 16.9191 5.5 15.7833 5.5 13.5116V9.49495Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          )}
                          {tepars?.media[0]?.type === "Video" && (
                            <div className="media-type">
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_311_7573)">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18.5134 4.29316L14.1693 7.11756V3.86756C14.1693 3.66233 14.0815 3.46551 13.9252 3.32039C13.7689 3.17528 13.557 3.09375 13.3359 3.09375H1.66927C1.44826 3.09375 1.2363 3.17528 1.08002 3.32039C0.923735 3.46551 0.835938 3.66233 0.835938 3.86756V14.7009C0.835938 14.9061 0.923735 15.1029 1.08002 15.2481C1.2363 15.3932 1.44826 15.4747 1.66927 15.4747H13.3359C13.557 15.4747 13.7689 15.3932 13.9252 15.2481C14.0815 15.1029 14.1693 14.9061 14.1693 14.7009V11.4509L18.5134 14.2753C18.5759 14.316 18.6492 14.3399 18.7253 14.3446C18.8014 14.3492 18.8775 14.3343 18.9451 14.3016C19.0128 14.2689 19.0694 14.2195 19.109 14.159C19.1485 14.0984 19.1694 14.0289 19.1693 13.958V4.61042C19.1694 4.53958 19.1485 4.47008 19.109 4.4095C19.0694 4.34891 19.0128 4.29957 18.9451 4.26684C18.8775 4.23411 18.8014 4.21926 18.7253 4.2239C18.6492 4.22854 18.5759 4.2525 18.5134 4.29316Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_311_7573">
                                    <rect
                                      width="20"
                                      height="18.5714"
                                      fill="white"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          )}

                          {/* <div className="story-viewed-number">
                          <div className="viewIcon">
                            <img src={view} alt="view" />
                            <span className="viewed-number">12</span>
                          </div>
                        </div> */}

                          {settingMenuOption === "selected" && (
                            <div
                              className="select-post"
                              onClick={(e) => handleSelectMedia(e, tepars?._id)}
                            >
                              <div className="group-check">
                                <input
                                  id={i}
                                  type="checkbox"
                                  aria-label="Check this box"
                                  checked={selectedTepars?.includes(
                                    tepars?._id
                                  )}
                                  onChange={(e) =>
                                    handleSelectMedia(e, tepars?._id)
                                  }
                                />
                                <span
                                  className="checkmark"
                                  onClick={(e) =>
                                    handleSelectMedia(e, tepars?._id)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    {loading && <Spinner />}
                  </div>
                ) : (
                  <NoMediaYet
                    text="No archived posts yet"
                    icon={
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 75 71"
                        fill="none"
                      >
                        <path
                          d="M9.89638 30.235C10.3436 29.7647 10.7092 29.3666 11.0953 28.981C12.076 28.006 13.548 27.9621 14.4785 28.8697C15.4091 29.7773 15.442 31.2335 14.4566 32.2367C12.5567 34.1784 10.635 36.1007 8.69121 38.0036C7.59275 39.0758 6.01411 38.9583 5.14476 37.7027C3.5112 35.3373 1.92157 32.9437 0.355481 30.5328C0.193954 30.2775 0.0844433 29.9928 0.0332221 29.6951C-0.0179991 29.3974 -0.00992474 29.0926 0.0569826 28.798C0.12389 28.5034 0.248316 28.2249 0.423133 27.9785C0.597951 27.732 0.819726 27.5224 1.07576 27.3617C1.57307 27.0319 2.17848 26.9062 2.76617 27.0107C3.35386 27.1153 3.87864 27.4419 4.23147 27.9229C4.52805 28.3085 4.79012 28.7223 5.13692 29.2208C5.51039 27.7709 5.78814 26.4291 6.20085 25.1311C9.79281 13.823 17.1635 6.05755 28.3379 2.05253C36.7145 -0.943004 45.0988 -0.615391 53.32 2.74537C67.4713 8.5311 76.3657 23.0244 74.8278 38.2325C73.4155 52.2399 66.0151 62.1968 53.0831 67.6611C43.6191 71.6599 33.9856 71.3354 24.6314 66.9087C19.6962 64.5794 15.6366 61.1308 12.382 56.7418C11.4217 55.4486 11.5362 54.077 12.6755 53.2149C13.8147 52.3527 15.158 52.6537 16.1388 53.9469C20.9108 60.2421 27.1987 64.1154 34.9993 65.2832C43.3288 66.5372 51.0321 64.7173 57.8645 59.7185C68.27 52.1051 72.6796 39.0492 69.1221 26.6438C65.8158 15.1163 55.6111 6.35694 43.6771 5.01201C32.3661 3.74232 23.0826 7.6768 15.8814 16.4236C12.6645 20.3283 10.7642 24.8882 9.85558 29.8682C9.85736 29.9915 9.87102 30.1143 9.89638 30.235Z"
                          fill={
                            theme === "Light"
                              ? "#2a2a2a"
                              : theme === "Dark"
                              ? "#ffffff"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#2a2a2a"
                              : "#ffffff"
                          }
                        />
                        <path
                          d="M36.3529 25.8148C36.3529 22.4713 36.4141 19.1262 36.3294 15.7827C36.2839 13.9283 37.4937 12.9439 38.6832 12.9612C40.1567 12.9831 41.0543 14.0114 41.0543 15.7545C41.0543 21.9713 41.0506 28.1891 41.0433 34.408C41.0433 34.9566 41.1877 35.3077 41.6396 35.6463C45.6757 38.6706 49.6971 41.7137 53.7039 44.7756C54.1818 45.1306 54.5693 45.5929 54.8353 46.1252C55.2558 47.0454 54.8933 48.1034 54.1103 48.7242C53.7128 49.0525 53.2154 49.2362 52.6997 49.2453C52.184 49.2544 51.6805 49.0884 51.2716 48.7743C49.7463 47.6771 48.2508 46.5328 46.7443 45.4057C43.7518 43.1673 40.7813 40.9038 37.7621 38.6999C36.7578 37.9663 36.3215 37.0869 36.3404 35.8438C36.3859 32.5035 36.3529 29.1521 36.3529 25.8148Z"
                          fill={
                            theme === "Light"
                              ? "#2a2a2a"
                              : theme === "Dark"
                              ? "#ffffff"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#2a2a2a"
                              : "#ffffff"
                          }
                        />
                      </svg>
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      </FocusTrapping>
      {settingMenu && (
        <SettingsMenu
          ddref={dropdownRef}
          type="archive"
          selectedTepars={selectedTepars}
          setSettingMenu={setSettingMenu}
          settingMenuOption={settingMenuOption}
          setSettingMenuOption={setSettingMenuOption}
          handleSubmit={handleUnarchiveOrDelete}
          setDeleteArchive={setDeleteArchive}
        />
      )}
      {deleteArchive && (
        <ArchiveDelete
          confirmDelete={() => handleUnarchiveOrDelete("archivedelet")}
          cancelDelete={() => {
            setSelectedTepars([]);
            setSettingMenu(false);
            setDeleteArchive(false);
            setSettingMenuOption("select");
          }}
        />
      )}
    </>
  );
}

export default ArchiveSettings;
