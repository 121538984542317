import React, { useState } from "react";
import TakePhoto from "./TakePhoto";
import MediaMenu from "../ChatComponent/MediaMenu";
import "../../assets/css/capturePhoto.scss";

function CapturePhoto({
  imgSrc,
  file,
  setCloseCamera,
  localstream,
  onImageSend,
}) {
  const [showTakePhoto, setShowTakePhoto] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleClose = () => {
    setCloseCamera(true);
    if (localstream && localstream.getTracks) {
      localstream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const retakes = () => {
    setShowTakePhoto(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      })
      .catch((err) => {
        console.log(`${err.name}: ${err.message}`);
      });
  };

  if (showTakePhoto) {
    return (
      <TakePhoto
        localstream={localstream}
        setCloseCamera={setCloseCamera}
        onImageSend={onImageSend}
      />
    );
  }

  const onButtonClick = async () => {
    handleClose();
    onImageSend(file);
    setIsButtonDisabled(true);
  };

  return (
    <div className="captureMedia">
      <div className="captureMedia-wrapper">
        <div className="heading">
          <div className="close-wrapper">
            <div
              className="btn btn-close"
              role="button"
              aria-label="Close"
              tabIndex="0"
              onClick={handleClose}
            >
              <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
                <title>Close</title>
                <g id="Frame 2595">
                  <path
                    id="Shape"
                    d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                    fill="#1891A2"
                  />
                </g>
              </svg>
            </div>
            <span className="text">Take photo</span>
          </div>
          <div className="retake-wrapper" onClick={retakes}>
            <div
              className="btn btn-retake"
              role="button"
              aria-label="Retake"
              tabIndex="0"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <title>Retake</title>
                <path
                  d="M5.05263 4.8V8L0 4L5.05263 0V3.2H9.26316C11.0499 3.2 12.7634 3.87428 14.0268 5.07452C15.2902 6.27475 16 7.90261 16 9.6C16 11.2974 15.2902 12.9253 14.0268 14.1255C12.7634 15.3257 11.0499 16 9.26316 16H1.68421V14.4H9.26316C10.6032 14.4 11.8884 13.8943 12.8359 12.9941C13.7835 12.0939 14.3158 10.873 14.3158 9.6C14.3158 8.32696 13.7835 7.10606 12.8359 6.20589C11.8884 5.30571 10.6032 4.8 9.26316 4.8H5.05263Z"
                  fill="#1891a2"
                />
              </svg>
            </div>
            <span className="text">Retake</span>
          </div>
        </div>

        <MediaMenu />

        <div className="container">
          <div className="photo-container">
            {imgSrc && <img src={imgSrc} alt="Captured" />}
          </div>
        </div>

        <footer className="footer-box">
          <div className="message-wrapper">
            <div className="input-wrapper">
              <div
                placeholder="Type Message.."
                // onInput={handleMessageChange}
                // onKeyDown={handleKeyPress}
                // onKeyUp={handleKeyUp}
                className="messageInput"
                // value={message}
                //onClick={handleChange}
                contentEditable
              />
            </div>
          </div>
          <div
            className="send-Photomessage"
            role="button"
            tabIndex="0"
            onClick={onButtonClick}
            style={{ pointerEvents: isButtonDisabled ? "none" : "" }}
          >
            <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
              <title>Send</title>
              <rect
                width="46"
                height="46"
                rx="23"
                transform="matrix(-1 0 0 1 46 0)"
                fill="#1891A2"
              />
              <path
                d="M18.8886 31.6163L26.34 24.3652C27.22 23.5088 27.22 22.1075 26.34 21.2512L18.8886 14"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default CapturePhoto;
