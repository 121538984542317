import { React, useState } from "react";
import "../../assets/css/contactUs.scss";

function ContactUs({ setIsContact }) {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };
  const backButton = () => {
    setIsContact(false);
  };

  return (
    <div className="contactUs-popup">
      <form className="dialog">
        <div className="dialog-info">
          <h4>Contact us</h4>
        </div>

        {/* <div className="contactUs-info">
          <textarea
            name="help"
            className="report-info"
            placeholder="Tell us how we can help"
          />
        </div> */}
        <div className="contactUs-info" contentEditable="true">
          <p className="report-info" placeholder="Tell us how we can help" />
        </div>

        <p className="contactUs-sub-info">
          Include device information? (optional)
        </p>

        <div className="contactUs-main-info" onClick={handleCheckmarkClick}>
          <p className="info">
            Technical details like your model and settings can help us answer
            your question.
            <a href="/" className="more-info">
              Learn More
            </a>
          </p>

          <div className="group-check">
            <input
              type="checkbox"
              id="technical-check"
              aria-label="Check this box"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />
            <span className="checkmark" onClick={handleCheckmarkClick} />
          </div>
        </div>

        <p className="support">Get support via email</p>

        <div className="contactUs-cta">
          <button className="btn btn-cancel" onClick={() => backButton()}>
            Cancel
          </button>
          <button className="btn btn-send active disabled">Send</button>
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
