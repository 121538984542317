import React from "react";

const BlockUser = ({ setReportMenu, setReportBlockModal }) => {
  return (
    <div className="blockUser">
      <div className="dialog-info">
        <h4 className="title">Block Pujan Desai?</h4>
      </div>
      <p className="description">
        They won't be able to find your profile, posts or story on Tepnot.
        Tepnot won't let them know that you've blocked them.
      </p>

      <div className="discardPost-cta">
        <button
          className="btn btn-cancel"
          onClick={() => {
            setReportMenu(false);
            setReportBlockModal(false);
          }}
        >
          Cancel
        </button>

        <button className="btn btn-block active">Block</button>
      </div>
    </div>
  );
};

export default BlockUser;
