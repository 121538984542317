import React from "react";
import { useSelector } from "react-redux";
import { getUserProfilePic } from "../../Utils/helpers";
import "../../assets/css/viewContacts.scss";

function ViewContacts({
  ref,
  onClose,
  contacts,
  selectedContactName,
  onContactSend,
  viewContactType,
}) {
  const chatBoxContact = useSelector((state) => state.chatBoxContact);

  const handleClick = () => {
    onClose(false);
  };

  return (
    <div ref={ref} className="viewContacts">
      <div className="viewContacts-container">
        <header className="viewContacts-to">
          {viewContactType === "view" && (
            <div className="viewContacts-to-container">
              <h2 className="title">{contacts.length} Contacts</h2>
              <button className="btn btn-close" onClick={() => onClose(false)}>
                <svg width="20" height="20" viewBox="0 0 25 25" fill="none">
                  <title>Close</title>
                  <g id="iconoir:cancel">
                    <path
                      id="Vector"
                      d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                      stroke="white"
                      strokeWidth="2.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </button>
            </div>
          )}
          {viewContactType === "send" && (
            <div className="viewContacts-to-container send">
              <button className="btn btn-close" onClick={() => onClose(false)}>
                <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                  <title>Back</title>
                  <g id="Group 18522">
                    <g id="Group 18522_2">
                      <path
                        id="Vector"
                        d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                        stroke="white"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </g>
                </svg>
              </button>
              <h2 className="title">
                Send {contacts.length} contact to{" "}
                {JSON.stringify(chatBoxContact?.contact?.name)} ?
              </h2>
            </div>
          )}
        </header>
        <div className="viewContact-container">
          {contacts.map((contact, i) => (
            <div key={i} className="viewContact-details">
              <div className="viewContact">
                <div className="viewContact-image">
                  <img
                    className="viewContact-avatar"
                    src={getUserProfilePic(contact?.profilePic)}
                    alt="profile thumbnail"
                  />
                </div>
                <div className="viewContact-info">
                  <p className="viewContact-name">{contact?.name}</p>
                </div>
              </div>
              <div className="viewContact-number-details">
                <div className="viewContact-number-detail">
                  <span className="viewContact-number">
                    {viewContactType === "send"
                      ? `${contact?.user?.countryCode} ${contact?.user?.phone}`
                      : `${contact?.countryCode} ${contact?.number}`}
                  </span>
                  <span className="viewContact-number-type">TEL</span>
                </div>
                <div
                  className="viewContact-message"
                  onClick={() => handleClick()}
                >
                  <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                    <title>Message</title>
                    <path
                      d="M0.750009 13C0.750009 6.2345 6.23451 0.75 13 0.75C19.7655 0.75 25.25 6.2345 25.25 13C25.25 19.7655 19.7655 25.25 13 25.25C10.928 25.25 8.97326 24.7346 7.26001 23.8246L2.42301 25.2001C2.1982 25.2638 1.96046 25.2664 1.73434 25.2075C1.50822 25.1486 1.30191 25.0305 1.13674 24.8652C0.971556 24.6999 0.853491 24.4936 0.794736 24.2674C0.73598 24.0413 0.738663 23.8035 0.802509 23.5788L2.17801 18.7426C1.23796 16.9746 0.747531 15.0024 0.750009 13ZM7.75001 10.375C7.75001 10.6071 7.8422 10.8296 8.00629 10.9937C8.17038 11.1578 8.39294 11.25 8.62501 11.25H17.375C17.6071 11.25 17.8296 11.1578 17.9937 10.9937C18.1578 10.8296 18.25 10.6071 18.25 10.375C18.25 10.1429 18.1578 9.92038 17.9937 9.75628C17.8296 9.59219 17.6071 9.5 17.375 9.5H8.62501C8.39294 9.5 8.17038 9.59219 8.00629 9.75628C7.8422 9.92038 7.75001 10.1429 7.75001 10.375ZM8.62501 14.75C8.39294 14.75 8.17038 14.8422 8.00629 15.0063C7.8422 15.1704 7.75001 15.3929 7.75001 15.625C7.75001 15.8571 7.8422 16.0796 8.00629 16.2437C8.17038 16.4078 8.39294 16.5 8.62501 16.5H13.875C14.1071 16.5 14.3296 16.4078 14.4937 16.2437C14.6578 16.0796 14.75 15.8571 14.75 15.625C14.75 15.3929 14.6578 15.1704 14.4937 15.0063C14.3296 14.8422 14.1071 14.75 13.875 14.75H8.62501Z"
                      fill="#1790A3"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
        {viewContactType === "send" && (
          <div className="sendContact-message">
            <div className="sendContact-message-names">
              <span className="name">{selectedContactName}</span>
            </div>
            {/* <div
              tabIndex={0}
              role="button"
              className="send-message"
              // onKeyDown={handleKeyDown}
              onClick={() => onContactSend()}
            >
              <img src={send} alt="send-message" />
            </div> */}

            <button
              className="btn btn-send-message"
              aria-label="Send"
              // onKeyDown={handleKeyDown}
              onClick={() => onContactSend()}
            >
              <svg width="12" height="16" viewBox="0 0 12 22" fill="none">
                <path
                  d="M1.69029 21.2L10.2425 12.8854C11.2525 11.9034 11.2525 10.2966 10.2425 9.31465L1.69029 1"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewContacts;
