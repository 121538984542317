import React, { useEffect } from "react";
import "../../assets/css/useHere.scss";
import { updateFavicon } from "../../Utils/helpers";

function UseHere({ status, message, setShowUseHere, webConnection }) {
  useEffect(() => {
    if (status === 200) {
      updateFavicon("tepnot-fav-alternate.png");
    } else {
      updateFavicon("tepnot-fav-warning.png");
    }
  }, [status]);
  return (
    <div className="useHere">
      <div className="dialog">
        <p className="description">{message}</p>
        <div className="useHere-cta">
          <button
            className="btn btn-cancel active"
            onClick={() => {
              window.location.href = "https://tepnot.com/";
              setShowUseHere(false);
            }}
          >
            Cancel
          </button>

          <button
            className="btn btn-useHere"
            onClick={() => {
              webConnection();
              setShowUseHere(false);
              window?.location?.reload();
              updateFavicon("tepnot-fav-alternate.png");
            }}
          >
            Use here
          </button>
        </div>
      </div>
    </div>
  );
}

export default UseHere;
