import { React, useEffect, useState } from "react";
import Spinner from "../Common/Spinner";
import { useDispatch } from "react-redux";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import { handleToast } from "../../Redux/actions";
import "../../assets/css/tags.scss";

function Tags({
  setSettingProfile,
  setProfileTag,
  initialChecked,
  onTagsUpdate,
}) {
  const dispatch = useDispatch();

  const [tags, setTags] = useState();
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [checked, setChecked] = useState(initialChecked);

  const filteredTags = tags?.filter((tag) =>
    tag?.toLowerCase()?.includes(searchValue?.toLowerCase())
  );

  const closeButton = (save = false) => {
    if (save) {
      onTagsUpdate(checked);
    }
    setSettingProfile(true);
    setProfileTag("");
  };

  const getTags = () => {
    setLoading(true);
    axiosRequest
      .get(`user/tag`)
      .then((res) => {
        setTags(res?.data?.data.sort());
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    getTags();
  }, []);

  const handleTagClick = (tag) => {
    if (checked === tag) {
      setChecked("");
    } else {
      setChecked(tag);
    }
  };

  return (
    <div className="tags">
      <div className="tags-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => closeButton(false)}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Tags</span>
          </div>

          <div className="done" onClick={() => closeButton(true)} tabIndex="1">
            <span className="text">Done</span>
          </div>
        </header>

        {loading ? (
          <div className="no-data">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="search-container">
              <div className="search-bar">
                <svg width="12" height="12" viewBox="0 0 18 18" fill="none">
                  <title>Search</title>
                  <path
                    id="Vector"
                    d="M14.2049 12.952L18 16.7462L16.7462 18L12.952 14.2049C11.5402 15.3366 9.78419 15.9522 7.9748 15.9496C3.57271 15.9496 0 12.3769 0 7.9748C0 3.57271 3.57271 0 7.9748 0C12.3769 0 15.9496 3.57271 15.9496 7.9748C15.9522 9.78419 15.3366 11.5402 14.2049 12.952ZM12.4274 12.2945C13.5519 11.138 14.18 9.58786 14.1774 7.9748C14.1774 4.54741 11.4013 1.77218 7.9748 1.77218C4.54741 1.77218 1.77218 4.54741 1.77218 7.9748C1.77218 11.4013 4.54741 14.1774 7.9748 14.1774C9.58786 14.18 11.138 13.5519 12.2945 12.4274L12.4274 12.2945Z"
                    fill="#999999"
                  />
                </svg>
                <input
                  type="search"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                {searchValue.length > 0 && (
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 25 25"
                    fill="none"
                    style={{
                      overflow: "visible",
                      pointerEvents: "auto",
                      cursor: "pointer",
                    }}
                    onClick={() => setSearchValue("")}
                  >
                    <title>Clear search</title>
                    <g id="iconoir:cancel">
                      <path
                        id="Vector"
                        d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                        stroke="#999999"
                        strokeWidth="2.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                )}
              </div>
            </div>
            <div className="current">
              <span className="current-tag-info">Currently set to</span>

              <div className="current-tag-name">
                <span className="name">
                  {checked ? checked : "No selected tag yet"}
                </span>
              </div>
            </div>
            <div className="select-about-list">
              <span className="about-list-info">Select your tag</span>

              <ul className="select-about-list-wrapper">
                {filteredTags &&
                  filteredTags?.map((tag, i) => (
                    <li
                      key={i}
                      className="select-about-listItem"
                      tabIndex="0"
                      onClick={() => handleTagClick(tag)}
                    >
                      <div className="select-about-listItem-wrapper">
                        <span className="select-about-list-value">{tag}</span>
                        <div className="group-check">
                          <input
                            type="radio"
                            id="myCheckbox"
                            aria-label="Check this box"
                            checked={checked === tag}
                          />

                          <div className="checkmark" role="radio" tabIndex="0">
                            <div className="offRadio radio-cmn"></div>
                            <div className="onRadio radio-cmn"></div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Tags;
