import { React, useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ERROR } from "../../Utils/enums";
import { convertToAgoFormat, getUserProfilePic } from "../../Utils/helpers";

import {
  storyImagePath,
  story_image_base_url,
  story_video_base_url,
} from "../../ServerConfiguration/awsConfiguration";
import "../../assets/css/viewStory.scss";
import {
  setCurrentIndex,
  markStoryAsSeen,
  handleToast,
} from "../../Redux/actions";
import axiosRequest from "../../Middleware/api";
import ProgressBar from "./progressBar";
import Spinner from "../Common/Spinner";
import StoryViewed from "./StoryViewed";
import ComingSoon from "../Common/ComingSoon";

function ViewStory({ story, onClose }) {
  const dispatch = useDispatch();

  let popupRef = useRef(null);
  const videoRef = useRef(null);
  const mediaRef = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const thumbnailRef = useRef(null);
  const UserID = localStorage.getItem("user_id");
  const myStatus = useSelector((state) => state.stories.myStatus);
  const currentIndex = useSelector((state) => state.currentIndex);
  const [storyData, setStoryData] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isPlay, setIsPlay] = useState(true);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showReplyPopup, setShowReplyPopup] = useState(false);
  const [showStoryViewedPopup, setShowStoryViewedPopup] = useState(false);
  const [seenStories, setSeenStories] = useState(new Set());
  const currentMedia = storyData[currentMediaIndex] || {};
  const isImage = currentMedia?.mediaType === "Image";
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [mediaDimensions, setMediaDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [containerSize, setContainerSize] = useState({
    width: 0,
    height: 0,
  });
  const [isBuffering, setIsBuffering] = useState(false);
  const [videoReady, setVideoReady] = useState(false);

  useEffect(() => {
    const markAsSeenStory = async (storyId) => {
      try {
        await axiosRequest.put(`/story/seen/${storyId}`);
      } catch (error) {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      }
    };

    if (
      story &&
      story[currentIndex] &&
      !story[currentIndex][currentMediaIndex]?.isSeen &&
      !seenStories.has(story[currentIndex][currentMediaIndex]._id) &&
      !isLoading
    ) {
      const storyId = story[currentIndex][currentMediaIndex]?._id;

      if (story[currentIndex][currentMediaIndex]?.userId !== UserID) {
        markAsSeenStory(storyId);
      }

      setSeenStories((prev) => new Set(prev).add(storyId));

      dispatch(markStoryAsSeen(storyId));
    }
  }, [isLoading, currentMediaIndex, currentIndex]);

  const handleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleBackStory = () => {
    if (currentIndex !== 0) {
      const previousStoryGroup = story[currentIndex - 1];
      const allStoriesSeen = previousStoryGroup?.every((s) => s?.isSeen);

      if (allStoriesSeen) {
        return;
      }

      const lastUnseenStoryIndex = previousStoryGroup?.reduceRight(
        (lastIndex, s, index) => {
          return s?.isSeen ? lastIndex : index;
        },
        -1
      );

      dispatch(setCurrentIndex(currentIndex - 1));
      setStoryData(previousStoryGroup);
      setCurrentMediaIndex(
        lastUnseenStoryIndex === -1 ? 0 : lastUnseenStoryIndex
      );
    }
  };

  const goBackward = () => {
    if (currentMediaIndex === 0) {
      handleBackStory();
    } else {
      setCurrentMediaIndex(currentMediaIndex - 1);
    }
    setProgress(0);
    setIsPlay(true);
  };

  const handleNextStory = () => {
    const nextUnseenStoryIndex = story.findIndex(
      (storyGroup, index) =>
        index > currentIndex && storyGroup?.some((s) => !s?.isSeen)
    );
    if (nextUnseenStoryIndex !== -1) {
      const nextUnseenMediaIndex = story[nextUnseenStoryIndex].findIndex(
        (media) => !media?.isSeen
      );
      dispatch(setCurrentIndex(nextUnseenStoryIndex));
      setStoryData(story[nextUnseenStoryIndex]);
      setCurrentMediaIndex(nextUnseenMediaIndex);
      setProgress(0);
      setIsPlay(true);
    } else {
      onClose(false);
    }
  };

  const goForward = () => {
    if (currentMediaIndex === storyData?.length - 1) {
      handleNextStory();
    } else {
      setCurrentMediaIndex(currentMediaIndex + 1);
      setProgress(0);
      setIsPlay(true);
    }
  };

  const handleMute = () => {
    setIsMute(!isMute);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (videoRef.current) {
          videoRef.current.pause();
          setIsPlay(false);
        }
        setIsVisible(false);
      } else {
        if (videoRef.current) {
          videoRef.current.play();
          setIsPlay(true);
        }
        setIsVisible(true);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const pauseAllStory = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlay(false);
    }
    setIsVisible(false);
  }, []);

  const resumeCurrentStory = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlay(true);
    }
    setIsVisible(true);
  }, [pauseAllStory]);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden || !isVisible) {
      pauseAllStory();
    } else {
      resumeCurrentStory();
    }
  }, [pauseAllStory, isVisible]);

  const handleAppFocus = useCallback(() => {
    setIsVisible(true);
    resumeCurrentStory();
  }, []);

  const handleAppBlur = useCallback(() => {
    setIsVisible(false);
    pauseAllStory();
  }, [pauseAllStory]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleAppFocus);
    window.addEventListener("blur", handleAppBlur);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleAppFocus);
      window.removeEventListener("blur", handleAppBlur);
    };
  }, [handleVisibilityChange, handleAppFocus, handleAppBlur]);

  useEffect(() => {
    const overlayOpenEvent = new Event("overlayOpen");
    document.dispatchEvent(overlayOpenEvent);
  }, []);

  useEffect(() => {
    setStoryData(story[currentIndex]);
    return () => {};
  }, [currentIndex]);

  useEffect(() => {
    const initialUnseenMediaIndex = story[currentIndex].findIndex(
      (media) => !media?.isSeen
    );

    if (initialUnseenMediaIndex === -1) {
      setCurrentMediaIndex(0);
    } else {
      setCurrentMediaIndex(initialUnseenMediaIndex);
    }
  }, [currentIndex]);

  useEffect(() => {
    const video = videoRef.current;

    if (video && !isImage) {
      const handleWaiting = () => {
        setIsBuffering(true);
        setIsPlay(false);
      };

      const handlePlaying = () => {
        setIsBuffering(false);
        setVideoReady(true);
        setIsPlay(true);
      };

      const handleStalled = () => {
        setIsBuffering(true);
        setIsPlay(false);
      };

      const handleCanPlay = () => {
        setVideoReady(true);
        if (!isBuffering) {
          setIsPlay(true);
          video.play().catch(() => {
            // Handle autoplay restriction
            setIsPlay(false);
          });
        }
      };

      video.addEventListener("waiting", handleWaiting);
      video.addEventListener("playing", handlePlaying);
      video.addEventListener("stalled", handleStalled);
      video.addEventListener("canplay", handleCanPlay);

      return () => {
        video.removeEventListener("waiting", handleWaiting);
        video.removeEventListener("playing", handlePlaying);
        video.removeEventListener("stalled", handleStalled);
        video.removeEventListener("canplay", handleCanPlay);
      };
    }
  }, [isImage, currentMediaIndex]);

  useEffect(() => {
    let intervalId;

    if (
      isPlay &&
      isVisible &&
      !isLoading &&
      (!isBuffering || isImage) &&
      (isImage || videoReady)
    ) {
      const currentDuration =
        storyData[currentMediaIndex]?.mediaType === "Video"
          ? storyData[currentMediaIndex]?.duration || 5
          : 5;

      intervalId = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(intervalId);
            goForward();
            return 0;
          }
          return prev + 100 / (currentDuration * 10);
        });
      }, 100);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [
    currentMediaIndex,
    isPlay,
    isVisible,
    storyData,
    isLoading,
    isBuffering,
    videoReady,
  ]);

  const handleVideoPress = () => {
    if (storyData[currentMediaIndex]?.mediaType === "Video") {
      if (isPlay) {
        videoRef.current.pause();
        setIsPlay(false);
      } else {
        videoRef.current.play();
        setIsPlay(true);
      }
    } else {
      setIsPlay(!isPlay);
    }
  };

  useEffect(() => {
    const handleKeydown = (e) => {
      if (showStoryViewedPopup) return;
      if (e.key === "ArrowLeft") {
        goBackward();
      } else if (e.key === "ArrowRight") {
        goForward();
      }
    };

    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [currentMediaIndex, storyData, currentIndex, showStoryViewedPopup]);

  // Add thumbnail preloading
  useEffect(() => {
    if (currentMedia?.thumbnail) {
      const img = new Image();
      img.src = currentMedia.thumbnail;
      // img.onload = () => setThumbnailLoaded(true);
    }
  }, [currentMedia]);

  const showSpinner = isLoading || (!isImage && (isBuffering || !videoReady));

  // Modified media loading logic
  useEffect(() => {
    setIsLoading(true);

    const updateContainerDimensions = () => {
      if (mediaRef.current) {
        const isVideo = mediaRef.current instanceof HTMLVideoElement;
        const width = isVideo
          ? mediaRef.current.videoWidth
          : mediaRef.current.naturalWidth;
        const height = isVideo
          ? mediaRef.current.videoHeight
          : mediaRef.current.naturalHeight;

        setMediaDimensions({ width, height });
      }
    };

    const handleMediaLoaded = () => {
      updateContainerDimensions();
      setIsLoading(false);
    };

    if (mediaRef.current) {
      if (mediaRef.current instanceof HTMLImageElement) {
        mediaRef.current.addEventListener("load", handleMediaLoaded);
      } else if (mediaRef.current instanceof HTMLVideoElement) {
        mediaRef.current.addEventListener("loadedmetadata", handleMediaLoaded);
        mediaRef.current.addEventListener("play", updateContainerDimensions);
      }
    }

    return () => {
      if (mediaRef.current) {
        if (mediaRef.current instanceof HTMLImageElement) {
          mediaRef.current.removeEventListener("load", handleMediaLoaded);
        } else if (mediaRef.current instanceof HTMLVideoElement) {
          mediaRef.current.removeEventListener(
            "loadedmetadata",
            handleMediaLoaded
          );
          mediaRef.current.removeEventListener(
            "play",
            updateContainerDimensions
          );
        }
      }
    };
  }, [currentMedia]);
  

  // Add container size calculation
  useEffect(() => {
    const updateContainerSize = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setContainerSize({
          width: clientWidth,
          height: clientHeight,
        });
      }
    };

    updateContainerSize();
    window.addEventListener("resize", updateContainerSize);
    return () => window.removeEventListener("resize", updateContainerSize);
  }, []);

  const getBackgroundImageUrl = () => {
    return currentMedia?.thumbnail;
  };

  const backwardDisplay = () => {
    const allPreviousStoriesSeen = story
      .slice(0, currentIndex)
      .every((storyGroup) => storyGroup?.every((s) => s.isSeen));

    if (allPreviousStoriesSeen) {
      return currentMediaIndex !== 0;
    } else {
      return currentIndex !== 0 || currentMediaIndex !== 0;
    }
  };

  const forwardDisplay = () => {
    const allNextStoriesSeen = story
      .slice(currentIndex + 1)
      .every((storyGroup) => storyGroup?.every((s) => s.isSeen));

    if (allNextStoriesSeen) {
      return currentMediaIndex !== storyData.length - 1;
    } else {
      return (
        currentIndex !== story?.length - 1 ||
        currentMediaIndex !== storyData?.length - 1
      );
    }
  };

  const handleStoryViewedClick = () => {
    setShowStoryViewedPopup(true);
    setIsPlay(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleCloseStoryViewedPopup = () => {
    setShowStoryViewedPopup(false);
    setIsPlay(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const getViewedCount = (storyId) => {
    const story = myStatus?.find((story) =>
      story.some((media) => media?._id === storyId)
    );
    if (story) {
      const media = story.find((media) => media?._id === storyId);
      const seenUsers = media?.seenUsers;

      if (Array.isArray(seenUsers)) {
        return seenUsers?.length;
      } else if (typeof seenUsers === "number") {
        return seenUsers;
      }
    }
    return 0;
  };

  const handleReplyClick = () => {
    const userId = story[currentIndex][currentMediaIndex]?.userId;
    if (userId && (userId._id === UserID || userId === UserID)) {
      return;
    }

    setShowReplyPopup(true);
    setIsPlay(false);
    videoRef.current?.pause();
  };

  const handleClosePopup = () => {
    setShowReplyPopup(false);
    setIsPlay(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const toggleReadMore = () => {
    setShowFullMessage((prevState) => !prevState);
  };

  return (
    <>
      <div className="viewStory">
        <div className="viewStory-wrapper" ref={popupRef}>
          <header className="header-viewStory">
            <div className="container">
              <button
                className="btn btn-back"
                aria-label="Back"
                onClick={() => onClose(false)}
              >
                <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                  <title>Back</title>
                  <g id="Group 18522">
                    <g id="Group 18522_2">
                      <path
                        id="Vector"
                        d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                        stroke="white"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </g>
                </svg>
              </button>

              <div className="status-details">
                <div className="total-status">
                  <div className="total-status-wrapper">
                    <ProgressBar
                      currentStoryIndex={currentMediaIndex}
                      totalStories={storyData?.length}
                      progress={progress}
                    />
                  </div>
                </div>

                <div className="status-info">
                  <div className="status-user-info">
                    <div
                      className="user-image"
                      onClick={() => {
                        handleReplyClick();
                      }}
                    >
                      <img
                        src={getUserProfilePic(
                          storyData[currentMediaIndex]?.userId?.profile
                            ? storyData[currentMediaIndex]?.userId?.profile
                            : storyData[currentMediaIndex]?.webUserId?.profile
                        )}
                        alt="profile"
                      />
                    </div>
                    <div className="info">
                      {story[currentIndex][currentMediaIndex]?.userId?._id ===
                        UserID ||
                      story[currentIndex][currentMediaIndex]?.userId ===
                        UserID ? (
                        <span className="name">You</span>
                      ) : (
                        <span
                          className="name"
                          onClick={() => {
                            handleReplyClick();
                          }}
                        >
                          {storyData[currentMediaIndex]?.userId?.name
                            ? storyData[currentMediaIndex]?.userId?.name
                            : storyData[currentMediaIndex]?.webUserId?.name}
                        </span>
                      )}

                      <span className="uploaded-date">
                        {convertToAgoFormat(
                          storyData[currentMediaIndex]?.createAt
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="status-menu">
                    {!isImage && (
                      <button className="btn btn-volume" onClick={handleMute}>
                        {isMute ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <title>Unmute</title>
                            <g id="wpf:mute">
                              <path
                                id="Vector"
                                d="M4.31237 2.46138C4.08714 2.50214 3.87773 2.6049 3.70768 2.75812C3.53764 2.91134 3.41369 3.10894 3.34976 3.32873C3.28583 3.54851 3.28446 3.78177 3.3458 4.00229C3.40713 4.22281 3.52875 4.42186 3.69699 4.57707L28.3124 29.1925C28.421 29.3264 28.5565 29.4361 28.7102 29.5143C28.8639 29.5925 29.0323 29.6375 29.2046 29.6465C29.3768 29.6554 29.549 29.6281 29.71 29.5662C29.8709 29.5043 30.0171 29.4093 30.139 29.2873C30.2609 29.1653 30.3558 29.0191 30.4176 28.8581C30.4793 28.6971 30.5065 28.5249 30.4975 28.3526C30.4884 28.1804 30.4433 28.012 30.3649 27.8584C30.2866 27.7047 30.1769 27.5693 30.0428 27.4608L20.9278 18.3469V4.07615C20.9278 2.51922 19.5678 2.12907 18.5044 3.19245L12.081 9.50015L5.42868 2.84538C5.30164 2.71007 5.14564 2.60524 4.97236 2.53874C4.79908 2.47224 4.61301 2.44579 4.42806 2.46138C4.38952 2.45956 4.35091 2.45956 4.31237 2.46138ZM6.15852 11.1543C5.48037 11.1543 4.92776 11.7057 4.92776 12.3851V19.7697C4.92776 20.4466 5.48037 21.0005 6.15852 21.0005H10.3899L18.4662 28.8835C19.697 30.1143 20.9278 29.4841 20.9278 27.8066V24.6521L7.42745 11.1543H6.15852Z"
                                fill="white"
                              />
                            </g>
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <title>Mute</title>
                            <g clipPath="url(#clip0_1657_59)">
                              <g filter="url(#filter0_d_1657_59)">
                                <path
                                  d="M2.9959 21.9013L2.97233 11.224H10.9391L16.2895 5.87351L16.3131 27.2517L10.9626 21.9013H2.9959ZM18.9058 28.1945V25.4604C20.9014 24.879 22.5121 23.7636 23.7377 22.1143C24.9634 20.4651 25.5762 18.6027 25.5762 16.5273C25.5762 14.4531 24.9634 12.5913 23.7377 10.9421C22.5121 9.29277 20.9014 8.1768 18.9058 7.59414L18.9058 4.85999C21.6557 5.47282 23.9027 6.86378 25.6469 9.03287C27.3911 11.202 28.2632 13.7001 28.2632 16.5273C28.2632 19.3557 27.3911 21.8544 25.6469 24.0235C23.9027 26.1926 21.6557 27.5829 18.9058 28.1945ZM18.9058 22.6319L18.9058 19.4264C19.2986 19.0336 19.6208 18.5939 19.8722 18.1074C20.1236 17.6209 20.2493 17.1099 20.2493 16.5744C20.2493 16.0087 20.1277 15.4785 19.8844 14.9839C19.6412 14.4892 19.315 14.0373 18.9058 13.6281L18.9058 10.4226C20.0686 11.0511 21.0271 11.8996 21.7814 12.9682C22.5356 14.0367 22.9127 15.2309 22.9127 16.5508C22.9127 17.8708 22.5356 19.0574 21.7814 20.1109C21.0271 21.1643 20.0686 22.0047 18.9058 22.6319Z"
                                  fill="white"
                                />
                              </g>
                            </g>
                            <defs>
                              <filter
                                id="filter0_d_1657_59"
                                x="-1.02734"
                                y="2.85938"
                                width="33.291"
                                height="31.3359"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset dy="2" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_1657_59"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_1657_59"
                                  result="shape"
                                />
                              </filter>
                              <clipPath id="clip0_1657_59">
                                <rect width="32" height="32" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </button>
                    )}

                    <button
                      className="btn btn-play"
                      onClick={() => handleVideoPress()}
                    >
                      {!isPlay ? (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <title>Play</title>
                          <g clipPath="url(#clip0_1657_55)">
                            <g filter="url(#filter0_d_1657_55)">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.55925 6.3463C7.59499 6.04342 7.70097 5.75308 7.86873 5.4984C8.0365 5.24371 8.26142 5.03172 8.52558 4.87931C8.78974 4.7269 9.08584 4.63828 9.3903 4.62052C9.69475 4.60275 9.99915 4.65632 10.2793 4.77696C11.6953 5.3823 14.8686 6.82096 18.8953 9.14496C22.9233 11.4703 25.7566 13.501 26.9873 14.4223C28.0379 15.2103 28.0406 16.773 26.9886 17.5636C25.7699 18.4796 22.9712 20.4836 18.8953 22.8383C14.8152 25.193 11.6793 26.6143 10.2766 27.2116C9.06858 27.7276 7.71658 26.945 7.55925 25.6423C7.37525 24.1196 7.03125 20.6623 7.03125 15.993C7.03125 11.3263 7.37392 7.8703 7.55925 6.3463Z"
                                fill="white"
                              />
                            </g>
                          </g>
                          <defs>
                            <filter
                              id="filter0_d_1657_55"
                              x="3.03125"
                              y="2.61719"
                              width="28.7451"
                              height="30.7559"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="2" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1657_55"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1657_55"
                                result="shape"
                              />
                            </filter>
                            <clipPath id="clip0_1657_55">
                              <rect width="32" height="32" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <title>Pause</title>
                          <path
                            d="M13.8842 5H8.39754C8.1738 5 8 5.1738 8 5.39754V26.6025C8 26.8262 8.1738 27 8.39754 27H13.8852C14.109 27 14.2828 26.8262 14.2828 26.6025L14.2818 5.39754C14.2818 5.1738 14.108 5 13.8842 5Z"
                            fill="white"
                          />
                          <path
                            d="M22.9243 5H17.4366C17.2129 5 17.0391 5.1738 17.0391 5.39754V26.6025C17.0391 26.8262 17.2129 27 17.4366 27H22.9243C23.1231 27 23.2969 26.8262 23.3218 26.6025V5.39754C23.3218 5.1738 23.148 5 22.9243 5Z"
                            fill="white"
                          />
                        </svg>
                      )}
                    </button>

                    {/* <button className="btn btn-menu" onClick={handleMenu}>
                    <svg width="4" height="18" viewBox="0 0 5 18" fill="none">
                      <title>Menu</title>
                      <path
                        d="M2.25 13.5C2.84674 13.5 3.41903 13.7371 3.84099 14.159C4.26295 14.581 4.5 15.1533 4.5 15.75C4.5 16.3467 4.26295 16.919 3.84099 17.341C3.41903 17.7629 2.84674 18 2.25 18C1.65326 18 1.08097 17.7629 0.65901 17.341C0.237053 16.919 0 16.3467 0 15.75C0 15.1533 0.237053 14.581 0.65901 14.159C1.08097 13.7371 1.65326 13.5 2.25 13.5ZM2.25 6.75C2.84674 6.75 3.41903 6.98705 3.84099 7.40901C4.26295 7.83097 4.5 8.40326 4.5 9C4.5 9.59674 4.26295 10.169 3.84099 10.591C3.41903 11.0129 2.84674 11.25 2.25 11.25C1.65326 11.25 1.08097 11.0129 0.65901 10.591C0.237053 10.169 0 9.59674 0 9C0 8.40326 0.237053 7.83097 0.65901 7.40901C1.08097 6.98705 1.65326 6.75 2.25 6.75ZM2.25 0C2.84674 0 3.41903 0.237053 3.84099 0.65901C4.26295 1.08097 4.5 1.65326 4.5 2.25C4.5 2.84674 4.26295 3.41903 3.84099 3.84099C3.41903 4.26295 2.84674 4.5 2.25 4.5C1.65326 4.5 1.08097 4.26295 0.65901 3.84099C0.237053 3.41903 0 2.84674 0 2.25C0 1.65326 0.237053 1.08097 0.65901 0.65901C1.08097 0.237053 1.65326 0 2.25 0Z"
                        fill="white"
                      />
                    </svg>

                    {menuOpen && (
                      <div className="menu-options">
                        <span className="menu-option">Report</span>
                        <span className="menu-option">Mute</span>
                      </div>
                    )}
                  </button> */}
                  </div>
                </div>
              </div>

              <button
                className="btn btn-close"
                aria-label="Close"
                onClick={() => onClose(false)}
              >
                <svg width="20" height="20" viewBox="0 0 25 25" fill="none">
                  <title>Close</title>
                  <g id="iconoir:cancel">
                    <path
                      id="Vector"
                      d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                      stroke="white"
                      strokeWidth="2.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </header>

          {/* Add buttons here, back and forwards */}
          {backwardDisplay() && (
            <button
              className="btn btn-backwards"
              title="Backward"
              onClick={() => goBackward()}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <title>Backward</title>
                <g clipPath="url(#clip0_2240_1583)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.29559 11.2932C8.10812 11.4807 8.0028 11.735 8.0028 12.0002C8.0028 12.2653 8.10812 12.5197 8.29559 12.7072L13.9526 18.3642C14.0448 18.4597 14.1552 18.5359 14.2772 18.5883C14.3992 18.6407 14.5304 18.6683 14.6632 18.6694C14.796 18.6706 14.9276 18.6453 15.0505 18.595C15.1734 18.5447 15.2851 18.4705 15.379 18.3766C15.4729 18.2827 15.5471 18.171 15.5974 18.0481C15.6477 17.9252 15.673 17.7936 15.6718 17.6608C15.6707 17.528 15.6431 17.3968 15.5907 17.2748C15.5383 17.1528 15.4621 17.0424 15.3666 16.9502L10.4166 12.0002L15.3666 7.05018C15.5487 6.86158 15.6495 6.60898 15.6473 6.34678C15.645 6.08458 15.5398 5.83377 15.3544 5.64836C15.169 5.46295 14.9182 5.35778 14.656 5.35551C14.3938 5.35323 14.1412 5.45402 13.9526 5.63618L8.29559 11.2932Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2240_1583">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="matrix(-1 0 0 1 24 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
          )}
          {forwardDisplay() && (
            <button
              className="btn btn-forward"
              title="Forward"
              onClick={() => goForward()}
            >
              <svg width="24" height="24" viewBox="0 0 30 30" fill="none">
                <title>Forward</title>
                <g clipPath="url(#clip0_2164_34396)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.6344 14.1175C19.8688 14.3519 20.0004 14.6697 20.0004 15.0012C20.0004 15.3327 19.8688 15.6505 19.6344 15.885L12.5632 22.9562C12.4479 23.0756 12.3099 23.1708 12.1574 23.2363C12.0049 23.3018 11.8409 23.3363 11.6749 23.3378C11.509 23.3392 11.3444 23.3076 11.1907 23.2447C11.0371 23.1819 10.8975 23.0891 10.7802 22.9717C10.6628 22.8543 10.57 22.7148 10.5071 22.5611C10.4443 22.4075 10.4127 22.2429 10.4141 22.077C10.4156 21.911 10.45 21.747 10.5155 21.5944C10.5811 21.4419 10.6763 21.304 10.7957 21.1887L16.9832 15.0012L10.7957 8.8137C10.568 8.57795 10.442 8.2622 10.4448 7.93445C10.4477 7.6067 10.5791 7.29319 10.8109 7.06143C11.0427 6.82967 11.3562 6.69821 11.6839 6.69536C12.0117 6.69251 12.3274 6.8185 12.5632 7.0462L19.6344 14.1175Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2164_34396">
                    <rect width="30" height="30" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          )}

          {/* Image is blurred in the background */}
          <div
            className="background-blurred"
            style={{
              backgroundImage: `url(${getBackgroundImageUrl()})`,
            }}
            aria-hidden="true"
          />

          {/* //<--------- Without Maximum Container's Size ---------> */}
          <div
            className="storyMedia-view"
            // onMouseDown={() => isPlay && handleVideoPress(true)}
            // onMouseUp={() => handleVideoPress()}
            // onClick={() => handleVideoPress()}
          >
            {showSpinner && (
              <div className="loading-spinner">
                <Spinner />
              </div>
            )}

            <div ref={containerRef} className="media-container">
              <div
                className="media-view-container"
                onClick={() => handleVideoPress()}
              >
                {/* Thumbnail */}
                {currentMedia?.thumbnail && showSpinner && (
                  <div className="thumbnail-wrapper">
                    <img
                      ref={thumbnailRef}
                      src={currentMedia.thumbnail}
                      alt="thumbnail"
                      className="thumbnail"
                    />
                  </div>
                )}

                {/* Main Media */}
                {isImage ? (
                  <img
                    ref={mediaRef}
                    src={`${story_image_base_url}${storyImagePath}${currentMedia.media}`}
                    alt=""
                    className="media-element"
                    style={{
                      opacity: isLoading ? 0 : 1,
                      visibility: isLoading ? "hidden" : "visible",
                    }}
                  />
                ) : (
                  <video
                    ref={(el) => {
                      mediaRef.current = el;
                      if (typeof videoRef === "function") videoRef(el);
                      else if (videoRef) videoRef.current = el;
                    }}
                    src={`${story_video_base_url}${
                      currentMedia?.media?.split(".")[0]
                    }/MP4/${currentMedia?.media?.split(".")[0]}.mp4`}
                    autoPlay
                    muted={isMute}
                    loop
                    className="media-element"
                    style={{
                      opacity: isLoading ? 0 : 1,
                      visibility: isLoading ? "hidden" : "visible",
                    }}
                    playsInline
                  />
                )}
              </div>
              {storyData[currentMediaIndex]?.text === "" ? (
                <></>
              ) : (
                !isLoading && (
                  <div className="story-text-wrapper">
                    <p className="story-text-info">
                      {showFullMessage
                        ? storyData[currentMediaIndex]?.text
                        : storyData[currentMediaIndex]?.text?.slice(
                            0,
                            140
                          )}{" "}
                      {storyData[currentMediaIndex]?.text?.length > 140 &&
                        !showFullMessage && (
                          <span onClick={toggleReadMore} className="read-more">
                            ...Read More
                          </span>
                        )}
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
          {UserID === storyData[currentMediaIndex]?.userId?._id ||
          UserID === storyData[currentMediaIndex]?.webUserId?._id ? (
            <div
              className="story-viewed-number"
              onClick={() => {
                handleStoryViewedClick();
              }}
            >
              <div className="viewIcon">
                <svg width="20" height="20" viewBox="0 0 34 32" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.0928 11.8979C13.9036 11.8979 11.4231 14.3784 11.4231 17.5676C11.4231 20.7569 13.9036 23.2373 17.0928 23.2373C20.2821 23.2373 22.7626 20.7569 22.7626 17.5676C22.7626 14.3784 20.2821 11.8979 17.0928 11.8979ZM17.0928 21.4656C14.9667 21.4656 13.1949 19.6938 13.1949 17.5676C13.1949 15.4415 14.9667 13.6697 16.7385 13.6697C18.8646 13.6697 20.6364 15.4415 20.6364 17.5676C20.9908 19.6938 19.219 21.4656 17.0928 21.4656ZM33.0389 17.2133C31.2671 14.3784 29.141 12.2523 26.3061 10.4805C23.4713 9.06307 20.2821 8 17.0928 8C10.7144 8 4.33599 11.5436 1.14678 17.2133C0.792423 17.5676 1.14678 18.2764 1.50114 18.6307H1.85549C2.20985 18.6307 2.56421 18.6307 2.56421 18.2764C5.39906 12.961 10.7144 10.1261 16.7385 10.1261C22.7626 10.1261 28.0779 13.3154 30.9128 18.2764C31.2671 18.6307 31.6215 18.9851 32.3302 18.6307C33.0389 18.2764 33.0389 17.922 33.0389 17.2133Z"
                    fill="white"
                  />
                </svg>
              </div>
              <span className="viewed-number">
                {storyData[currentMediaIndex]?._id
                  ? getViewedCount(storyData[currentMediaIndex]?._id)
                  : 0}{" "}
              </span>
            </div>
          ) : storyData[currentMediaIndex]?.isadmin ? (
            <></>
          ) : (
            <footer
              className="story-reply"
              onClick={() => {
                handleReplyClick();
              }}
            >
              <div className="story-reply-wrapper">
                {/* Emojis and Stories Container */}
                <div className="emojis-stickers-container"></div>

                <div className="story-sendMessage">
                  <div className="story-sendMessage-cta">
                    <button className="btn btn-cancel">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <g id="iconoir:cancel">
                          <path
                            id="Vector"
                            d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                            stroke="white"
                            strokeWidth="2.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    </button>
                    <button className="btn btn-emoji">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 23 23"
                        fill="none"
                      >
                        <path
                          d="M17.7735 15.8103C16.9234 17.0466 15.727 18.0042 14.3346 18.5628C12.9421 19.1214 11.4156 19.2562 9.94681 18.9502C8.47801 18.6442 7.13235 17.911 6.07883 16.8428C5.02531 15.7746 4.3109 14.4189 4.02529 12.9459C3.88068 12.1912 4.56568 11.5912 5.32933 11.6812C6.93907 11.8664 9.57124 12.1341 11.4994 12.1341C13.4275 12.1341 16.0597 11.8677 17.6694 11.68C18.4331 11.5912 19.1181 12.1899 18.9735 12.9459C18.7762 13.9735 18.3676 14.9489 17.7735 15.8103ZM17.6745 12.9561C16.0508 13.1426 13.4516 13.4026 11.4994 13.4026C9.54587 13.4026 6.94668 13.1426 5.32425 12.9561C5.46379 13.5498 5.68705 14.1104 5.98007 14.6267C7.81108 14.8562 9.65408 14.9769 11.4994 14.9883C13.3447 14.977 15.1877 14.8562 17.0187 14.6267C17.3117 14.1104 17.535 13.5485 17.6745 12.9561ZM15.9493 16.0208C14.6554 16.1477 13.0888 16.2568 11.4994 16.2568C9.90993 16.2568 8.34332 16.1477 7.0507 16.0208C8.23598 17.19 9.83451 17.8445 11.4994 17.8424C13.1642 17.8444 14.7627 17.1899 15.9481 16.0208H15.9493ZM9.58139 10.2313C9.726 9.85838 9.80845 9.42455 9.80845 8.96281C9.80845 7.5611 9.05115 6.42578 8.11625 6.42578C7.18263 6.42578 6.42532 7.5611 6.42532 8.96281C6.42532 9.42455 6.50778 9.85838 6.65112 10.2313C6.94288 9.47275 7.49088 8.96281 8.11625 8.96281C8.7429 8.96281 9.28963 9.47275 9.58139 10.2313ZM16.5734 8.96281C16.5734 9.42455 16.491 9.85838 16.3476 10.2313C16.0559 9.47275 15.5079 8.96281 14.8825 8.96281C14.2559 8.96281 13.7091 9.47275 13.4174 10.2313C13.2648 9.82592 13.1878 9.39597 13.1903 8.96281C13.1903 7.5611 13.9476 6.42578 14.8825 6.42578C15.8161 6.42578 16.5734 7.5611 16.5734 8.96281Z"
                          fill="white"
                        />
                        <path
                          d="M11.4987 21.6478C14.1902 21.6478 16.7714 20.5786 18.6745 18.6755C20.5776 16.7723 21.6468 14.1911 21.6468 11.4997C21.6468 8.80823 20.5776 6.22702 18.6745 4.32388C16.7714 2.42074 14.1902 1.35156 11.4987 1.35156C8.80725 1.35156 6.22604 2.42074 4.3229 4.32388C2.41976 6.22702 1.35059 8.80823 1.35059 11.4997C1.35059 14.1911 2.41976 16.7723 4.3229 18.6755C6.22604 20.5786 8.80725 21.6478 11.4987 21.6478ZM11.4987 20.3793C9.14369 20.3793 6.88512 19.4438 5.21988 17.7785C3.55463 16.1133 2.6191 13.8547 2.6191 11.4997C2.6191 9.14466 3.55463 6.8861 5.21988 5.22085C6.88512 3.5556 9.14369 2.62008 11.4987 2.62008C13.8537 2.62008 16.1123 3.5556 17.7775 5.22085C19.4428 6.8861 20.3783 9.14466 20.3783 11.4997C20.3783 13.8547 19.4428 16.1133 17.7775 17.7785C16.1123 19.4438 13.8537 20.3793 11.4987 20.3793Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                    <button className="btn btn-sticker">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 23 23"
                        fill="none"
                      >
                        <path
                          d="M15.7275 1.35352H7.27166C5.70232 1.35519 4.19775 1.97935 3.08806 3.08904C1.97838 4.19873 1.35422 5.7033 1.35254 7.27263V15.7285C1.35422 17.2978 1.97838 18.8024 3.08806 19.9121C4.19775 21.0218 5.70232 21.646 7.27166 21.6476H12.3452C12.4361 21.6475 12.5263 21.6329 12.6126 21.6043C15.3851 20.6794 20.6785 15.3861 21.6033 12.6136C21.6319 12.5273 21.6465 12.437 21.6467 12.3462V7.27263C21.645 5.7033 21.0208 4.19873 19.9111 3.08904C18.8014 1.97935 17.2969 1.35519 15.7275 1.35352ZM3.04372 15.7285V7.27263C3.04372 6.15131 3.48916 5.07592 4.28205 4.28303C5.07494 3.49013 6.15034 3.04469 7.27166 3.04469H15.7275C16.8489 3.04469 17.9243 3.49013 18.7171 4.28303C19.51 5.07592 19.9555 6.15131 19.9555 7.27263V11.5006H17.4187C15.8494 11.5023 14.3448 12.1264 13.2351 13.2361C12.1254 14.3458 11.5013 15.8504 11.4996 17.4197V19.9565H7.27166C6.15034 19.9565 5.07494 19.511 4.28205 18.7181C3.48916 17.9252 3.04372 16.8498 3.04372 15.7285ZM13.1908 19.4428V17.4197C13.1908 16.2984 13.6362 15.223 14.4291 14.4301C15.222 13.6372 16.2974 13.1918 17.4187 13.1918H19.4418C18.1586 15.2529 15.2519 18.1596 13.1908 19.4428Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="message-wrapper active">
                    <div className="input-wrapper">
                      <div
                        placeholder="Type a reply..."
                        className="messageInput"
                        contentEditable={false}
                        ref={buttonRef}
                        onClick={() => {
                          handleVideoPress();
                        }}
                      />
                    </div>
                  </div>

                  <div role="button" className="send-message">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <g
                        id="mingcute:right-line"
                        clipPath="url(#clip0_519_2610)"
                      >
                        <g id="Group">
                          <path
                            id="Vector"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.707 11.2932C15.8944 11.4807 15.9998 11.735 15.9998 12.0002C15.9998 12.2653 15.8944 12.5197 15.707 12.7072L10.05 18.3642C9.95773 18.4597 9.84739 18.5359 9.72538 18.5883C9.60338 18.6407 9.47216 18.6683 9.33938 18.6694C9.2066 18.6706 9.07492 18.6453 8.95202 18.595C8.82913 18.5447 8.71747 18.4705 8.62358 18.3766C8.52969 18.2827 8.45544 18.171 8.40515 18.0481C8.35487 17.9252 8.32957 17.7936 8.33073 17.6608C8.33188 17.528 8.35947 17.3968 8.41188 17.2748C8.46428 17.1528 8.54047 17.0424 8.63598 16.9502L13.586 12.0002L8.63598 7.05018C8.45382 6.86158 8.35302 6.60898 8.3553 6.34678C8.35758 6.08458 8.46275 5.83377 8.64816 5.64836C8.83357 5.46295 9.08438 5.35778 9.34658 5.35551C9.60877 5.35323 9.86137 5.45402 10.05 5.63618L15.707 11.2932Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_519_2610">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </footer>
          )}
        </div>
      </div>
      {showReplyPopup && <ComingSoon onClose={handleClosePopup} />}
      {showStoryViewedPopup && (
        <div className="story-viewed-popup">
          <StoryViewed
            selectStoryId={storyData[currentMediaIndex]?._id || []}
            onClose={handleCloseStoryViewedPopup}
          />
        </div>
      )}
    </>
  );
}

export default ViewStory;
