import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Sharemenu from "../Sharemenu";
import { useSelector } from "react-redux";
import pdf from "../../../assets/image/pdf.png";
import star from "../../../assets/image/star.png";
import video from "../../../assets/image/video.png";
import photo from "../../../assets/image/photo.png";
import location from "../../../assets/image/location.png";
import { MessageAction } from "../../Common/MessageAction";
import downArrow from "../../../assets/image/downArrow.png";
import contactPic from "../../../assets/image/contactPic.png";
import contactgreen from "../../../assets/image/SVG_Images/contact-user-green.svg";
import {
  MAP_UI,
  MAP_API,
  LINK_REGX,
  EMAIL_REGX,
  MAP_API_KEY,
  CONTACT_REGX,
  RECEIVER_TEXT,
  SENDER_TEXT_REPLY,
  RECEIVER_TEXT_REPLY,
} from "../../../Utils/enums";
import {
  getReplyType,
  getMessageText,
  getTimeFromDate,
  getMessageStatus,
  TN_CHAT_IMAGE_BASE_URL,
  TN_THUMB_VIDEO_IMAGE_BASE_URL,
} from "../../../Utils/helpers";
import "../../../assets/css/chatReply.scss";

function ChatReply({ message, messages, forward }) {
  const dropdownRef = useRef(null);
  const downArrowRef = useRef(null);

  const isUrl = message.message.match(LINK_REGX);
  const isEmail = EMAIL_REGX.test(message.message);
  const isPhone = CONTACT_REGX.test(message.message);
  const MessageMedia = useSelector((state) => state.messageMedia);
  const chatBoxContact = useSelector((state) => state.chatBoxContact);

  const [images, setImages] = useState();
  const [mapImage, setMapImage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [replyedMassage, setReplyedMassage] = useState();
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const replyedMassage = messages?.find(
      (msg) => msg?._id === message?.messageReferance
    );
    setReplyedMassage(replyedMassage);
  }, [message, messages]);

  useEffect(() => {
    const images = MessageMedia?.find((image) => {
      return image?.messageId === replyedMassage?._id;
    });
    setImages(images);
  }, [message, MessageMedia, replyedMassage]);

  const toggleReadMore = () => {
    setShowFullMessage((prevState) => !prevState);
  };

  const convertToFullURL = () => {
    if (message?.message.startsWith("www.")) {
      return "http://" + message?.message;
    } else {
      return "/";
    }
  };

  useEffect(() => {
    const fetchMapImage = async () => {
      try {
        const location = replyedMassage?.location;
        const URL = `${MAP_API}staticmap?center=${location?.latitude},${location?.longitude}${MAP_UI}${location?.latitude},${location?.longitude}${MAP_API_KEY}`;
        const response = await axios.get(URL);
        setMapImage(response?.request?.responseURL);
      } catch (error) {
        console.error("Error fetching map image:", error);
      }
    };
    fetchMapImage();
  }, [replyedMassage?.location]);

  const toggleShareMenu = (event) => {
    event.preventDefault();
    const arrowRect = downArrowRef?.current?.getBoundingClientRect();
    const { right, top, left } = arrowRect;
    const isReceiverText = message?.messageType === RECEIVER_TEXT;
    const position = isReceiverText
      ? { x: left, y: top }
      : { x: right, y: top };
    setMenuPosition(position);
    setIsShareMenuOpen(true);
    setScrollEnabled(false);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [scrollEnabled]);

  const closeDropdown = (event) => {
    if (
      !dropdownRef?.current ||
      !dropdownRef?.current.contains(event?.target)
    ) {
      setIsShareMenuOpen(false);
      setScrollEnabled(true);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef?.current && !dropdownRef?.current.contains(event?.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isShareMenuOpen) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isShareMenuOpen]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  }, []);

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      {/* for Text */}
      {getReplyType(message, replyedMassage) === SENDER_TEXT_REPLY && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="textReplySend">
              <div className="wrapper">
                <p className="sender">You</p>
                <p className="common-message">{replyedMassage?.message}</p>
                <span className="space" />
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                  {message?.message?.length > 650 && !showFullMessage && (
                    <span onClick={toggleReadMore} className="read-more">
                      ...Read More
                    </span>
                  )}
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="reply-status"
                    src={getMessageStatus(message)}
                    alt="img-status"
                  />
                </div>
              </div>
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {getReplyType(message, replyedMassage) === RECEIVER_TEXT_REPLY && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="textReplyRecieved">
              <div className="wrapper">
                <p className="sender">{chatBoxContact?.contact?.name}</p>
                <p className="common-message">{replyedMassage?.message}</p>
                <span className="space" />
              </div>
              <div className="replyText additional">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>

                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                </div>
              </div>
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {/* for Image */}
      {getReplyType(message, replyedMassage) === "SenderReplyImage" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="imgReplySend">
              <div className="wrapper">
                <div className="info">
                  <p className="sender">You</p>
                  <div className="description">
                    <img src={photo} alt="#" />
                    <span>Photo</span>
                  </div>
                </div>
                <div className="image">
                  <img
                    src={TN_CHAT_IMAGE_BASE_URL + images?.mediaUrl}
                    className="img-reply"
                    alt="gallery-img"
                  />
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="reply-status"
                    src={getMessageStatus(message)}
                    alt="img-status"
                  />
                </div>
              </div>
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {getReplyType(message, replyedMassage) === "ReceiverReplyImage" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="imgReplyRecieved">
              <div className="wrapper">
                <div className="info">
                  <p className="sender">{chatBoxContact?.contact?.name}</p>
                  <div className="description">
                    <img src={photo} alt="#" />
                    <span>Photo</span>
                  </div>
                </div>
                <div className="image">
                  <img
                    src={
                      TN_CHAT_IMAGE_BASE_URL + replyedMassage?.media?.mediaUrl
                    }
                    className="img-reply"
                    alt="gallery-img"
                  />
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                </div>
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {/* for Video */}
      {getReplyType(message, replyedMassage) === "SenderReplyVideo" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="videoReplySend">
              <div className="wrapper">
                <div className="info">
                  <p className="sender">You</p>
                  <div className="description">
                    <img src={video} alt="#" />
                    <span>Video</span>
                  </div>
                </div>
                <div className="video">
                  <img
                    src={TN_THUMB_VIDEO_IMAGE_BASE_URL + images?.thumbUrl}
                    className="video-reply"
                    alt="video-img"
                  />
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="reply-status"
                    src={getMessageStatus(message)}
                    alt="img-status"
                  />
                </div>
              </div>
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {getReplyType(message, replyedMassage) === "ReceiverReplyVideo" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="videoReplyRecieved">
              <div className="wrapper">
                <div className="info">
                  <p className="sender">{message?.name}</p>
                  <div className="description">
                    <img src={video} alt="#" />
                    <span>Video</span>
                  </div>
                </div>
                <div className="video">
                  <img
                    src={TN_THUMB_VIDEO_IMAGE_BASE_URL + images?.thumbUrl}
                    className="video-reply"
                    alt="video-img"
                  />
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                </div>
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {/* for Audio */}
      {getReplyType(message, replyedMassage) === "SenderReplyAudio" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="audioReplySend">
              <div className="wrapper">
                <p className="sender">You</p>
                <div className="description">
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path
                      d="M1.27053 6.35263H3.17632C3.51328 6.35263 3.83644 6.48649 4.07471 6.72476C4.31298 6.96303 4.44684 7.28619 4.44684 7.62316V10.7995C4.44684 11.1364 4.31298 11.4596 4.07471 11.6979C3.83644 11.9361 3.51328 12.07 3.17632 12.07H1.27053C0.933562 12.07 0.610398 11.9361 0.372128 11.6979C0.133859 11.4596 0 11.1364 0 10.7995V6.35263C0 2.84407 2.84407 0 6.35263 0C9.86119 0 12.7053 2.84407 12.7053 6.35263V10.7995C12.7053 11.1364 12.5714 11.4596 12.3331 11.6979C12.0949 11.9361 11.7717 12.07 11.4347 12.07H9.52894C9.19198 12.07 8.86882 11.9361 8.63055 11.6979C8.39228 11.4596 8.25842 11.1364 8.25842 10.7995V7.62316C8.25842 7.28619 8.39228 6.96303 8.63055 6.72476C8.86882 6.48649 9.19198 6.35263 9.52894 6.35263H11.4347C11.4347 5.00477 10.8993 3.71212 9.94622 2.75904C8.99314 1.80596 7.70049 1.27053 6.35263 1.27053C5.00477 1.27053 3.71212 1.80596 2.75904 2.75904C1.80596 3.71212 1.27053 5.00477 1.27053 6.35263Z"
                      fill="#8E8E93"
                    />
                  </svg>
                  <p>Audio</p>
                  {/* <span>(0.30)</span> */}
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="reply-status"
                    src={getMessageStatus(message)}
                    alt="img-status"
                  />
                </div>
              </div>
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {getReplyType(message, replyedMassage) === "ReceiverReplyAudio" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="audioReplyRecieved">
              <div className="wrapper">
                <p className="sender">{message?.name}</p>
                <div className="description">
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path
                      d="M1.27053 6.35263H3.17632C3.51328 6.35263 3.83644 6.48649 4.07471 6.72476C4.31298 6.96303 4.44684 7.28619 4.44684 7.62316V10.7995C4.44684 11.1364 4.31298 11.4596 4.07471 11.6979C3.83644 11.9361 3.51328 12.07 3.17632 12.07H1.27053C0.933562 12.07 0.610398 11.9361 0.372128 11.6979C0.133859 11.4596 0 11.1364 0 10.7995V6.35263C0 2.84407 2.84407 0 6.35263 0C9.86119 0 12.7053 2.84407 12.7053 6.35263V10.7995C12.7053 11.1364 12.5714 11.4596 12.3331 11.6979C12.0949 11.9361 11.7717 12.07 11.4347 12.07H9.52894C9.19198 12.07 8.86882 11.9361 8.63055 11.6979C8.39228 11.4596 8.25842 11.1364 8.25842 10.7995V7.62316C8.25842 7.28619 8.39228 6.96303 8.63055 6.72476C8.86882 6.48649 9.19198 6.35263 9.52894 6.35263H11.4347C11.4347 5.00477 10.8993 3.71212 9.94622 2.75904C8.99314 1.80596 7.70049 1.27053 6.35263 1.27053C5.00477 1.27053 3.71212 1.80596 2.75904 2.75904C1.80596 3.71212 1.27053 5.00477 1.27053 6.35263Z"
                      fill="#8E8E93"
                    />
                  </svg>
                  <p>Audio</p>
                  {/* <span className="time">(0.30)</span> */}
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                </div>
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {/* for Contact */}
      {getReplyType(message, replyedMassage) === "SenderReplyContact" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="contactReplySend">
              <div className="wrapper">
                <div className="info">
                  <p className="sender">You</p>
                  <div className="description">
                    <img src={contactgreen} alt="#" />
                    <p>Contact:</p>
                    <span className="name">
                      {replyedMassage && replyedMassage?.Contact[0]?.name}
                    </span>
                  </div>
                </div>
                <div className="contact">
                  <img
                    src={contactPic}
                    className="contact-reply"
                    alt="contact-img"
                  />
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="reply-status"
                    src={getMessageStatus(message)}
                    alt="img-status"
                  />
                </div>
              </div>
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {getReplyType(message, replyedMassage) === "ReceiverReplyContact" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="contactReplyRecieved">
              <div className="wrapper">
                <div className="info">
                  <p className="sender">{message?.name}</p>
                  <div className="description">
                    <img src={contactgreen} alt="#" />
                    <span>Contact:</span>
                    <span className="name">
                      {replyedMassage?.contact[0]?.contactName}
                    </span>
                  </div>
                </div>
                <div className="contact">
                  <img
                    src={contactPic}
                    className="contact-reply"
                    alt="contact-img"
                  />
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                </div>
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {/* for Document */}
      {getReplyType(message, replyedMassage) === "SenderReplyDocument" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="documentReplySend">
              <div className="wrapper">
                <div className="info">
                  <p className="sender">You</p>
                  <div className="description">
                    <img src={pdf} alt="#" />
                    <span>Document</span>
                  </div>
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="reply-status"
                    src={getMessageStatus(message)}
                    alt="img-status"
                  />
                </div>
              </div>
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {getReplyType(message, replyedMassage) === "ReceiverReplyDocument" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="documentReplyRecieved">
              <div className="wrapper">
                <div className="info">
                  <p className="sender">{message?.name}</p>
                  <div className="description">
                    <img src={pdf} alt="#" />
                    <span>Document</span>
                  </div>
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                </div>
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {/* for Location */}
      {getReplyType(message, replyedMassage) === "SenderReplyLocation" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="locationReplySend">
              <div className="wrapper">
                <div className="info">
                  <p className="sender">You</p>
                  <div className="description">
                    <img src={location} alt="#" />
                    <span>Location</span>
                  </div>
                </div>
                <div className="location">
                  <img
                    src={mapImage}
                    className="location-reply"
                    alt="location"
                  />
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="reply-status"
                    src={getMessageStatus(message)}
                    alt="img-status"
                  />
                </div>
              </div>
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {getReplyType(message, replyedMassage) === "ReceiverReplyLocation" && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="locationReplyRecieved">
              <div className="wrapper">
                <div className="info">
                  <p className="sender">{message?.name}</p>
                  <div className="description">
                    <img src={location} alt="#" />
                    <span>Location</span>
                  </div>
                </div>
                <div className="location">
                  <img
                    src={mapImage}
                    className="location-reply"
                    alt="location"
                  />
                </div>
              </div>
              <div className="replyText">
                <p className="reply-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message?.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <a href="/" className="additional">
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="additional"
                    >
                      {message?.message}
                    </a>
                  ) : (
                    getMessageText(showFullMessage, message)
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {/* <img className="important" src={star} alt="important" /> */}
                  <span className="date">{getTimeFromDate(message)}</span>
                </div>
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {isShareMenuOpen && (
        <Sharemenu
          ddref={dropdownRef}
          menuPosition={menuPosition}
          isFromReceiver={message?.messageType === RECEIVER_TEXT}
        />
      )}
    </>
  );
}

export default ChatReply;
