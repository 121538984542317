import React, { useEffect, useRef, useState } from "react";
import Sharemenu from "./Sharemenu";
import WaveSurfer from "wavesurfer.js";
import { useSelector } from "react-redux";
import star from "../../assets/image/star.png";
import DeleteMessage from "../Group/DeleteMessage";
import playBtn from "../../assets/image/audioPlay.png";
import pauseBtn from "../../assets/image/Pausebtn.png";
import { MessageAction } from "../Common/MessageAction";
import downArrow from "../../assets/image/downArrow.png";
import {
  getTimeFromDate,
  getMessageStatus,
  convertSecondsToTimeFormat,
  TN_CLOUD_FRONT_BUCKET_AUDIO_BASE_URL,
} from "../../Utils/helpers";
import {
  SENDER_AUDIO,
  RECEIVER_AUDIO,
  SENDER_TEXT_REPLY,
} from "../../Utils/enums";
import "../../assets/css/audio.scss";

function Audio({
  message,
  tapped,
  getTappedMessage,
  getSenderMessageType,
  getIds,
  showForward,
  setForwardMessage,
  forward,
  deleteMessage,
}) {
  const waveSurferRef = useRef({
    playing: () => false,
  });
  const downArrowRef = useRef(null);
  const dropdownRef = useRef(null);

  const [playing, setPlay] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [deletedMessage, setDeletedMessage] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const toggleShareMenu = (event) => {
    event.preventDefault();
    const arrowRect = downArrowRef.current.getBoundingClientRect();
    const { right, top, left } = arrowRect;
    const isReceiverText = message?.type === RECEIVER_AUDIO;
    const position = isReceiverText
      ? { x: left, y: top }
      : { x: right, y: top };
    setMenuPosition(position);
    setIsShareMenuOpen(true);
    setScrollEnabled(false);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [scrollEnabled]);

  const closeDropdown = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event.target)) {
      setIsShareMenuOpen(false);
      setScrollEnabled(true);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isShareMenuOpen) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isShareMenuOpen]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  }, []);

  const onDoubleTapped = () => {
    tapped(true);
    getTappedMessage(message);
    getSenderMessageType(SENDER_TEXT_REPLY);
    getIds(message?._id);
    handleClickOutside();
  };

  const onForwardMessage = (message) => {
    showForward(true);
    setForwardMessage(message);
  };

  useEffect(() => {
    if (waveSurferRef?.current) {
      const waveSurfer = WaveSurfer.create({
        container: waveSurferRef.current,
        progressColor: "#1891A2",
        waveColor: "#939499",
        height: 15,
        width: 140,
        barWidth: 1.5,
        barGap: 1.5,
        normalize: true,
        barRadius: 2,
        url: TN_CLOUD_FRONT_BUCKET_AUDIO_BASE_URL + message?.media?.mediaUrl,
      });
      waveSurfer.load(
        TN_CLOUD_FRONT_BUCKET_AUDIO_BASE_URL + message?.media?.mediaUrl
      );
      waveSurfer.on("ready", () => {
        waveSurferRef.current = waveSurfer;
      });
      waveSurfer.on("finish", () => {
        setPlay(false);
        waveSurfer.setTime(0);
      });

      return () => {
        waveSurfer.destroy();
      };
    }
  }, []);

  const handlePlayPause = () => {
    setPlay(!playing);
    waveSurferRef.current.playPause();
  };

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const onDelete = () => {
    setDeletedMessage(true);
  };

  const onStar = async () => {
    // Realm.write(() => {
    //   Realm.create(
    //     "RMessageModel",
    //     {
    //       _id: message._id,
    //       isStarMessage: !message?.isStarMessage,
    //     },
    //     "modified"
    //   );
    // });
    setIsShareMenuOpen(false);
    setScrollEnabled(true);
  };

  return (
    <>
      {message?.type === RECEIVER_AUDIO && (
        <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="audioRecieved">
              <div className="wrapper">
                <div className="audio">
                  {message?.media?.mediaStatus === "Download" && (
                    <>
                      {!playing && (
                        <div className="audio-img">
                          <img
                            className="audioPlay"
                            src={playBtn}
                            alt="play"
                            onClick={() => handlePlayPause()}
                          />
                        </div>
                      )}
                      {playing && (
                        <div className="audio-img">
                          <img
                            className="audioPlay"
                            src={pauseBtn}
                            alt="play"
                            onClick={() => handlePlayPause()}
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className="audio-details">
                    <div id="waveform" className="wave" ref={waveSurferRef} />
                    <span className="duration">
                      {convertSecondsToTimeFormat(message?.media)}
                    </span>
                  </div>
                </div>
                <div className="message-status">
                  {message?.isStarMessage && (
                    <img className="important" src={star} alt="important" />
                  )}
                  <span className="date">{getTimeFromDate(message)}</span>
                </div>
                <div className="down-arrow">
                  <img
                    src={downArrow}
                    ref={downArrowRef}
                    alt="down"
                    role="button"
                    onClick={toggleShareMenu}
                  />
                </div>
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {message?.type === SENDER_AUDIO && (
        <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="audioSend">
              <div className="wrapper">
                <div className="audio">
                  <>
                    {!playing && (
                      <div className="audio-img">
                        <img
                          className="audioPlay"
                          src={playBtn}
                          alt="play"
                          onClick={() => handlePlayPause()}
                        />
                      </div>
                    )}
                    {playing && (
                      <div className="audio-img">
                        <img
                          className="audioPlay"
                          src={pauseBtn}
                          alt="play"
                          onClick={() => handlePlayPause()}
                        />
                      </div>
                    )}
                  </>
                  <div className="audio-details">
                    <div id="waveform" className="wave" ref={waveSurferRef} />
                    <span className="duration">
                      {convertSecondsToTimeFormat(message?.media)}
                    </span>
                  </div>
                </div>
                <div className="message-status">
                  {message?.isStarMessage && (
                    <img className="important" src={star} alt="important" />
                  )}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="audio-status"
                    src={getMessageStatus(message)}
                    alt="seen"
                  />
                </div>
                <div className="down-arrow">
                  <img
                    src={downArrow}
                    ref={downArrowRef}
                    alt="down"
                    role="button"
                    onClick={toggleShareMenu}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {isShareMenuOpen && (
        <Sharemenu
          ddref={dropdownRef}
          menuPosition={menuPosition}
          isFromReceiver={message?.type === RECEIVER_AUDIO}
          onReplyClick={onDoubleTapped}
          onDeleteClick={onDelete}
          onStarClick={onStar}
          message={message}
        />
      )}
      {deletedMessage && (
        <DeleteMessage
          message={message}
          setDeleteMessage={setDeletedMessage}
          deleteMessage={deleteMessage}
        />
      )}
    </>
  );
}

export default Audio;
