import React, { useContext, useEffect, useState } from "react";
import Login from "./Login";
import Cookies from "js-cookie";
import { ERROR } from "../Utils/enums";
import axiosRequest from "../Middleware/api";
import OpenFire from "../Connection/OpenFire";
import Spinner from "../component/Common/Spinner";
import UseHere from "../component/Common/UseHere";
import SocketContext from "../Contexts/SocketContext";
import { useDispatch, useSelector } from "react-redux";
import loginLogo from "../assets/image/SVG_Images/light.webp";
import loginLogoDark from "../assets/image/SVG_Images/dark.webp";
import {
  resetStore,
  handleToast,
  setSettings,
  setSelfContact,
  setAuthenticateSync,
} from "../Redux/actions";
import {
  getDeviceOS,
  getDeviceId,
  getSessionId,
  getBrowserName,
} from "../Utils/helpers";
import "../assets/css/loadingPage.scss";

function LoadingPage() {
  const dispatch = useDispatch();

  const token = Cookies.get("token");

  const socket = useContext(SocketContext);

  const theme = useSelector((state) => state.theme);
  const authSync = useSelector((state) => state.authSync);
  const currentLocation = useSelector((state) => state.currentLocation);
  const [errorMessage, setErrorMessage] = useState();
  const [statusCode, setStatusCode] = useState();
  const [userSuccess, setUserSuccess] = useState(false);
  const [showUseHere, setShowUseHere] = useState(false);

  useEffect(() => {
    if (token) {
      userAuthentication();
    } else {
      dispatch(setAuthenticateSync("login"));
    }
  }, []);

  const handleGetStartedEmit = () => {
    socket.emit("webGetStarted", {
      Authorization: `Bearer ${token}`,
      deviceId: getDeviceId(),
      latitude: currentLocation?.latitude,
      longitude: currentLocation?.longitude,
      city: currentLocation?.city,
      deviceType: getBrowserName().browser,
      session: getSessionId(),
      devicename: getDeviceOS(),
      version: getBrowserName().version,
    });
  };

  const handleLoginSuccess = () => {
    dispatch(setAuthenticateSync("synced"));
  };

  const handleWebConnectionError = (error) => {
    // console.log("handleWebConnectionError", error);
    if (error?.success === false) {
      setStatusCode(error.status);
      setShowUseHere(true);
      setErrorMessage(error?.message);
    } else if (error?.status === 500 && error?.type === "logout") {
      logoutSuccess(error.session);
    }
  };

  const logoutSuccess = (data) => {
    if (data === getSessionId()) {
      Cookies.remove("token");
      Cookies.remove("user_id");
      Cookies.remove("sessionId");
      localStorage.clear();
      dispatch(resetStore());
      dispatch(setAuthenticateSync("login"));
      window.location.reload();
    }
  };

  const getStarted = () => {
    if (socket) {
      handleGetStartedEmit();
      socket.on("loginSuccess", handleLoginSuccess);
      socket.on("logOutSuccess", logoutSuccess);
      socket.on("webConnectionError", handleWebConnectionError);
    }
  };

  const userAuthentication = async () => {
    await axiosRequest
      .get("user/web/sync")
      .then((response) => {
        if (response?.data?.status === "200") {
          const success = response?.data?.data;
          setUserSuccess(true);
          dispatch(setSettings(success?.Privacy));
          dispatch(setSelfContact(success?.userData));
          localStorage.setItem("user_id", success?.userData?._id);
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          setUserSuccess(false);
          dispatch(handleToast(ERROR));
          dispatch(setAuthenticateSync("login"));
        }
      });
  };

  useEffect(() => {
    if (userSuccess) {
      getStarted();
    }
  }, [userSuccess]);

  return (
    <>
      {authSync === "login" ? (
        <Login />
      ) : authSync === "synced" ? (
        <OpenFire />
      ) : (
        <div className="loadingPage-wrapper">
          <div className="loadingPage">
            <div className="loadingPage-image">
              <img
                src={
                  theme === "Light"
                    ? loginLogo
                    : theme === "Dark"
                    ? loginLogoDark
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? loginLogo
                    : loginLogoDark
                }
                alt="logo"
              />
            </div>

            {/* <div className={`loader-line ${progress}`} id="loader-line" /> */}
            {/* <Spinner /> */}

            <div className="loadingPage-text">
              <h1 className="title">Loading your chats</h1>
            </div>

            <div className="loadingPage-info">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
                <g clipPath="url(#clip0_2100_42644)">
                  <path
                    d="M7.08557 2.04297L8.44668 3.40408H14.2899C14.4704 3.40408 14.6435 3.47578 14.7712 3.60341C14.8988 3.73104 14.9705 3.90414 14.9705 4.08464V13.6124C14.9705 13.7929 14.8988 13.966 14.7712 14.0936C14.6435 14.2213 14.4704 14.293 14.2899 14.293H2.03993C1.85944 14.293 1.68633 14.2213 1.55871 14.0936C1.43108 13.966 1.35938 13.7929 1.35938 13.6124V2.72352C1.35938 2.54303 1.43108 2.36993 1.55871 2.2423C1.68633 2.11467 1.85944 2.04297 2.03993 2.04297H7.08557ZM8.16493 6.1263C7.86531 6.12632 7.57407 6.22519 7.33638 6.40759C7.09868 6.58999 6.92781 6.84572 6.85026 7.13513C6.77271 7.42453 6.79282 7.73144 6.90747 8.00825C7.02212 8.28507 7.22491 8.51631 7.48438 8.66614V11.5707H8.84549L8.84617 8.66614C9.10569 8.51629 9.3085 8.28498 9.42313 8.0081C9.53777 7.73122 9.55783 7.42424 9.4802 7.1348C9.40256 6.84536 9.23157 6.58962 8.99376 6.40728C8.75595 6.22493 8.46461 6.12617 8.16493 6.1263Z"
                    fill="#999999"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2100_42644">
                    <rect width="16.3333" height="16.3333" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <p className="info">End-to-end encrypted</p>
            </div>

            <button className="btn btn-logout">Log Out</button>
          </div>
        </div>
      )}
      {showUseHere && (
        <UseHere
          status={statusCode}
          message={errorMessage}
          setShowUseHere={setShowUseHere}
          webConnection={() => handleGetStartedEmit()}
        />
      )}
    </>
  );
}

export default LoadingPage;
