import React, { useState } from "react";
import Spinner from "../Common/Spinner";
import { useSelector } from "react-redux";
import { getGridTepars } from "../../Utils/helpers";
import "../../assets/css/media.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function Media({ mediaRef, onScroll, loading, handlePostSelect }) {
  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const teparsLoad = useSelector((state) => state.teparsLoad);

  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (index) => {
    setImageErrors((prev) => ({ ...prev, [index]: true }));
  };

  return (
    <>
      {teparsLoad ? (
        <div className="tepars-profile-media">
          <div className="media-container" style={{ rowGap: "0px" }}>
            {Array.from({ length: 21 }).map((posts, index) => (
              <SkeletonTheme
                baseColor={
                  theme === "Light"
                    ? ""
                    : theme === "Dark"
                    ? "#202020"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? ""
                    : "#202020"
                }
                highlightColor={
                  theme === "Light"
                    ? ""
                    : theme === "Dark"
                    ? "#2A2A2A"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? ""
                    : "#2A2A2A"
                }
              >
                <div className="media">
                  <Skeleton width={130} height={130} />
                </div>
              </SkeletonTheme>
            ))}
          </div>
        </div>
      ) : (
        <>
          {Tepars && Tepars?.length > 0 ? (
            <div
              ref={mediaRef}
              onScroll={() => onScroll()}
              className="tepars-profile-media"
            >
              <div className="media-container">
                {Tepars?.map((post, index) => (
                  <div
                    className="media"
                    key={index}
                    tabIndex="0"
                    role="listitem"
                    onClick={() => handlePostSelect(post?._id)}
                  >
                    {getGridTepars(post.media[0]) && !imageErrors[index] ? (
                      <img
                        src={getGridTepars(post.media[0])}
                        alt="post media"
                        onError={() => handleImageError(index)}
                      />
                    ) : (
                      <div className="blank-image"></div>
                    )}
                    {post?.isPin ? (
                      <div className="media-type">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_6430_9262)">
                            <path
                              d="M4.35097 9.59681C4.34768 9.54451 4.3621 9.49277 4.39168 9.44982L4.43199 9.40416L5.66882 8.16733L5.66958 8.16657C5.68414 8.15196 5.70055 8.13936 5.71839 8.12908L5.76496 8.10913L8.91543 6.92646L9.03695 6.88084L9.12876 6.78906L11.7713 4.1474L12.0861 3.83265L11.8761 3.44008C11.8509 3.39291 11.8427 3.3385 11.8528 3.28597C11.8629 3.23344 11.8907 3.18597 11.9316 3.15152C11.9726 3.11707 12.0241 3.09773 12.0776 3.09674C12.1253 3.09586 12.1738 3.11038 12.2152 3.13936L12.2618 3.1805L16.8308 7.74953C16.831 7.74966 16.8311 7.74978 16.8312 7.74991C16.8703 7.78941 16.894 7.84158 16.8981 7.89703C16.9022 7.95266 16.8862 8.0079 16.8531 8.05276C16.8199 8.09762 16.7718 8.12911 16.7174 8.14155C16.663 8.15398 16.606 8.14652 16.5567 8.12053L16.1655 7.91451L15.8529 8.22707L13.2095 10.8696L13.1176 10.9615L13.0719 11.0832L11.8853 14.2474L11.8851 14.2477C11.8774 14.2685 11.8667 14.288 11.8534 14.3057L11.8187 14.3454L10.5832 15.5809C10.5831 15.581 10.583 15.5811 10.583 15.5811C10.5428 15.6212 10.4894 15.6452 10.4328 15.6487C10.3801 15.652 10.3279 15.6373 10.2848 15.6072L10.2378 15.5661L7.92458 13.2537L7.50037 12.8297L7.07618 13.2537L3.91602 16.4131C3.9159 16.4132 3.91579 16.4133 3.91568 16.4134C3.87372 16.4551 3.81752 16.4792 3.75843 16.481C3.69917 16.4828 3.64145 16.462 3.59698 16.4228C3.55252 16.3836 3.52464 16.329 3.51902 16.2699C3.51383 16.2154 3.52798 16.161 3.55875 16.116L3.59869 16.0708L6.74555 12.9231L7.16962 12.4989L6.74557 12.0747L4.4189 9.74721L4.41884 9.74715C4.37867 9.70697 4.35453 9.65352 4.35097 9.59681Z"
                              stroke="white"
                              stroke-width="1.2"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6430_9262">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    ) : (
                      <>
                        {post?.media?.length > 1 && (
                          <div className="media-type">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 26 25"
                              fill="none"
                            >
                              <path
                                d="M17.167 3.125H4.66699V16.6667"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.83398 7.29175H21.334V19.7917C21.334 20.3443 21.1145 20.8742 20.7238 21.2649C20.3331 21.6556 19.8032 21.8751 19.2507 21.8751H10.9173C10.3648 21.8751 9.83488 21.6556 9.44418 21.2649C9.05348 20.8742 8.83398 20.3443 8.83398 19.7917V7.29175Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        )}
                        {post?.media?.length <= 1 &&
                          post?.media[0]?.type === "Video" && (
                            <div className="media-type">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <rect
                                  x="3.42861"
                                  y="2.00002"
                                  width="13.1429"
                                  height="13.1429"
                                  rx="1.57143"
                                  stroke="white"
                                  stroke-width="1.14286"
                                />
                                <path
                                  d="M4.28564 17.1429L15.7142 17.1429"
                                  stroke="white"
                                  stroke-width="1.14286"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M12.9285 8.69514L8.64279 11.1695C8.54755 11.2245 8.4285 11.1558 8.4285 11.0458V6.09706C8.4285 5.98709 8.54755 5.91836 8.64279 5.97334L12.9285 8.4477C13.0237 8.50269 13.0237 8.64015 12.9285 8.69514Z"
                                  stroke="white"
                                  stroke-width="1.14286"
                                />
                              </svg>
                            </div>
                          )}
                      </>
                    )}
                    {/* <div className="story-viewed-number">
                      <div className="viewIcon">
                        <img src={view} alt="view" />
                        <span className="viewed-number">12</span>
                      </div>
                    </div> */}
                  </div>
                ))}
              </div>
              {loading && <Spinner />}
            </div>
          ) : (
            <div className="media-post">
              <div className="default-media-wrapper">
                <div className="default-media">
                  <div className="default-media-image">
                    <svg width="72" height="69" viewBox="0 0 72 69" fill="none">
                      <path
                        d="M36 45.9336C40.9706 45.9336 45 42.128 45 37.4336C45 32.7392 40.9706 28.9336 36 28.9336C31.0294 28.9336 27 32.7392 27 37.4336C27 42.128 31.0294 45.9336 36 45.9336Z"
                        stroke={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                        strokeWidth="2.25"
                      />
                      <path
                        d="M29.334 60.1017H42.666C52.029 60.1017 56.712 60.1017 60.075 58.0192C61.5261 57.121 62.7758 55.9618 63.753 54.6079C66 51.4912 66 47.1477 66 38.4664C66 29.7822 66 25.4415 63.753 22.3249C62.7759 20.9708 61.5261 19.8117 60.075 18.9135C57.915 17.5734 55.209 17.0945 51.066 16.9245C49.089 16.9245 47.388 15.5362 47.001 13.737C46.7052 12.4192 45.9368 11.2381 44.8255 10.3936C43.7143 9.54898 42.3284 9.09265 40.902 9.10169H31.098C28.134 9.10169 25.581 11.0425 24.999 13.737C24.612 15.5362 22.911 16.9245 20.934 16.9245C16.794 17.0945 14.088 17.5762 11.925 18.9135C10.4749 19.8119 9.22612 20.971 8.25 22.3249C6 25.4415 6 29.7822 6 38.4664C6 47.1477 6 51.4884 8.247 54.6079C9.219 55.9565 10.467 57.1154 11.925 58.0192C15.288 60.1017 19.971 60.1017 29.334 60.1017Z"
                        stroke={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                        strokeWidth="2.25"
                      />
                      <path
                        d="M57 28.9336H54"
                        stroke={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                        strokeWidth="2.25"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                  <span className="default-text">No posts yet</span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Media;
