import React, { useState, useRef, useEffect } from "react";
import HeaderMenu from "./HeaderMenu";
import { useSelector } from "react-redux";
import { getUserProfilePic, toggleUserStatus } from "../../Utils/helpers";
import "../../assets/css/header.scss";

function Headerprofile({
  setUserProfileToggle,
  getUserProfileData,
  checkMediaToggle,
  setUserMediaToggle,
}) {
  const menuRef = useRef(null);
  const menubuttonRef = useRef(null);

  const theme = useSelector((state) => state.theme);
  const chatBoxContact = useSelector((state) => state.chatBoxContact);

  const [isOpen, setIsOpen] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(true);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
    setScrollEnabled((prev) => !prev);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [scrollEnabled]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isOpen &&
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        menubuttonRef.current &&
        !menubuttonRef.current.contains(e.target)
      ) {
        setIsOpen(false);
        setScrollEnabled(true);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  const checkProfileAndMediaHandle = () => {
    if (checkMediaToggle) {
      setUserMediaToggle(false);
      setUserProfileToggle(true);
    } else {
      setUserProfileToggle(true);
    }
  };

  return (
    <div>
      <header className="content-profile">
        <div className="content-profile-details">
          <div
            className="content-profile-detail"
            onClick={() => {
              checkProfileAndMediaHandle();
              getUserProfileData(chatBoxContact);
            }}
          >
            <div className="content-profile-image">
              <img
                className="profile-avatar"
                src={getUserProfilePic(
                  chatBoxContact?.contact?.user?.profilePic
                )}
                alt="profile"
              />
            </div>
            <div className="content-profile-info">
              <p className="info-name">
                {chatBoxContact?.contact?.name
                  ? chatBoxContact?.contact?.name
                  : chatBoxContact?.contact?.countryCode +
                    " " +
                    chatBoxContact?.contact?.user?.phone}
              </p>
              <p className="info-status">
                {toggleUserStatus(
                  chatBoxContact?.contact?.user?.onlineStatus,
                  chatBoxContact?.contact?.user?.typingStatus
                )}
              </p>
            </div>
          </div>

          <div className="content-profile-search">
            <button className="btn btn-searchIcon">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
                <title>Search</title>
                <path
                  d="M10.393 19.6081C12.4206 19.6081 14.3139 18.9548 15.8529 17.8659L21.6426 23.6007C21.9113 23.8669 22.2655 24 22.632 24C23.4259 24 24 23.3951 24 22.6207C24 22.2578 23.8779 21.919 23.6091 21.6528L17.8561 15.9421C19.0653 14.3693 19.786 12.4214 19.786 10.3041C19.786 5.18622 15.5597 1 10.393 1C5.21402 1 1 5.18622 1 10.3041C1 15.4219 5.21402 19.6081 10.393 19.6081ZM10.393 17.5997C6.34997 17.5997 3.02762 14.2967 3.02762 10.3041C3.02762 6.31142 6.34997 3.00842 10.393 3.00842C14.4238 3.00842 17.7584 6.31142 17.7584 10.3041C17.7584 14.2967 14.4238 17.5997 10.393 17.5997Z"
                  fill={
                    theme === "Light"
                      ? "#2a2a2a"
                      : theme === "Dark"
                      ? "#ffffff"
                      : window.matchMedia("(prefers-color-scheme: light)")
                          .matches
                      ? "#2a2a2a"
                      : "#ffffff"
                  }
                  stroke="white"
                  strokeWidth="0.5"
                />
              </svg>
            </button>
            <button
              className="btn btn-menuIcon"
              ref={menubuttonRef}
              onClick={toggleDropdown}
            >
              <svg width="6" height="16" viewBox="0 0 6 24" fill="none">
                <title>Menu</title>
                <path
                  d="M3 0.75C1.625 0.75 0.5 1.875 0.5 3.25C0.5 4.625 1.625 5.75 3 5.75C4.375 5.75 5.5 4.625 5.5 3.25C5.5 1.875 4.375 0.75 3 0.75ZM3 18.25C1.625 18.25 0.5 19.375 0.5 20.75C0.5 22.125 1.625 23.25 3 23.25C4.375 23.25 5.5 22.125 5.5 20.75C5.5 19.375 4.375 18.25 3 18.25ZM3 9.5C1.625 9.5 0.5 10.625 0.5 12C0.5 13.375 1.625 14.5 3 14.5C4.375 14.5 5.5 13.375 5.5 12C5.5 10.625 4.375 9.5 3 9.5Z"
                  fill={
                    theme === "Light"
                      ? "#2a2a2a"
                      : theme === "Dark"
                      ? "#ffffff"
                      : window.matchMedia("(prefers-color-scheme: light)")
                          .matches
                      ? "#2a2a2a"
                      : "#ffffff"
                  }
                />
              </svg>
            </button>
            {isOpen && (
              <HeaderMenu
                isOpen={isOpen}
                ref={menuRef}
                onClose={() => setIsOpen(false)}
                variant="HeaderProfile"
              />
            )}
          </div>
        </div>
      </header>
    </div>
  );
}

export default Headerprofile;
