import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import Spinner from "../Common/Spinner";
import { ERROR } from "../../Utils/enums";
import { useDispatch } from "react-redux";
import { handleToast } from "../../Redux/actions";
import earth from "../../assets/image/SVG_Images/earth.svg";
import "../../assets/css/tepars.scss";
import axiosRequest from "../../Middleware/api";

function Location({
  getSelectedLocation,
  setGetSelectedLocation,
  setHandlePrivacyOptions,
}) {
  const searchInputRef = useRef(null);
  const dispatch = useDispatch();

  const [locations, setLocations] = useState();
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    checkLocationPermission();
  }, []);

  const checkLocationPermission = async () => {
    if ("permissions" in navigator && "geolocation" in navigator) {
      try {
        const result = await navigator.permissions.query({
          name: "geolocation",
        });
        setPermission(result.state);

        result.onchange = () => {
          setPermission(result.state);
        };
      } catch (error) {
        setPermission("denied");
      }
    } else {
      setPermission("unsupported");
    }
  };

  const requestLocationPermission = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        () => {
          setPermission("granted");
        },
        () => {
          setPermission("denied");
        }
      );
    } else {
      alert("Geolocation is not supported in your browser.");
      setPermission("unsupported");
    }
  };

  const searchPlaces = async (value) => {
    setLoading(true);
    await axiosRequest
      .get(`/websync?input=${value}`)
      .then((response) => {
        setLoading(false);
        setLocations(response?.data?.data?.results);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const debouncedSearchPlaces = useCallback(
    debounce((value) => {
      if (value) {
        searchPlaces(value);
      } else {
        setLocations();
      }
      setIsTyping(false);
    }, 1000),
    []
  );

  const handleSearchInputChange = (e) => {
    const keyword = e.target.value;
    setSearchValue(keyword);
    setIsTyping(true);
    debouncedSearchPlaces(keyword);
  };

   const onSelectLocation = (location) => {
    setGetSelectedLocation(location);
    setHandlePrivacyOptions("Privacy");
  };

  const handleClearSearch = () => {
    searchInputRef.current.focus();
    setSearchValue("");
    setLocations();
    setIsTyping(false);
  };

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  return (
    <div className="location">
      <div className="location-header">
        {permission === "granted" && (
          <div className="search-bar">
            <div className="search-bar-wrapper">
              <svg width="12" height="12" viewBox="0 0 18 18" fill="none">
                <title>Search</title>
                <path
                  id="Vector"
                  d="M14.2049 12.952L18 16.7462L16.7462 18L12.952 14.2049C11.5402 15.3366 9.78419 15.9522 7.9748 15.9496C3.57271 15.9496 0 12.3769 0 7.9748C0 3.57271 3.57271 0 7.9748 0C12.3769 0 15.9496 3.57271 15.9496 7.9748C15.9522 9.78419 15.3366 11.5402 14.2049 12.952ZM12.4274 12.2945C13.5519 11.138 14.18 9.58786 14.1774 7.9748C14.1774 4.54741 11.4013 1.77218 7.9748 1.77218C4.54741 1.77218 1.77218 4.54741 1.77218 7.9748C1.77218 11.4013 4.54741 14.1774 7.9748 14.1774C9.58786 14.18 11.138 13.5519 12.2945 12.4274L12.4274 12.2945Z"
                  fill="#999999"
                />
              </svg>

              <input
                type="search"
                placeholder="Search"
                value={searchValue}
                onChange={handleSearchInputChange}
                ref={searchInputRef}
              />
              {searchValue.length > 0 && (
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 25 25"
                  fill="none"
                  style={{
                    overflow: "visible",
                    pointerEvents: "auto",
                    cursor: "pointer",
                  }}
                  onClick={handleClearSearch}
                >
                  <title>Close</title>
                  <g id="iconoir:cancel">
                    <path
                      id="Vector"
                      d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                      stroke="#999999"
                      strokeWidth="2.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              )}
            </div>
          </div>
        )}
        {permission === "prompt" && (
          <div className="turnOn-location-wrapper">
            <div className="turnOn-location">
              <div className="earth-image">
                <img src={earth} alt="earth" />
              </div>
              <span className="text">Find places near you</span>
              <p className="info">
                To see places near you, or to check in to a specific location,
                turn on Location Services
              </p>
              <button
                className="btn btn-turnOn"
                onClick={requestLocationPermission}
              >
                Turn on
              </button>
            </div>
          </div>
        )}
        {(permission === "denied" || permission === "unsupported") && (
          <div className="turnOn-location-wrapper">
            <div className="turnOn-location">
              <div className="earth-image">
                <img src={earth} alt="earth" />
              </div>
              <span className="text">Find places near you</span>
              <p className="info">
                {permission === "unsupported"
                  ? "Location is not supported in your browser."
                  : "Location access is denied. Please enable it in your browser settings to use this feature."}
              </p>
            </div>
          </div>
        )}
      </div>

      {getSelectedLocation && permission === "granted" && (
        <div className="selected location-list-wrapper">
          <div className=" location-list">
            <div className="location-heading">
              <span>Currently selected to:</span>
            </div>
            <div tabIndex="-1" className="selected location">
              <div className="location-image">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g id="ion:location-sharp">
                    <path
                      id="Vector"
                      d="M10 1.25C6.54961 1.25 3.75 3.76992 3.75 6.875C3.75 11.875 10 18.75 10 18.75C10 18.75 16.25 11.875 16.25 6.875C16.25 3.76992 13.4504 1.25 10 1.25ZM10 10C9.50555 10 9.0222 9.85338 8.61107 9.57867C8.19995 9.30397 7.87952 8.91352 7.6903 8.45671C7.50108 7.99989 7.45157 7.49723 7.54804 7.01227C7.6445 6.52732 7.8826 6.08186 8.23223 5.73223C8.58186 5.3826 9.02732 5.1445 9.51227 5.04804C9.99723 4.95157 10.4999 5.00108 10.9567 5.1903C11.4135 5.37952 11.804 5.69995 12.0787 6.11107C12.3534 6.5222 12.5 7.00555 12.5 7.5C12.4993 8.16282 12.2357 8.79828 11.767 9.26697C11.2983 9.73565 10.6628 9.99928 10 10Z"
                      fill="#1891A2"
                    />
                  </g>
                </svg>
              </div>
              <div className="location-info-container">
                <div className="location-info">
                  <span className="name">{getSelectedLocation?.name}</span>
                  <p className="location-status-info">
                    {getSelectedLocation?.formatted_address}
                  </p>
                </div>
                <button
                  className="btn btn-locationCancel"
                  title="Cancel location"
                  onClick={() => setGetSelectedLocation("")}
                >
                  <svg width="9" height="9" viewBox="0 0 25 25" fill="none">
                    <title>Cancel</title>
                    <g id="iconoir:cancel">
                      <path
                        id="Vector"
                        d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                        stroke="#ffffff"
                        strokeWidth="2.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {permission === "granted" && (
        <div className="location-list-wrapper">
          {isTyping || loading ? (
            <Spinner />
          ) : (
            <div className="location-list">
              {locations ? (
                locations?.map((location, i) => {
                  return (
                    <div
                      key={i}
                      tabIndex="-1"
                      className="location"
                      onClick={() => onSelectLocation(location)}
                    >
                      <div className="location-image">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g id="ion:location-sharp">
                            <path
                              id="Vector"
                              d="M10 1.25C6.54961 1.25 3.75 3.76992 3.75 6.875C3.75 11.875 10 18.75 10 18.75C10 18.75 16.25 11.875 16.25 6.875C16.25 3.76992 13.4504 1.25 10 1.25ZM10 10C9.50555 10 9.0222 9.85338 8.61107 9.57867C8.19995 9.30397 7.87952 8.91352 7.6903 8.45671C7.50108 7.99989 7.45157 7.49723 7.54804 7.01227C7.6445 6.52732 7.8826 6.08186 8.23223 5.73223C8.58186 5.3826 9.02732 5.1445 9.51227 5.04804C9.99723 4.95157 10.4999 5.00108 10.9567 5.1903C11.4135 5.37952 11.804 5.69995 12.0787 6.11107C12.3534 6.5222 12.5 7.00555 12.5 7.5C12.4993 8.16282 12.2357 8.79828 11.767 9.26697C11.2983 9.73565 10.6628 9.99928 10 10Z"
                              fill="#1891A2"
                            />
                          </g>
                        </svg>
                      </div>

                      <div className="location-info-container">
                        <div className="location-info">
                          <span className="name">{location?.name}</span>
                          <p className="location-status-info">
                            {location?.formatted_address}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <span className="no-location">
                  {!getSelectedLocation && "No location selected yet"}
                </span>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Location;
