import React, { useEffect, useState } from "react";

function ShowDots({
  media,
  mediaIndexFinal,
  getSelectedImage,
  getCurrentIndex,
  setRightClick,
  checkRightClick,
  setLeftClick,
  checkLeftClick,
  goToSlide,
}) {
  const images = Object.values(media);

  const [index, setIndex] = useState(0);

  const selectImage = (image, i) => {
    getSelectedImage(image);
    setIndex(i);
    getCurrentIndex(i);
    goToSlide(i);
  };

  useEffect(() => {
    if (checkRightClick) {
      // setIndex(index + 1);
      getCurrentIndex(index + 1);
      setRightClick(false);
    }
    if (checkLeftClick) {
      // setIndex(index - 1);
      getCurrentIndex(index - 1);
      setLeftClick(false);
    }
    if (mediaIndexFinal !== undefined) {
      setIndex(mediaIndexFinal);
    }
  }, [checkRightClick, checkLeftClick, mediaIndexFinal]);

  return (
    <div
      className="media-wrapper"
      onKeyDown={(e) => {
        if (e.key === "ArrowRight" && index < images.length - 1) {
          selectImage(images[index + 1], index + 1);
        } else if (e.key === "ArrowLeft" && index > 0) {
          selectImage(images[index - 1], index - 1);
        }
      }}
      tabIndex={0}
    >
      {images?.map((image, i) => {
        return (
          <div
            key={i}
            className={` Imagedots ${
              images.length >= 1 && index === i && "activedots"
            }`}
            onClick={() => {
              selectImage(image, i);
            }}
            tabIndex={index === i ? 0 : -1}
          />
        );
      })}
    </div>
  );
}

export default ShowDots;
