import React, { useState, useEffect, useRef } from "react";
import Sharemenu from "./Sharemenu";
import star from "../../assets/image/star.png";
import DeleteMessage from "../Group/DeleteMessage";
import { MessageAction } from "../Common/MessageAction";
import downArrow from "../../assets/image/SVG_Images/down-white.svg";
import cancleDownload from "../../assets/image/cancel.png";
import audioDownload from "../../assets/image/audioDownload.png";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { chatDocumentPath } from "../../ServerConfiguration/awsConfiguration";
import {
  DOCLINK,
  DOWNLOAD,
  UPLOADED,
  DOWNLOADED,
  SENDER_DOCUMENT,
  RECEIVER_DOCUMENT,
  SENDER_TEXT_REPLY,
} from "../../Utils/enums";
import {
  getFileTypeIcon,
  getTimeFromDate,
  getMessageStatus,
  calculateFileSize,
} from "../../Utils/helpers";
import "../../assets/css/document.scss";
import "react-circular-progressbar/dist/styles.css";

function Documents({
  message,
  tapped,
  getTappedMessage,
  showForward,
  setForwardMessage,
  getIds,
  getSenderMessageType,
  UpdateMediaStatusAtRealm,
  forward,
  deleteMessage,
}) {
  const downArrowRef = useRef(null);
  const dropdownRef = useRef(null);
  const [percentage, setPercentage] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [arrayFileInfo, setArrayFileInfo] = useState();
  const [showProgress, setShowProgress] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [deletedMessage, setDeletedMessage] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [downloadInterval, setDownloadInterval] = useState(null);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setArrayFileInfo(message?.media?.mediaUrl?.split(DOCLINK));
  }, [message]);

  const toggleShareMenu = (event) => {
    event.preventDefault();
    const arrowRect = downArrowRef.current.getBoundingClientRect();
    const { right, top, left } = arrowRect;
    const isReceiverText = message?.type === RECEIVER_DOCUMENT;
    const position = isReceiverText
      ? { x: left, y: top }
      : { x: right, y: top };
    setMenuPosition(position);
    setIsShareMenuOpen(true);
    setScrollEnabled(false);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [scrollEnabled]);

  const closeDropdown = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event?.target)) {
      setIsShareMenuOpen(false);
      setScrollEnabled(true);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event?.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isShareMenuOpen) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isShareMenuOpen]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  }, []);
  //endHere

  const handleDownload = () => {
    if (!downloadComplete) {
      if (showProgress) {
        setShowProgress(false);
        clearInterval(downloadInterval);
      } else {
        setShowProgress(true);
        const intervalDuration =
          message?.media?.fileSize <= 1024 * 100 ? 100 : 500;
        const interval = setInterval(() => {
          setPercentage((prevPercentage) => {
            if (prevPercentage < 100) {
              return prevPercentage + 1000 / intervalDuration;
            } else {
              clearInterval(interval);
              initiateDownload();
              return 100;
            }
          });
        }, intervalDuration);
        setDownloadInterval(interval);
      }
    }
  };

  const initiateDownload = () => {
    fetch(
      process.env.REACT_APP_CLOUDFRONT_BUCKET_VIDEO +
        chatDocumentPath +
        arrayFileInfo?.[0] +
        "." +
        arrayFileInfo?.[2]
    )
      .then((response) => response.blob())
      .then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = arrayFileInfo?.[3];
        alink.click();
        setDownloadComplete(true);
        window.URL.revokeObjectURL(fileURL);
      })
      .finally(() => {
        UpdateMediaStatusAtRealm(message?.media?._id, DOWNLOADED);
        setShowProgress(false);
        setPercentage(0);
        clearInterval(downloadInterval);
      });
  };

  useEffect(() => {
    return () => {
      clearInterval(downloadInterval);
    };
  }, [downloadInterval]);

  const onDoubleTapped = () => {
    tapped(true);
    getTappedMessage(message);
    getIds(message?._id);
    getSenderMessageType(SENDER_TEXT_REPLY);
    handleClickOutside();
  };

  const onForwardMessage = (message) => {
    showForward(true);
    setForwardMessage(message);
  };

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const onDelete = () => {
    setDeletedMessage(true);
  };

  const onStar = async () => {
    // Realm.write(() => {
    //   Realm.create(
    //     "RMessageModel",
    //     {
    //       _id: message._id,
    //       isStarMessage: !message?.isStarMessage,
    //     },
    //     "modified"
    //   );
    // });
    setIsShareMenuOpen(false);
    setScrollEnabled(true);
  };

  return (
    <>
      {message?.type === RECEIVER_DOCUMENT && (
        <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="documentRecieved">
              <div className="wrapper">
                <div className="document-details" onClick={handleDownload}>
                  <div className="file">
                    <img
                      src={getFileTypeIcon(arrayFileInfo?.[2])}
                      alt="thumbnail"
                    />
                  </div>
                  <div className="detail">
                    <p className="name">{arrayFileInfo?.[3]}</p>
                    <p className="size">
                      {calculateFileSize(message?.media?.fileSize)} -{" "}
                      {arrayFileInfo?.[2].toUpperCase()}
                    </p>
                  </div>
                  {message?.media?.mediaStatus &&
                    (message?.media?.mediaStatus === UPLOADED ||
                      message?.media?.mediaStatus === DOWNLOAD) && (
                      <>
                        {showProgress ? (
                          <div
                            onClick={handleDownload}
                            className="download-progress"
                          >
                            <CircularProgressbarWithChildren value={percentage}>
                              <img
                                style={{ width: 12 }}
                                src={cancleDownload}
                                alt="cancel"
                              />
                            </CircularProgressbarWithChildren>
                          </div>
                        ) : (
                          <div className="download">
                            <img
                              className="download-document"
                              src={audioDownload}
                              onClick={handleDownload}
                              alt="download"
                            />
                          </div>
                        )}
                      </>
                    )}
                  <div className="down-arrow">
                    <img
                      src={downArrow}
                      ref={downArrowRef}
                      alt="down"
                      role="button"
                      onClick={toggleShareMenu}
                    />
                  </div>
                </div>
                <div className="message-status">
                  {message?.isStarMessage && (
                    <img className="important" src={star} alt="important" />
                  )}
                  <span className="date">{getTimeFromDate(message)}</span>
                </div>
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {message?.type === SENDER_DOCUMENT && (
        <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="documentSend">
              <div className="wrapper">
                <div className="document-details">
                  {/* <div className="document-details" onClick={handleDownload}> */}
                  <div className="file">
                    <img
                      src={getFileTypeIcon(arrayFileInfo?.[2])}
                      alt="thumbnail"
                    />
                  </div>
                  <div className="detail">
                    <p className="name">{arrayFileInfo?.[3]}</p>
                    <p className="size">
                      {calculateFileSize(message?.media?.fileSize)} -{" "}
                      {arrayFileInfo?.[2].toUpperCase()}
                    </p>
                  </div>
                  {message?.media?.mediaStatus &&
                    (message?.media?.mediaStatus === UPLOADED ||
                      message?.media?.mediaStatus === DOWNLOAD) && (
                      <>
                        {showProgress ? (
                          <div
                            onClick={handleDownload}
                            className="download-progress"
                          >
                            <CircularProgressbarWithChildren value={percentage}>
                              <img
                                style={{ width: 12 }}
                                src={cancleDownload}
                                alt="cancel"
                              />
                            </CircularProgressbarWithChildren>
                          </div>
                        ) : (
                          <div className="download">
                            <img
                              className="download-document"
                              src={audioDownload}
                              onClick={handleDownload}
                              alt="download"
                            />
                          </div>
                        )}
                      </>
                    )}
                  <div className="down-arrow">
                    <img
                      src={downArrow}
                      ref={downArrowRef}
                      alt="down"
                      role="button"
                      onClick={toggleShareMenu}
                    />
                  </div>
                </div>
                <div className="message-status">
                  {message?.isStarMessage && (
                    <img className="important" src={star} alt="important" />
                  )}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="text-status"
                    src={getMessageStatus(message)}
                    alt="img-status"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {isShareMenuOpen && (
        <Sharemenu
          ddref={dropdownRef}
          menuPosition={menuPosition}
          isFromReceiver={message?.type === RECEIVER_DOCUMENT}
          onReplyClick={onDoubleTapped}
          onDeleteClick={onDelete}
          onStarClick={onStar}
          message={message}
        />
      )}
      {deletedMessage && (
        <DeleteMessage
          message={message}
          setDeleteMessage={setDeletedMessage}
          deleteMessage={deleteMessage}
        />
      )}
    </>
  );
}

export default Documents;
