import React, { useState, useEffect } from "react";
import { pdfjs } from "react-pdf";
import docImage from "../../assets/image/document.png";
import transDelete from "../../assets/image/transDelete.png";
import { DOCUMENT, IMAGE, PDF, VIDEO } from "../../Utils/enums";
import { captureVideoThumbnail, getMediaType } from "../../Utils/helpers";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PopupImage = ({
  i,
  index,
  imagesList,
  media,
  setNumPages,
  setPdfPagesCount,
  handleSelect,
  handleDelete,
}) => {
  const fileType = getMediaType([media]);
  const [pdfPage, setPdfPage] = useState();
  const [thumbImage, setThumbImage] = useState([]);

  useEffect(() => {
    const getVideoThumb = async () => {
      if (fileType && fileType === VIDEO) {
        const thumb = await captureVideoThumbnail(media);
        setThumbImage(thumb);
      }
    };
    getVideoThumb();
  }, [fileType, media]);

  const getPdfPageForPreview = async () => {
    const file = media;
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const url = e.target.result;
        const loadingTask = pdfjs.getDocument({ url });
        const pdf = await loadingTask.promise;
        setNumPages(pdf.numPages); // Set the total number of pages
        setPdfPagesCount(pdf.numPages);
        const pdfPage = await pdf.getPage(1);
        const scale = 1.5;
        const viewport = pdfPage.getViewport({ scale });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await pdfPage.render(renderContext).promise;
        setPdfPage(canvas.toDataURL("image/png"));
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (fileType && fileType[1] === PDF) {
      getPdfPageForPreview();
    }
  }, []);

  return (
    <>
      <div
        key={i}
        className={`media ${imagesList.length >= 1 && index === i && "active"}`}
        onClick={() => {
          handleSelect(media, i);
        }}
        tabIndex={index === i ? 0 : -1} // Set tabindex to 0 for the active image, -1 for others
      >
        <img
          id={i}
          src={
            fileType && fileType === VIDEO && thumbImage
              ? thumbImage
              : fileType && fileType[0] === DOCUMENT && fileType[1] === PDF
              ? pdfPage
              : fileType && fileType[0] === DOCUMENT && fileType[1] !== PDF
              ? docImage
              : fileType && fileType === IMAGE
              ? URL.createObjectURL(media)
              : ""
          }
          alt="media"
        />
        <div className="mediaDelete">
          <img
            src={transDelete}
            alt="delete"
            onClick={() => handleDelete(media, i)}
          />
        </div>
      </div>
    </>
  );
};

export { PopupImage };
