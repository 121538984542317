import React, { useEffect, useRef, useState } from "react";
import Sharemenu from "./Sharemenu";
import DeleteMessage from "../Group/DeleteMessage";
import { getTimeFromDate } from "../../Utils/helpers";
import downArrow from "../../assets/image/downArrow.png";
import {
  RECEIVER_TEXT,
  SENDER_DELETED,
  RECEIVER_DELETED,
} from "../../Utils/enums";
import "../../assets/css/deletedMessage.scss";

const DeletedMessage = ({ message, forward }) => {
  const dropdownRef = useRef(null);
  const downArrowRef = useRef(null);

  const [isChecked, setIsChecked] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const toggleShareMenu = (event) => {
    event.preventDefault();
    const arrowRect = downArrowRef.current.getBoundingClientRect();
    const { right, top, left } = arrowRect;
    const isReceiverText = message?.type === RECEIVER_TEXT;
    const position = isReceiverText
      ? { x: left, y: top }
      : { x: right, y: top };
    setMenuPosition(position);
    setIsShareMenuOpen(true);
    setScrollEnabled(false);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [scrollEnabled]);

  const closeDropdown = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event.target)) {
      setIsShareMenuOpen(false);
      setScrollEnabled(true);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isShareMenuOpen) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isShareMenuOpen]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  }, []);

  const onDelete = () => {
    setDeleteMessage(true);
  };

  return (
    <>
      {message?.type === RECEIVER_DELETED && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="msgReceivedText">
              <div className="delete-message">
                <div className="delete-img">
                  <svg width="16" height="16" viewBox="0 0 21 21" fill="none">
                    <path
                      d="M4.93153 4.93153C8.00691 1.85616 12.9931 1.85616 16.0685 4.93153C19.1439 8.00691 19.1439 12.9931 16.0685 16.0685C12.9931 19.1439 8.00691 19.1439 4.93153 16.0685C1.85616 12.9931 1.85616 8.00691 4.93153 4.93153Z"
                      stroke="#535353"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M5.25 5.25L15.75 15.75"
                      stroke="#535353"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <p>
                  This message was deleted
                  <span className="space" />
                </p>
              </div>
              <div className="message-status">
                <span className="date">{getTimeFromDate(message)}</span>
              </div>
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {message?.type === SENDER_DELETED && (
        <div className="message-group">
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            <div className="msgSendText">
              <div className="delete-message">
                <div className="delete-img">
                  <svg width="16" height="16" viewBox="0 0 21 21" fill="none">
                    <path
                      d="M4.93153 4.93153C8.00691 1.85616 12.9931 1.85616 16.0685 4.93153C19.1439 8.00691 19.1439 12.9931 16.0685 16.0685C12.9931 19.1439 8.00691 19.1439 4.93153 16.0685C1.85616 12.9931 1.85616 8.00691 4.93153 4.93153Z"
                      stroke="#535353"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M5.25 5.25L15.75 15.75"
                      stroke="#535353"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
                <p>
                  This message was deleted
                  <span className="space" />
                </p>
              </div>
              <div className="message-status">
                <span className="date">{getTimeFromDate(message)}</span>
              </div>
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isShareMenuOpen && (
        <Sharemenu
          ddref={dropdownRef}
          menuPosition={menuPosition}
          deleted={true}
          onDeleteClick={onDelete}
        />
      )}
      {deleteMessage && (
        <DeleteMessage
          message={message}
          setDeleteMessage={setDeleteMessage}
          deleted={true}
        />
      )}
    </>
  );
};

export { DeletedMessage };
