import React from "react";
import { REPORT_NUDITY_OPTION } from "../../../Utils/enums";

const Nudity = ({
  selectedReportOption,
  setSelectedReportOption,
  SubmitReport,
}) => {
  const handleReportOptionChange = (option) => {
    setSelectedReportOption(option);
  };

  return (
    <div className="reportPost-info">
      <div className="why-report-list" role="list">
        <p className="title">Why are you reporting this post?</p>
        {REPORT_NUDITY_OPTION.map((option, i) => (
          <div
            tabIndex="0"
            className="report-reason"
            onClick={() => {
              handleReportOptionChange(option);
            }}
          >
            <div className="group-check">
              <input
                id={`myCheckbox${i}`}
                type="radio"
                aria-label="Check this box"
                value={option}
                checked={selectedReportOption === option}
              />
              {/* <span className="checkmark" /> */}

              <div className="checkmark" role="radio" tabIndex="0">
                <div className="offRadio radio-cmn"></div>
                <div className="onRadio radio-cmn"></div>
              </div>
            </div>
            <label htmlFor={`myCheckbox${i}`} className="report-reason-title">
              {option}
            </label>
          </div>
        ))}
        <div className="nudity-guidelines">
          <p className="title">Nudity or sexual activity guidelines</p>
          <div className="we-remove">
            <span className="subtitle">We remove:</span>
            <ul className="remove-list-wrapper">
              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">
                  Photos, videos and some digitally created content that show
                  sexual intercourse, genitals and close-ups of fully nude
                  buttocks.
                </p>
              </li>
              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">
                  Some photos of female nipples, but videos of post-mastectomy
                  scarring and women actively breastfeeding are allowed.
                </p>
              </li>
              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">
                  Nudity in photos of paintings and sculptures is OK.
                </p>
              </li>
              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">
                  There are times when we may remove videos that show nude or
                  partially nude children.
                </p>
              </li>
              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">
                  Intimate images of others that were shared without permission.
                </p>
              </li>
            </ul>
          </div>
          <button className="btn btn-submit" onClick={() => SubmitReport()}>
            Submit Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default Nudity;
