import { React, useEffect, useState } from "react";
import {
  CONTACT_ALL,
  CONTACT_EXCEPT,
  ONLY_SHARE_WITH,
} from "../../Utils/enums";
import "../../assets/css/tepars.scss";

function SeePost({
  privacy,
  setPrivacy,
  countContactExcept,
  countGetOnlyShareWith,
  setHandlePrivacyOptions,
}) {
  const [isContactAll, setIsContactAll] = useState(false);
  const [isContactExcept, setIsContactExcept] = useState(false);
  const [isOnlyShareWith, setIsOnlyShareWith] = useState(false);

  const handleCheckmarkClick = (value) => {
    setPrivacy(value);
    // setIsContactAll(value === CONTACT_ALL);
    // setIsContactExcept(value === CONTACT_EXCEPT);
    // setIsOnlyShareWith(value === ONLY_SHARE_WITH);
  };

  useEffect(() => {
    // if (privacy === CONTACT_ALL) {
    //   setIsContactAll(privacy === CONTACT_ALL);
    // }

    if (countContactExcept <= 0) {
      if (privacy === CONTACT_EXCEPT) {
        setPrivacy(CONTACT_ALL);
        // setIsContactAll(true);
        // setIsContactExcept(false);
      }
    } else {
      // setIsContactAll(false);
      // setIsContactExcept(true);
    }

    if (countGetOnlyShareWith <= 0) {
      if (privacy === ONLY_SHARE_WITH) {
        setPrivacy(CONTACT_ALL);
        // setIsContactAll(true);
        // setIsOnlyShareWith(false);
      }
    } else {
      // setIsContactAll(false);
      // setIsOnlyShareWith(true);
    }
  }, [privacy, countContactExcept, countGetOnlyShareWith]);

  // if (countContactExcept === 0 || countGetOnlyShareWith === 0) {
  //   setPrivacy(CONTACT_ALL);
  // }

  return (
    <div className="seePost">
      <div className="post-visible">
        <h3 className="title">Who can see my post?</h3>
        <p className="sub-info">
          Your post will be visible on Tepnot member and your groups.
        </p>
        <div className="visible-to-contacts">
          <div className="contact-select all-contacts">
            <div className="group-check">
              <input
                type="radio"
                id="contact-check"
                aria-label="Check this box"
                checked={
                  privacy === CONTACT_ALL ||
                  (countContactExcept === 0 && countGetOnlyShareWith === 0)
                }
              />
              {/* <span
                className="checkmark"
                onClick={() => handleCheckmarkClick(CONTACT_ALL)}
              ></span> */}
              <div
                className="checkmark"
                role="radio"
                tabIndex="0"
                onClick={() => handleCheckmarkClick(CONTACT_ALL)}
              >
                <div className="offRadio radio-cmn"></div>
                <div className="onRadio radio-cmn"></div>
              </div>
            </div>
            <div className="contact-select-info">
              <div
                className="wrapper"
                onClick={() => handleCheckmarkClick(CONTACT_ALL)}
              >
                <span className="title">My all contacts</span>
                <p className="sub-text">Anyone on your contacts</p>
              </div>
            </div>
          </div>

          <div className="contact-select my-contacts">
            <div className="group-check">
              <input
                type="radio"
                id="contact-check"
                aria-label="Check this box"
                checked={privacy === CONTACT_EXCEPT}
              />
              {/* <span
                className="checkmark"
                onClick={() => handleCheckmarkClick(CONTACT_EXCEPT)}
              ></span> */}
              <div
                className="checkmark"
                role="radio"
                tabIndex="0"
                onClick={() => handleCheckmarkClick(CONTACT_EXCEPT)}
              >
                <div className="offRadio radio-cmn"></div>
                <div className="onRadio radio-cmn"></div>
              </div>
            </div>
            <div
              className="contact-select-info"
              onClick={() => setHandlePrivacyOptions("ContactExcept")}
            >
              <div
                className="wrapper"
                onClick={() => handleCheckmarkClick(CONTACT_EXCEPT)}
              >
                <span className="title">My contact except...</span>
                <p className="sub-text">Choose who to hide your post from</p>
              </div>
              <div className="excluded">
                <span className="excluded-info">
                  {countContactExcept} Excluded
                </span>
                <div className="btn btn-more">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <title>More info</title>
                    <title>See Excluded Contacts</title>
                    <path
                      id="Vector"
                      d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                      fill="#C5C5C5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="contact-select only-share">
            <div className="group-check">
              <input
                type="radio"
                id="contact-check"
                aria-label="Check this box"
                checked={privacy === ONLY_SHARE_WITH}
              />
              {/* <span
                className="checkmark"
                onClick={() => handleCheckmarkClick(ONLY_SHARE_WITH)}
              ></span> */}

              <div
                className="checkmark"
                role="radio"
                tabIndex="0"
                onClick={() => handleCheckmarkClick(ONLY_SHARE_WITH)}
              >
                <div className="offRadio radio-cmn"></div>
                <div className="onRadio radio-cmn"></div>
              </div>
            </div>
            <div
              className="contact-select-info"
              onClick={() => setHandlePrivacyOptions("OnlyShareWith")}
            >
              <div
                className="wrapper"
                onClick={() => handleCheckmarkClick(ONLY_SHARE_WITH)}
              >
                <span className="title">Only share with</span>
                <p className="sub-text">Seen only on share with</p>
              </div>
              <div className="excluded">
                <span className="excluded-info">
                  {countGetOnlyShareWith} Included
                </span>
                <div className="btn btn-more">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <title>See Excluded Contacts</title>
                    <path
                      id="Vector"
                      d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                      fill="#C5C5C5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="seePost-info">
          <p className="info">
            Changes to your privacy settings won't affect post updates that
            you've already uploaded.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SeePost;
