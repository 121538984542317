import React from "react";
import { REPORT_FALSE_OPTION } from "../../../Utils/enums";

const FalseInformation = ({ setSelectedReportOption, SubmitReport }) => {
  return (
    <div className="reportPost-info">
      <div className="why-report-list" role="list">
        <p className="title">Why are you reporting this post?</p>
        {REPORT_FALSE_OPTION.map((option, i) => (
          <div
            tabIndex="0"
            className="report-reason me"
            onClick={() => {
              setSelectedReportOption(option);
              SubmitReport();
            }}
          >
            <p className="report-reason-title">{option}</p>
            <div className="more-info">
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                <title>More info</title>
                <path
                  id="Vector"
                  d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                  fill="#C5C5C5"
                />
              </svg>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FalseInformation;
