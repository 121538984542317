import React, { useEffect, useRef } from "react";
import "../../assets/css/storyProgressBar.scss";

const ProgressBar = ({ currentStoryIndex, totalStories, progress }) => {
  const progressRef = useRef(null);
  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.style.width = `${progress}%`;
    }
  }, [progress]);
  return (
    <div className="progress-bar-container">
      {Array.from({ length: totalStories }).map((_, index) => (
        <div
          key={index}
          className={`progress-segment ${
            index < currentStoryIndex ? "filled" : ""
          } ${index === currentStoryIndex ? "active" : ""}`}
        >
          {index === currentStoryIndex && (
            <div ref={progressRef} className="progress-segment-fill" />
          )}
        </div>
      ))}
    </div>
  );
};
export default ProgressBar;
