import React from "react";
import "../../assets/css/archiveDelete.scss";

const ArchiveDelete = ({ confirmDelete, cancelDelete }) => {
  return (
    <div className="archive-delete">
      <div className="dialog">
        <div className="dialog-info">
          <h4 className="title">Delete post?</h4>
        </div>
        <p className="description">
          Are you sure you want to delete this post?
        </p>

        <div className="discardPost-cta">
          <button className="btn btn-cancel" onClick={cancelDelete}>
            Cancel
          </button>

          <button
            className="btn btn-delete active"
            onClick={() => confirmDelete()}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ArchiveDelete;
