import React from "react";
import { useSelector } from "react-redux";
import PhoneFrame from "../assets/image/SVG_Images/light.webp";
import PhoneFrameDark from "../assets/image/SVG_Images/dark.webp";
import "../assets/css/default.scss";

function Default({ MenuType }) {
  const theme = useSelector((state) => state.theme);

  return (
    <div className="default">
      <div className="wrapper">
        <div className="logo">
          {MenuType === "chat" ? (
            <img
              src={
                theme === "Light"
                  ? PhoneFrame
                  : theme === "Dark"
                  ? PhoneFrameDark
                  : window.matchMedia("(prefers-color-scheme: light)").matches
                  ? PhoneFrame
                  : PhoneFrameDark
              }
              alt="logo"
            />
          ) : MenuType === "tepars" ? (
            <div className="no-post">
              <div className="no-post-image">
                <svg width="55" height="55" viewBox="0 0 72 69" fill="none">
                  <path
                    d="M36 45.9336C40.9706 45.9336 45 42.128 45 37.4336C45 32.7392 40.9706 28.9336 36 28.9336C31.0294 28.9336 27 32.7392 27 37.4336C27 42.128 31.0294 45.9336 36 45.9336Z"
                    stroke={
                      theme === "Light"
                        ? "#2a2a2a"
                        : theme === "Dark"
                        ? "#ffffff"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? "#2a2a2a"
                        : "#ffffff"
                    }
                    strokeWidth="2.25"
                  />
                  <path
                    d="M29.334 60.1017H42.666C52.029 60.1017 56.712 60.1017 60.075 58.0192C61.5261 57.121 62.7758 55.9618 63.753 54.6079C66 51.4912 66 47.1477 66 38.4664C66 29.7822 66 25.4415 63.753 22.3249C62.7759 20.9708 61.5261 19.8117 60.075 18.9135C57.915 17.5734 55.209 17.0945 51.066 16.9245C49.089 16.9245 47.388 15.5362 47.001 13.737C46.7052 12.4192 45.9368 11.2381 44.8255 10.3936C43.7143 9.54898 42.3284 9.09265 40.902 9.10169H31.098C28.134 9.10169 25.581 11.0425 24.999 13.737C24.612 15.5362 22.911 16.9245 20.934 16.9245C16.794 17.0945 14.088 17.5762 11.925 18.9135C10.4749 19.8119 9.22612 20.971 8.25 22.3249C6 25.4415 6 29.7822 6 38.4664C6 47.1477 6 51.4884 8.247 54.6079C9.219 55.9565 10.467 57.1154 11.925 58.0192C15.288 60.1017 19.971 60.1017 29.334 60.1017Z"
                    stroke={
                      theme === "Light"
                        ? "#2a2a2a"
                        : theme === "Dark"
                        ? "#ffffff"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? "#2a2a2a"
                        : "#ffffff"
                    }
                    strokeWidth="2.25"
                  />
                  <path
                    d="M57 28.9336H54"
                    stroke={
                      theme === "Light"
                        ? "#2a2a2a"
                        : theme === "Dark"
                        ? "#ffffff"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? "#2a2a2a"
                        : "#ffffff"
                    }
                    strokeWidth="2.25"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <span className="title">No posts yet</span>
            </div>
          ) : MenuType === "profile" ? (
            <span className="title">profile</span>
          ) : (
            ""
          )}
        </div>
        {MenuType === "chat" && (
          <>
            <h2 className="title">Tepnot Web</h2>
            <p className="info">
              Send and receive messages without keeping your phone online. Use
              Tepnot on up to 4 linked devices and 1 phone at the same time.
            </p>
            <div className="security">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM12 17C10.9 17 10 16.1 10 15C10 13.9 10.9 13 12 13C13.1 13 14 13.9 14 15C14 16.1 13.1 17 12 17ZM15.1 8H8.9V6C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8Z"
                  fill="#999999"
                ></path>
              </svg>

              <p className="security-info">
                Your personal messages are end-to-end encrypted on all of your
                devices.
              </p>
              {/* <p className="security-info">
                Your personal messages are{" "}
                <span style={{ color: "#1891a2" }}>end-to-end encrypted</span>{" "}
                on all of your devices.
              </p> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Default;
