import React, { useState, useEffect, useRef } from "react";
import Gallary from "./Gallary";
import Sharemenu from "./Sharemenu";
import ProgressBar from "./ProgressBar";
import DeleteMessage from "../Group/DeleteMessage";
import loading from "../../assets/video/loader.gif";
import { MessageAction } from "../Common/MessageAction";
import downArrow from "../../assets/image/white-down.png";
import star from "../../assets/image/SVG_Images/white-star.svg";
import {
  getTimeFromDate,
  getMessageStatus,
  VIEW_TN_CHAT_IMAGE_BASE_URL,
} from "../../Utils/helpers";
import {
  DOWNLOAD,
  DOWNLOADED,
  SENDER_IMAGE,
  RECEIVER_IMAGE,
  SENDER_TEXT_REPLY,
} from "../../Utils/enums";
import "../../assets/css/image.scss";

function Image({
  message,
  progressCount,
  setShowViewMedia,
  setcloseViewMedia,
  tapped,
  getTappedMessage,
  getIds,
  getSenderMessageType,
  UpdateMediaStatusAtRealm,
  forward,
  deleteMessage,
}) {
  const rootRef = useRef();
  const dropdownRef = useRef(null);
  const downArrowRef = useRef(null);

  const [isChecked, setIsChecked] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [deletedMessage, setDeletedMessage] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const toggleShareMenu = (event) => {
    event.preventDefault();
    const arrowRect = downArrowRef.current.getBoundingClientRect();
    const { right, top, left } = arrowRect;
    const isReceiverText = message?.type === RECEIVER_IMAGE;
    const position = isReceiverText
      ? { x: left, y: top }
      : { x: right, y: top };
    setMenuPosition(position);
    setIsShareMenuOpen(true);
    setScrollEnabled(false);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [scrollEnabled]);

  const closeDropdown = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event?.target)) {
      setIsShareMenuOpen(false);
      setScrollEnabled(true);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event?.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isShareMenuOpen) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isShareMenuOpen]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  }, []);
  //endHere

  const handleViewMedia = () => {
    setShowViewMedia({ show: true, url: message?.media?.mediaUrl });
    setcloseViewMedia(false);
  };

  const onDoubleTapped = () => {
    tapped(true);
    getTappedMessage(message);
    getIds(message?._id);
    getSenderMessageType(SENDER_TEXT_REPLY);
    handleClickOutside();
  };

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const onDelete = () => {
    setDeletedMessage(true);
  };

  const onStar = async () => {
    // Realm.write(() => {
    //   Realm.create(
    //     "RMessageModel",
    //     {
    //       _id: message._id,
    //       isStarMessage: !message?.isStarMessage,
    //     },
    //     "modified"
    //   );
    // });
    setIsShareMenuOpen(false);
    setScrollEnabled(true);
  };

  return (
    <>
      {/* <ProgressBar progressVal={progressCount} /> */}
      {/* <Gallary /> */}
      {/* <div className="loader">
          <img src={loading} alt="" />
          </div> */}
      {message?.type === RECEIVER_IMAGE && (
        <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="recieved-img">
              <div
                ref={rootRef}
                className={
                  message?.media?.mediaStatus === DOWNLOAD
                    ? "image-blur"
                    : "image-unblur"
                }
              >
                <img
                  src={VIEW_TN_CHAT_IMAGE_BASE_URL + message?.media?.mediaUrl}
                  className={`chat-img ${
                    message?.media?.mediaStatus === DOWNLOAD && "blur"
                  }`}
                  alt=""
                  onClick={() => handleViewMedia()}
                />
                {message?.media?.mediaStatus === DOWNLOAD && (
                  <div
                    className="loader"
                    onClick={() =>
                      UpdateMediaStatusAtRealm(message?.media?._id, DOWNLOADED)
                    }
                  >
                    <p>Download</p>
                  </div>
                )}
              </div>
              <div className="message-status">
                {message?.isStarMessage && (
                  <img className="important" src={star} alt="important" />
                )}
                <span className="date">{getTimeFromDate(message)}</span>
              </div>
              <div className="shadow-background" />
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {message?.type === SENDER_IMAGE && (
        <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{ justifyContent: "flex-end" }}
          >
            {!forward && <MessageAction message={message} />}
            <div className="send-img">
              <div
                ref={rootRef}
                className={
                  message?.Media?.mediaStatus === DOWNLOAD
                    ? "image-blur"
                    : "image-unblur"
                }
              >
                <img
                  src={VIEW_TN_CHAT_IMAGE_BASE_URL + message?.media?.mediaUrl}
                  className={`chat-img ${
                    message?.Media?.mediaStatus === DOWNLOAD && "blur"
                  }`}
                  alt=""
                  onClick={() => handleViewMedia()}
                />
                {message?.Media?.mediaStatus === DOWNLOAD && (
                  <div
                    className="loader"
                    onClick={() =>
                      UpdateMediaStatusAtRealm(message?.Media?._id, DOWNLOADED)
                    }
                  >
                    <p>Download</p>
                  </div>
                )}
              </div>
              <div className="message-status">
                {message?.isStarMessage && (
                  <img className="important" src={star} alt="important" />
                )}
                <span className="date">{getTimeFromDate(message)}</span>
                <img
                  className="img-status"
                  src={getMessageStatus(message)}
                  alt="seen"
                />
              </div>
              <div className="shadow-background" />
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
          </div>
          {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {isShareMenuOpen && (
        <Sharemenu
          ddref={dropdownRef}
          menuPosition={menuPosition}
          isFromReceiver={message?.type === RECEIVER_IMAGE}
          onReplyClick={onDoubleTapped}
          onDeleteClick={onDelete}
          onStarClick={onStar}
          message={message}
        />
      )}
      {deletedMessage && (
        <DeleteMessage
          message={message}
          setDeleteMessage={setDeletedMessage}
          deleteMessage={deleteMessage}
        />
      )}
    </>
  );
}

export default Image;
