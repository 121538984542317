import { React, useState, useEffect, useRef } from "react";
import Spinner from "../Common/Spinner";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import { handleToast } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfilePic } from "../../Utils/helpers";
import "../../assets/css/tepars.scss";

function ExceptContact({
  getContactExcept,
  setGetContactExcept,
  getContactArray,
  setGetContactArray,
}) {
  const dispatch = useDispatch();

  const containerRef = useRef(null);
  const searchInputRef = useRef(null);

  const userId = localStorage.getItem("user_id");
  const theme = useSelector((state) => state.theme);
  const savedContacts = useSelector((state) => state.exceptContact);

  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTotalPages, setSearchTotalPages] = useState(0);
  const [count, setCount] = useState();
  const [appendContacts, setAppendContacts] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [Contacts, setContactsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Contacts) {
      setAppendContacts(Contacts);
    }
  }, [Contacts]);

  const fetchContacts = (pageNumber, isSearch = false) => {
    setLoading(true);
    const endpoint = isSearch
      ? `user/contact?search=${searchValue}&page=${pageNumber}`
      : `user/contact?page=${pageNumber}`;

    axiosRequest
      .get(endpoint, {})
      .then((res) => {
        setCount(res?.data?.totalCount);
        const fetchedContacts = res?.data?.data?.filter(
          (contact) => contact?.userType !== "Admin" && contact?._id !== userId
        );

        if (isSearch) {
          setSearchTotalPages(res?.data?.totalPages);
          setAppendContacts((prevContacts) =>
            pageNumber === 1
              ? fetchedContacts
              : [...prevContacts, ...fetchedContacts]
          );
          setSearchPage(pageNumber + 1);
        } else {
          setTotalPages(res?.data?.totalPages);
          setContactsData((prevContacts) => [
            ...prevContacts,
            ...fetchedContacts,
          ]);
          setPage(pageNumber + 1);
        }

        setLoading(false);
        setSearchLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
        setSearchLoading(false);
      });
  };

  useEffect(() => {
    fetchContacts(1);
  }, []);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      count !== appendContacts.length
    ) {
      if (searchValue) {
        if (searchPage <= searchTotalPages) {
          fetchContacts(searchPage, true);
        }
      } else {
        if (page <= totalPages) {
          fetchContacts(page);
        }
      }
    }
  };

  useEffect(() => {
    if (getContactArray?.length > 0) {
      // Use passed in state if available
      setAppendContacts(getContactArray);
    } else if (savedContacts?.length > 0) {
      // Fall back to Redux state
      setGetContactExcept(savedContacts.map((contact) => contact._id));
      setGetContactArray(savedContacts);
    }
  }, []);

  const handleContactSelect = (contact) => {
    setGetContactExcept((prevChecked) => {
      if (prevChecked?.includes(contact?._id)) {
        return prevChecked?.filter((id) => id !== contact?._id);
      } else {
        return [...prevChecked, contact?._id];
      }
    });

    setGetContactArray((prevSelectedContactArray) => {
      if (!prevSelectedContactArray) {
        prevSelectedContactArray = [];
      }
      if (Array.isArray(prevSelectedContactArray)) {
        const isSelected = prevSelectedContactArray?.some(
          (c) => c?._id === contact?._id
        );
        if (isSelected) {
          return prevSelectedContactArray?.filter(
            (c) => c?._id !== contact?._id
          );
        } else {
          return [...prevSelectedContactArray, contact];
        }
      }
      return prevSelectedContactArray;
    });
  };

  const removeContact = (contactId) => {
    const remainContactsList = getContactArray?.filter(
      (contact) => contact?._id !== contactId
    );
    const remainContacts = getContactExcept?.filter(
      (contact) => contact !== contactId
    );
    setGetContactExcept(remainContacts);
    setGetContactArray(remainContactsList);
  };

  useEffect(() => {
    if (searchValue !== "") {
      var timer = setTimeout(() => {
        setSearchLoading(true);
        setSearchPage(1);
        fetchContacts(1, true);

        if (containerRef.current) {
          containerRef.current.scrollTo(0, 0);
        }
      }, 1000);
    } else {
      if (containerRef.current) {
        containerRef.current.scrollTo(0, 0);
      }
      setSearchLoading(false);
      setAppendContacts(Contacts);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  const handleSearchInputChange = (e) => {
    const keyword = e.target.value;
    setSearchValue(keyword);
    setSearchLoading(true);
  };

  const handleClearSearch = () => {
    setSearchValue("");
    setAppendContacts(Contacts);
    setSearchPage(searchPage);
    setPage(page);
    searchInputRef.current.focus();
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  };

  return (
    <div className="shareContact">
      <div className="shareContact-header">
        <div className="search-bar">
          <div className="search-bar-wrapper">
            <svg width="12" height="12" viewBox="0 0 18 18" fill="none">
              <title>Search</title>
              <path
                id="Vector"
                d="M14.2049 12.952L18 16.7462L16.7462 18L12.952 14.2049C11.5402 15.3366 9.78419 15.9522 7.9748 15.9496C3.57271 15.9496 0 12.3769 0 7.9748C0 3.57271 3.57271 0 7.9748 0C12.3769 0 15.9496 3.57271 15.9496 7.9748C15.9522 9.78419 15.3366 11.5402 14.2049 12.952ZM12.4274 12.2945C13.5519 11.138 14.18 9.58786 14.1774 7.9748C14.1774 4.54741 11.4013 1.77218 7.9748 1.77218C4.54741 1.77218 1.77218 4.54741 1.77218 7.9748C1.77218 11.4013 4.54741 14.1774 7.9748 14.1774C9.58786 14.18 11.138 13.5519 12.2945 12.4274L12.4274 12.2945Z"
                fill="#999999"
              />
            </svg>

            <input
              type="search"
              placeholder="Search"
              value={searchValue}
              onChange={handleSearchInputChange}
              ref={searchInputRef}
            />
            {searchValue?.length > 0 && (
              <svg
                width="12"
                height="12"
                viewBox="0 0 25 25"
                fill="none"
                style={{
                  overflow: "visible",
                  pointerEvents: "auto",
                  cursor: "pointer",
                }}
                onClick={handleClearSearch}
              >
                <title>Close</title>
                <g id="iconoir:cancel">
                  <path
                    id="Vector"
                    d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                    stroke="#999999"
                    strokeWidth="2.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            )}
          </div>
        </div>
        {getContactArray && getContactArray?.length > 0 && (
          <div className="selected-contacts-wrapper">
            <div className="selected-contacts">
              {getContactArray?.map((contact, index) => (
                <div key={index} className="selected-contact-wrapper">
                  <div className="selected-contact">
                    <img
                      src={getUserProfilePic(contact?.profile)}
                      alt={contact?.name}
                    />
                    <div
                      className="delete"
                      onClick={() => removeContact(contact?._id)}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <title>Unselect contact</title>
                        <g id="Frame">
                          <g clipPath="url(#clip0_974_4249)">
                            <path
                              id="Vector"
                              d="M9.00665 7.98493L12.0374 4.95422C12.3163 4.67531 12.7685 4.67531 13.0474 4.95422C13.3263 5.23312 13.3263 5.68531 13.0474 5.96422L10.0167 8.99493L13.0474 12.0256C13.3263 12.3046 13.3263 12.7567 13.0474 13.0356C12.7685 13.3146 12.3163 13.3146 12.0374 13.0356L9.00665 10.0049L5.97594 13.0356C5.69703 13.3146 5.24484 13.3146 4.96594 13.0356C4.68703 12.7567 4.68703 12.3046 4.96594 12.0256L7.99665 8.99493L4.96594 5.96422C4.68703 5.68531 4.68703 5.23312 4.96594 4.95422C5.24484 4.67531 5.69703 4.67531 5.97594 4.95422L9.00665 7.98493Z"
                              fill="#8E8E93"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_974_4249">
                            <rect
                              x="0.4375"
                              y="0.421875"
                              width="17.1429"
                              height="17.1429"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <span className="selected-contact-name ">
                    {contact?.name?.length > 9
                      ? `${contact.name.substring(0, 9)}...`
                      : contact?.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {searchLoading && <Spinner />}
      <div
        className="shareContact-list"
        onScroll={handleScroll}
        ref={containerRef}
      >
        {loading && page === 1 ? (
          <div className="no-data">
            <Spinner />
          </div>
        ) : appendContacts && appendContacts?.length > 0 ? (
          appendContacts?.map((contact, index) => (
            <div
              className="profile"
              tabIndex="0"
              key={contact._id}
              onClick={() => handleContactSelect(contact)}
            >
              <div className="profile-image">
                <img
                  className="profile-avatar"
                  src={getUserProfilePic(contact?.profile)}
                  alt="Profile Avatar"
                />
              </div>
              <div className="profile-info-container">
                <div className="profile-info">
                  <span className="name">{contact?.name}</span>
                  <p className="profile-status-info">{contact?.description}</p>
                </div>
                <div className="group-check">
                  <input
                    type="radio"
                    id={`contact-check-${index}`}
                    aria-label="Check this box"
                    checked={getContactExcept?.includes(contact?._id)}
                    readOnly
                  />
                  <div className="checkmark" role="radio" tabIndex="0">
                    <div className="offRadio radio-cmn"></div>
                    <div className="onRadio radio-cmn"></div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : searchValue ? (
          <div className="no-contact-waraper">
            <div className="no-contact">
              <span className="text">No contacts found</span>
            </div>
          </div>
        ) : (
          <div className="no-contact-waraper">
            <div className="no-contact">
              <div className="no-contact-img">
                <svg width="45" height="45" viewBox="0 0 56 56" fill="none">
                  <g clipPath="url(#clip0_2399_591)">
                    <path
                      d="M7.02828 51.7659L7.03442 51.7351L7.03861 51.704C8.52261 40.6773 18.0238 32.6176 29.0238 32.6176C40.0019 32.6176 49.4872 40.6453 51 51.6383V51.8824C51 52.5916 50.7688 53.1044 50.4376 53.437C50.1068 53.7692 49.5981 54 48.8951 54H9.15244H8.84196C8.26736 54 7.76952 53.716 7.42527 53.264C7.07121 52.7991 6.93616 52.2285 7.02828 51.7659ZM40.4434 14.4706C40.4434 20.781 35.2984 25.9412 29.0238 25.9412C22.7491 25.9412 17.6042 20.781 17.6042 14.4706C17.6042 8.16018 22.7491 3 29.0238 3C35.2984 3 40.4434 8.16018 40.4434 14.4706Z"
                      stroke={
                        theme === "Light"
                          ? "#2A2A2A"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#2A2A2A"
                          : "#ffffff"
                      }
                      strokeWidth="2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2399_591">
                      <rect width="56" height="56" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <span className="text">No contact yet</span>
            </div>
          </div>
        )}
        {!searchLoading && loading && page >= 2 && <Spinner />}
      </div>
    </div>
  );
}

export default ExceptContact;
