import React from "react";
import { HELPCENTERURL } from "../../../Utils/enums";

const EatingDisorders = ({ SubmitReport }) => {
  return (
    <div className="reportPost-info">
      <div className="nudity-guidelines">
        <p className="title">Eating disorders guidelines</p>

        <div className="we-remove">
          <ul className="remove-list-wrapper">
            <li className="remove-list">
              <p className="info">
                When it comes to sensitive topics such as this, we want to
                support our community. We've gathered some resources that may be
                helpful.
              </p>
            </li>
          </ul>
          <span className="subtitle">We remove:</span>
          <ul className="remove-list-wrapper">
            <li className="remove-list">
              <div className="circle" aria-hidden="true" />
              <p className="info">
                Posts encouraging or promoting eating disorders.
              </p>
            </li>
          </ul>
          <ul className="remove-list-wrapper">
            <li className="remove-list">
              <div className="circle" aria-hidden="true" />
              <p className="info">
                Comments identifying those suffering from an eating disorder if
                the post attacks or makes fun of them.
              </p>
            </li>
          </ul>
        </div>

        <button className="btn btn-submit" onClick={() => SubmitReport()}>
          Submit Report
        </button>

        <button
          className="btn btn-resources"
          onClick={() => window.open(HELPCENTERURL, "_blank")}
        >
          See Resources
        </button>
      </div>
    </div>
  );
};

export default EatingDisorders;
