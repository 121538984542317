import React, { useRef, useState, useCallback } from "react";
import Webcam from "react-webcam";
import CapturePhoto from "./CapturePhoto";
import takephoto from "../../assets/image/Frame 181.png";
import close from "../../assets/image/SVG_Images/close-green.svg";
import { VideoConstraints, base64StringToFile } from "../../Utils/helpers";
import "../../assets/css/takePhoto.scss";

function TakePhoto({ localstream, setCloseCamera, onImageSend }) {
  const webcamRef = useRef(null);
  const { v4 } = require("uuid");
  const [file, setFile] = useState(null);
  const [base64img, setBase64imgSrc] = useState(null);
  const [showCapture, setShowCapture] = useState(false);

  const handleClose = () => {
    setCloseCamera(true);
    if (localstream && localstream.getTracks) {
      localstream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const capture = useCallback(async () => {
    const base64img = webcamRef.current.getScreenshot({
      width: 1920,
      height: 1080,
    });
    const blob = base64StringToFile(base64img, v4() + ".jpg");
    setFile(blob);
    setShowCapture(true);
    setBase64imgSrc(base64img);

    if (localstream && localstream.getTracks) {
      localstream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }, []);

  return (
    <>
      {showCapture ? (
        <CapturePhoto
          imgSrc={base64img}
          file={file}
          localstream={localstream}
          setCloseCamera={setCloseCamera}
          onImageSend={onImageSend}
        />
      ) : (
        <div className="takePhoto">
          <div className="heading">
            <div className="close">
              <img src={close} alt="close" onClick={handleClose} />
            </div>
            <span>Take photo</span>
          </div>
          <div className="container">
            <div className="photo-container">
              <Webcam
                ref={webcamRef}
                imageSmoothing={true}
                videoConstraints={VideoConstraints}
                screenshotFormat="image/jpeg"
              />
              <div className="takePhoto-camera">
                <img src={takephoto} onClick={capture} alt="camera" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TakePhoto;
