import React, { forwardRef } from "react";
import "../../assets/css/menu.scss";
const HeaderMenu = forwardRef(({ isOpen, variant, menuPosition }, ref) => {
  const getMenuStyle = () => {
    if (variant === "HeaderProfile") {
      return { right: 8, top: 45 };
    }
  };
  const menuStyle = {
    ...getMenuStyle(),
    ...menuPosition,
  };

  return (
    <div
      className={`menu ${isOpen ? "" : "hidden"}`}
      style={menuStyle}
      // style={{ right: 15, top: 45 }}
      ref={ref} // Forward the ref to the underlying div element
    >
      <ul className="menu-list">
        <li key="contact-info">Contact Info</li>
        <li key="select-messages">Select messages</li>
        <li key="mute-notifications">Mute Notifications</li>
        <li key="disappearing-messages">Disappearing Messages</li>
        <li key="close-chat">Close Chat</li>
        <li key="clear-messages">Clear messages</li>
        <li key="delete-chat">Delete Chat</li>
        <li key="report">Report</li>
        <li className="block" key="block">
          Block
        </li>
      </ul>
    </div>
  );
});

export default HeaderMenu;
