import React, { useState, useEffect, useRef } from "react";
import Sharemenu from "./Sharemenu";
import star from "../../assets/image/star.png";
import DeleteMessage from "../Group/DeleteMessage";
import { useDispatch, useSelector } from "react-redux";
import { setChatBoxContact } from "../../Redux/actions";
import { MessageAction } from "../Common/MessageAction";
import downArrow from "../../assets/image/downArrow.png";
import {
  getTimeFromDate,
  getMessageStatus,
  getUserProfilePic,
  TN_DEFAULT_IMAGE,
} from "../../Utils/helpers";
import {
  SENDER_CONTACT,
  RECEIVER_CONTACT,
  SENDER_TEXT_REPLY,
} from "../../Utils/enums";
import "../../assets/css/contact.scss";

function Contact({
  message,
  tapped,
  getTappedMessage,
  getSenderMessageType,
  getIds,
  viewAllContact,
  getViewContacts,
  showGroups,
  showForward,
  setForwardMessage,
  setViewContactType,
  forward,
  deleteMessage,
}) {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const downArrowRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [deletedMessage, setDeletedMessage] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [scrollEnabled]);

  const closeDropdown = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event.target)) {
      setIsShareMenuOpen(false);
      setScrollEnabled(true);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isShareMenuOpen) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isShareMenuOpen]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  }, []);

  const onDoubleTapped = () => {
    tapped(true);
    getTappedMessage(message);
    getSenderMessageType(SENDER_TEXT_REPLY);
    getIds(message?._id);
    handleClickOutside();
  };

  const onViewAll = (contact) => {
    viewAllContact(true);
    getViewContacts(contact);
    setViewContactType("view");
  };

  const handleClick = (contact) => {
    dispatch(setChatBoxContact(contact));
  };

  const onForwardMessage = () => {
    showForward(true);
    setForwardMessage(message);
  };

  const toggleShareMenu = (event) => {
    event.preventDefault();
    const arrowRect = downArrowRef.current.getBoundingClientRect();
    const { right, top, left } = arrowRect;
    const isReceiverText = message?.type === RECEIVER_CONTACT;
    const position = isReceiverText
      ? { x: left, y: top }
      : { x: right, y: top };
    setMenuPosition(position);
    setIsShareMenuOpen(true);
    setScrollEnabled(false);
  };

  // useEffect(() => {
  //   const getContacts = async () => {
  //     var messageIds = [];
  //     message?.Contact?.map((contact) =>
  //       messageIds.push(`${contact?.number}-${contact?.owner_id}`)
  //     );
  //     const MsgContacts = await fetchMessageContactsByIds(dispatch, messageIds);
  //     setContacts(MsgContacts);
  //   };
  //   getContacts();
  // }, [message, MessageContacts]);

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const onDelete = () => {
    setDeletedMessage(true);
  };

  const onStar = async () => {
    // Realm.write(() => {
    //   Realm.create(
    //     "RMessageModel",
    //     {
    //       _id: message._id,
    //       isStarMessage: !message?.isStarMessage,
    //     },
    //     "modified"
    //   );
    // });
    setIsShareMenuOpen(false);
    setScrollEnabled(true);
  };

  return (
    <>
      {message && message.contact && (
        <>
          {message.contact.length === 1 && (
            <>
              {message?.type === RECEIVER_CONTACT && (
                <div
                  className="message-group"
                  onDoubleClick={() => onDoubleTapped()}
                >
                  {forward && (
                    <div className="message-check">
                      <input
                        type="checkbox"
                        id="message-check"
                        aria-label="Check this box"
                        checked={isChecked}
                      />
                      <span
                        className="checkmark"
                        onClick={handleCheckmarkClick}
                      />
                    </div>
                  )}
                  <div
                    className="mainMessage-wrapper"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <div
                      className="contactRecieved"
                      style={{ paddingLeft: forward ? "40px" : "" }}
                    >
                      <div className="wrapper">
                        <div className="contact-wrapper">
                          <div className="contact">
                            <div className="contact-img">
                              <img
                                className="contact-thumbnail"
                                src={
                                  message.contact[0]?.profilePic
                                    ? message.contact[0]?.profilePic
                                    : TN_DEFAULT_IMAGE
                                }
                                alt="thumbnail"
                              />
                            </div>
                            <div className="contact-details">
                              <p className="name">{message.contact[0]?.name}</p>
                              <p className="number">
                                {message.contact[0]?.countryCode}{" "}
                                {message.contact[0]?.number}
                              </p>
                            </div>
                            <div className="contact-status">
                              {message?.isStarMessage && (
                                <img
                                  className="important"
                                  src={star}
                                  alt="important"
                                />
                              )}
                              <span className="date">
                                {getTimeFromDate(message)}
                              </span>
                            </div>
                          </div>
                          <div
                            className="contact-cta"
                            onClick={() => handleClick(message.contact)}
                          >
                            <div className="cta cta-message">
                              <span>Message</span>
                            </div>
                            <div
                              className="cta cta-group"
                              onClick={() => showGroups(true)}
                            >
                              <span>Add to a group</span>
                            </div>
                          </div>
                          <div className="down-arrow">
                            <img
                              src={downArrow}
                              ref={downArrowRef}
                              alt="down"
                              role="button"
                              onClick={toggleShareMenu}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {!forward && <MessageAction message={message} />}
                  </div>
                  {/* <div
                    className="messageReactedEmojis messageReactedEmojis-left"
                    style={{ left: forward && "55px", alignSelf: "flex-start" }}
                  >
                    <div className="reacted-emoji">😜</div>
                    <div className="reacted-emoji">😜</div>
                  </div> */}
                </div>
              )}
              {message?.type === SENDER_CONTACT && (
                <div
                  className="message-group"
                  onDoubleClick={() => onDoubleTapped()}
                >
                  {forward && (
                    <div className="message-check">
                      <input
                        type="checkbox"
                        id="message-check"
                        aria-label="Check this box"
                        checked={isChecked}
                      />
                      <span
                        className="checkmark"
                        onClick={handleCheckmarkClick}
                      />
                    </div>
                  )}
                  <div
                    className="mainMessage-wrapper"
                    style={{ justifyContent: "flex-end" }}
                  >
                    {!forward && <MessageAction message={message} />}
                    <div className="contactSend">
                      <div className="wrapper">
                        <div className="contact-wrapper">
                          <div className="contact">
                            <div className="contact-img">
                              <img
                                className="contact-thumbnail"
                                src={getUserProfilePic(
                                  message.contact[0]?.profilePic
                                )}
                                alt="thumbnail"
                              />
                            </div>
                            <div className="contact-details">
                              <p className="name">{message.contact[0]?.name}</p>
                              <p className="number">
                                {message.contact[0]?.countryCode}{" "}
                                {message.contact[0]?.number}
                              </p>
                            </div>
                            <div className="contact-status">
                              {message?.isStarMessage && (
                                <img
                                  className="important"
                                  src={star}
                                  alt="important"
                                />
                              )}
                              <span className="date">
                                {getTimeFromDate(message)}
                              </span>
                              <img
                                className="status"
                                src={getMessageStatus(message)}
                                alt="seen"
                              />
                            </div>
                          </div>
                          <div
                            className="contact-cta"
                            onClick={() => handleClick(message.contact)}
                          >
                            <div className="cta cta-message">
                              <span>Message</span>
                            </div>
                            <div
                              className="cta cta-group"
                              onClick={() => showGroups(true)}
                            >
                              <span>Add to a group</span>
                            </div>
                          </div>
                          <div className="down-arrow">
                            <img
                              src={downArrow}
                              ref={downArrowRef}
                              alt="down"
                              role="button"
                              onClick={toggleShareMenu}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="messageReactedEmojis messageReactedEmojis-right">
                    <div className="reacted-emoji">😜</div>
                    <div className="reacted-emoji">😜</div>
                  </div> */}
                </div>
              )}
            </>
          )}
          {message.contact.length === 2 && (
            <>
              {message?.type === RECEIVER_CONTACT && (
                <div
                  className="message-group"
                  onDoubleClick={() => onDoubleTapped()}
                >
                  {forward && (
                    <div className="message-check">
                      <input
                        type="checkbox"
                        id="message-check"
                        aria-label="Check this box"
                        checked={isChecked}
                      />
                      <span
                        className="checkmark"
                        onClick={handleCheckmarkClick}
                      />
                    </div>
                  )}
                  <div
                    className="mainMessage-wrapper"
                    style={{
                      justifyContent: "flex-start",
                      paddingLeft: forward ? "40px" : "",
                    }}
                  >
                    <div className="contactRecieved-Multiple">
                      <div className="wrapper">
                        <div className="contact-wrapper">
                          <div className="contact">
                            <div className="contact-img-wrapper">
                              <div className="contact-img">
                                <img
                                  className="contact-thumbnail"
                                  src={
                                    message.contact[1]?.profilePic
                                      ? message.contact[1]?.profilePic
                                      : TN_DEFAULT_IMAGE
                                  }
                                  alt="thumbnail"
                                />
                              </div>
                              <div className="contact-img contact-multiple">
                                <img
                                  className="contact-thumbnail"
                                  src={
                                    message.contact[0]?.profilePic
                                      ? message.contact[0]?.profilePic
                                      : TN_DEFAULT_IMAGE
                                  }
                                  alt="thumbnail"
                                />
                              </div>
                            </div>
                            <div className="contact-multiple-info">
                              <p className="multiple-info">
                                {message.contact[0]?.name} and 1 other contact
                              </p>
                            </div>
                            <div className="contact-status">
                              {message?.isStarMessage && (
                                <img
                                  className="important"
                                  src={star}
                                  alt="important"
                                />
                              )}
                              <span className="date">
                                {getTimeFromDate(message)}
                              </span>
                            </div>
                          </div>
                          <div
                            className="contact-cta"
                            onClick={() => onViewAll(message.contact)}
                          >
                            <div className="cta-view">
                              <span>View All</span>
                            </div>
                          </div>
                          <div className="down-arrow">
                            <img
                              src={downArrow}
                              ref={downArrowRef}
                              alt="down"
                              role="button"
                              onClick={toggleShareMenu}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {!forward && <MessageAction message={message} />}
                  </div>
                  {/* <div
                    className="messageReactedEmojis messageReactedEmojis-left"
                    style={{ left: forward && "55px", alignSelf: "flex-start" }}
                  >
                    <div className="reacted-emoji">😜</div>
                    <div className="reacted-emoji">😜</div>
                  </div> */}
                </div>
              )}
              {message?.type === SENDER_CONTACT && (
                <div
                  className="message-group"
                  onDoubleClick={() => onDoubleTapped()}
                >
                  {forward && (
                    <div className="message-check">
                      <input
                        type="checkbox"
                        id="message-check"
                        aria-label="Check this box"
                        checked={isChecked}
                      />
                      <span
                        className="checkmark"
                        onClick={handleCheckmarkClick}
                      />
                    </div>
                  )}
                  <div
                    className="mainMessage-wrapper"
                    style={{ justifyContent: "flex-end" }}
                  >
                    {!forward && <MessageAction message={message} />}
                    <div className="contactSend-Multiple">
                      <div className="wrapper">
                        <div className="contact-wrapper">
                          <div className="contact">
                            <div className="contact-img-wrapper">
                              <div className="contact-img">
                                <img
                                  className="contact-thumbnail"
                                  src={getUserProfilePic(
                                    message.contact[1]?.profilePic
                                  )}
                                  alt="thumbnail"
                                />
                              </div>
                              <div className="contact-img contact-multiple">
                                <img
                                  className="contact-thumbnail"
                                  src={getUserProfilePic(
                                    message.contact[0]?.profilePic
                                  )}
                                  alt="thumbnail"
                                />
                              </div>
                            </div>
                            <div className="contact-multiple-info">
                              <p className="multiple-info">
                                {message.contact[0]?.name} and 1 other contact
                              </p>
                            </div>
                            <div className="contact-status">
                              {message?.isStarMessage && (
                                <img
                                  className="important"
                                  src={star}
                                  alt="important"
                                />
                              )}
                              <span className="date">
                                {getTimeFromDate(message)}
                              </span>
                              <img
                                className="status"
                                src={getMessageStatus(message)}
                                alt="seen"
                              />
                            </div>
                          </div>
                          <div
                            className="contact-cta"
                            onClick={() => onViewAll(message.contact)}
                          >
                            <div className="cta-view">
                              <span>View All</span>
                            </div>
                          </div>
                          <div className="down-arrow">
                            <img
                              src={downArrow}
                              ref={downArrowRef}
                              alt="down"
                              role="button"
                              onClick={toggleShareMenu}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="messageReactedEmojis messageReactedEmojis-right">
                    <div className="reacted-emoji">😜</div>
                    <div className="reacted-emoji">😜</div>
                  </div> */}
                </div>
              )}
            </>
          )}
          {message.contact.length > 2 && (
            <>
              {message?.type === RECEIVER_CONTACT && (
                <div
                  className="message-group"
                  onDoubleClick={() => onDoubleTapped()}
                >
                  {forward && (
                    <div className="message-check">
                      <input
                        type="checkbox"
                        id="message-check"
                        aria-label="Check this box"
                        checked={isChecked}
                      />
                      <span
                        className="checkmark"
                        onClick={handleCheckmarkClick}
                      />
                    </div>
                  )}
                  <div
                    className="mainMessage-wrapper"
                    style={{
                      justifyContent: "flex-start",
                      paddingLeft: forward ? "40px" : "",
                    }}
                  >
                    <div className="contactRecieved-Multiples">
                      <div className="wrapper">
                        <div className="contact-wrapper">
                          <div className="contact">
                            <div className="contact-img-wrapper">
                              <div className="contact-img">
                                <img
                                  className="contact-thumbnail"
                                  src={
                                    message.contact[2]?.profilePic
                                      ? message.contact[2]?.profilePic
                                      : TN_DEFAULT_IMAGE
                                  }
                                  alt="thumbnail"
                                />
                              </div>
                              <div className="contact-img contact-multiple">
                                <img
                                  className="contact-thumbnail"
                                  src={
                                    message.contact[1]?.profilePic
                                      ? message.contact[1]?.profilePic
                                      : TN_DEFAULT_IMAGE
                                  }
                                  alt="thumbnail"
                                />
                              </div>
                              <div className="contact-img contact-multiple">
                                <img
                                  className="contact-thumbnail"
                                  src={
                                    message.contact[0]?.profilePic
                                      ? message.contact[0]?.profilePic
                                      : TN_DEFAULT_IMAGE
                                  }
                                  alt="thumbnail"
                                />
                              </div>
                            </div>
                            <div className="contact-multiple-info">
                              <p className="multiple-info">
                                {message.contact[0]?.name} and{" "}
                                {message.contact.length - 1} other contact
                              </p>
                            </div>
                            <div className="contact-status">
                              {message?.isStarMessage && (
                                <img
                                  className="important"
                                  src={star}
                                  alt="important"
                                />
                              )}
                              <span className="date">
                                {getTimeFromDate(message)}
                              </span>
                            </div>
                          </div>
                          <div
                            className="contact-cta"
                            onClick={() => onViewAll(message.contact)}
                          >
                            <div className="cta-view">
                              <span>View All</span>
                            </div>
                          </div>
                          <div className="down-arrow">
                            <img
                              src={downArrow}
                              ref={downArrowRef}
                              alt="down"
                              role="button"
                              onClick={toggleShareMenu}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {!forward && <MessageAction message={message} />}
                  </div>
                  {/* <div
                    className="messageReactedEmojis messageReactedEmojis-left"
                    style={{ left: forward && "55px", alignSelf: "flex-start" }}
                  >
                    <div className="reacted-emoji">😜</div>
                    <div className="reacted-emoji">😜</div>
                  </div> */}
                </div>
              )}
              {message?.type === SENDER_CONTACT && (
                <div
                  className="message-group"
                  onDoubleClick={() => onDoubleTapped()}
                >
                  {forward && (
                    <div className="message-check">
                      <input
                        type="checkbox"
                        id="message-check"
                        aria-label="Check this box"
                        checked={isChecked}
                      />
                      <span
                        className="checkmark"
                        onClick={handleCheckmarkClick}
                      />
                    </div>
                  )}
                  <div
                    className="mainMessage-wrapper"
                    style={{ justifyContent: "flex-end" }}
                  >
                    {!forward && <MessageAction message={message} />}
                    <div className="contactSend-Multiples">
                      <div className="wrapper">
                        <div className="contact-wrapper">
                          <div className="contact">
                            <div className="contact-img-wrapper">
                              <div className="contact-img">
                                <img
                                  className="contact-thumbnail"
                                  src={getUserProfilePic(
                                    message.contact[2]?.profilePic
                                  )}
                                  alt="thumbnail"
                                />
                              </div>
                              <div className="contact-img contact-multiple">
                                <img
                                  className="contact-thumbnail"
                                  src={getUserProfilePic(
                                    message.contact[1]?.profilePic
                                  )}
                                  alt="thumbnail"
                                />
                              </div>
                              <div className="contact-img contact-multiple">
                                <img
                                  className="contact-thumbnail"
                                  src={getUserProfilePic(
                                    message.contact[0]?.profilePic
                                  )}
                                  alt="thumbnail"
                                />
                              </div>
                            </div>
                            <div className="contact-multiple-info">
                              <p className="multiple-info">
                                {message.contact[0]?.name} and{" "}
                                {message.contact.length - 1} other contact
                              </p>
                            </div>
                            <div className="contact-status">
                              {message?.isStarMessage && (
                                <img
                                  className="important"
                                  src={star}
                                  alt="important"
                                />
                              )}
                              <span className="date">
                                {getTimeFromDate(message)}
                              </span>

                              {message?.type !== RECEIVER_CONTACT && (
                                <img
                                  className="status"
                                  src={getMessageStatus(message)}
                                  alt="seen"
                                />
                              )}
                            </div>
                          </div>
                          <div
                            className="contact-cta"
                            onClick={() => onViewAll(message.contact)}
                          >
                            <div className="cta-view">
                              <span>View All</span>
                            </div>
                          </div>
                          <div className="down-arrow">
                            <img
                              src={downArrow}
                              ref={downArrowRef}
                              alt="down"
                              role="button"
                              onClick={toggleShareMenu}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="messageReactedEmojis messageReactedEmojis-right">
                    <div className="reacted-emoji">😜</div>
                    <div className="reacted-emoji">😜</div>
                  </div> */}
                </div>
              )}
            </>
          )}
        </>
      )}
      {isShareMenuOpen && (
        <Sharemenu
          ddref={dropdownRef}
          menuPosition={menuPosition}
          isFromReceiver={message?.type === RECEIVER_CONTACT}
          onDeleteClick={onDelete}
          onStarClick={onStar}
          message={message}
        />
      )}
      {deletedMessage && (
        <DeleteMessage
          message={message}
          setDeleteMessage={setDeletedMessage}
          deleteMessage={deleteMessage}
        />
      )}
    </>
  );
}

export default Contact;
