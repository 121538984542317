import React, { useEffect, useState } from "react";
import { PopupTeparsImage } from "./PopupTeparsImage";

function AddTeparsMedia({
  goToSlide,
  media,
  setCropdeImages,
  getSelectedFileType,
  updateMedia,
  getSelectedImage,
  setSelectImageIndex,
  onClose,
  getCurrentIndex,
  setGetCurrentIndex,
  setRightClick,
  checkRightClick,
  setLeftClick,
  checkLeftClick,
  mediaIndex,
}) {
  const images = Object.values(media);
  const [index, setIndex] = useState(0);
  const [check, setCheck] = useState(false);
  const [newIndexSet, setNewIndexSet] = useState(0);

  const selectImage = (image, i) => {
    // const selectedfiletype = getMediaType([image]);
    // getSelectedFileType(selectedfiletype);
    getSelectedImage(image);
    setIndex(i);
    setGetCurrentIndex(i);
    goToSlide(i);
  };

  useEffect(() => {
    setIndex(mediaIndex);
  }, [mediaIndex]);

  useEffect(() => {
    if (checkRightClick) {
      setIndex(index + 1);
      setGetCurrentIndex(index + 1);
      setRightClick(false);
    }
    if (checkLeftClick) {
      setIndex(index - 1);
      setGetCurrentIndex(index - 1);
      setLeftClick(false);
    }
  }, [checkRightClick, checkLeftClick]);

  const onDelete = (img, i) => {
    const filterImages = images.filter((image) => img !== image);
    if (filterImages.length > 0) {
      const newIndex = i < filterImages.length ? i : filterImages.length - 1;

      if (filterImages.length !== 0) {
        setIndex(i + 1);
        setCropdeImages(filterImages);
        updateMedia(filterImages);
        setNewIndexSet(newIndex);
        setCheck(true);
      } else {
        onClose(null);
      }
    } else {
      onClose(null);
    }
  };

  useEffect(() => {
    if (check) {
      selectImage(images[newIndexSet], newIndexSet);
      setCheck(false);
    }
  }, [images]);

  return (
    <div
      className="media-wrapper"
      onKeyDown={(e) => {
        if (e.key === "ArrowRight" && index < images.length - 1) {
          selectImage(images[index + 1], index + 1);
        } else if (e.key === "ArrowLeft" && index > 0) {
          selectImage(images[index - 1], index - 1);
        }
      }}
      tabIndex="0"
    >
      {images?.map((image, i) => {
        return (
          <PopupTeparsImage
            key={image?.id || i}
            i={i}
            index={index}
            imagesList={images}
            media={image}
            handleSelect={selectImage}
            handleDelete={onDelete}
          />
        );
      })}
    </div>
  );
}

export default AddTeparsMedia;
