import React, { useEffect, useState, useRef, forwardRef } from "react";
import { DOC, IMAGE } from "../../Utils/enums";
import { getMediaType, processImage } from "../../Utils/helpers";
import "../../assets/css/filesharemenu.scss";

const FileShareMenu = forwardRef(
  (
    {
      isOpen,
      onClose,
      setHaveCamaraPermissions,
      setcloseAlert,
      setAskPermission,
      setlocalstream,
      setCloseCamera,
      showContact,
      handleFileShareMenu,
      onMediaSend,
      getSelectedMedia,
    },
    ref
  ) => {
    const fileInputRef = useRef(null);
    const [scale, setScale] = useState(0);
    const [type, setType] = useState(null);
    const [camera, setCamera] = useState(false);

    const checkPermissions = () => {
      if (navigator.permissions && navigator.permissions.query) {
        navigator.permissions
          .query({ name: "camera" })
          .then((cameraPermission) => {
            if (cameraPermission.state === "prompt") {
              setTimeout(() => {
                setAskPermission(true);
              }, 2000);
            }
          })
          .catch((error) => {
            console.error("Error querying camera permission:", error);
          });
      }
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          setlocalstream(stream);

          const tracks = stream.getTracks();
          if (tracks.some((track) => track.readyState === "live")) {
            setAskPermission(false);
          }

          setCamera(!camera);
          setHaveCamaraPermissions((prevState) => !prevState.havePermissions);
        })

        .catch((err) => {
          if (err.name === "NotAllowedError" || err.name === "NotFoundError") {
            setCamera(false);
            setAskPermission(false);
            setHaveCamaraPermissions(false);
          } else {
            console.log(`${err.name}: ${err.message}`);
          }
        });
    };

    useEffect(() => {
      setTimeout(() => {
        isOpen ? setScale(1) : setScale(0);
      }, 0.5);
    }, [isOpen]);

    const handleCloseMenu = () => {
      onClose();
    };

    const handleImageClick = (e, type) => {
      setType(type);
      e.preventDefault();
      fileInputRef.current.click();
    };

    const getType = () => {
      return type === IMAGE
        ? "image/*,video/mp4,video/3gpp,video/quicktime"
        : type === DOC
        ? "*"
        : "";
    };

    const handleFileSelected = async (e) => {
      const selectedFile = e.target.files;
      var arrData = [];
      if (selectedFile) {
        for (var i = 0; i < selectedFile?.length; i++) {
          const file = selectedFile[i];
          const filetype = getMediaType([file]);
          if (filetype === IMAGE) {
            const imageData = await processImage(file);
            arrData.push(imageData);
          } else {
            arrData.push(file);
          }
        }
      }
      getSelectedMedia(arrData);
      handleFileShareMenu();
    };

    // const handleDocClick = (e) => {
    //   const selectedFile = e.target.files;
    //   if (selectedFile) {
    //     getSelectedMedia(selectedFile);
    //   }
    //   handleFileShareMenu();
    // };

    const handleCamera = (e) => {
      setCloseCamera(false);
      setTimeout(() => {
        setcloseAlert(false);
      }, 500);
      checkPermissions();
    };

    function handleContact() {
      showContact(true);
    }

    return (
      <>
        <div
          ref={ref}
          id="imageContainer"
          className="imageContainer"
          onAnimationEnd={() => {
            if (!isOpen) {
              handleCloseMenu();
            }
          }}
        >
          {isOpen &&
            [
              {
                imageSrc: (
                  <svg width="40" height="40" viewBox="0 0 66 66" fill="none">
                    <title>Image & Video</title>
                    <rect width="66" height="66" rx="20" fill="#DFF1F3" />
                    <path
                      d="M19.375 39.7923L27.0021 30.894C27.5894 30.2088 28.6519 30.2162 29.2296 30.9095L34.3943 37.1071C34.9433 37.7659 35.9395 37.8111 36.5459 37.2047L38.6395 35.1111C39.2542 34.4965 40.2665 34.5525 40.8095 35.2312L45.625 41.2506"
                      stroke="#1891A2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M35.4141 28.1243C35.4141 27.3189 36.067 26.666 36.8724 26.666C37.6778 26.666 38.3307 27.3189 38.3307 28.1243C38.3307 28.9298 37.6778 29.5827 36.8724 29.5827C36.067 29.5827 35.4141 28.9298 35.4141 28.1243Z"
                      stroke="#1891A2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.375 23.7507C19.375 22.1398 20.6808 20.834 22.2917 20.834H42.7083C44.3192 20.834 45.625 22.1398 45.625 23.7507V41.2506C45.625 42.8615 44.3192 44.1673 42.7083 44.1673H22.2917C20.6808 44.1673 19.375 42.8615 19.375 41.2506V23.7507Z"
                      stroke="#1891A2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ),

                onClick: (e) => {
                  handleImageClick(e, IMAGE);
                },
                titleData: "Image & Video",
              },
              {
                imageSrc: (
                  <svg width="40" height="40" viewBox="0 0 66 66" fill="none">
                    <title>Document</title>
                    <rect width="66" height="66" rx="20" fill="#DFF1F3" />
                    <path
                      d="M26.2057 46.625C24.5949 46.625 23.2891 45.3192 23.2891 43.7083V23.2917C23.2891 21.6808 24.5949 20.375 26.2057 20.375H36.4141L43.7057 27.6667V43.7083C43.7057 45.3192 42.3999 46.625 40.7891 46.625H26.2057Z"
                      stroke="#1891A2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M34.9609 20.375V29.125H43.7109"
                      stroke="#1891A2"
                      strokeLinejoin="round"
                    />
                  </svg>
                ),
                onClick: (e) => {
                  handleImageClick(e, DOC);
                },
                titleData: "Document",
              },
              {
                imageSrc: (
                  <svg width="40" height="40" viewBox="0 0 66 66" fill="none">
                    <title>Camera</title>
                    <rect width="66" height="66" rx="20" fill="#DFF1F3" />
                    <path
                      d="M26.0192 47H39.9815C43.6585 47 45.124 44.7185 45.2972 41.9375L45.99 30.7865C46.0332 30.0951 45.936 29.402 45.7044 28.7502C45.4727 28.0983 45.1116 27.5015 44.6434 26.9967C44.1752 26.4919 43.6097 26.0897 42.982 25.8151C42.3543 25.5404 41.6777 25.3992 40.994 25.4C40.1813 25.4 39.4352 24.9275 39.0622 24.1985L38.1029 22.241C37.4901 21.0125 35.8914 20 34.5324 20H31.4815C30.1093 20 28.5106 21.0125 27.8977 22.241L26.9385 24.1985C26.5654 24.9275 25.8194 25.4 25.0067 25.4C22.1156 25.4 19.8241 27.8705 20.0106 30.7865L20.7034 41.9375C20.8633 44.7185 22.3421 47 26.0192 47Z"
                      stroke="#1891A2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M31.1538 27H34.8462M33 40C35.2031 40 37 38.102 37 35.775C37 33.448 35.2031 31.55 33 31.55C30.7969 31.55 29 33.448 29 35.775C29 38.102 30.7969 40 33 40Z"
                      stroke="#1790A3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ),
                onClick: handleCamera,
                titleData: "Camera",
              },
              {
                imageSrc: (
                  <svg width="40" height="40" viewBox="0 0 66 66" fill="none">
                    <title>Contact</title>
                    <rect width="66" height="66" rx="20" fill="#DFF1F3" />
                    <path
                      d="M32.5 19C25.044 19 19 25.044 19 32.5C19 39.9561 25.044 46 32.5 46C39.9561 46 46 39.9561 46 32.5C46 25.044 39.9561 19 32.5 19Z"
                      stroke="#1891A2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 41C22 41 25.0279 37.0812 32.4993 37.0812C39.9707 37.0812 43 41 43 41M32.4993 32.2618C33.5802 32.2618 34.6167 31.8266 35.381 31.0519C36.1453 30.2772 36.5746 29.2265 36.5746 28.1309C36.5746 27.0353 36.1453 25.9846 35.381 25.2099C34.6167 24.4352 33.5802 24 32.4993 24C31.4185 24 30.3819 24.4352 29.6177 25.2099C28.8534 25.9846 28.424 27.0353 28.424 28.1309C28.424 29.2265 28.8534 30.2772 29.6177 31.0519C30.3819 31.8266 31.4185 32.2618 32.4993 32.2618Z"
                      stroke="#1891A2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ),
                onClick: handleContact,
                titleData: "Contact",
              },
              {
                imageSrc: (
                  <svg width="40" height="40" viewBox="0 0 66 66" fill="none">
                    <title>Location</title>
                    <rect width="66" height="66" rx="20" fill="#DFF1F3" />
                    <ellipse
                      cx="32.5026"
                      cy="31.0417"
                      rx="2.91667"
                      ry="2.91667"
                      stroke="#1891A2"
                    />
                    <path
                      d="M32.4994 47.0841L40.5203 39.0632C44.95 34.6334 44.95 27.4513 40.5203 23.0216C36.0905 18.5918 28.9084 18.5918 24.4786 23.0216C20.0488 27.4513 20.0488 34.6334 24.4786 39.0632L32.4994 47.0841Z"
                      stroke="#1891A2"
                      strokeLinejoin="round"
                    />
                  </svg>
                ),
                onClick: handleContact,
                titleData: "Location",
              },
              // {
              //   imageSrc: (
              //     <svg width="40" height="40" viewBox="0 0 66 66" fill="none">
              //       <title>Audio</title>
              //       <rect width="66" height="66" rx="20" fill="#DFF1F3" />
              //       <g clipPath="url(#clip0_2101_43556)">
              //         <path
              //           d="M23.8473 46.3503C27.3874 46.3503 30.2571 43.4588 30.2571 39.892C30.2571 36.3251 27.3874 33.4336 23.8473 33.4336C20.3073 33.4336 17.4375 36.3251 17.4375 39.892C17.4375 43.4588 20.3073 46.3503 23.8473 46.3503Z"
              //           stroke="#1891A2"
              //           strokeLinecap="round"
              //           strokeLinejoin="round"
              //         />
              //         <path
              //           d="M43.1598 46.3503C46.6999 46.3503 49.5696 43.4588 49.5696 39.892C49.5696 36.3251 46.6999 33.4336 43.1598 33.4336C39.6198 33.4336 36.75 36.3251 36.75 39.892C36.75 43.4588 39.6198 46.3503 43.1598 46.3503Z"
              //           stroke="#1891A2"
              //           strokeLinecap="round"
              //           strokeLinejoin="round"
              //         />
              //         <path
              //           d="M30.25 39.8921V27.1696C30.25 26.9516 30.2926 26.7357 30.3755 26.5342C30.4583 26.3328 30.5798 26.1498 30.7328 25.9956C30.8859 25.8415 31.0676 25.7192 31.2676 25.6359C31.4675 25.5525 31.6819 25.5097 31.8983 25.5098H47.9146C48.131 25.5098 48.3453 25.5527 48.5452 25.6362C48.7451 25.7197 48.9268 25.842 49.0797 25.9962C49.2327 26.1505 49.3541 26.3336 49.4368 26.535C49.5195 26.7365 49.5621 26.9525 49.5619 27.1705V39.8921"
              //           stroke="#1891A2"
              //           strokeLinecap="round"
              //           strokeLinejoin="round"
              //         />
              //         <path
              //           d="M30.25 28.1377V22.9691C30.25 22.533 30.3352 22.1012 30.5009 21.6983C30.6665 21.2954 30.9092 20.9294 31.2153 20.621C31.5213 20.3127 31.8846 20.0681 32.2845 19.9012C32.6843 19.7343 33.1129 19.6484 33.5457 19.6484H46.2663C47.1404 19.6484 47.9786 19.9983 48.5967 20.621C49.2147 21.2438 49.5619 22.0884 49.5619 22.9691V28.1377"
              //           stroke="#1891A2"
              //           strokeLinecap="round"
              //           strokeLinejoin="round"
              //         />
              //       </g>
              //       <defs>
              //         <clipPath id="clip0_2101_43556">
              //           <rect
              //             width="33"
              //             height="28"
              //             fill="white"
              //             transform="translate(17 19)"
              //           />
              //         </clipPath>
              //       </defs>
              //     </svg>
              //   ),
              //   titleData: "Audio",
              // },
              {
                imageSrc: (
                  <svg width="40" height="40" viewBox="0 0 66 66" fill="none">
                    <title>Poll</title>
                    <rect width="66" height="66" rx="20" fill="#DFF1F3" />
                    <path
                      d="M31.4671 20.3421C32.0062 19.8029 32.7375 19.5 33.5 19.5C34.2625 19.5 34.9938 19.8029 35.5329 20.3421C36.0721 20.8812 36.375 21.6125 36.375 22.375V42.625C36.375 43.3875 36.0721 44.1188 35.5329 44.6579C34.9938 45.1971 34.2625 45.5 33.5 45.5C32.7375 45.5 32.0062 45.1971 31.4671 44.6579C30.9279 44.1188 30.625 43.3875 30.625 42.625V22.375C30.625 21.6125 30.9279 20.8812 31.4671 20.3421ZM21.3421 33.8421C21.8812 33.3029 22.6125 33 23.375 33C24.1375 33 24.8688 33.3029 25.4079 33.8421C25.9471 34.3812 26.25 35.1125 26.25 35.875V42.625C26.25 43.3875 25.9471 44.1188 25.4079 44.6579C24.8688 45.1971 24.1375 45.5 23.375 45.5C22.6125 45.5 21.8812 45.1971 21.3421 44.6579C20.8029 44.1188 20.5 43.3875 20.5 42.625V35.875C20.5 35.1125 20.8029 34.3812 21.3421 33.8421ZM41.5921 27.0921C42.1312 26.5529 42.8625 26.25 43.625 26.25C44.3875 26.25 45.1188 26.5529 45.6579 27.0921C46.1971 27.6312 46.5 28.3625 46.5 29.125V42.625C46.5 43.3875 46.1971 44.1188 45.6579 44.6579C45.1188 45.1971 44.3875 45.5 43.625 45.5C42.8625 45.5 42.1312 45.1971 41.5921 44.6579C41.0529 44.1188 40.75 43.3875 40.75 42.625V29.125C40.75 28.3625 41.0529 27.6312 41.5921 27.0921Z"
                      stroke="#1891A2"
                    />
                  </svg>
                ),
                titleData: "Poll",
              },
            ].map(({ imageSrc, onClick, titleData }, index) => (
              <div
                key={index}
                style={{
                  transitionDelay: `${0.1 * (4 - index)}s`,
                  transform: `scale(${scale})`,
                }}
                className={`file ${isOpen ? "visible" : ""}`}
                onClick={onClick}
              >
                {imageSrc}
              </div>
            ))}
        </div>
        <input
          accept="*"
          type="file"
          multiple
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileSelected}
        />
        {/* {type === IMAGE ? (
          <input accept="image/*,video/mp4,video/3gpp,video/quicktime" type="file" multiple ref={fileInputRef} style={{ display: "none" }} onChange={handleFileSelected} />
        ) : (
          <input accept="*" type="file" multiple ref={fileInputRef} style={{ display: "none" }} onChange={handleFileSelected} />
        )} */}
      </>
    );
  }
);

export default FileShareMenu;
