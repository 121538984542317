import React, { useEffect, useState, useRef } from "react";
import Spinner from "../Common/Spinner";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import { handleToast } from "../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getGroupProfilePic, getGroupMembersName } from "../../Utils/helpers";
import "../../assets/css/commongroups.scss";
import ComingSoon from "../Common/ComingSoon";

function CommonGroups({ onClose }) {
  const dispatch = useDispatch();

  const containerRef = useRef(null);
  const searchInputRef = useRef(null);
  const popupRef = useRef(null);

  const user = useSelector((state) => state.user);

  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTotalPages, setSearchTotalPages] = useState(0);
  const [count, setCount] = useState();
  const [appendGroup, setAppendGroup] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [Groups, setGroupsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (groupId) => {
    setImageErrors((prevErrors) => ({
      ...prevErrors,
      [groupId]: true,
    }));
  };

  useEffect(() => {
    if (Groups) {
      setAppendGroup(Groups);
    }
  }, [Groups]);

  const fetchGroup = (pageNumber, isSearch = false) => {
    setLoading(true);
    const endpoint = isSearch
      ? `group/web/${user?._id}?search=${searchValue}&page=${pageNumber}`
      : `group/web/${user?._id}?page=${pageNumber}`;

    axiosRequest
      .get(endpoint, {})
      .then((res) => {
        setCount(res?.data?.totalGroups);
        if (isSearch) {
          setSearchTotalPages(res?.data?.totalPages);
          setAppendGroup((prevGroups) =>
            pageNumber === 1 ? res.data.data : [...prevGroups, ...res.data.data]
          );
          setSearchPage(pageNumber + 1);
        } else {
          setTotalPages(res?.data?.totalPages);
          setGroupsData((prevTepars) => [...prevTepars, ...res.data.data]);
          setPage(pageNumber + 1);
        }
        setLoading(false);
        setSearchLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
        setSearchLoading(false);
      });
  };

  useEffect(() => {
    fetchGroup(1);
  }, []);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      count !== appendGroup.length
    ) {
      if (searchValue) {
        if (searchPage <= searchTotalPages) {
          fetchGroup(searchPage, true);
        }
      } else {
        if (page <= totalPages) {
          fetchGroup(page);
        }
      }
    }
  };

  useEffect(() => {
    if (searchValue !== "") {
      var timer = setTimeout(() => {
        setSearchLoading(true);
        setSearchPage(1);
        fetchGroup(1, true);

        if (containerRef.current) {
          containerRef.current.scrollTo(0, 0);
        }
      }, 1000);
    } else {
      if (containerRef.current) {
        containerRef.current.scrollTo(0, 0);
      }
      setSearchLoading(false);
      setAppendGroup(Groups);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  const handleSearchInputChange = (e) => {
    const keyword = e.target.value;
    setSearchValue(keyword);
    setSearchLoading(true);
  };

  const handleClearSearch = () => {
    setSearchValue("");
    setAppendGroup(Groups);
    setSearchPage(searchPage);
    setPage(page);
    searchInputRef.current.focus();
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  };

  const handleClickOutside = (event) => {
    if (popupRef.current === event.target) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="commonGroups" ref={popupRef}>
      <div className="commonGroups-container">
        <div className="heading">
          <div className="hidden" aria-hidden="true" />
          <h4 className="title">Common groups</h4>
          <div
            className="btn btn-close"
            aria-label="Close"
            role="button"
            tabIndex="0"
            onClick={() => {
              onClose();
            }}
          >
            <svg width="18" height="18" viewBox="0 0 25 25" fill="none">
              <title>Close</title>
              <g id="iconoir:cancel">
                <path
                  id="Vector"
                  d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                  stroke="white"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="search-bar">
          <div className="search-bar-wrapper">
            <svg width="12" height="12" viewBox="0 0 18 18" fill="none">
              <title>Search</title>
              <path
                id="Vector"
                d="M14.2049 12.952L18 16.7462L16.7462 18L12.952 14.2049C11.5402 15.3366 9.78419 15.9522 7.9748 15.9496C3.57271 15.9496 0 12.3769 0 7.9748C0 3.57271 3.57271 0 7.9748 0C12.3769 0 15.9496 3.57271 15.9496 7.9748C15.9522 9.78419 15.3366 11.5402 14.2049 12.952ZM12.4274 12.2945C13.5519 11.138 14.18 9.58786 14.1774 7.9748C14.1774 4.54741 11.4013 1.77218 7.9748 1.77218C4.54741 1.77218 1.77218 4.54741 1.77218 7.9748C1.77218 11.4013 4.54741 14.1774 7.9748 14.1774C9.58786 14.18 11.138 13.5519 12.2945 12.4274L12.4274 12.2945Z"
                fill="#999999"
              />
            </svg>

            <input
              type="search"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => handleSearchInputChange(e)}
              ref={searchInputRef}
            />
            {searchValue.length > 0 && (
              <svg
                width="12"
                height="12"
                viewBox="0 0 25 25"
                fill="none"
                style={{
                  overflow: "visible",
                  pointerEvents: "auto",
                  cursor: "pointer",
                }}
                onClick={handleClearSearch}
              >
                <title>Close</title>
                <g id="iconoir:cancel">
                  <path
                    id="Vector"
                    d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                    stroke="#999999"
                    strokeWidth="2.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            )}
          </div>
        </div>
        {searchLoading && <Spinner />}
        {loading && page === 1 ? (
          <div className="no-data">
            <Spinner />
          </div>
        ) : appendGroup && appendGroup.length > 0 ? (
          <div
            ref={containerRef}
            onScroll={handleScroll}
            className="all-groups"
          >
            <div className="user-groups">
              {appendGroup?.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="user-groups-list"
                    onClick={() => setShowGroup(true)}
                  >
                    <div
                      className="user-groups-listItem"
                      role="list"
                      tabIndex="0"
                    >
                      <div className="profile-image">
                        <img
                          className="profile-avatar"
                          src={getGroupProfilePic(
                            data?.groupProfile,
                            imageErrors[data?._id]
                          )}
                          alt="profile"
                          onError={() => handleImageError(data?._id)}
                        />
                      </div>
                      <div className="profile-info">
                        <div className="profile-info-name">
                          <span className="name">{data?.groupName}</span>
                          <p className="profile-info-message">
                            {getGroupMembersName(data?.memberList)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : searchValue ? (
          <div className="no-group-waraper">
            <div className="no-group">
              <span className="text">No groups found</span>
            </div>
          </div>
        ) : (
          <div className="no-group-waraper">
            <span className="text">No groups yet</span>
          </div>
        )}
        {!searchLoading && loading && page >= 2 && <Spinner />}
      </div>
      {showGroup && <ComingSoon onClose={() => setShowGroup(false)} />}
    </div>
  );
}

export default CommonGroups;
