import React from "react";

const HateSpeech = ({ SubmitReport }) => {
  return (
    <div className="reportPost-info">
      <div className="nudity-guidelines">
        <p className="title">Hate speech or symbols guidelines</p>
        <div className="we-remove">
          <span className="subtitle">We remove:</span>
          <ul className="remove-list-wrapper">
            <li className="remove-list">
              <div className="circle" aria-hidden="true" />
              <p className="info">
                Photos or videos of hate speech or symbols.
              </p>
            </li>
            <li className="remove-list">
              <div className="circle" aria-hidden="true" />
              <p className="info">
                Posts with captions that encourage violence or attack anyone
                based on who they are.
              </p>
            </li>
            <li className="remove-list">
              <div className="circle" aria-hidden="true" />
              <p className="info">
                Specific threats of physical harm, theft or vandalism.
              </p>
            </li>
          </ul>
        </div>
        <button className="btn btn-submit" onClick={() => SubmitReport()}>
          Submit Report
        </button>
      </div>
    </div>
  );
};

export default HateSpeech;
