import React, { useState, useRef, useEffect } from "react";
import Sharemenu from "./Sharemenu";
import ContactMenu from "./ContectMenu";
import { Twemoji } from "react-emoji-render";
import star from "../../assets/image/star.png";
import DeleteMessage from "../Group/DeleteMessage";
import { MessageAction } from "../Common/MessageAction";
import downArrow from "../../assets/image/downArrow.png";
import SocialLinkPreviewChat from "./SocialLinkPreviewChat";
import {
  getMessageStatus,
  getMessageText,
  getTimeFromDate,
} from "../../Utils/helpers";
import {
  CONTACT_REGX,
  EMAIL_REGX,
  LINK_REGX,
  RECEIVER_TEXT,
  SENDER_TEXT,
  SENDER_TEXT_REPLY,
} from "../../Utils/enums";
import "../../assets/css/message.scss";
import MessageReaction from "./MessageReaction";
import MessageReactionView from "./MessageReactionView";

function Message({
  message,
  tapped,
  getTappedMessage,
  getIds,
  getSenderMessageType,
  urlPreview,
  metadata,
  forward,
  deleteMessage,
}) {
  const downArrowRef = useRef(null);
  const dropdownRef = useRef(null);
  const contactRef = useRef(null);
  const isUrl = message.message.match(LINK_REGX);
  const isEmail = EMAIL_REGX.test(message.message);
  const isPhone = CONTACT_REGX.test(message.message);
  const [isChecked, setIsChecked] = useState(false);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [deletedMessage, setDeletedMessage] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [showContactMenu, setShowContactMenu] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const toggleShareMenu = (event) => {
    event.preventDefault();
    const arrowRect = downArrowRef.current.getBoundingClientRect();
    const { right, top, left } = arrowRect;
    const isReceiverText = message?.type === RECEIVER_TEXT;
    const position = isReceiverText
      ? { x: left, y: top }
      : { x: right, y: top };
    setMenuPosition(position);
    setIsShareMenuOpen(true);
    setScrollEnabled(false);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [scrollEnabled]);

  const closeDropdown = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event.target)) {
      setIsShareMenuOpen(false);
      setScrollEnabled(true);
    }
    // if (!contactRef.current || !contactRef.current.contains(event.target)) {
    //   setShowContactMenu(false);
    //   setScrollEnabled(true);
    // }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
    // if (contactRef.current && !contactRef.current.contains(event.target)) {
    //   closeDropdown();
    // }
  };

  useEffect(() => {
    if (isShareMenuOpen) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    // if (showContactMenu) {
    //   document.addEventListener("mousedown", closeDropdown);
    // } else {
    //   document.removeEventListener("mousedown", closeDropdown);
    // }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [isShareMenuOpen]);
  // }, [isShareMenuOpen, showContactMenu]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  }, []);
  //endHere

  const toggleReadMore = () => {
    setShowFullMessage((prevState) => !prevState);
  };

  const onDoubleTapped = () => {
    tapped(true);
    getTappedMessage(message);
    getIds(message?._id);
    getSenderMessageType(SENDER_TEXT_REPLY);
    setIsShareMenuOpen(false);
    setScrollEnabled(true);
  };

  const onDelete = () => {
    setDeletedMessage(true);
  };

  const convertToFullURL = () => {
    if (message.message.startsWith("www.")) {
      return "http://" + message.message;
    } else {
      return message.message;
    }
  };

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const onStar = async () => {
    // Realm.write(() => {
    //   Realm.create(
    //     "RMessageModel",
    //     {
    //       _id: message._id,
    //       isStarMessage: !message?.isStarMessage,
    //     },
    //     "modified"
    //   );
    // });
    setIsShareMenuOpen(false);
    setScrollEnabled(true);
  };

  return (
    <>
      {/* {urlPreview && metadata && (
        <SocialLinkPreviewChat UrlPreviewData={metadata} />
      )} */}
      {message?.type === RECEIVER_TEXT && (
        <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
          {forward && (
            <div className="message-check">
              <input
                type="checkbox"
                id="message-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          )}
          <div
            className="mainMessage-wrapper"
            style={{
              justifyContent: "flex-start",
              paddingLeft: forward ? "40px" : "",
            }}
          >
            <div className="msgReceivedText">
              <p className="common-message">
                {isEmail ? (
                  <a href={`mailto:${message.message}`} className="additional">
                    {getMessageText(showFullMessage, message)}
                  </a>
                ) : isPhone ? (
                  <span className="additional">
                    {getMessageText(showFullMessage, message)}
                  </span>
                ) : isUrl ? (
                  <a
                    href={convertToFullURL()}
                    className="additional"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {message.message}
                  </a>
                ) : (
                  <span>
                    <Twemoji
                      set="twemoji"
                      className="temoji"
                      text={getMessageText(showFullMessage, message)}
                    />
                    {message.message.length > 587 && !showFullMessage && (
                      <span onClick={toggleReadMore} className="read-more">
                        ...Read More
                      </span>
                    )}
                  </span>
                )}
                <span className="space" />
              </p>
              <div className="message-status">
                {message?.isStarMessage && (
                  <img className="important" src={star} alt="important" />
                )}
                <span className="date">{getTimeFromDate(message)}</span>
              </div>
              <div className="down-arrow">
                <img
                  src={downArrow}
                  ref={downArrowRef}
                  alt="down"
                  role="button"
                  onClick={toggleShareMenu}
                />
              </div>
            </div>
            {!forward && <MessageAction message={message} />}
          </div>
          {/* <div
            className="messageReactedEmojis messageReactedEmojis-left"
            style={{ left: forward && "55px", alignSelf: "flex-start" }}
          >
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
        </div>
      )}
      {message?.type === SENDER_TEXT && (
        <>
          {/* <MessageReaction /> */}
          {/* <MessageReactionView /> */}
          <div className="message-group" onDoubleClick={() => onDoubleTapped()}>
            {forward && (
              <div className="message-check">
                <input
                  type="checkbox"
                  id="message-check"
                  aria-label="Check this box"
                  checked={isChecked}
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
            )}
            <div
              className="mainMessage-wrapper"
              style={{ justifyContent: "flex-end" }}
            >
              {!forward && <MessageAction message={message} />}
              <div className="msgSendText">
                <p className="common-message">
                  {isEmail ? (
                    <a
                      href={`mailto:${message.message}`}
                      className="additional"
                    >
                      {getMessageText(showFullMessage, message)}
                    </a>
                  ) : isPhone ? (
                    <span className="additional">
                      {getMessageText(showFullMessage, message)}
                    </span>
                  ) : isUrl ? (
                    <a
                      href={convertToFullURL()}
                      className="additional"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {message.message}
                    </a>
                  ) : (
                    <span>
                      <Twemoji
                        set="twemoji"
                        className="temoji"
                        text={getMessageText(showFullMessage, message)}
                      />
                      {message.message.length > 587 && !showFullMessage && (
                        <span onClick={toggleReadMore} className="read-more">
                          ...Read More
                        </span>
                      )}
                    </span>
                  )}
                  <span className="space" />
                </p>
                <div className="message-status">
                  {message?.isStarMessage && (
                    <img className="important" src={star} alt="important" />
                  )}
                  <span className="date">{getTimeFromDate(message)}</span>
                  <img
                    className="text-status"
                    src={getMessageStatus(message)}
                    alt="img-status"
                  />
                </div>

                <div className="down-arrow">
                  <img
                    src={downArrow}
                    ref={downArrowRef}
                    alt="down"
                    role="button"
                    onClick={toggleShareMenu}
                  />
                </div>
              </div>
            </div>
            {/* <div className="messageReactedEmojis messageReactedEmojis-right">
            <div className="reacted-emoji">😜</div>
            <div className="reacted-emoji">😜</div>
          </div> */}
          </div>
        </>
      )}
      {isShareMenuOpen && (
        <Sharemenu
          ddref={dropdownRef}
          menuPosition={menuPosition}
          isFromReceiver={message?.type === RECEIVER_TEXT}
          onReplyClick={onDoubleTapped}
          onDeleteClick={onDelete}
          onStarClick={onStar}
          message={message}
        />
      )}
      {deletedMessage && (
        <DeleteMessage
          message={message}
          setDeleteMessage={setDeletedMessage}
          deleteMessage={deleteMessage}
        />
      )}
      {/* {showContactMenu && (
        <ContactMenu
          contactref={contactRef}
          menuPosition={menuPosition}
          isFromReceiver={message.messageType === "ReceiverText"}
          hideContactMenu={setShowContactMenu}
        />
      )} */}
    </>
  );
}

export default Message;
