import React from "react";
import axiosRequest from "../../Middleware/api";
import { deletePostComments } from "../../Redux/actions";
import { useDispatch } from "react-redux";

function CommentAction({
  passPostData,
  setIsCommentOrReply,
  isCommentId,
  setIsCommentId,
  isUserPost,
  setIsCommentDelete,
  setIsReplyDelete,
  isReplyId,
  setIsReplyId,
  setReportMenu,
  setReportCommentAndReply,
  setIsCommentAndReplyId,
}) {
  const dispatch = useDispatch();

  const handleDeleteComment = (cmtID) => {
    const data = {
      commentId: cmtID,
    };
    axiosRequest
      .put(`post/comment/delete/${passPostData?._id}`, data)
      .then((res) => {
        setIsCommentOrReply(false);
        setIsCommentDelete(true);
        dispatch(deletePostComments(passPostData?._id));
      })
      .catch((error) => {});
  };

  const handleDeleteReply = (cmtID, replyID) => {
    const data = {
      commentId: cmtID,
      replyId: replyID,
    };
    axiosRequest
      .put(`post/comment/replie/delete/${passPostData?._id}`, data)
      .then((res) => {
        setIsCommentOrReply(false);
        setIsReplyDelete(true);
      })
      .catch((error) => {});
  };

  const renderTitle = () => {
    const action = isUserPost ? "delete" : "report";
    const type = isReplyId ? "reply" : "comment";
    return `You want to ${action} this ${type}?`;
  };

  return (
    <div className="comment-action">
      <div className="dialog">
        <div className="dialog-info">
          <h4>{renderTitle()}</h4>
        </div>
        <div className="cta">
          <button
            className="btn cancel "
            onClick={() => {
              setIsCommentOrReply(false);
              setIsCommentId();
              setIsReplyId();
            }}
          >
            Cancel
          </button>

          {!isReplyId ? (
            isUserPost ? (
              <button
                className="btn for-everyone active"
                onClick={() => handleDeleteComment(isCommentId)}
              >
                Delete
              </button>
            ) : (
              <button
                className="btn for-everyone active"
                onClick={() => {
                  setIsCommentOrReply(false);
                  setIsCommentAndReplyId(isCommentId);
                  setReportMenu(true);
                  setReportCommentAndReply(true);
                }}
              >
                Report
              </button>
            )
          ) : isUserPost ? (
            <button
              className="btn for-everyone active"
              onClick={() => handleDeleteReply(isCommentId, isReplyId)}
            >
              Delete
            </button>
          ) : (
            <button
              className="btn for-everyone active"
              onClick={() => {
                setIsCommentOrReply(false);
                setIsCommentAndReplyId(isReplyId);
                setReportMenu(true);
                setReportCommentAndReply(true);
              }}
            >
              Report
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommentAction;
