import axios from "axios";
import Cookies from "js-cookie";
import { resetStore, setAuthenticateSync } from "../Redux/actions";

const axiosRequest = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

axiosRequest.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
  "token"
)}`;
axiosRequest.defaults.headers.common["Content-Type"] = "application/json";

const handleUnauthorized = (dispatch) => {
  Cookies.remove("token");
  Cookies.remove("user_id");
  Cookies.remove("sessionId");
  localStorage.clear();
  dispatch(resetStore());
  dispatch(setAuthenticateSync("login"));
  window.location.reload();
};

export const setupAxiosInterceptors = (dispatch) => {
  axiosRequest.interceptors.response.use(
    (response) => {
      // if (response.data.status === 401) {
      //   return Promise.reject(response.data.message);
      // }
      // if (response.data.status === "500") {
      //   return Promise.reject(response.data.message);
      // }
      if (response.data?.status === "401") {
        console.log("401Error in response data:", response);
        handleUnauthorized(dispatch);
        return Promise.reject(response.data.message);
      }
      return response;
    },
    (error) => {
      // if (error.response.status === 500) {
      //   // Redirect to the 500 error page
      //   window.location.href = "/error/500";
      // }
      if (error.response?.status === 401) {
        console.log("401Error in error response:", error);
        handleUnauthorized(dispatch);
      }
      return Promise.reject(error);
    }
  );
};

export default axiosRequest;
