import React from "react";
import close from "../../assets/image/close-black.png";
import "../../assets/css/socialLinkPreview.scss";

function SocialLinkPreview({ metaData, showUrlPreview }) {
  return (
    <div className="socialLinkPreview">
      <div className="wrapper">
        <div className="socialMedia-preview">
          <img src={metaData?.images} alt="social-media" />
        </div>
        <div className="info">
          <div className="info-wrapper">
            <p className="social-name">{metaData?.title}</p>
            <p className="description">{metaData?.description}</p>
          </div>
          <div className="cancel" onClick={() => showUrlPreview(false)}>
            <img src={close} alt="close" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialLinkPreview;
