import React, { useState, useEffect } from "react";
import { Docs } from "./Docs";
import { Links } from "./Links";
import { Medias } from "./Medias";
import "../../assets/css/mediaLinksDocs.scss";

function MediaLinksDocs({ onClose }) {
  const [selected, setSelected] = useState("medias");

  const [activeMenu, setActiveMenu] = useState(selected);
  const [sliderStyle, setSliderStyle] = useState({});

  useEffect(() => {
    const activeElement = document.querySelector(".btns-wrapper .active");
    if (activeElement) {
      const position = activeElement.offsetLeft;
      const width = activeElement.offsetWidth;
      setSliderStyle({ left: position, width: width });
    }
  }, [activeMenu, selected]);

  // const handleMenuClick = (menu) => {
  //   setActiveMenu(menu);
  //   setSelected(menu);
  // };

  return (
    <div className="mediaLinksDocs">
      <header className="header">
        <button className="btn btn-back" onClick={() => onClose(false)}>
          <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
            <title>Back</title>
            <g id="Group 18522">
              <g id="Group 18522_2">
                <path
                  id="Vector"
                  d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                  stroke="white"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </svg>
        </button>
        <span className="title">Media, Links, Docs</span>
        {/* <button className="btn close" onClick={() => onClose(false)}>
          <img src={close} alt="close"/>
        </button> */}
      </header>

      <div className="mediaLinksDocs-btn">
        <div className="btns-wrapper">
          <div className="slider" style={sliderStyle}></div>
          <div
            className={`media ${selected === "medias" && "active"}`}
            onClick={() => setSelected("medias")}
          >
            <div className="media-image">
              <svg width="26" height="23" viewBox="0 0 26 23" fill="none">
                <g id="Frame" clipPath="url(#clip0_7992_1860)">
                  <g id="Group">
                    <path
                      id="Vector"
                      d="M24.5454 2.2491C24.5454 6.31125 24.5454 10.3619 24.5454 14.4241C24.5341 14.447 24.5116 14.4585 24.5116 14.4814C24.2189 15.8929 23.2617 16.6846 21.8429 16.6846C16.8209 16.6846 11.7988 16.6846 6.77669 16.6846C6.58526 16.6846 6.39384 16.6846 6.20241 16.6502C4.90748 16.4092 4.07422 15.3535 4.07422 13.9536C4.07422 10.2242 4.07422 6.48337 4.07422 2.754C4.07422 1.12455 5.16647 0 6.76543 0C8.63463 0 10.5038 0 12.373 0C15.5597 0 18.7351 0 21.9218 0C23.0928 0 24.0612 0.699975 24.4215 1.81305C24.4666 1.95075 24.5004 2.09992 24.5454 2.2491ZM22.4397 9.94882C22.4623 9.93735 22.4735 9.93735 22.496 9.92587C22.496 9.85702 22.496 9.7767 22.496 9.70785C22.496 7.3899 22.496 5.08342 22.496 2.76547C22.496 2.2491 22.3384 2.08845 21.8317 2.08845C16.8209 2.08845 11.81 2.08845 6.78795 2.08845C6.26997 2.08845 6.11233 2.2491 6.11233 2.76547C6.11233 5.89815 6.11233 9.01935 6.11233 12.152C6.11233 12.2323 6.08981 12.3127 6.14611 12.4045C6.21367 12.3356 6.28123 12.2782 6.33753 12.2209C7.18206 11.3602 8.02658 10.4996 8.85984 9.65047C9.67058 8.83575 10.729 8.82427 11.5398 9.65047C11.8663 9.98325 12.2041 10.3045 12.5194 10.6488C12.6658 10.798 12.7334 10.8209 12.8798 10.6373C14.0621 9.16852 15.2557 7.7112 16.4493 6.26535C17.26 5.28997 18.4874 5.2785 19.3094 6.2424C19.8611 6.885 20.3904 7.5276 20.9309 8.1702C21.4488 8.7669 21.9443 9.35212 22.4397 9.94882Z"
                      fill={
                        selected === "medias" && "active"
                          ? "#1891A2"
                          : "#C1C1C1"
                      }
                    />
                    <path
                      id="Vector_2"
                      d="M2.53835 7.44897C2.53835 7.54077 2.53835 7.6211 2.53835 7.7129C2.53835 9.89315 2.52709 12.0619 2.53835 14.2422C2.54961 15.8028 3.22523 17.0076 4.5652 17.7879C5.17325 18.1322 5.82635 18.2584 6.52449 18.2584C11.3101 18.2584 16.107 18.2584 20.8926 18.2584C21.2304 18.2584 21.2304 18.2584 21.1291 18.5912C20.8476 19.4862 20.5773 20.3928 20.2958 21.2878C19.913 22.5271 18.7194 23.2156 17.4695 22.8714C12.2222 21.4485 6.96364 20.0141 1.71635 18.5682C0.376376 18.201 -0.321761 16.8814 0.0723486 15.5388C0.849308 12.9111 1.62627 10.2718 2.39197 7.64405C2.44827 7.56372 2.47079 7.50635 2.49331 7.4375C2.50457 7.4375 2.52709 7.4375 2.53835 7.44897Z"
                      fill={
                        selected === "medias" && "active"
                          ? "#1891A2"
                          : "#C1C1C1"
                      }
                    />
                    <path
                      id="Vector_3"
                      d="M9.20331 7.29043C8.07728 7.29043 7.15394 6.37243 7.14268 5.22493C7.13142 4.07743 8.06602 3.125 9.19205 3.125C10.3181 3.125 11.2302 4.06595 11.2414 5.21345C11.2527 6.34948 10.3293 7.29043 9.20331 7.29043Z"
                      fill={
                        selected === "medias" && "active"
                          ? "#1891A2"
                          : "#C1C1C1"
                      }
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_7992_1860">
                    <rect width="25.245" height="22.95" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <span className="text">Media</span>
          </div>
          <div
            className={`docs ${selected === "docs" && "active"}`}
            onClick={() => setSelected("docs")}
          >
            <div className="docs-image">
              {selected === "docs" && "active" ? (
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
                  <g clipPath="url(#clip0_2172_81696)">
                    <path
                      d="M2.86719 4.29688V18.6338"
                      stroke="#1891A2"
                      strokeWidth="1.43366"
                      strokeLinecap="round"
                    />
                    <path
                      d="M20.0781 8.60156V18.6422"
                      stroke="#1891A2"
                      strokeWidth="1.43366"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.73438 1.42969H12.9063"
                      stroke="#1891A2"
                      strokeWidth="1.43366"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.73438 21.5078H17.2094"
                      stroke="#1891A2"
                      strokeWidth="1.43366"
                      strokeLinecap="round"
                    />
                    <path
                      d="M20.0797 18.6406C20.0875 20.0734 18.6453 21.5094 17.2109 21.5094"
                      stroke="#1891A2"
                      strokeWidth="1.43366"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.86563 18.6406C2.86563 20.075 4.3 21.5094 5.73438 21.5094"
                      stroke="#1891A2"
                      strokeWidth="1.43366"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.86563 4.29688C2.86563 2.86458 4.3 1.46215 5.73438 1.43021"
                      stroke="#1891A2"
                      strokeWidth="1.43366"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.0703 8.608L12.9062 1.42969"
                      stroke="#1891A2"
                      strokeWidth="1.43366"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.9125 5.73438C12.9169 7.16094 14.3527 8.60312 15.7812 8.60312"
                      stroke="#1891A2"
                      strokeWidth="1.43366"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.9062 5.73281V1.42969"
                      stroke="#1891A2"
                      strokeWidth="1.43366"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.7812 8.60156H20.0844"
                      stroke="#1891A2"
                      strokeWidth="1.43366"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.73438 18.6406H10.0375"
                      stroke="#1891A2"
                      strokeWidth="1.43437"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.73438 15.7734H12.9063"
                      stroke="#1891A2"
                      strokeWidth="1.43437"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.73438 12.9062H10.0375"
                      stroke="#1891A2"
                      strokeWidth="1.43437"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2172_81696">
                      <rect width="22.95" height="22.95" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
                  <g id="gala:file-document" clipPath="url(#clip0_7957_270)">
                    <g id="galaFileDocument0">
                      <path
                        id="galaFileDocument1"
                        d="M2.86719 4.29883V18.6357"
                        stroke="#C1C1C1"
                        strokeWidth="1.43366"
                        strokeLinecap="round"
                      />
                      <path
                        id="galaFileDocument2"
                        d="M20.0781 8.60547V18.6461"
                        stroke="#C1C1C1"
                        strokeWidth="1.43366"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="galaFileDocument3"
                        d="M5.73633 1.42969H12.9082"
                        stroke="#C1C1C1"
                        strokeWidth="1.43366"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="galaFileDocument4"
                        d="M5.73633 21.5098H17.2113"
                        stroke="#C1C1C1"
                        strokeWidth="1.43366"
                        strokeLinecap="round"
                      />
                      <path
                        id="galaFileDocument5"
                        d="M20.0777 18.6406C20.0856 20.0734 18.6434 21.5094 17.209 21.5094"
                        stroke="#C1C1C1"
                        strokeWidth="1.43366"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="galaFileDocument6"
                        d="M2.86758 18.6406C2.86758 20.075 4.30195 21.5094 5.73633 21.5094"
                        stroke="#C1C1C1"
                        strokeWidth="1.43366"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="galaFileDocument7"
                        d="M2.86758 4.29688C2.86758 2.86458 4.30195 1.46215 5.73633 1.43021"
                        stroke="#C1C1C1"
                        strokeWidth="1.43366"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="galaFileDocument8"
                        d="M20.0742 8.608L12.9102 1.42969"
                        stroke="#C1C1C1"
                        strokeWidth="1.43366"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="galaFileDocument9"
                        d="M12.9105 5.73633C12.915 7.1629 14.3507 8.60508 15.7793 8.60508"
                        stroke="#C1C1C1"
                        strokeWidth="1.43366"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="galaFileDocumenta"
                        d="M12.9102 5.73281V1.42969"
                        stroke="#C1C1C1"
                        strokeWidth="1.43366"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="galaFileDocumentb"
                        d="M15.7793 8.60547H20.0824"
                        stroke="#C1C1C1"
                        strokeWidth="1.43366"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="galaFileDocumentc"
                        d="M5.73633 18.6406H10.0395"
                        stroke="#C1C1C1"
                        strokeWidth="1.43437"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="galaFileDocumentd"
                        d="M5.73633 15.7734H12.9082"
                        stroke="#C1C1C1"
                        strokeWidth="1.43437"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="galaFileDocumente"
                        d="M5.73633 12.9043H10.0395"
                        stroke="#C1C1C1"
                        strokeWidth="1.43437"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_7957_270">
                      <rect width="22.95" height="22.95" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </div>
            <span className="text">Docs</span>
          </div>
          <div
            className={`links ${selected === "links" && "active"}`}
            onClick={() => setSelected("links")}
          >
            <div className="link-image">
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
                <g id="ion:link">
                  <path
                    id="Vector"
                    d="M9.03251 16.0648H6.53185C5.40814 16.0648 4.33047 15.5812 3.53589 14.7204C2.74131 13.8596 2.29492 12.6921 2.29492 11.4748C2.29492 10.2574 2.74131 9.08994 3.53589 8.22915C4.33047 7.36835 5.40814 6.88477 6.53185 6.88477H8.97734M13.9725 6.88477H16.418C17.5417 6.88477 18.6194 7.36835 19.414 8.22915C20.2085 9.08994 20.6549 10.2574 20.6549 11.4748C20.6549 12.6921 20.2085 13.8596 19.414 14.7204C18.6194 15.5812 17.5417 16.0648 16.418 16.0648H13.9173M7.6383 11.4748H15.3998"
                    stroke={
                      selected === "links" && "active" ? "#1891A2" : "#C1C1C1"
                    }
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </div>
            <span className="text">Links</span>
          </div>
        </div>
      </div>
      {selected === "medias" && <Medias />}
      {selected === "docs" && <Docs />}
      {selected === "links" && <Links />}
    </div>
  );
}

export default MediaLinksDocs;
