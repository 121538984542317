export const setDBConnectionObj = (connection) => ({
  type: "SET_DB_CONNECTION_OBJ",
  payload: connection,
});

export const setContacts = (contacts) => ({
  type: "SET_CONTACTS",
  payload: contacts,
});

export const setUsers = (users) => ({
  type: "SET_USERS",
  payload: users,
});

export const setSavedContacts = (contacts) => ({
  type: "SET_SAVED_CONTACTS",
  payload: contacts,
});

export const setMessages = (message) => ({
  type: "SET_MESSAGES",
  payload: message,
});

export const setLoader = (loading) => ({
  type: "LOADER",
  payload: loading,
});

export const setInboxContacts = (inbox) => ({
  type: "SET_INBOX_CONTACTS",
  payload: inbox,
});

export const setChatBoxContact = (contact) => ({
  type: "SET_CHAT_BOX_CONTACT",
  payload: contact,
});

export const clearChatBoxContact = () => ({
  type: "CLEAR_CHAT_BOX_CONTACT",
  payload: [],
});

export const setMessageContacts = (contacts) => ({
  type: "SET_MESSAGE_CONTACTS",
  payload: contacts,
});

export const setMessageMedia = (media) => ({
  type: "SET_MESSAGE_MEDIA",
  payload: media,
});

export const setGroups = (group) => ({
  type: "SET_GROUPS",
  payload: group,
});

export const setSelfContact = (contact) => ({
  type: "SET_SELF_CONTACT",
  payload: contact,
});

export const setTepars = (teparsData) => ({
  type: "SET_TEPARS",
  payload: teparsData,
});

export const setUserTepars = (teparsData) => ({
  type: "SET_USER_TEPARS",
  payload: teparsData,
});

export const setTeparsTotalCount = (totalCount) => ({
  type: "SET_TEPARS_TOTAL_COUNT",
  payload: totalCount,
});

export const incrementPostComments = (postId) => {
  return {
    type: "INCREMENT_POST_COMMENTS",
    payload: postId,
  };
};

export const deletePostComments = (postId) => {
  return {
    type: "DELETE_POST_COMMENTS",
    payload: postId,
  };
};

export const setUser = (user) => {
  return {
    type: "SET_USER",
    payload: user,
  };
};

export const setTheme = (theme) => ({
  type: "SET_THEME",
  payload: theme,
});

export const addStories = (stories) => ({
  type: "ADD_STORIES",
  payload: stories,
});

export const addToMyStatus = (story) => ({
  type: "ADD_TO_MY_STATUS",
  payload: story,
});

export const updateSeenUsers = (payload) => ({
  type: "UPDATE_SEEN_USERS",
  payload,
});

export const updateStory = ({ userId, newStory }) => ({
  type: "UPDATE_STORY",
  payload: { userId, newStory },
});

export const updateMyStatus = ({ userId, newStory }) => ({
  type: "UPDATE_MY_STATUS",
  payload: { userId, newStory },
});

export const deleteMyStatus = (storyId) => ({
  type: "DELETE_MY_STATUS",
  payload: storyId,
});

export const deleteStory = (storyId) => ({
  type: "DELETE_STORY",
  payload: storyId,
});

export const handleToast = (toast) => ({
  type: "SET_TOAST",
  payload: toast,
});

export const setCurrentIndex = (index) => ({
  type: "SET_CURRENT_INDEX",
  payload: index,
});

export const setAnimationDirection = (direction) => ({
  type: "SET_ANIMATION_DIRECTION",
  payload: direction,
});

export const setVideoCurrentTime = (time) => ({
  type: "SET_VIDEO_CURRENT_TIME",
  payload: time,
});

export const setSettingOptions = (option) => ({
  type: "SET_SETTING_OPTIONS",
  payload: option,
});

export const setSelectedSetting = (option) => ({
  type: "SET_SELECTED_SETTING",
  payload: option,
});

export const markStoryAsSeen = (storyId) => ({
  type: "MARK_STORY_AS_SEEN",
  payload: storyId,
});

export const updateStoryList = (storyList) => ({
  type: "UPDATE_STORY_LIST",
  payload: storyList,
});

export const setPrivacyOption = (option) => ({
  type: "SET_PRIVACY_OPTION",
  payload: option,
});

export const setSettings = (settings) => ({
  type: "SET_SETTINGS",
  payload: settings,
});

export const setExceptContactType = (type) => ({
  type: "SET_EXCEPT_CONTACT_TYPE",
  payload: type,
});

export const setNotification = (notify) => ({
  type: "SET_NOTIFICATION",
  payload: notify,
});

export const setNotifications = (notifications) => ({
  type: "SET_NOTIFICATIONS",
  payload: notifications,
});

export const setCurrentLocation = (location) => ({
  type: "SET_CURRENT_LOCATION",
  payload: location,
});

export const setAuthenticateSync = (authenticate) => ({
  type: "SET_AUTHENTICATE_SYNC",
  payload: authenticate,
});

export const setSelectedTepars = (post) => ({
  type: "SET_SELECTED_TEPARS",
  payload: post,
});

export const setPostDetails = (details) => ({
  type: "SET_POST_DETAILS",
  payload: details,
});

export const setOtherPostDetails = (details) => ({
  type: "SET_OTHER_POST_DETAILS",
  payload: details,
});

export const setViewProfilePhoto = (profile) => ({
  type: "SET_VIEW_PROFILE_PHOTO",
  payload: profile,
});

export const setTogglePostAction = (toggle) => ({
  type: "SET_TOGGLE_POST_ACTION",
  payload: toggle,
});

export const setPostCreateProgress = (progress) => ({
  type: "SET_POST_CREATE_PROGRESS",
  payload: progress,
});

export const setTeparsProgress = (progress) => ({
  type: "SET_TEPARS_PROGRESS",
  payload: progress,
});

export const setToggeleViewOtherProfile = (progress) => ({
  type: "SET_TOGGLE_VIEW_OTHER_PROFILE",
  payload: progress,
});

export const setScrollToPost = (value) => ({
  type: "SET_SCROLL_TO_TOP",
  payload: value,
});

export const setTeparsPage = (page) => ({
  type: "SET_TEPARS_PAGE",
  payload: page,
});

export const setTeparsCount = (count) => ({
  type: "SET_TEPARS_COUNT",
  payload: count,
});

export const setTeparsLoad = (load) => ({
  type: "SET_TEPARS_LOAD",
  payload: load,
});

export const setExceptContacts = (contacts) => ({
  type: "SET_EXCEPT_CONTACTS",
  payload: contacts,
});

export const setOnlyShareContacts = (contacts) => ({
  type: "SET_ONLY_SHARE_CONTACTS",
  payload: contacts,
});

export const resetStore = () => ({
  type: "RESET_STORE",
});
