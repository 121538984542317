import React, { useState } from "react";
import {
  getComment,
  getSelfUser,
  getGridTepars,
  getTagComment,
  getUserProfilePic,
  convertToAgoFormat,
} from "../../Utils/helpers";

function NotificationItem({
  notify,
  getSelectedUserDetails,
  handleDeleteNotification,
  handlePostClick,
}) {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <>
      {notify?.event === "post_like" && (
        <div className="notification-posts-info" role="listitem">
          <div className="profile-image" tabIndex="0">
            <img
              src={getUserProfilePic(notify?.userFrom?.profile)}
              alt="profile avatar"
            />
          </div>
          <div className="profile-info">
            <div className="profile-name">
              <p className="notification-user-info">
                <span
                  className="notification-user-name"
                  onClick={() => getSelectedUserDetails(notify?.userFrom?._id)}
                  tabIndex="0"
                >
                  {getSelfUser(notify?.userFrom)}{" "}
                </span>
                Teps your post
              </p>
              <div className="notification-time">
                <span className="time">
                  {convertToAgoFormat(notify?.createAt)}
                </span>
              </div>
            </div>

            <div className="profile-cta">
              <div
                className="notification-delete"
                onClick={() => handleDeleteNotification(notify?._id)}
                tabIndex="0"
              >
                <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                  <title>Delete Notification</title>
                  <path
                    d="M5.56732 20.5835V20.5833V6.5V6.35H5.41732H4.48398V4.48333H9.75065H9.90065V4.33333V3.4H16.1007V4.33333V4.48333H16.2507H21.5173V6.35H20.584H20.434V6.5V20.5833C20.434 21.139 20.2377 21.6112 19.8409 22.008C19.4441 22.4048 18.9724 22.6007 18.4175 22.6H18.4173H7.58398C7.02829 22.6 6.55609 22.4037 6.1593 22.0069C5.76252 21.6102 5.56664 21.1384 5.56732 20.5835ZM18.5673 6.5V6.35H18.4173H7.58398H7.43398V6.5V20.5833V20.7333H7.58398H18.4173H18.5673V20.5833V6.5ZM11.7673 8.81667V18.2667H9.90065V8.81667H11.7673ZM16.1007 8.81667V18.2667H14.234V8.81667H16.1007Z"
                    fill="#1891A2"
                    stroke="white"
                    stroke-width="0.3"
                  />
                </svg>
              </div>
              <div
                className="notification-post-image"
                onClick={() => handlePostClick(notify)}
                tabIndex="0"
              >
                {getGridTepars(notify?.postId?.media[0]) && !imageError ? (
                  <img
                    src={getGridTepars(notify?.postId?.media[0])}
                    alt="post media"
                    onError={handleImageError}
                  />
                ) : (
                  <div className="blank-image"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {notify?.event === "post_comment" && (
        <div className="notification-posts-info" role="listitem">
          <div className="profile-image" tabIndex="0">
            <img
              src={getUserProfilePic(notify?.userFrom?.profile)}
              alt="profile avatar"
            />
          </div>
          <div className="profile-info">
            <div className="profile-name">
              <p className="notification-user-info">
                <span
                  className="notification-user-name"
                  onClick={() => getSelectedUserDetails(notify?.userFrom?._id)}
                  tabIndex="0"
                >
                  {getSelfUser(notify?.userFrom)}{" "}
                </span>
                commented: {getComment(notify?.message)}
              </p>
              <div className="notification-time">
                <span className="time">
                  {convertToAgoFormat(notify?.createAt)}
                </span>
              </div>
            </div>
            <div className="profile-cta">
              <div
                className="notification-delete"
                onClick={() => handleDeleteNotification(notify?._id)}
                tabIndex="0"
              >
                <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                  <title>Delete Notification</title>
                  <path
                    d="M5.56732 20.5835V20.5833V6.5V6.35H5.41732H4.48398V4.48333H9.75065H9.90065V4.33333V3.4H16.1007V4.33333V4.48333H16.2507H21.5173V6.35H20.584H20.434V6.5V20.5833C20.434 21.139 20.2377 21.6112 19.8409 22.008C19.4441 22.4048 18.9724 22.6007 18.4175 22.6H18.4173H7.58398C7.02829 22.6 6.55609 22.4037 6.1593 22.0069C5.76252 21.6102 5.56664 21.1384 5.56732 20.5835ZM18.5673 6.5V6.35H18.4173H7.58398H7.43398V6.5V20.5833V20.7333H7.58398H18.4173H18.5673V20.5833V6.5ZM11.7673 8.81667V18.2667H9.90065V8.81667H11.7673ZM16.1007 8.81667V18.2667H14.234V8.81667H16.1007Z"
                    fill="#1891A2"
                    stroke="white"
                    stroke-width="0.3"
                  />
                </svg>
              </div>
              <div
                className="notification-post-image"
                onClick={() => handlePostClick(notify)}
                tabIndex="0"
              >
                {getGridTepars(notify?.commentsId?.postId?.media[0]) &&
                !imageError ? (
                  <img
                    src={getGridTepars(notify?.commentsId?.postId?.media[0])}
                    alt="post media"
                    onError={handleImageError}
                  />
                ) : (
                  <div className="blank-image"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {notify?.event === "replyComment" && (
        <div className="notification-posts-info" role="listitem">
          <div className="profile-image" tabIndex="0">
            <img
              src={getUserProfilePic(notify?.userFrom?.profile)}
              alt="profile avatar"
            />
          </div>
          <div className="profile-info">
            <div className="profile-name">
              <p className="notification-user-info">
                <span
                  className="notification-user-name"
                  onClick={() => getSelectedUserDetails(notify?.userFrom?._id)}
                  tabIndex="0"
                >
                  {getSelfUser(notify?.userFrom)}{" "}
                </span>
                commented:
                <span
                  className="tag-user-name"
                  onClick={() => getSelectedUserDetails(notify?.tag?._id)}
                  tabIndex="0"
                >
                  @{getSelfUser(notify?.tag)}
                </span>
                {getTagComment(notify?.message)}
              </p>
              <div className="notification-time">
                <span className="time">
                  {convertToAgoFormat(notify?.createAt)}
                </span>
              </div>
            </div>
            <div className="profile-cta">
              <div
                className="notification-delete"
                onClick={() => handleDeleteNotification(notify?._id)}
                tabIndex="0"
              >
                <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                  <title>Delete Notification</title>
                  <path
                    d="M5.56732 20.5835V20.5833V6.5V6.35H5.41732H4.48398V4.48333H9.75065H9.90065V4.33333V3.4H16.1007V4.33333V4.48333H16.2507H21.5173V6.35H20.584H20.434V6.5V20.5833C20.434 21.139 20.2377 21.6112 19.8409 22.008C19.4441 22.4048 18.9724 22.6007 18.4175 22.6H18.4173H7.58398C7.02829 22.6 6.55609 22.4037 6.1593 22.0069C5.76252 21.6102 5.56664 21.1384 5.56732 20.5835ZM18.5673 6.5V6.35H18.4173H7.58398H7.43398V6.5V20.5833V20.7333H7.58398H18.4173H18.5673V20.5833V6.5ZM11.7673 8.81667V18.2667H9.90065V8.81667H11.7673ZM16.1007 8.81667V18.2667H14.234V8.81667H16.1007Z"
                    fill="#1891A2"
                    stroke="white"
                    stroke-width="0.3"
                  />
                </svg>
              </div>
              <div
                className="notification-post-image"
                onClick={() => handlePostClick(notify)}
                tabIndex="0"
              >
                {getGridTepars(notify?.commentsId?.postId?.media[0]) &&
                !imageError ? (
                  <img
                    src={getGridTepars(notify?.commentsId?.postId?.media[0])}
                    alt="post media"
                    onError={handleImageError}
                  />
                ) : (
                  <div className="blank-image"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {notify?.event === "new_user" && (
        <div className="notification-posts-info" role="listitem">
          <div className="profile-image" tabIndex="0">
            <img
              src={getUserProfilePic(notify?.userFrom?.profile)}
              alt="profile avatar"
            />
          </div>
          <div className="profile-info">
            <div className="profile-name">
              <p className="notification-user-info">
                Your contact
                <span
                  className="notification-user-name"
                  onClick={() => getSelectedUserDetails(notify?.userFrom?._id)}
                  tabIndex="0"
                >
                  {" "}
                  {getSelfUser(notify?.userFrom)}{" "}
                </span>
                joined Tepnot
              </p>
              <div className="notification-time">
                <span className="time">
                  {convertToAgoFormat(notify?.createAt)}
                </span>
              </div>
            </div>
            <div className="profile-cta">
              <div
                className="notification-delete"
                onClick={() => handleDeleteNotification(notify?._id)}
                tabIndex="0"
              >
                <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                  <title>Delete Notification</title>
                  <path
                    d="M5.56732 20.5835V20.5833V6.5V6.35H5.41732H4.48398V4.48333H9.75065H9.90065V4.33333V3.4H16.1007V4.33333V4.48333H16.2507H21.5173V6.35H20.584H20.434V6.5V20.5833C20.434 21.139 20.2377 21.6112 19.8409 22.008C19.4441 22.4048 18.9724 22.6007 18.4175 22.6H18.4173H7.58398C7.02829 22.6 6.55609 22.4037 6.1593 22.0069C5.76252 21.6102 5.56664 21.1384 5.56732 20.5835ZM18.5673 6.5V6.35H18.4173H7.58398H7.43398V6.5V20.5833V20.7333H7.58398H18.4173H18.5673V20.5833V6.5ZM11.7673 8.81667V18.2667H9.90065V8.81667H11.7673ZM16.1007 8.81667V18.2667H14.234V8.81667H16.1007Z"
                    fill="#1891A2"
                    stroke="white"
                    stroke-width="0.3"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}

      {notify?.event === "repost" && (
        <div className="notification-posts-info" role="listitem" tabIndex="0">
          <div className="profile-image" tabIndex="0">
            <img
              src={getUserProfilePic(notify?.userFrom?.profile)}
              alt="profile avatar"
            />
          </div>
          <div className="profile-info">
            <div className="profile-name">
              <p className="notification-user-info">
                <span
                  className="notification-user-name"
                  onClick={() => getSelectedUserDetails(notify?.userFrom?._id)}
                  tabIndex="0"
                >
                  {getSelfUser(notify?.userFrom)}{" "}
                </span>
                Reposted Your Post
              </p>
              <div className="notification-time">
                <span className="time">
                  {convertToAgoFormat(notify?.createAt)}
                </span>
              </div>
            </div>
            <div className="profile-cta">
              <div
                className="notification-delete"
                onClick={() => handleDeleteNotification(notify?._id)}
                tabIndex="0"
              >
                <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                  <title>Delete Notification</title>
                  <path
                    d="M5.56732 20.5835V20.5833V6.5V6.35H5.41732H4.48398V4.48333H9.75065H9.90065V4.33333V3.4H16.1007V4.33333V4.48333H16.2507H21.5173V6.35H20.584H20.434V6.5V20.5833C20.434 21.139 20.2377 21.6112 19.8409 22.008C19.4441 22.4048 18.9724 22.6007 18.4175 22.6H18.4173H7.58398C7.02829 22.6 6.55609 22.4037 6.1593 22.0069C5.76252 21.6102 5.56664 21.1384 5.56732 20.5835ZM18.5673 6.5V6.35H18.4173H7.58398H7.43398V6.5V20.5833V20.7333H7.58398H18.4173H18.5673V20.5833V6.5ZM11.7673 8.81667V18.2667H9.90065V8.81667H11.7673ZM16.1007 8.81667V18.2667H14.234V8.81667H16.1007Z"
                    fill="#1891A2"
                    stroke="white"
                    stroke-width="0.3"
                  />
                </svg>
              </div>
              <div
                className="notification-post-image"
                onClick={() => handlePostClick(notify)}
                tabIndex="0"
              >
                {getGridTepars(notify?.postId?.media[0]) && !imageError ? (
                  <img
                    src={getGridTepars(notify?.postId?.media[0])}
                    alt="post media"
                    onError={handleImageError}
                  />
                ) : (
                  <div className="blank-image"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NotificationItem;
