import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getUserProfilePic } from "../../Utils/helpers";
import "../../assets/css/sendContacts.scss";

function SendContact({
  ref,
  onClose,
  onContactSend,
  selectedContacts,
  SelectedContactName,
  setViewContactType,
}) {
  const Contacts = useSelector((state) => state.savedContacts);

  const [searchValue, setSearchValue] = useState("");
  const [contactList, setContactList] = useState(Contacts);
  const [selectedContact, setSelectedContact] = useState([]);
  const [selectedContactName, setSelectedContactName] = useState([]);

  // useEffect(() => {
  //   setContactList(Contacts);
  // }, [Contacts]);

  const handleChange = (e, contact) => {
    if (e.target.checked) {
      setSelectedContact([...selectedContact, contact]);
      setSelectedContactName([
        selectedContactName.length > 0
          ? contact?.name + ", "
          : contact?.name + " ",
        ...selectedContactName,
      ]);
    }
    if (!e.target.checked) {
      const nameList = selectedContactName.filter((name) => {
        return name.replace(",", "").trim() !== contact?.name.toString();
      });
      const contactList = selectedContact.filter((contactObj) => {
        return contactObj?.name !== contact?.name;
      });
      setSelectedContactName(nameList);
      setSelectedContact(contactList);
    }
  };

  const onSendContact = () => {
    onClose(false);
    onContactSend(true);
    setViewContactType("send");
    selectedContacts(selectedContact);
    SelectedContactName(selectedContactName);
  };

  const handleSearchInputChange = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = Contacts?.filter((contact) => {
        return (
          contact?.name.toLowerCase().startsWith(keyword.toLowerCase()) ||
          contact?.User?.phone.toString().startsWith(keyword.toString())
        );
      });
      setContactList(results);
    } else {
      setContactList(Contacts);
    }
    setSearchValue(keyword);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onSendContact();
    }
  };

  const getSelectedName = () => {
    var selectedName = selectedContactName?.slice(0, 3);
    var formattedName = [];
    for (let i = 0; i < selectedName?.length; i++) {
      let str = selectedName[i];
      formattedName.push(" " + str.replace(",", "").trim());
    }
    return formattedName;
  };

  return (
    <div ref={ref} className="sendContacts">
      <div className="sendContacts-container">
        <header className="sendContacts-to">
          <div className="sendContacts-to-container">
            <h2 className="title">Send Contacts</h2>
            <button className="btn btn-close" onClick={() => onClose(false)}>
              <svg width="20" height="20" viewBox="0 0 25 25" fill="none">
                <title>Close</title>
                <g id="iconoir:cancel">
                  <path
                    id="Vector"
                    d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                    stroke="white"
                    strokeWidth="2.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </button>
          </div>
        </header>
        <div className="sendContact-search">
          <div className="search-bar">
            <svg
              width="12"
              height="12"
              viewBox="0 0 25 25"
              fill="none"
              className="search"
            >
              <path
                d="M10.393 19.6081C12.4206 19.6081 14.3139 18.9548 15.8529 17.8659L21.6426 23.6007C21.9113 23.8669 22.2655 24 22.632 24C23.4259 24 24 23.3951 24 22.6207C24 22.2578 23.8779 21.919 23.6091 21.6528L17.8561 15.9421C19.0653 14.3693 19.786 12.4214 19.786 10.3041C19.786 5.18622 15.5597 1 10.393 1C5.21402 1 1 5.18622 1 10.3041C1 15.4219 5.21402 19.6081 10.393 19.6081ZM10.393 17.5997C6.34997 17.5997 3.02762 14.2967 3.02762 10.3041C3.02762 6.31142 6.34997 3.00842 10.393 3.00842C14.4238 3.00842 17.7584 6.31142 17.7584 10.3041C17.7584 14.2967 14.4238 17.5997 10.393 17.5997Z"
                fill="#2A2A2A"
                stroke="white"
                strokeWidth="0.5"
              />
            </svg>

            <input
              type="search"
              placeholder="Search contact"
              value={searchValue}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <div className="sendContact-Wrapper">
          {contactList && contactList?.length > 0 ? (
            contactList?.map((contact, i) => {
              return (
                <div key={i} className="sendContact-details">
                  <label htmlFor={i} className="sendContact-wrapper">
                    <div className="sendContact" tabIndex="0">
                      <div className="sendContact-image">
                        <img
                          className="sendContact-avatar"
                          src={getUserProfilePic(contact?.user?.profilePic)}
                          alt="profile"
                        />
                      </div>
                      <div className="sendContact-info">
                        <div className="sendContact-info-name">
                          <span className="sendContact-name">
                            {contact?.name}
                          </span>
                          <p className="sendContact-info-message">
                            {contact?.user.bio || " "}
                          </p>
                        </div>
                        {/* <div className="sendContact-check">
                          <input
                            type="checkbox"
                            name="contact"
                            id={i}
                            value={i}
                            onChange={(e) => handleChange(e, contact)}
                          />
                        </div> */}
                        <div className="group-check">
                          <input
                            id={i}
                            value={i}
                            type="checkbox"
                            aria-label="Check this box"
                            onChange={(e) => handleChange(e, contact)}
                          />
                          <span className="checkmark" />
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              );
            })
          ) : (
            <div className="contact-notFound">
              <span>No contacts found</span>
            </div>
          )}
        </div>
        <div className="sendContact-message">
          <div className="sendContact-message-names">
            <span className="name">
              {selectedContactName?.length > 3
                ? getSelectedName() + " ..."
                : selectedContactName}
            </span>
          </div>
          {/* <div
            tabIndex={0}
            role="button"
            className="send-message"
            onKeyDown={handleKeyDown}
            onClick={() => onSendContact()}
          >
            <img src={send} alt="send-message" />
          </div> */}

          <button
            className="btn btn-send-message"
            aria-label="Send"
            onKeyDown={handleKeyDown}
            onClick={() => onSendContact()}
          >
            <svg width="12" height="16" viewBox="0 0 12 22" fill="none">
              <path
                d="M1.69029 21.2L10.2425 12.8854C11.2525 11.9034 11.2525 10.2966 10.2425 9.31465L1.69029 1"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default SendContact;
