import React, { useEffect, useRef, useState } from "react";
import Spinner from "../Common/Spinner";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import { useDispatch, useSelector } from "react-redux";
import { getGroupMembersName, getGroupProfilePic } from "../../Utils/helpers";
import {
  handleToast,
  setSelectedSetting,
  setAnimationDirection,
} from "../../Redux/actions";
import "../../assets/css/myGroups.scss";

function MyGroups() {
  const dispatch = useDispatch();

  const containerRef = useRef(null);
  const searchInputRef = useRef(null);

  const UserID = localStorage.getItem("user_id");
  const theme = useSelector((state) => state.theme);

  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTotalPages, setSearchTotalPages] = useState(0);
  const [count, setCount] = useState();
  const [searchCount, setSearchCount] = useState();
  const [appendGroup, setAppendGroup] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [Groups, setGroupsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [imageErrors, setImageErrors] = useState({});

  const handleImageError = (groupId) => {
    setImageErrors((prevErrors) => ({
      ...prevErrors,
      [groupId]: true,
    }));
  };

  useEffect(() => {
    if (Groups) {
      setAppendGroup(Groups);
    }
  }, [Groups]);

  const handleBackButton = () => {
    dispatch(setAnimationDirection("right"));
    setIsMounted(false);
  };

  useEffect(() => {
    const element = document.querySelector(".myGroups");

    const handleAnimationEnd = () => {
      if (!isMounted) {
        dispatch(setSelectedSetting(""));
      }
    };

    if (isMounted) {
      element.classList.add("open");
      element.classList.remove("close");
      element.removeEventListener("animationend", handleAnimationEnd);
    } else {
      element.classList.add("close");
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      element.classList.remove("open", "close");
      element.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [isMounted]);

  const fetchGroup = (pageNumber, isSearch = false) => {
    setLoading(true);
    const endpoint = isSearch
      ? `group/web/${UserID}?search=${searchValue}&page=${pageNumber}`
      : `group/web/${UserID}?page=${pageNumber}`;

    axiosRequest
      .get(endpoint, {})
      .then((res) => {
        if (isSearch) {
          setSearchCount(res?.data?.totalGroups);
          setSearchTotalPages(res?.data?.totalPages);
          setAppendGroup((prevGroups) =>
            pageNumber === 1 ? res.data.data : [...prevGroups, ...res.data.data]
          );
          setSearchPage(pageNumber + 1);
        } else {
          setCount(res?.data?.totalGroups);
          setTotalPages(res?.data?.totalPages);
          setGroupsData((prevTepars) => [...prevTepars, ...res.data.data]);
          setPage(pageNumber + 1);
        }
        setLoading(false);
        setSearchLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
        setSearchLoading(false);
      });
  };

  useEffect(() => {
    fetchGroup(1);
  }, []);

  useEffect(() => {
    if (searchCount && searchTotalPages > 1 && searchValue) {
      fetchGroup(searchPage, true);
    } else if (!searchCount && count && totalPages > 1) {
      fetchGroup(page);
    }
  }, [count, searchCount]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10 && !loading) {
      if (
        searchValue &&
        searchPage <= searchTotalPages &&
        searchCount !== appendGroup.length
      ) {
        fetchGroup(searchPage, true);
      } else if (
        !searchValue &&
        page <= totalPages &&
        count !== appendGroup.length
      ) {
        fetchGroup(page);
      }
    }
  };

  useEffect(() => {
    if (searchValue !== "") {
      var timer = setTimeout(() => {
        setSearchLoading(true);
        setSearchPage(1);
        fetchGroup(1, true);

        if (containerRef.current) {
          containerRef.current.scrollTo(0, 0);
        }
      }, 1000);
    } else {
      if (containerRef.current) {
        containerRef.current.scrollTo(0, 0);
      }
      setSearchLoading(false);
      setAppendGroup(Groups);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  const handleSearchInputChange = (e) => {
    const keyword = e.target.value;
    setSearchValue(keyword);
    setSearchLoading(true);
  };

  const handleClearSearch = () => {
    setSearchValue("");
    setAppendGroup(Groups);
    setSearchPage(searchPage);
    setPage(page);
    searchInputRef.current.focus();
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  };

  return (
    <div className="myGroups">
      <div className="myGroups-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => handleBackButton()}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Groups</span>
          </div>
        </header>
        <div className="search-bar">
          <div className="search-bar-wrapper">
            <svg width="12" height="12" viewBox="0 0 18 18" fill="none">
              <title>Search</title>
              <path
                id="Vector"
                d="M14.2049 12.952L18 16.7462L16.7462 18L12.952 14.2049C11.5402 15.3366 9.78419 15.9522 7.9748 15.9496C3.57271 15.9496 0 12.3769 0 7.9748C0 3.57271 3.57271 0 7.9748 0C12.3769 0 15.9496 3.57271 15.9496 7.9748C15.9522 9.78419 15.3366 11.5402 14.2049 12.952ZM12.4274 12.2945C13.5519 11.138 14.18 9.58786 14.1774 7.9748C14.1774 4.54741 11.4013 1.77218 7.9748 1.77218C4.54741 1.77218 1.77218 4.54741 1.77218 7.9748C1.77218 11.4013 4.54741 14.1774 7.9748 14.1774C9.58786 14.18 11.138 13.5519 12.2945 12.4274L12.4274 12.2945Z"
                fill="#999999"
              />
            </svg>

            <input
              type="search"
              placeholder="Search"
              value={searchValue}
              onChange={handleSearchInputChange}
              ref={searchInputRef}
            />
            {searchValue.length > 0 && (
              <svg
                width="12"
                height="12"
                viewBox="0 0 25 25"
                fill="none"
                style={{
                  overflow: "visible",
                  pointerEvents: "auto",
                  cursor: "pointer",
                }}
                onClick={handleClearSearch}
              >
                <title>Close</title>
                <g id="iconoir:cancel">
                  <path
                    id="Vector"
                    d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                    stroke="#999999"
                    strokeWidth="2.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            )}
          </div>
        </div>
        <div className="all-groups" onScroll={handleScroll} ref={containerRef}>
          {searchLoading && <Spinner />}
          {loading && page === 1 ? (
            <div className="no-data">
              <Spinner />
            </div>
          ) : appendGroup && appendGroup.length > 0 ? (
            <div className="user-groups">
              {appendGroup?.map((data, index) => {
                return (
                  <div key={index} className="user-groups-list">
                    <div
                      className="user-groups-listItem"
                      role="list"
                      tabIndex="0"
                    >
                      <div className="profile-image">
                        <img
                          className="profile-avatar"
                          src={getGroupProfilePic(
                            data?.groupProfile,
                            imageErrors[data?._id]
                          )}
                          alt="profile"
                          onError={() => handleImageError(data?._id)}
                        />
                      </div>
                      <div className="profile-info">
                        <div className="profile-info-name">
                          <span className="name">{data?.groupName}</span>
                          <p className="profile-info-message">
                            {getGroupMembersName(data?.memberList)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : searchValue ? (
            <div className="no-group-waraper">
              <div className="no-group">
                <span className="text">No groups found</span>
              </div>
            </div>
          ) : (
            <div className="no-group-waraper">
              <div className="no-group">
                <div className="no-group-img">
                  <svg
                    width="45"
                    height="45"
                    viewBox="0 0 56 56"
                    fill={
                      theme === "Light"
                        ? "#1891a2"
                        : theme === "Dark"
                        ? "#ffffff"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? "#1891a2"
                        : "#ffffff"
                    }
                  >
                    <path
                      d="M35.52 18.5217C35.52 22.6759 32.1524 26.0435 27.9983 26.0435C23.8442 26.0435 20.4766 22.6759 20.4766 18.5217C20.4766 14.3676 23.8442 11 27.9983 11C32.1524 11 35.52 14.3676 35.52 18.5217Z"
                      stroke="#2A2A2A"
                      strokeWidth="2"
                    />
                    <path
                      d="M50.1348 25.8252C50.1348 28.6347 47.8573 30.9122 45.0479 30.9122C42.2384 30.9122 39.9609 28.6347 39.9609 25.8252C39.9609 23.0158 42.2384 20.7383 45.0479 20.7383C47.8573 20.7383 50.1348 23.0158 50.1348 25.8252Z"
                      stroke="#2A2A2A"
                      strokeWidth="2"
                    />
                    <path
                      d="M16.2208 25.8252C16.2208 28.6347 13.9433 30.9122 11.1338 30.9122C8.32438 30.9122 6.04688 28.6347 6.04688 25.8252C6.04688 23.0158 8.32438 20.7383 11.1338 20.7383C13.9433 20.7383 16.2208 23.0158 16.2208 25.8252Z"
                      stroke="#2A2A2A"
                      strokeWidth="2"
                    />
                    <path
                      d="M8.73913 45.3066H8.73908L8.73918 45.3163C8.73985 45.3856 8.74209 45.4548 8.74588 45.5239H1.21739C1.15973 45.5239 1.10444 45.501 1.06367 45.4603C1.0229 45.4195 1 45.3642 1 45.3066H0L0.999999 45.3077C1.00293 42.6676 2.05297 40.1366 3.91976 38.2698C5.78519 36.4044 8.31393 35.3545 10.9519 35.35C11.1427 35.3521 11.3334 35.3597 11.5236 35.3728C9.76467 38.2727 8.73913 41.6687 8.73913 45.3066ZM47.2609 45.3066V45.3063C47.2599 41.7919 46.292 38.3565 44.478 35.3705C44.667 35.3571 44.8555 35.35 45.0435 35.35C50.5319 35.35 55 39.8181 55 45.3066C55 45.3642 54.9771 45.4195 54.9363 45.4603C54.8956 45.501 54.8403 45.5239 54.7826 45.5239H47.2552C47.2589 45.4523 47.2609 45.3798 47.2609 45.3066ZM17.5221 34.8286C20.3015 32.0492 24.0699 30.4855 28.0006 30.4805C36.1743 30.4808 42.8261 37.1328 42.8261 45.3066C42.8261 45.3642 42.8032 45.4195 42.7624 45.4603C42.7216 45.501 42.6664 45.5239 42.6087 45.5239H13.3913C13.3336 45.5239 13.2784 45.501 13.2376 45.4603C13.1968 45.4195 13.1739 45.3642 13.1739 45.3066H12.1739L13.1739 45.3078C13.1787 41.3768 14.7425 37.6082 17.5221 34.8286Z"
                      stroke="#2A2A2A"
                      strokeWidth="2"
                    />
                  </svg>
                </div>
                <span className="text">No groups yet</span>
              </div>
            </div>
          )}
          {!searchLoading && loading && page >= 2 && <Spinner />}
        </div>
      </div>
    </div>
  );
}

export default MyGroups;
