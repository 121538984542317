import React from "react";
import hide from "../../../assets/image/SVG_Images/hide.svg";
import notify from "../../../assets/image/SVG_Images/notify.svg";
import thankyou from "../../../assets/image/SVG_Images/thankyou.svg";

const ThankYou = ({
  setReportMenu,
  setReportBlockModal,
  setReportThanksModal,
}) => {
  return (
    <div className="report-submitted">
      <div className="report-submitted-info">
        <div className="heading">
          <div
            className="btn btn-back"
            role="button"
            aria-label="back"
            tabIndex="0"
            onClick={() => setReportMenu(false)}
          >
            <svg width="18" height="18" viewBox="0 0 26 26" fill="none">
              <title>Back</title>
              <g id="Group 18522">
                <g id="Group 18522_2">
                  <path
                    id="Vector"
                    d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                    stroke="#1891a2"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </svg>
          </div>
          <h4 className="title">Report</h4>

          <div className="hidden" aria-hidden="true" />
        </div>

        <div className="report-done">
          <div className="done-image">
            <img src={thankyou} alt="thankyou" />
          </div>

          <span className="title">Thanks for letting us know</span>
          <p className="info">We use these reports to:</p>
        </div>

        <ul className="reported-info">
          <li className="reported-info-list">
            <div className="cta-image ">
              <img src={notify} alt="notify" />
            </div>
            <p className="text">
              Understand problems that people are having with different types of
              content on Tepnot.
            </p>
          </li>

          <li className="reported-info-list">
            <div className="cta-image archive-image">
              <img src={hide} alt="hide" />
            </div>
            <p className="text">
              Show you less of this kind of content in the future.
            </p>
          </li>
        </ul>

        <button className="btn btn-close" onClick={() => setReportMenu(false)}>
          Done
        </button>
      </div>
    </div>
  );
};

export default ThankYou;
