import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfilePic } from "../../Utils/helpers";
import { setAnimationDirection, setSelectedSetting } from "../../Redux/actions";
import "../../assets/css/blockedContacts.scss";

function BlockedContacts() {
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings);

  const [isMounted, setIsMounted] = useState(true);

  const handleBackButton = () => {
    dispatch(setAnimationDirection("left"));
    dispatch(setSelectedSetting("privacy"));
    setIsMounted(false);
  };

  useEffect(() => {
    const element = document.querySelector(".blockedContacts");

    const handleAnimationEnd = () => {
      if (!isMounted) {
      }
    };

    if (isMounted) {
      element.classList.add("open");
      element.classList.remove("close");
      element.removeEventListener("animationend", handleAnimationEnd);
    } else {
      element.classList.add("close");
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      element.classList.remove("open", "close");
      element.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [isMounted]);

  return (
    <div className="blockedContacts">
      <div className="blockedContacts-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => handleBackButton()}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Blocked contacts</span>
          </div>
        </header>

        <div className="contacts-wrapper">
          {settings?.BlockUser?.length > 0 ? (
            settings?.BlockUser?.map((contact) => (
              <div className="profiles-wrapper">
                <div className="profile" tabIndex="0">
                  <div className="profile-image">
                    <img
                      className="profile-avatar"
                      src={getUserProfilePic(contact?.profile)}
                      alt="profile"
                    />
                  </div>
                  <div className="profile-info">
                    <div className="profile-info-number">
                      <span className="name">{contact?.name}</span>
                      <p className="status">{contact?.description}</p>
                    </div>
                    <div className="close" aria-label="delete" role="button">
                      {/* <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M3 16L9.5 9.5L16 16M16 3L9.49876 9.5L3 3"
                            stroke="#999999"
                            strokeWidth="2.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg> */}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="default-blockedContacts-info">
              <div className="default-blockedContacts-info-image">
                <svg width="60" height="95" viewBox="0 0 77 95" fill="none">
                  <path
                    d="M43.8033 53.3268C31.5963 60.612 27.8205 76.1193 35.1247 88.992C36.4325 91.2936 36.4087 91.3697 33.779 91.3697C25.4476 91.2984 17.1352 90.8609 9.29359 87.6986C5.83644 86.3052 3.07357 84.0702 1.88948 80.2374C-1.38221 69.6757 2.29845 60.9639 12.1849 56.1515C22.2092 51.2725 32.7756 49.6795 43.808 50.9634C44.1362 51.534 45.2346 50.873 45.2346 51.7243C45.2346 52.5755 44.3977 52.856 43.8033 53.3268Z"
                    fill="white"
                  />
                  <path
                    d="M19.0357 18.8968C19.0737 8.55389 27.1579 0.621953 37.515 0.679017C47.3301 0.736081 55.2478 9.03894 55.1955 19.2249C55.1384 29.1018 46.9306 36.9386 36.7114 36.8721C31.9847 36.8293 27.4683 34.9123 24.1541 31.5419C20.8399 28.1715 18.999 23.6235 19.0357 18.8968Z"
                    fill="white"
                  />
                  <path
                    d="M76.7611 75.4861C76.8325 85.8813 68.23 94.6597 57.9204 94.7073C47.2826 94.7596 38.2427 86.2332 38.1476 76.0567C38.0477 65.2478 46.6739 56.2602 57.2261 56.1841C59.7806 56.1526 62.3159 56.6287 64.685 57.5846C67.0541 58.5406 69.2097 59.9575 71.027 61.753C72.8442 63.5486 74.2868 65.6871 75.2712 68.0446C76.2555 70.402 76.7619 72.9314 76.7611 75.4861Z"
                    fill="white"
                  />
                  <path
                    d="M43.8037 53.3339C43.9654 52.5398 45.8675 51.7456 43.8037 50.9562C44.8404 51.0418 46.4239 50.3856 46.733 51.5316C47.0088 52.5588 45.1352 52.4447 44.4695 53.2103C44.3744 53.3339 44.051 53.2959 43.8037 53.3339Z"
                    fill="white"
                  />
                  <path
                    d="M45.1111 75.74C45.0826 67.0424 53.2571 61.0602 61.2794 63.6661C63.562 64.408 63.6571 64.66 62.0165 66.3149C57.5781 70.8134 53.1224 75.2962 48.6491 79.763C46.7708 81.6366 46.4569 81.5415 45.6057 79.0069C45.2506 77.9553 45.0832 76.8495 45.1111 75.74Z"
                    fill="#1A91A2"
                  />
                  <path
                    d="M69.9822 75.5113C69.8871 84.1233 61.7935 89.9819 53.5097 87.3997C52.2685 87.0098 51.4981 86.5771 52.8915 85.2028C57.6468 80.4902 62.3546 75.692 67.0482 70.8749C68.1134 69.7811 68.7601 70.0189 69.2404 71.3504C69.7704 72.6718 70.0229 74.0882 69.9822 75.5113Z"
                    fill="#1891A5"
                  />
                </svg>
              </div>
              <div className="info-wrapper">
                <span className="heading">No blocked contacts</span>
                <p className="info">
                  Blocked contacts will no longer be able to call you or send
                  you messages.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BlockedContacts;
