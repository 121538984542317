import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

function SearchBox({ setInboxContacts, setIsSearchVisible }) {
  const InboxContacts = useSelector((state) => state.inboxContacts);
  const [searchValue, setSearchValue] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  let popupRef = useRef(null);

  useEffect(() => {
    setInboxContacts(InboxContacts);
  }, []);

  useEffect(() => {
    const element = document.querySelector(".search-bar");

    const handleAnimationEnd = () => {
      if (!isMounted) {
      }
    };

    if (isMounted) {
      element.classList.add("open");
      element.classList.remove("close");
      element.removeEventListener("animationend", handleAnimationEnd);
    } else {
      element.classList.add("close");
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      element.classList.remove("open", "close");
      element.removeEventListener("animationend", handleAnimationEnd);
      // setIsSearchVisible(false);
    };
  }, [isMounted]);

  useEffect(() => {
    let handler = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setIsMounted(false);
        setIsSearchVisible(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const handleSearchInputChange = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = InboxContacts?.filter((contact) => {
        return (
          contact?.inboxContact?.name
            .toLowerCase()
            .startsWith(keyword.toLowerCase()) ||
          contact?.inboxContact?.phone.toString().startsWith(keyword.toString())
        );
      });
      setInboxContacts(results);
    } else {
      setInboxContacts(InboxContacts);
    }
    setSearchValue(keyword);
  };

  return (
    <div className="search-bar" ref={popupRef}>
      <div className="search-bar-wrapper">
        <svg width="13" height="13" viewBox="0 0 16 16" fill="none">
          <path
            d="M6.3833 12.7666C7.76123 12.7666 9.04785 12.3184 10.0938 11.5713L14.0283 15.5059C14.2109 15.6885 14.4517 15.7798 14.7007 15.7798C15.2402 15.7798 15.6304 15.3647 15.6304 14.8335C15.6304 14.5845 15.5474 14.3521 15.3647 14.1694L11.4551 10.2515C12.2769 9.17236 12.7666 7.83594 12.7666 6.3833C12.7666 2.87207 9.89453 0 6.3833 0C2.86377 0 0 2.87207 0 6.3833C0 9.89453 2.86377 12.7666 6.3833 12.7666ZM6.3833 11.3887C3.63574 11.3887 1.37793 9.12256 1.37793 6.3833C1.37793 3.64404 3.63574 1.37793 6.3833 1.37793C9.12256 1.37793 11.3887 3.64404 11.3887 6.3833C11.3887 9.12256 9.12256 11.3887 6.3833 11.3887Z"
            fill="#8E8E93"
          />
        </svg>

        <input
          type="search"
          placeholder="Search"
          value={searchValue}
          onChange={handleSearchInputChange}
        />
      </div>
    </div>
  );
}

export default SearchBox;
