import React, { useRef, useState, useEffect } from "react";
import axiosRequest from "../../Middleware/api";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import TepsView from "./TepsView";
import ShowDots from "../Tepars/ShowDots";
import { getTepars } from "../../Utils/helpers";
import CommentView from "../Tepars/CommentView";
import { IMAGE, VIDEO } from "../../Utils/enums";
import CommentAction from "../Tepars/CommentAction";
import { setSelfContact, setTepars, handleToast } from "../../Redux/actions";
import BigTep from "../../assets/image/SVG_Images/BigTep.svg";
import { useSwiperSlider } from "../../Utils/helpers";
import { LIKED, UNLIKE, ERROR } from "../../Utils/enums";
import "../../assets/css/tepars.scss";
import SliderPopup from "../Tepars/SliderPopup";

function ViewPost({
  post,
  postDetail,
  setSelectedMenu,
  toggleSelfLike,
  onClose,
  showAnimation,
  setShowAnimation,
}) {
  const dispatch = useDispatch();
  const popupRef = useRef(null);
  const sliderRef = useRef(null);
  const sliderWrapperRef = useRef(null);
  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const selfUser = useSelector((state) => state.selfContact);
  const [isReplyId, setIsReplyId] = useState();
  const [isCommentId, setIsCommentId] = useState();
  const [passPostData, setPassPostData] = useState();
  const [isUserPost, setIsUserPost] = useState(false);
  const [mediaIndexFinal, setMediaIndexFinal] = useState(0);
  const [isReplyDelete, setIsReplyDelete] = useState(false);
  const [isCommentDelete, setIsCommentDelete] = useState(false);
  const [isCommentOrReply, setIsCommentOrReply] = useState(false);
  const imagesList = postDetail?.media;

  useEffect(() => {
    const overlayOpenEvent = new Event("overlayOpen");
    document.dispatchEvent(overlayOpenEvent);
  }, []);

  let width = "43.75rem";
  let height = "85vh";

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  if (viewportWidth <= 1024 && viewportHeight <= 1380) {
    width = "40rem";
    height = "30rem";
  } else if (viewportWidth <= 1440 || viewportWidth < 1600) {
    width = "60rem";
  } else if (viewportWidth > 1600) {
    width = "60rem";
    height = "40rem";
  }

  const goToSlide = (index) => {
    sliderRef?.current?.slickGoTo(index);
  };

  useSwiperSlider(sliderRef, sliderWrapperRef, postDetail.media.length);

  const toggleLike = (postId) => {
    const data = {};
    axiosRequest
      .put(`post/like/${postId}`, data)
      .then((res) => {
        if (res?.data?.message === LIKED || res?.data?.message === UNLIKE) {
          if (res?.data?.message === LIKED) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps + 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          if (res?.data?.message === UNLIKE) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps - 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          let newpost = postDetail;
          const newObj = {
            userId: {
              _id: selfUser?._id,
              name: "You",
              profile: selfUser?.profile,
              phone: selfUser?.phone,
              countryCode: selfUser?.countryCode,
              contactStatus: selfUser?.isUserVerified,
            },
          };
          if (postDetail?.selfLike) {
            newpost.TotalLike -= 1;
            newpost.selfLike = false;
            newpost.likeUser = newpost?.likeUser?.filter(
              (item) => item?.userId?._id !== selfUser?._id
            );
          } else {
            newpost.TotalLike += 1;
            newpost.selfLike = true;
            newpost.likeUser = [...(newpost?.likeUser || []), newObj];
          }
          const findPost = Tepars.map((Post) =>
            Post?._id === postId ? newpost : Post
          );
          dispatch(setTepars(findPost));
          setShowAnimation(true);
          setTimeout(() => {
            setShowAnimation(false);
          }, 1000);
        } else {
          dispatch(handleToast(res?.data?.message));
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const handleClickOutside = (event) => {
    if (popupRef.current === event.target) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDoubleClick = () => {
    if (!postDetail.selfLike) {
      toggleLike(postDetail._id);
    } else {
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, 1000);
    }
  };

  return (
    <div className="uploadTepars" style={{ zIndex: 50 }} ref={popupRef}>
      <div
        className="uploadTepars-popup"
        // style={{ width, height }}
      >
        <main className="container" style={{ display: "flex" }}>
          {/* Media Container */}
          <div
            className="upload-image-container"
            style={{
              // width: "450px",
              // height: "562.5px",
              // width: "450px",
              // height: "500px",
              aspectRatio: postDetail.media[0].aspectRatio,
            }}
            ref={sliderWrapperRef}
            onDoubleClick={handleDoubleClick}
          >
            <SliderPopup
              sliderWrapperRef={sliderWrapperRef}
              imagesList={imagesList}
              mediaIndexFinal={mediaIndexFinal}
              setMediaIndexFinal={setMediaIndexFinal}
            />

            {/* show post length */}
            {imagesList?.length > 1 && (
              <div className="image-number">
                <span className="number">
                  {`${mediaIndexFinal + 1}/${imagesList?.length}`}
                </span>
              </div>
            )}

            {/* <div className="gradient" aria-hidden="true" /> */}
            {showAnimation && (
              <div className="doubleTepAnimation">
                <img src={BigTep} alt="Teps" />
              </div>
            )}
          </div>

          <div className="filters">
            {/* header */}
            <div className="header">
              <div className="heading">
                <h1 className="title">View post</h1>
              </div>
              <div
                className="btn btn-back"
                onClick={onClose}
                tabIndex="0"
                aria-label="Back"
                role="button"
              >
                <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
                  <title>Close</title>
                  <g id="Frame 2595">
                    <path
                      id="Shape"
                      d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                      fill={
                        theme === "Light"
                          ? "#1891a2"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#1891a2"
                          : "#ffffff"
                      }
                    />
                  </g>
                </svg>
              </div>
            </div>

            <div className="filters-wrapper">
              {/* TEPSVIEW || COMMENTVIEW */}
              {post?.event === "post_like" ? (
                <TepsView
                  post={postDetail}
                  setSelectedMenu={setSelectedMenu}
                  onClose={onClose}
                />
              ) : (
                <CommentView
                  postData={postDetail}
                  setPassPostData={setPassPostData}
                  setIsCommentOrReply={setIsCommentOrReply}
                  isCommentId={isCommentId}
                  setIsCommentId={setIsCommentId}
                  isReplyId={isReplyId}
                  setIsReplyId={setIsReplyId}
                  setIsUserPost={setIsUserPost}
                  isCommentDelete={isCommentDelete}
                  setIsCommentDelete={setIsCommentDelete}
                  isReplyDelete={isReplyDelete}
                  setIsReplyDelete={setIsReplyDelete}
                  goToSlide={goToSlide}
                  setSelectedMenu={setSelectedMenu}
                  notificationComment={post}
                  toggleSelfLike={toggleSelfLike}
                />
              )}
            </div>
          </div>
        </main>
      </div>
      {/* COMMENTACTION */}
      {isCommentOrReply && (
        <CommentAction
          passPostData={passPostData}
          setIsCommentOrReply={setIsCommentOrReply}
          isCommentId={isCommentId}
          setIsCommentId={setIsCommentId}
          isReplyId={isReplyId}
          setIsReplyId={setIsReplyId}
          isUserPost={isUserPost}
          setIsCommentDelete={setIsCommentDelete}
          setIsReplyDelete={setIsReplyDelete}
        />
      )}
    </div>
  );
}

export default ViewPost;
