import { React, useRef, useEffect } from "react";
import "../../assets/css/comingSoon.scss";

function ComingSoon({ onClose }) {
  const dialogRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="comingSoon">
      <div ref={dialogRef} className="dialog">
        <div className="dialog-info">
          <div className="comingSoon-image">
            <svg width="50" height="50" viewBox="0 0 62 62" fill="none">
              <path
                d="M30.7749 60.6974C24.0932 60.6974 17.4114 60.6966 10.7295 60.6951C8.38218 60.6927 6.27245 59.9707 4.46859 58.4587C2.73602 57.0057 1.63051 55.1582 1.19137 52.9278C1.06091 52.2525 0.996857 51.5659 1.00012 50.8781C1.00256 42.1042 1.00256 33.3308 1.00012 24.5581C1.00012 22.2224 1.66283 20.0974 3.02889 18.1986C3.7637 17.178 4.67754 16.2991 5.72603 15.6047C8.63585 13.666 11.5438 11.7261 14.4498 9.78495C17.873 7.49774 21.2957 5.20986 24.718 2.92132C25.9397 2.10268 27.2528 1.50495 28.6982 1.21641C30.8113 0.794374 32.8667 0.98962 34.8645 1.80215C35.6931 2.13733 36.4454 2.60346 37.1844 3.09824C40.1358 5.07337 43.0885 7.04627 46.0425 9.01695C49.2995 11.1938 52.5556 13.3716 55.8108 15.5504C58.4187 17.2996 59.9823 19.7315 60.5138 22.8258C60.6049 23.3847 60.6485 23.9502 60.644 24.5164C60.6491 33.3452 60.6482 42.1739 60.641 51.0027C60.635 53.8564 59.5689 56.2843 57.4555 58.2135C56.0074 59.5349 54.2925 60.3226 52.3451 60.5904C51.7734 60.6656 51.1973 60.7015 50.6208 60.6977C44.0053 60.6971 37.39 60.6969 30.7749 60.6974ZM30.8416 57.6504H50.6535C51.0193 57.6552 51.385 57.6389 51.749 57.6018C53.21 57.4352 54.4774 56.8538 55.5413 55.8399C56.9354 54.5118 57.5894 52.8662 57.6031 50.9534C57.6077 50.287 57.6031 49.6206 57.6031 48.9543C57.6031 40.8585 57.6037 32.7629 57.6051 24.6674C57.6051 24.0993 57.5694 23.5345 57.4531 22.9801C57.02 20.9187 55.9418 19.2841 54.1829 18.1106C51.2287 16.1395 48.2744 14.1687 45.3202 12.1982C41.8772 9.90123 38.4335 7.60513 34.9891 5.30993C33.5317 4.34169 31.9101 3.9572 30.1769 4.09047C28.8108 4.19509 27.5714 4.67688 26.4312 5.43854C20.112 9.66156 13.7896 13.8811 7.46392 18.0973C7.15657 18.2966 6.86506 18.5194 6.59198 18.7636C4.90672 20.31 4.04444 22.2211 4.04477 24.5168C4.04611 33.3453 4.04611 42.1738 4.04477 51.0024C4.04031 51.3463 4.0617 51.6901 4.10874 52.0309C4.31732 53.4136 4.89473 54.6188 5.86296 55.62C7.17005 56.9717 8.78434 57.6451 10.6608 57.6471C17.3874 57.6546 24.114 57.6557 30.8406 57.6504H30.8416Z"
                fill="#1891A2"
                stroke="#1891A2"
              />
              <path
                d="M33.8134 27.8279C33.8134 29.2272 33.8184 30.6246 33.8114 32.023C33.8074 32.86 33.5025 33.585 32.9151 34.1767C31.9489 35.1503 30.4709 35.0996 29.5546 33.9835C29.0835 33.4101 28.8356 32.743 28.8359 31.9907C28.8375 29.1939 28.8382 26.3971 28.8379 23.6001C28.8379 22.7641 29.1315 22.0304 29.7229 21.442C30.7747 20.3958 32.2241 20.5621 33.1077 21.6363C33.5748 22.1957 33.8258 22.904 33.8151 23.6327C33.8107 25.0321 33.8151 26.4295 33.8151 27.8279H33.8134Z"
                fill="#1891A2"
              />
              <path
                d="M33.8162 43.987C33.8852 45.6833 32.3735 47.01 30.8072 46.996C29.3032 46.9824 27.7852 45.8039 27.8049 43.9597C27.8235 42.2035 29.1709 40.973 30.8189 40.961C32.6131 40.947 33.9078 42.4884 33.8162 43.987Z"
                fill="#1891A2"
              />
            </svg>
          </div>
          <h4 className="info">Coming Soon!</h4>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
