import React, { useState } from "react";

function FooterText({ media, onSendMedia }) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const onButtonClick = async () => {
    onSendMedia();
    setIsButtonDisabled(true);
  };

  return (
    <footer className="footer-box">
      <div className="message-wrapper">
        <div className="input-wrapper">
          <div
            placeholder="Type Message.."
            className="messageInput"
            contentEditable
          />
        </div>
      </div>
      <div
        role="button"
        tabIndex="0"
        className="send-Mediamessage"
        onClick={onButtonClick}
        style={{ pointerEvents: isButtonDisabled ? "none" : "" }}
      >
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
          <title>Send</title>
          <rect
            width="46"
            height="46"
            rx="23"
            transform="matrix(-1 0 0 1 46 0)"
            fill="#1891A2"
          />
          <path
            d="M18.8886 31.6163L26.34 24.3652C27.22 23.5088 27.22 22.1075 26.34 21.2512L18.8886 14"
            stroke="white"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        {media.length > 1 && <span className="number">{media.length}</span>}
      </div>
    </footer>
  );
}

export default FooterText;
