import React from "react";
import close from "../../assets/image/close-white.png";
import search from "../../assets/image/black-search.png";
import back from "../../assets/image/back.png";
import statusSeen from "../../assets/image/read.png";
import profile from "../../assets/image/profilePic.png";
import "../../assets/css/groups.scss";

function Groups({ onClose }) {
  return (
    <div className="groups">
      <div className="groups-container">
        {/* group-to */}
        <header className="group-to">
          <div className="group-to-container">
            <h2 className="title">Groups</h2>
            <button className="close" onClick={() => onClose(false)}>
              <img src={close} alt="close" />
            </button>
          </div>
        </header>

        {/* group-search */}
        <div className="group-search">
          <div className="search-bar">
            <img className="search" src={search} alt="search" />
            <img
              className="back"
              src={back}
              alt="back"
              style={{ display: "none" }}
            />
            <input type="search" placeholder="Search contact" />
          </div>
        </div>

        {/* group-details */}
        <div className="group-details">
          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>

          <div className="group">
            <div className="group-image">
              <img
                className="group-avatar"
                src={profile}
                alt="profile thumbnail"
              />
            </div>
            <div className="group-info">
              <div className="group-info-name">
                <p className="group-name">Jaydip Godhani</p>
                <p className="group-info-message">
                  I've arrived at the office...
                </p>
              </div>

              <div className="group-check">
                <input type="checkbox" name="contact" value="" />
              </div>
            </div>
          </div>
        </div>

        {/* group-message */}
        {/* group-message (Without Img & Vid) */}
        <div className="group-message">
          <div className="group-message-names">
            <span className="name">Pujan</span>
            <span className="name">Pujan</span>
            <span className="name">Pujan</span>
            <span className="name">Pujan</span>
            <span className="name">Pujan</span>
            <span className="name">Pujan</span>
            <span className="name">Pujan</span>
            <span className="name">Pujan</span>
            <span className="name">Pujan</span>
            <span className="name">Pujan</span>
            <span className="name">Pujan</span>
          </div>

          <div
            role="button"
            className="send-message"
            //   onClick={handleSendMessage}
          >
            <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
              <title>Send</title>
              <rect
                width="46"
                height="46"
                rx="23"
                transform="matrix(-1 0 0 1 46 0)"
                fill="#1891A2"
              />
              <path
                d="M18.8886 31.6163L26.34 24.3652C27.22 23.5088 27.22 22.1075 26.34 21.2512L18.8886 14"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Groups;
