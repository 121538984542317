import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../assets/css/pollChat.scss";

function PollChat() {
  // const options = [
  //   { id: "option1", label: "Sandip" },
  //   { id: "option2", label: "Option 2" },
  //   // Add more options as needed
  // ];
  // function PollOptionSelect() {
  // }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const handleLabelClick = () => {
    setIsChecked(!isChecked);
  };

  const theme = useSelector((state) => state.theme);

  return (
    <>
      <div className="pollChat-received">
        <div className="pollChat-container">
          <p className="poll-question">You Know my name?</p>
          <div className="poll-cta">
            <div className="poll-cta-image">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
                <circle cx="5.5" cy="5.5" r="5.5" fill="#999999" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.89563 3.322L4.93746 8.27108L1.82812 5.67967L2.41479 4.97567L4.80546 6.96758L8.18062 2.75L8.89563 3.322Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className="poll-cta-info">Select one or more</p>
          </div>

          <div className="poll-options">
            <div className="poll-option" id="voted">
              <div className="poll-option-select">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  aria-label="Check this box"
                  checked={isChecked}
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <div className="poll-option-info">
                <div className="poll-option-info-value">
                  <label
                    htmlFor="myCheckbox"
                    className="value"
                    onClick={handleLabelClick}
                  >
                    Sandip
                  </label>
                </div>
                <span className="poll-value">0</span>
              </div>
            </div>

            <div className="poll-option" id="voted">
              <div className="poll-option-select">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  aria-label="Check this box"
                  checked={isChecked}
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <div className="poll-option-info">
                <div className="poll-option-info-value">
                  <label
                    htmlFor="myCheckbox"
                    className="value"
                    onClick={handleLabelClick}
                  >
                    Sandip
                  </label>
                </div>
                <span className="poll-value">0</span>
              </div>
            </div>

            <div className="poll-option" id="voted">
              <div className="poll-option-select">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  aria-label="Check this box"
                  checked={isChecked}
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <div className="poll-option-info">
                <div className="poll-option-info-value">
                  <label
                    htmlFor="myCheckbox"
                    className="value"
                    onClick={handleLabelClick}
                  >
                    Sandip
                  </label>
                </div>
                <span className="poll-value">0</span>
              </div>
            </div>

            <div className="poll-option" id="voted">
              <div className="poll-option-select">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  aria-label="Check this box"
                  checked={isChecked}
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <div className="poll-option-info">
                <div className="poll-option-info-value">
                  <label
                    htmlFor="myCheckbox"
                    className="value"
                    onClick={handleLabelClick}
                  >
                    Sandip
                  </label>
                </div>
                <span className="poll-value">0</span>
              </div>
            </div>

            <div className="message-status">
              {/* <----- Change the star icon from white to #999 in dark mode from send side ------> */}
              <svg
                width="9"
                height="9"
                viewBox="0 0 11 11"
                fill="none"
                className="important"
              >
                <path
                  d="M3.61821 3.16898L0.428209 3.63148L0.371709 3.64298C0.286179 3.66569 0.208207 3.71069 0.145755 3.77339C0.0833039 3.83608 0.0386109 3.91423 0.0162402 3.99985C-0.00613046 4.08547 -0.00537724 4.17549 0.0184229 4.26072C0.0422231 4.34595 0.0882174 4.42334 0.151709 4.48498L2.46271 6.73448L1.91771 9.91198L1.91121 9.96698C1.90597 10.0554 1.92434 10.1437 1.96443 10.2227C2.00453 10.3018 2.0649 10.3687 2.13938 10.4167C2.21385 10.4648 2.29975 10.4921 2.38828 10.496C2.47681 10.4999 2.56479 10.4803 2.64321 10.439L5.49621 8.93898L8.34271 10.439L8.39271 10.462C8.47524 10.4945 8.56493 10.5045 8.65259 10.4909C8.74024 10.4773 8.8227 10.4406 8.89151 10.3846C8.96031 10.3286 9.01299 10.2554 9.04413 10.1723C9.07527 10.0892 9.08375 9.9994 9.06871 9.91198L8.52321 6.73448L10.8352 4.48448L10.8742 4.44198C10.9299 4.37337 10.9664 4.29121 10.9801 4.20388C10.9937 4.11655 10.9839 4.02717 10.9518 3.94485C10.9196 3.86253 10.8662 3.7902 10.797 3.73524C10.7277 3.68028 10.6452 3.64465 10.5577 3.63198L7.36771 3.16898L5.94171 0.278985C5.90045 0.195252 5.83657 0.124742 5.7573 0.0754369C5.67804 0.0261317 5.58656 0 5.49321 0C5.39986 0 5.30838 0.0261317 5.22911 0.0754369C5.14985 0.124742 5.08597 0.195252 5.04471 0.278985L3.61821 3.16898Z"
                  // fill="#838383"
                  fill={
                    theme === "Light"
                      ? "#838383"
                      : theme === "Dark"
                      ? "#ffffff"
                      : window.matchMedia("(prefers-color-scheme: light)")
                          .matches
                      ? "#838383"
                      : "#ffffff"
                  }
                />
              </svg>

              <span className="date">11:10 PM</span>

              <svg
                width="14"
                height="10"
                viewBox="0 0 20 10"
                fill="none"
                className="status"
              >
                <path
                  d="M9.19922 7.69043L10.5093 9L18.5086 1"
                  stroke="#1891A2"
                  strokeWidth="1.49462"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 5.25135L4.80449 8.97129L12.957 1"
                  stroke="#1891A2"
                  strokeWidth="1.49462"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="view-votes no-votes">
          <span>View Votes</span>
        </div>
      </div>

      <div className="pollChat-send">
        <div className="pollChat-container">
          <p className="poll-question">You Know my name?</p>
          <div className="poll-cta">
            <div className="poll-cta-image">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
                <circle cx="5.5" cy="5.5" r="5.5" fill="#999999" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.89563 3.322L4.93746 8.27108L1.82812 5.67967L2.41479 4.97567L4.80546 6.96758L8.18062 2.75L8.89563 3.322Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className="poll-cta-info">Select one or more</p>
          </div>

          <div className="poll-options">
            <div
              className={`poll-option ${
                isChecked ? "poll-option-selected" : ""
              }`}
              id="voted"
            >
              <div className="poll-option-select">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  aria-label="Check this box"
                  checked={isChecked}
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <div className="poll-option-info">
                <div className="poll-option-info-value">
                  <label
                    htmlFor="myCheckbox"
                    className="value"
                    onClick={handleLabelClick}
                  >
                    Sandip
                  </label>
                </div>
                <span className="poll-value">0</span>
              </div>
            </div>

            <div
              className={`poll-option ${
                isChecked ? "poll-option-selected" : ""
              }`}
              id="voted"
            >
              <div className="poll-option-select">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  aria-label="Check this box"
                  checked={isChecked}
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <div className="poll-option-info">
                <div className="poll-option-info-value">
                  <label
                    htmlFor="myCheckbox"
                    className="value"
                    onClick={handleLabelClick}
                  >
                    Sandip
                  </label>
                </div>
                <span className="poll-value">0</span>
              </div>
            </div>

            <div
              className={`poll-option ${
                isChecked ? "poll-option-selected" : ""
              }`}
              id="voted"
            >
              <div className="poll-option-select">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  aria-label="Check this box"
                  checked={isChecked}
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <div className="poll-option-info">
                <div className="poll-option-info-value">
                  <label
                    htmlFor="myCheckbox"
                    className="value"
                    onClick={handleLabelClick}
                  >
                    Sandip
                  </label>
                </div>
                <span className="poll-value">0</span>
              </div>
            </div>

            <div
              className={`poll-option ${
                isChecked ? "poll-option-selected" : ""
              }`}
              id="voted"
            >
              <div className="poll-option-select">
                <input
                  type="checkbox"
                  id="myCheckbox"
                  aria-label="Check this box"
                  checked={isChecked}
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
              <div className="poll-option-info">
                <div className="poll-option-info-value">
                  <label
                    htmlFor="myCheckbox"
                    className="value"
                    onClick={handleLabelClick}
                  >
                    Sandip
                  </label>
                </div>
                <span className="poll-value">0</span>
              </div>
            </div>

            <div className="message-status">
              <svg
                width="9"
                height="9"
                viewBox="0 0 11 11"
                fill="none"
                className="important"
              >
                <path
                  d="M3.61821 3.16898L0.428209 3.63148L0.371709 3.64298C0.286179 3.66569 0.208207 3.71069 0.145755 3.77339C0.0833039 3.83608 0.0386109 3.91423 0.0162402 3.99985C-0.00613046 4.08547 -0.00537724 4.17549 0.0184229 4.26072C0.0422231 4.34595 0.0882174 4.42334 0.151709 4.48498L2.46271 6.73448L1.91771 9.91198L1.91121 9.96698C1.90597 10.0554 1.92434 10.1437 1.96443 10.2227C2.00453 10.3018 2.0649 10.3687 2.13938 10.4167C2.21385 10.4648 2.29975 10.4921 2.38828 10.496C2.47681 10.4999 2.56479 10.4803 2.64321 10.439L5.49621 8.93898L8.34271 10.439L8.39271 10.462C8.47524 10.4945 8.56493 10.5045 8.65259 10.4909C8.74024 10.4773 8.8227 10.4406 8.89151 10.3846C8.96031 10.3286 9.01299 10.2554 9.04413 10.1723C9.07527 10.0892 9.08375 9.9994 9.06871 9.91198L8.52321 6.73448L10.8352 4.48448L10.8742 4.44198C10.9299 4.37337 10.9664 4.29121 10.9801 4.20388C10.9937 4.11655 10.9839 4.02717 10.9518 3.94485C10.9196 3.86253 10.8662 3.7902 10.797 3.73524C10.7277 3.68028 10.6452 3.64465 10.5577 3.63198L7.36771 3.16898L5.94171 0.278985C5.90045 0.195252 5.83657 0.124742 5.7573 0.0754369C5.67804 0.0261317 5.58656 0 5.49321 0C5.39986 0 5.30838 0.0261317 5.22911 0.0754369C5.14985 0.124742 5.08597 0.195252 5.04471 0.278985L3.61821 3.16898Z"
                  // fill="#838383"
                  fill={
                    theme === "Light"
                      ? "#838383"
                      : theme === "Dark"
                      ? "#ffffff"
                      : window.matchMedia("(prefers-color-scheme: light)")
                          .matches
                      ? "#838383"
                      : "#ffffff"
                  }
                />
              </svg>

              <span className="date">11:10 PM</span>

              <svg
                width="14"
                height="10"
                viewBox="0 0 20 10"
                fill="none"
                className="status"
              >
                <path
                  d="M9.19922 7.69043L10.5093 9L18.5086 1"
                  stroke="#1891A2"
                  strokeWidth="1.49462"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 5.25135L4.80449 8.97129L12.957 1"
                  stroke="#1891A2"
                  strokeWidth="1.49462"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="view-votes">
          <span>View Votes</span>
        </div>
      </div>
    </>
  );
}

export default PollChat;
