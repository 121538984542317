import React, { useState, useRef, useEffect } from "react";
import axiosRequest from "../../Middleware/api";
import ReportPost from "./ReportPost";
import BigTep from "../../assets/image/SVG_Images/BigTep.svg";
import CommentView from "./CommentView";
import CommentAction from "./CommentAction";
import SliderPopup from "./SliderPopup";
import { useSelector, useDispatch } from "react-redux";
import {
  setPostDetails,
  setTogglePostAction,
  setSelfContact,
  setTepars,
  handleToast,
} from "../../Redux/actions";
import { LIKED, UNLIKE, ERROR } from "../../Utils/enums";

import "../../assets/css/viewPost.scss";

function ViewPost({
  setSelectedMenu,
  toggleSelfLike,
  showAnimation,
  setShowAnimation,
}) {
  const dispatch = useDispatch();
  const popupRef = useRef(null);
  const sliderRef = useRef(null);

  const sliderWrapperRef = useRef(null);

  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const postDetails = useSelector((state) => state.postDetails);
  const selfUser = useSelector((state) => state.selfContact);

  const imagesList = postDetails?.media;

  const [isReplyId, setIsReplyId] = useState();
  const [isCommentId, setIsCommentId] = useState();
  const [passPostData, setPassPostData] = useState();
  const [isUserPost, setIsUserPost] = useState(false);
  const [reportMenu, setReportMenu] = useState(false);
  const [isReplyDelete, setIsReplyDelete] = useState(false);
  const [mediaIndexFinal, setMediaIndexFinal] = useState(0);
  const [isCommentDelete, setIsCommentDelete] = useState(false);
  const [isCommentOrReply, setIsCommentOrReply] = useState(false);
  const [isCommentAndReplyId, setIsCommentAndReplyId] = useState();
  const [reportCommentAndReply, setReportCommentAndReply] = useState(false);

  const HendlePostClose = () => {
    dispatch(setTogglePostAction(null));
    dispatch(setPostDetails(null));
  };

  const goToSlide = (index) => {
    sliderRef?.current?.slickGoTo(index);
  };

  const toggleLike = (postId) => {
    const data = {};
    axiosRequest
      .put(`post/like/${postId}`, data)
      .then((res) => {
        if (res?.data?.message === LIKED || res?.data?.message === UNLIKE) {
          if (res?.data?.message === LIKED) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps + 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          if (res?.data?.message === UNLIKE) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps - 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          let newpost = postDetails;
          const newObj = {
            userId: {
              _id: selfUser?._id,
              name: "You",
              profile: selfUser?.profile,
              phone: selfUser?.phone,
              countryCode: selfUser?.countryCode,
              contactStatus: selfUser?.isUserVerified,
            },
          };
          if (postDetails?.selfLike) {
            newpost.TotalLike -= 1;
            newpost.selfLike = false;
            newpost.likeUser = newpost?.likeUser?.filter(
              (item) => item?.userId?._id !== selfUser?._id
            );
          } else {
            newpost.TotalLike += 1;
            newpost.selfLike = true;
            newpost.likeUser = [...(newpost?.likeUser || []), newObj];
          }
          const findPost = Tepars.map((Post) =>
            Post?._id === postId ? newpost : Post
          );
          dispatch(setTepars(findPost));
          setShowAnimation(true);
          setTimeout(() => {
            setShowAnimation(false);
          }, 1000);
        } else {
          dispatch(handleToast(res?.data?.message));
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const handleDoubleClick = () => {
    if (!postDetails.selfLike) {
      toggleLike(postDetails._id);
    } else {
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, 1000);
    }
  };

  const handleClickOutside = (event) => {
    if (popupRef.current === event.target) {
      HendlePostClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <div className="viewPost" ref={popupRef}>
      <div className={`viewPost-popup `}>
        <main className="container" style={{ display: "flex" }}>
          <div
            className="upload-image-container"
            style={{
              // width: "436px",
              // width: "460px",
              // height: "500px",
              aspectRatio: postDetails?.media[0]?.aspectRatio,
            }}
            ref={sliderWrapperRef}
            onDoubleClick={handleDoubleClick}
          >
            <SliderPopup
              sliderWrapperRef={sliderWrapperRef}
              post={postDetails}
              imagesList={imagesList}
              mediaIndexFinal={mediaIndexFinal}
              setMediaIndexFinal={setMediaIndexFinal}
            />

            {/* show post length */}
            {imagesList?.length > 1 && (
              <div className="image-number">
                <span className="number">
                  {`${mediaIndexFinal + 1}/${imagesList?.length}`}
                </span>
              </div>
            )}

            {showAnimation && (
              <div className="doubleTepAnimation">
                <img src={BigTep} alt="Teps" />
              </div>
            )}
          </div>
          <div className="filters">
            {/* HEADER */}
            <div className="header">
              <div className="heading">
                <h1 className="title">View post</h1>
              </div>
              <div
                className="btn btn-back"
                onClick={HendlePostClose}
                tabIndex="0"
                aria-label="Back"
                role="button"
              >
                <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
                  <title>Close</title>
                  <g id="Frame 2595">
                    <path
                      id="Shape"
                      d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                      fill={
                        theme === "Light"
                          ? "#1891a2"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#1891a2"
                          : "#ffffff"
                      }
                    />
                  </g>
                </svg>
              </div>
            </div>
            {/* COMMENTVIEW */}
            <CommentView
              postData={postDetails}
              setPassPostData={setPassPostData}
              setIsCommentOrReply={setIsCommentOrReply}
              isCommentId={isCommentId}
              setIsCommentId={setIsCommentId}
              isReplyId={isReplyId}
              setIsReplyId={setIsReplyId}
              setIsUserPost={setIsUserPost}
              isCommentDelete={isCommentDelete}
              setIsCommentDelete={setIsCommentDelete}
              isReplyDelete={isReplyDelete}
              setIsReplyDelete={setIsReplyDelete}
              goToSlide={goToSlide}
              setSelectedMenu={setSelectedMenu}
              toggleSelfLike={toggleSelfLike}
            />
          </div>
        </main>
      </div>
      {/* COMMENTACTION */}
      {isCommentOrReply && (
        <CommentAction
          passPostData={passPostData}
          setIsCommentOrReply={setIsCommentOrReply}
          isCommentId={isCommentId}
          setIsCommentId={setIsCommentId}
          isReplyId={isReplyId}
          setIsReplyId={setIsReplyId}
          isUserPost={isUserPost}
          setIsCommentDelete={setIsCommentDelete}
          setIsReplyDelete={setIsReplyDelete}
          setReportMenu={setReportMenu}
          setReportCommentAndReply={setReportCommentAndReply}
          setIsCommentAndReplyId={setIsCommentAndReplyId}
        />
      )}
      {/* REPORTPOST */}
      {reportMenu && (
        <ReportPost
          post={passPostData}
          reportMenu={reportMenu}
          setReportMenu={setReportMenu}
          isCommentAndReplyId={isCommentAndReplyId}
          setIsCommentAndReplyId={setIsCommentAndReplyId}
          reportCommentAndReply={reportCommentAndReply}
          setReportCommentAndReply={setReportCommentAndReply}
        />
      )}
    </div>
  );
}

export default ViewPost;
