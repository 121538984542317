import { React, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAnimationDirection, setSelectedSetting } from "../../Redux/actions";
import "../../assets/css/notificationSettings.scss";

function Notifications() {
  const dispatch = useDispatch();

  const [isMessageNotificationChecked, setIsMessageNotificationChecked] =
    useState(false);
  const [isNotificationPopup, setIsNotificationPopup] = useState(false);
  const [isAllownotificationpopup, setIsAllowNotificationPopup] =
    useState(false);
  const [isPreviewChecked, setIsPreviewChecked] = useState(false);
  const [isReactionPreviewChecked, setIsReactionPreviewChecked] =
    useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  const handleBackButton = () => {
    dispatch(setAnimationDirection("right"));
    dispatch(setSelectedSetting(""));
    setIsMounted(false);
  };

  useEffect(() => {
    const element = document.querySelector(".notifications-settings");

    const handleAnimationEnd = () => {
      if (!isMounted) {
      }
    };

    if (isMounted) {
      element.classList.add("open");
      element.classList.remove("close");
      element.removeEventListener("animationend", handleAnimationEnd);
    } else {
      element.classList.add("close");
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      element.classList.remove("open", "close");
      element.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [isMounted]);

  const handleMessageNotificationChange = () => {
    setIsMessageNotificationChecked(!isMessageNotificationChecked);
    // Reset the state of other checkboxes when message-notifications is unchecked
    if (isMessageNotificationChecked) {
      setIsPreviewChecked(false);
      setIsReactionPreviewChecked(false);
    }
  };
  const handlePreviewChange = () => {
    if (isMessageNotificationChecked) {
      setIsPreviewChecked(!isPreviewChecked);
    }
  };
  const handleReactionPreviewChange = () => {
    if (isMessageNotificationChecked != true) {
      setIsNotificationPopup(true);
    }
    if (isMessageNotificationChecked != false) {
      setIsReactionPreviewChecked(!isReactionPreviewChecked);
      setIsAllowNotificationPopup(true);
    }
  };
  const handleCheckmarkClick = () => {
    // Toggle the isChecked state
    setIsChecked(!isChecked);
  };
  const cancelfun = () => {
    setIsNotificationPopup(false);
  };
  return (
    <>
      <div className="notifications-settings">
        <div className="notifications-settings-wrapper">
          <header className="header">
            <div className="back">
              <div
                className="btn btn-back"
                aria-label="Back"
                role="button"
                tabIndex="0"
                onClick={() => handleBackButton()}
              >
                <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                  <title>Back</title>
                  <g id="Group 18522">
                    <g id="Group 18522_2">
                      <path
                        id="Vector"
                        d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                        stroke="white"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="text">Notifications</span>
            </div>
          </header>
          <div className="messages">
            <div className="messages-heading">
              <span className="heading">Messages</span>
            </div>
            <div
              className="notifications-setting message-notifications"
              onClick={handleMessageNotificationChange}
            >
              <div className="info">
                <span className="title">Message notifications</span>
                <p className="sub-title">Show notifications for new messages</p>
              </div>
              <div className="group-check">
                <input
                  type="checkbox"
                  id="message-check"
                  aria-label="Check this box"
                  checked={isMessageNotificationChecked}
                />
                <span
                  className="checkmark"
                  onClick={handleMessageNotificationChange}
                />
              </div>
            </div>
            <div
              className={`notifications-setting show-previews ${
                isMessageNotificationChecked ? "" : "disabled"
              }`}
              style={{ opacity: isMessageNotificationChecked ? 1 : 0.2 }}
              onClick={handlePreviewChange}
            >
              <label className="title" htmlFor="preview-check">
                Show previews
              </label>
              <div className="group-check">
                <input
                  type="checkbox"
                  id="preview-check"
                  aria-label="Check this box"
                  checked={isPreviewChecked}
                  // onChange={handlePreviewChange}
                  // disabled={!isMessageNotificationChecked}
                />
                <span className="checkmark" onClick={handlePreviewChange} />
              </div>
            </div>
            <div
              className={`notifications-setting show-reaction-previews ${
                isMessageNotificationChecked ? "" : "disabled"
              }`}
              style={{ opacity: isMessageNotificationChecked ? 1 : 0.2 }}
              onClick={handleReactionPreviewChange}
            >
              <label className="title" htmlFor="reactionPreview-check">
                Show reaction previews
              </label>
              <div className="group-check">
                <input
                  type="checkbox"
                  id="reactionPreview-check"
                  aria-label="Check this box"
                  checked={isReactionPreviewChecked}
                  // onChange={handleReactionPreviewChange}
                  // disabled={!isMessageNotificationChecked}
                />
                <span
                  className="checkmark"
                  onClick={handleReactionPreviewChange}
                />
              </div>
            </div>
          </div>
          <div className="sounds">
            <div
              className="notifications-setting background-sync"
              onClick={handleCheckmarkClick}
            >
              <div className="info">
                <span className="title">Background sync</span>
                <p className="sub-title">
                  Get faster performance by syncing message in the background.
                </p>
              </div>
              <div className="group-check">
                <input
                  type="checkbox"
                  id="backgroundSync-check"
                  aria-label="Check this box"
                  checked={isChecked}
                  // onChange={handleReactionPreviewChange}
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
            </div>
            <div
              className="notifications-setting sound"
              onClick={handleCheckmarkClick}
            >
              <div className="info">
                <span className="title">Sounds</span>
                <p className="sub-title">Paly sound for incoming messages</p>
              </div>
              <div className="group-check">
                <input
                  type="checkbox"
                  id="sounds-check"
                  aria-label="Check this box"
                  checked={isChecked}
                />
                <span className="checkmark" onClick={handleCheckmarkClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isNotificationPopup && (
        <div className="themePopup">
          <div className="dialog">
            <div className="dialog-info">
              <h4>Turn off all reactions notifications for: </h4>
            </div>

            <div className="dialog-info-wrapper">
              <div className="light-wrapper">
                <div className="group-check">
                  <input
                    type="radio"
                    aria-label="Check this box"
                    checked={isChecked}
                    id="lighMode"
                  />
                  <span className="checkmark" onClick={handleCheckmarkClick} />
                </div>
                <label
                  className="description"
                  htmlFor="lighMode"
                  //   onClick={handleCheckmarkClick}
                >
                  8 Hours
                </label>
              </div>

              <div className="dark-wrapper">
                <div className="group-check">
                  <input
                    type="radio"
                    aria-label="Check this box"
                    checked={isChecked}
                    id="darkMode"
                  />
                  <span className="checkmark" onClick={handleCheckmarkClick} />
                </div>
                <label
                  className="description"
                  htmlFor="darkMode"
                  //   onClick={handleCheckmarkClick}
                >
                  1 Week
                </label>
              </div>

              <div className="systemDefault-wrapper">
                <div className="group-check">
                  <input
                    type="radio"
                    aria-label="Check this box"
                    checked={isChecked}
                    id="systemDefault"
                  />
                  <span className="checkmark" onClick={handleCheckmarkClick} />
                </div>
                <label
                  className="description"
                  htmlFor="systemDefault"
                  //   onClick={handleCheckmarkClick}
                >
                  Always
                </label>
              </div>
            </div>
            <div className="cta">
              <button className="btn cancel" onClick={() => cancelfun()}>
                Cancel
              </button>
              <button className="btn for-me active">Ok</button>
            </div>
          </div>
        </div>
      )}
      {isAllownotificationpopup && (
        <div className="allowCamera">
          <div className="dialog">
            <div className="dialog-info">
              <div className="allow-image">
                <svg width="40" height="40" viewBox="0 0 46 46" fill="none">
                  <rect
                    width="46"
                    height="46"
                    rx="23"
                    transform="matrix(-1 0 0 1 46 0)"
                    fill="#1891A2"
                  />
                  <path
                    d="M31.75 31.75L15.5 15.5M15.5 15.5V31.1M15.5 15.5H31.1"
                    stroke="white"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h4>Allow Notifications</h4>
            </div>
            <p className="description space">
              To get notifications for new messages, click “Allow” above.
            </p>

            <button className="btn btn-allow">Ok, got it</button>
          </div>
        </div>
      )}
    </>
  );
}
export default Notifications;
