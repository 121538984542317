import React, { useState, useEffect, useRef } from "react";
import axiosRequest from "../Middleware/api.js";
import MainchatApp from "../pages/MainchatApp.js";
import { Strophe, $msg, $pres, $iq } from "strophe.js";
import { useSelector, useDispatch } from "react-redux";
import {
  getDate,
  RUserModel,
  RInboxModel,
  splitUserID,
  RContactModel,
  RMessageMediaModel,
  RMessageContactModel,
  getDeleteMessageType,
  RMessageLocationModel,
  joinUserID,
  XmppContactObject,
  XmppMediaObject,
} from "../Utils/helpers.js";
import {
  SENDER_TEXT,
  OPENFIRE_IP,
  SENDER_AUDIO,
  SENDER_IMAGE,
  SENDER_VIDEO,
  RECEIVER_TEXT,
  RECEIVER_IMAGE,
  RECEIVER_VIDEO,
  SENDER_CONTACT,
  RECEIVER_AUDIO,
  SENDER_DOCUMENT,
  SENDER_LOCATION,
  RECEIVER_CONTACT,
  RECEIVER_DOCUMENT,
  RECEIVER_LOCATION,
  SENDER_TEXT_REPLY,
  RECEIVER_TEXT_REPLY,
  OPENFIRE_CONNECTION,
  REALM_DB_USER_PASSWORD,
} from "../Utils/enums.js";

function OpenFire({ isOnline }) {
  const dispatch = useDispatch();
  const connectionRef = useRef(null);

  const { v4 } = require("uuid");
  const { v4: uuidv4 } = require("uuid");
  const uuid = v4();
  const userId = localStorage.getItem("user_id");
  const ownerId = localStorage.getItem("owner_id");

  const Messages = useSelector((state) => state.messages);
  const Contacts = useSelector((state) => state.savedContacts);
  const selfContact = useSelector((state) => state.selfContact);
  const chatBoxContact = useSelector((state) => state.chatBoxContact);
  const inboxContacts = useSelector((state) => state.inboxContacts);

  const [connected, setconnected] = useState(false);

  // useEffect(() => {
  //   // get device id
  //   let deviceId = localStorage.getItem("deviceId");
  //   if (!deviceId) {
  //     localStorage.setItem("deviceId", uuid);
  //   }
  //   connectionRef.current = new Strophe.Connection(OPENFIRE_CONNECTION);
  //   connectionRef.current.connect(
  //     `${selfContact?._id}@${OPENFIRE_IP}/Web-${deviceId}`,
  //     REALM_DB_USER_PASSWORD,
  //     OnConnectionStatus
  //   );
  //   function OnConnectionStatus(status) {
  //     const statusMessages = {
  //       0: "Connection status: ERROR.",
  //       1: "Connection status: CONNECTING.",
  //       2: "Connection status: CONNFAIL.",
  //       3: "Connection status: AUTHENTICATING.",
  //       4: "Connection status: AUTHFAIL.",
  //       5: "Connection status: CONNECTED.",
  //       6: "Connection status: DISCONNECTED.",
  //       7: "Connection status: DISCONNECTING.",
  //       8: "Connection status: ATTACHED.",
  //       9: "Connection status: REDIRECT.",
  //       10: "Connection status: CONNTIMEOUT.",
  //       11: "Connection status: BINDREQUIRED.",
  //       12: "Connection status: ATTACHFAIL.",
  //     };
  //     if (statusMessages.hasOwnProperty(status)) {
  //       if (status === 5) {
  //         console.log(statusMessages[status]);
  //         console.log(connectionRef.current.jid);
  //         setconnected(true);
  //         OnConnected();
  //       }
  //     } else {
  //       console.log("Unknown status code:", status);
  //     }
  //   }
  // }, []);

  function OnConnected() {
    SendPresencetoServer();
    SendPresencetoAllFriend();
    ResendMessages();
    connectionRef.current.addHandler(GetPresencetoAllFriend, null, "presence");
    connectionRef.current.addHandler(
      onMessageReceived,
      null,
      "message",
      null,
      null,
      null
    );
  }

  function SendPresencetoServer() {
    setInterval(() => {
      connectionRef.current.sendIQ(
        $iq({
          to: OPENFIRE_CONNECTION,
          from: connectionRef.current.jid,
          type: "get",
        }).c("ping", { xmlns: "urn:xmpp:ping" })
      );
    }, 2000);
    connectionRef.current.send($pres());
    connectionRef.current.sendPresence($pres().c("status").t("online").up());
  }

  function SendPresencetoAllFriend() {
    Contacts.forEach((e) => {
      const _id = splitUserID(e?.User?._id);
      const jid = _id + "@" + OPENFIRE_IP;
      var iq = $iq({ type: "set" })
        .c("query", { xmlns: "jabber:iq:roster" })
        .c("item", { jid: jid, name: "", subscription: "" });
      connectionRef.current.sendIQ(iq);
      var subscribe = $pres({ to: jid, type: "subscribe" });
      connectionRef.current.send(subscribe);
    });
  }

  function GetPresencetoAllFriend(stanza) {
    const sFrom = stanza.getAttribute("from");
    const sType = stanza.getAttribute("type");
    const from = sFrom.split("@" + OPENFIRE_IP);
    const fromId = from[0];

    Contacts.map((contact) => {
      const _id = splitUserID(contact?.user?._id);
      if (fromId === _id) {
        if (sType === "unavailable" || sType === "error") {
          console.log(`${fromId} is offline`);

          // Realm.write(() => {
          //   contact.user.onlineStatus = 0;
          // });
        } else {
          console.log(`${fromId} is online`);

          // Realm.write(() => {
          //   contact.user.onlineStatus = 1;
          // });
        }
      }
      return null;
    });
    return true;
  }

  const ResendMessages = () => {
    const readyToSendMessages = Messages?.filter((message) => {
      return message?.status === "ReadyToSent";
    });
    readyToSendMessages.forEach((message) =>
      sendMessage(message, message.message)
    );
  };

  const contactMessage = async (msgObj, xmlMessage) => {
    const contacts = xmlMessage?.contact;
    var contactsIds = [];
    for (let i = 0; i < contacts.length; i++) {
      const RMessageContactObj = RMessageContactModel(
        ownerId,
        contacts[i].contactName,
        contacts[i].contactNumber,
        contacts[i].countryCode,
        contacts[i]?.profilePic,
        contacts[i]?.contactUserId,
        msgObj?._id
      );
      contactsIds.push(RMessageContactObj);
    }
    const contactMessage = {
      ...msgObj,
      contact: contactsIds,
      type: RECEIVER_CONTACT,
    };
    await createMessageAtRealm(contactMessage, xmlMessage);
  };

  const mediaMessage = (msgObj, xmlMessage) => {
    const media = xmlMessage?.media;

    if (msgObj?.type === RECEIVER_VIDEO || msgObj?.type === RECEIVER_IMAGE) {
      const videoUrl = media?.mediaUrl;
      const videoFileName = videoUrl.substring(videoUrl.lastIndexOf("/") + 1);
      var thumbnailFileName = videoFileName.replace(/\.(mov|mp4)/i, ".jpg");
    }

    const RMessageMediaObj = RMessageMediaModel(
      ownerId,
      media?.fileSize,
      media?.mediaLength,
      media?.mediaUrl,
      msgObj?._id,
      thumbnailFileName
    );

    const messageObject = {
      ...msgObj,
      media: RMessageMediaObj,
    };

    createMessageAtRealm(messageObject, xmlMessage);
  };

  const locationMessage = async (msgObj, xmlMessage) => {
    const RMessageLocationObj = RMessageLocationModel(
      msgObj?._id,
      ownerId,
      xmlMessage?.location
    );

    const locationMessage = {
      ...msgObj,
      location: RMessageLocationObj,
    };

    await createMessageAtRealm(locationMessage, xmlMessage);
  };
  const createMessageAtRealm = async (msgObj, xmlMessage) => {
    const _id = joinUserID(xmlMessage?.userId, ownerId) + "_user";
    const inboxChatAvailable = inboxContacts?.find(
      (inbox) => inbox?._id === _id
    );
    if (inboxChatAvailable) {
      if (chatBoxContact.length === 0) {
        // addMessage(
        //   msgObj,
        //   inboxChatAvailable,
        //   inboxChatAvailable.unreadCount + 1
        // );
      } else {
        // addMessage(msgObj, inboxChatAvailable, 0);
      }
    } else {
      const contact = Contacts?.find(
        (contact) => joinUserID(xmlMessage?.phone, ownerId) === contact?._id
      );
      if (contact) {
        if (contact?.user) {
          const inboxObj = RInboxModel(_id, ownerId, contact, msgObj);
          // addInboxContact(inboxObj);
        } else {
          const userObj = RUserModel(ownerId, xmlMessage);
          // Realm.write(() => {
          //   contact.user = userObj;
          // });
          const inboxObj = RInboxModel(_id, ownerId, contact, msgObj);
          // addInboxContact(inboxObj);
        }
      } else {
        const userObj = RUserModel(ownerId, xmlMessage);
        const RContactObj = RContactModel(
          ownerId,
          xmlMessage?.phone,
          xmlMessage?.countryCode,
          userObj
        );
        const inboxObj = RInboxModel(_id, ownerId, RContactObj, msgObj);
        // addInboxContact(inboxObj);
      }
    }
  };

  const updateMessageUnreadCountAtRealm = async (msgObj, readStatus) => {
    return;
    const _id = joinUserID(msgObj?.senderID, ownerId) + "_user";
    const inboxChatAvailable = inboxContacts?.find(
      (inbox) => inbox?._id === _id
    );
    if (inboxChatAvailable) {
      const unreadCount = readStatus ? 0 : inboxChatAvailable?.unreadCount + 1;
      // Realm.write(() => {
      //   inboxChatAvailable.unreadCount = unreadCount;
      // });
    }
  };

  async function onMessageReceived(message) {
    const body = message.getElementsByTagName("body")[0];
    const receipt = message.getElementsByTagName("receipt")[0];
    const deleteMessage = message.getElementsByTagName("delete")[0];
    const message_Id = message.getAttribute("id");
    const sfrom = message.getAttribute("from");
    const from = sfrom.split("@" + OPENFIRE_IP);
    const fromId = from[0];
    const composingStatus = message.getElementsByTagName("composing")[0];
    const pausedStatus = message.getElementsByTagName("paused")[0];
    const sto = message.getAttribute("to");
    const to = sto.split("@" + OPENFIRE_IP);
    const toId = to[0];
    const d = new Date();
    const n = d.getTime() / 1000;

    if (composingStatus) {
      Contacts.map((contact) => {
        if (fromId === splitUserID(contact?.user?._id)) {
          // Realm.write(() => {
          //   contact.user.typingStatus = "composing";
          // });
        }
        return null;
      });
    }

    if (pausedStatus) {
      Contacts.map((contact) => {
        if (fromId === splitUserID(contact?.user?._id)) {
          // Realm.write(() => {
          //   contact.user.typingStatus = "paused";
          // });
        }
        return null;
      });
    }

    if (body) {
      const messageBody = JSON.parse(body.innerHTML);
      const date = getDate(messageBody?.timeStamp * 1000);

      const senderContact = Contacts.find(
        (contact) => contact?._id === joinUserID(messageBody?.phone, ownerId)
      );

      const commonFields = {
        from: fromId,
        to: toId,
        _id: joinUserID(message_Id, ownerId),
        owner_id: ownerId,
        date: date,
        message: messageBody.message,
        messageParentType: messageBody.userType,
        sender: senderContact,
        senderID: fromId,
        status: "Received",
        timestamp: messageBody.timeStamp,
        type: messageBody.messageType,
        groupMemberActionUserId: null,
        isMessageDeleted: false,
        isStarMessage: false,
        messageReceivedTimestamp: n,
        forwardedIds: "",
      };
      // const commonFields = {
      //   from: sfrom,
      //   to: sto,
      //   phone: messageBody.phone,
      //   timeStamp: messageBody.timeStamp,
      //   profilePic: messageBody.profilePic,
      //   countryCode: messageBody.countryCode,
      //   name: messageBody.name,
      //   messageId: message_Id,
      //   message: messageBody.message,
      //   userId: messageBody.userId,
      // };
      switch (messageBody.messageType) {
        case SENDER_TEXT:
          const textMessage = {
            ...commonFields,
            type: RECEIVER_TEXT,
          };
          await createMessageAtRealm(textMessage, messageBody);
          return textMessage;
        case SENDER_CONTACT:
          contactMessage(commonFields, messageBody);
          return;
        case SENDER_IMAGE:
          const imageMessageReceiver = {
            ...commonFields,
            type: RECEIVER_IMAGE,
            contact: [],
            userType: messageBody.userType,
          };
          mediaMessage(imageMessageReceiver, messageBody);
          return imageMessageReceiver;
        case SENDER_VIDEO:
          const videoMessageReceiver = {
            ...commonFields,
            media: {
              fileSize: messageBody.media.fileSize,
              mediaUrl: messageBody.media.mediaUrl,
              mediaLength: messageBody.media.mediaLength,
            },
            contact: [],
            userType: messageBody.userType,
            type: RECEIVER_VIDEO,
          };
          mediaMessage(videoMessageReceiver, messageBody);
          return videoMessageReceiver;
        case SENDER_DOCUMENT:
          const documentMessageReceiver = {
            ...commonFields,
            media: {
              fileSize: messageBody.media.fileSize,
              mediaUrl: messageBody.media.mediaUrl,
              mediaLength: messageBody.media.mediaLength,
            },
            contact: [],
            userType: messageBody.userType,
            type: RECEIVER_DOCUMENT,
          };
          mediaMessage(documentMessageReceiver, messageBody);
          return documentMessageReceiver;
        case SENDER_AUDIO:
          const audioMessageReceiver = {
            ...commonFields,
            media: {
              fileSize: messageBody.media.fileSize,
              mediaUrl: messageBody.media.mediaUrl,
              mediaLength: messageBody.media.mediaLength,
            },
            contact: [],
            userType: messageBody.userType,
            type: RECEIVER_AUDIO,
          };
          mediaMessage(audioMessageReceiver, messageBody);
          return audioMessageReceiver;
        case SENDER_TEXT_REPLY:
          const replyTextMessage = {
            ...commonFields,
            contact: [],
            userType: messageBody.userType,
            type: RECEIVER_TEXT_REPLY,
            messageReferance: messageBody?.replyMessageId,
          };
          await createMessageAtRealm(replyTextMessage, messageBody);
          return replyTextMessage;
        case SENDER_LOCATION:
          const locationMessageReceiver = {
            ...commonFields,
            location: {
              latitude: messageBody.location.latitude,
              longitude: messageBody.location.longitude,
              mediaUrl: messageBody.location.mediaUrl,
              address: messageBody.location.address,
            },
            contact: [],
            userType: messageBody.userType,
            type: RECEIVER_LOCATION,
          };
          await locationMessage(locationMessageReceiver, messageBody);
          return locationMessageReceiver;
        default:
      }
      if (receipt) {
        await UpdateReceiptForMessages(messageBody);
      }
      if (deleteMessage) {
        await onDeleteForEveryone(messageBody?.messageId);
      }
    }
    return true;
  }

  function sendReceiptForMessages() {
    const deliverdMessages = Messages?.filter((message) => {
      return message?.status === "Delivered";
    });
    const receivedMessages = Messages?.filter((message) => {
      return message?.status === "Received";
    });
    receivedMessages.forEach((e) => {
      const messageIdToCheck = splitUserID(e?._id);
      if (
        e.type === RECEIVER_TEXT ||
        e.type === RECEIVER_LOCATION ||
        e.type === RECEIVER_CONTACT ||
        e.type === RECEIVER_DOCUMENT ||
        e.type === RECEIVER_VIDEO ||
        e.type === RECEIVER_IMAGE ||
        e.type === RECEIVER_TEXT_REPLY
      ) {
        const sto = e.to + "@" + OPENFIRE_IP;
        const sfrom = e.from + "@" + OPENFIRE_IP;
        const d = new Date();
        const n = d.getTime() / 1000;
        const messageIds = uuidv4();
        const messageDataReceipt = {
          messageId: messageIdToCheck,
          timeStamp: n,
          groupID: "0",
          type: "Delivered",
        };
        const receiptMessage = $msg({
          to: sfrom,
          from: sto,
          id: messageIds,
          type: "chat",
        })
          .c("body")
          .t(JSON.stringify(messageDataReceipt))
          .up()
          .c("receipt", { xmlns: "urn:xmpp:receipt" });
        connectionRef.current.send(receiptMessage);
        const message = receiptMessage?.nodeTree;
        const body = message.getElementsByTagName("body")[0];
        if (body) {
          const receipt = message.getElementsByTagName("receipt")[0];
          const messageBody = JSON.parse(body.innerHTML);
          if (receipt) {
            UpdateReceiptForMessages(messageBody);
            if (Array.isArray(chatBoxContact)) {
              updateMessageUnreadCountAtRealm(e, false);
            } else {
              updateMessageUnreadCountAtRealm(e, true);
            }
          }
        }
      }
    });

    if (!Array.isArray(chatBoxContact)) {
      deliverdMessages.forEach((e) => {
        if (
          e.from === splitUserID(chatBoxContact?.contact?.user?._id) &&
          e.to === userId
        ) {
          const sto = e.to + "@" + OPENFIRE_IP;
          const sfrom = e.from + "@" + OPENFIRE_IP;
          const d = new Date();
          const n = d.getTime() / 1000;
          const messageIds = uuidv4();
          const messageDataReceipt = {
            messageId: splitUserID(e?._id),
            timeStamp: n,
            groupID: "0",
            type: "Read",
          };

          const receiptMessage = $msg({
            to: sfrom,
            from: sto,
            id: messageIds,
            type: "chat",
          })
            .c("body")
            .t(JSON.stringify(messageDataReceipt))
            .up()
            .c("receipt", { xmlns: "urn:xmpp:receipt" });

          connectionRef.current.send(receiptMessage);

          const message = receiptMessage?.nodeTree;
          const body = message.getElementsByTagName("body")[0];
          if (body) {
            const receipt = message.getElementsByTagName("receipt")[0];
            const messageBody = JSON.parse(body.innerHTML);
            if (receipt) {
              UpdateReceiptForMessages(messageBody);
              updateMessageUnreadCountAtRealm(e, true);
            }
          }
        }
      });
    }
  }

  function sendMessage(msgObj, messageContent, ids) {
    // to: splitUserID(chatBoxContact?.contact?.user?._id) + "@" + OPENFIRE_IP,
    const xmlMessage = $msg({
      to: msgObj?.to + "@" + OPENFIRE_IP,
      type: "chat",
      id: splitUserID(msgObj?._id),
    });

    const commonMessageData = {
      to: msgObj?.to,
      from: connectionRef.current.jid,
      userId: msgObj?.from,
      phone: selfContact?.phone,
      timeStamp: msgObj?.timestamp,
      messageId: splitUserID(msgObj?._id),
      message: msgObj?.message,
      profilePic: selfContact?.profile,
      countryCode: selfContact?.countryCode,
      userType: msgObj?.messageParentType,
      messageType: msgObj?.type,
      name: selfContact?.name,
      status: msgObj?.status,
    };

    if (typeof messageContent === "boolean") {
      if (messageContent === true) {
        const composingElement = xmlMessage
          .c("composing", {
            xmlns: "http://jabber.org/protocol/chatstates",
          })
          .up();
        commonMessageData.messageType = SENDER_TEXT;
        connectionRef.current.send(composingElement);
      } else if (messageContent === false) {
        const pausedElement = xmlMessage
          .c("paused", {
            xmlns: "http://jabber.org/protocol/chatstates",
          })
          .up();
        commonMessageData.messageType = SENDER_TEXT;
        connectionRef.current.send(pausedElement);
      }
    } else if (typeof messageContent === "string") {
      const getMessageData = () => {
        switch (msgObj?.type) {
          case SENDER_TEXT:
            return commonMessageData;
          case SENDER_CONTACT:
            const contactMessage = {
              ...commonMessageData,
              contact: XmppContactObject(msgObj?.contact),
            };
            return contactMessage;
          case SENDER_IMAGE:
            const imageMessage = {
              ...commonMessageData,
              media: XmppMediaObject(msgObj?.media),
            };
            return imageMessage;
          case SENDER_VIDEO:
            const videoMessage = {
              ...commonMessageData,
              media: XmppMediaObject(msgObj?.media),
            };
            return videoMessage;
          case SENDER_DOCUMENT:
            const documentMessage = {
              ...commonMessageData,
              media: XmppMediaObject(msgObj?.media),
            };
            return documentMessage;
          case SENDER_AUDIO:
            const audioMessage = {
              ...commonMessageData,
              media: XmppMediaObject(msgObj?.media),
            };
            return audioMessage;
          case SENDER_TEXT_REPLY:
            const replyTextMessage = {
              ...commonMessageData,
              replyMessageId: ids,
            };
            return replyTextMessage;
          default:
            return null;
        }
      };
      const messageReceipt = xmlMessage
        .c("body")
        .t(JSON.stringify(getMessageData()))
        .up()
        .c("paused", {
          xmlns: "http://jabber.org/protocol/chatstates",
        })
        .up();
      connectionRef.current.send(xmlMessage);
      const message = messageReceipt?.nodeTree;
      const body = message.getElementsByTagName("body")[0];
      const messageBody = JSON.parse(body.innerHTML);
      if (body) {
        sendNotification(messageBody);
      }
    }
  }

  const sendNotification = async (message) => {
    const data = {
      conversationId: splitUserID(chatBoxContact?.contact?.user?._id),
      message: message?.message,
      url: `chat/callback/${message?.userId}/${message?.messageId}/0`,
      type: "user",
      stanzaId: message?.messageId,
      stanza: JSON.stringify(message),
      MessageType: message?.messageType,
    };

    axiosRequest
      .post(`notification/message`, data)
      .then((res) => {})
      .catch((error) => {});
  };

  useEffect(() => {
    if (connectionRef?.current?.connected) {
      sendReceiptForMessages();
    }
  }, [Messages, connectionRef?.current?.connected]);

  async function UpdateReceiptForMessages(messageBody) {
    const messageIds = messageBody.messageId.split(",");
    Messages.map((message) => {
      if (messageBody?.type === "Read") {
        messageIds.forEach((messageId) => {
          const messageIdToUpdate = [joinUserID(messageId, ownerId)];
          if (messageIdToUpdate.includes(message?._id)) {
            // Realm.write(() => {
            //   message.status = messageBody.type;
            // });
          }
        });
        return null;
      } else {
        const messageIdToUpdate = [joinUserID(messageBody?.messageId, ownerId)];
        if (messageIdToUpdate.includes(message?._id)) {
          // Realm.write(() => {
          //   message.status = messageBody.type;
          // });
        }
        return null;
      }
    });
  }

  const onDeleteForEveryone = async (id) => {
    const _id = joinUserID(id, ownerId);
    const message = Messages.find((msg) => msg?._id === _id);
    const type = getDeleteMessageType(message);
    // Realm.write(() => {
    //   message.type = type;
    // });
  };

  function deleteMessage(message) {
    const messageIdToDelete = splitUserID(message?._id);
    const sto = message?.to + "@" + OPENFIRE_IP;
    const messageIds = uuidv4();
    const messageDataReceipt = {
      messageId: messageIdToDelete,
      groupID: "0",
    };
    const receiptMessage = $msg({
      to: sto,
      id: messageIds,
      type: "chat",
    })
      .c("body")
      .t(JSON.stringify(messageDataReceipt))
      .up()
      .c("delete", { xmlns: "urn:xmpp:delete" });
    connectionRef.current.send(receiptMessage);
  }

  // update to my contacts to show I am offline.
  function OnDisconnected() {}

  return (
    <>
      <MainchatApp sendMessage={sendMessage} deleteMessage={deleteMessage} />
    </>
  );
}

export default OpenFire;
