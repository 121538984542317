import React, { createContext, useState, useEffect } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const Socket = io(process.env.REACT_APP_SOCKET_URL);
    setSocket(Socket);

    return () => {
      Socket.disconnect();
      setSocket(null);
      console.log("disConnected!", Socket);
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default SocketContext;
