import { React, useContext, useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { getSessionId } from "../../Utils/helpers";
import SocketContext from "../../Contexts/SocketContext";
import {
  resetStore,
  setSelectedSetting,
  setAuthenticateSync,
} from "../../Redux/actions";
import "../../assets/css/contactUs.scss";

function LogoutPopup() {
  const dispatch = useDispatch();

  const token = Cookies.get("token");

  const popupRef = useRef(null);

  const socket = useContext(SocketContext);

  const [sessionId, setSessionId] = useState("");
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const id = getSessionId();
    setSessionId(id);
  }, []);

  const logout = () => {
    socket.emit("userLogout", {
      session: sessionId,
      Authorization: `Bearer ${token}`,
    });
    socket.on("logOutSuccess", logoutSuccess);
  };

  const logoutSuccess = (data) => {
    if (data === getSessionId()) {
      Cookies.remove("token");
      Cookies.remove("user_id");
      Cookies.remove("sessionId");
      localStorage.clear();
      dispatch(resetStore());
      dispatch(setAuthenticateSync("login"));
      window.location.reload();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(setSelectedSetting(""));
    }, 250);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current === event.target) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`contactUs-popup ${isClosing ? "closing" : ""}`}
      ref={popupRef}
    >
      <div className={`dialog ${isClosing ? "closing" : ""}`}>
        <div className="dialog-info">
          <h4>Log out?</h4>
        </div>
        <p className="contactUs-sub-info">Are you sure you want to log out?</p>
        <div className="contactUs-cta">
          <button className="btn btn-cancel" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn btn-send active" onClick={() => logout()}>
            Log out
          </button>
        </div>
      </div>
    </div>
  );
}

export default LogoutPopup;
