import React from "react";
import { useSelector } from "react-redux";
import "../../assets/css/networkError.scss";

function NetworkError() {
  const theme = useSelector((state) => state.theme);

  return (
    <div className="networkError">
      <div className="networkError-wrapper">
        <div className="networkError-image">
          <svg width="26" height="26" viewBox="0 0 30 30" fill="none">
            <path
              d="M14.4395 25.8867C14.1992 25.6406 1.67188 12.0352 0.365234 10.582C0.0898438 10.2832 0.0546875 10.2129 0.0546875 9.99023C0.0546875 9.74414 0.0722656 9.71484 0.541016 9.29297C2.52148 7.47656 5.19922 5.90625 7.89453 4.97461C10.2207 4.17188 12.4473 3.80273 14.9961 3.80273C17.0996 3.80273 18.8164 4.03125 20.7852 4.57031C23.9316 5.42578 27.1309 7.17188 29.457 9.29297C29.9199 9.71484 29.9375 9.74414 29.9375 9.99023C29.9375 10.2129 29.9023 10.2832 29.6328 10.582C28.3203 12.0352 15.793 25.6406 15.5527 25.8867C15.2773 26.1562 15.2129 26.1914 14.9961 26.1914C14.7793 26.1914 14.7148 26.1562 14.4395 25.8867Z"
              fill="white"
            />
            <path
              d="M14.1905 21.5135C13.7803 21.3292 13.49 20.8268 13.5361 20.3844C13.596 19.7806 14.0707 19.3474 14.6652 19.3428C15.1031 19.3428 15.4487 19.5502 15.6653 19.9465C15.9741 20.5134 15.7391 21.2324 15.1584 21.5043C14.8957 21.6288 14.4486 21.6334 14.1905 21.5135Z"
              fill="#1891A2"
            />
            <path
              d="M14.2171 18.1858C13.8484 18.0199 13.5672 17.6789 13.5073 17.3148C13.475 17.1258 13.2031 10.3416 13.2031 9.73324C13.2031 9.2447 13.3137 8.94974 13.5903 8.70547C13.8852 8.44737 14.0419 8.40589 14.6549 8.40129C15.1711 8.39668 15.2264 8.40589 15.4707 8.52572C15.7887 8.68242 15.9915 8.91287 16.0883 9.22627C16.1574 9.44288 16.1528 9.71941 16.0191 13.1899C15.9408 15.2454 15.867 17.0336 15.8532 17.1673C15.7702 17.9923 14.936 18.5131 14.2171 18.1858Z"
              fill="#1891A2"
            />
          </svg>
        </div>

        <div className="networkError-info">
          <div className="networkError-info-wrapper">
            <div className="networkError-title">
              <span className="title">Computer not connected</span>
            </div>

            <div className="networkError-text">
              <p className="info">
                Make sure your computer has an active internet connection.{" "}
              </p>
            </div>

            <div
              className="networkError-reconnect"
              aria-label="Reconnect to Network"
            >
              <span className="text">Reconnect</span>
              <span className="more-icon">
                <svg width="6" height="10" viewBox="0 0 9 14" fill="none">
                  <title>Reconnect</title>
                  <path
                    d="M8.22998 7.12704C8.22998 7.29142 8.19857 7.45086 8.13574 7.60537C8.07292 7.75989 7.98915 7.8881 7.88445 7.99001L2.10461 13.661C1.87426 13.887 1.58108 14 1.22507 14C0.869068 14 0.575889 13.887 0.345533 13.661C0.115177 13.435 -3.79882e-08 13.1473 -5.35496e-08 12.798C-6.91111e-08 12.4487 0.115177 12.161 0.345533 11.935L5.24583 7.12704L0.345532 2.31905C0.115177 2.09304 -5.43277e-07 1.80538 -5.58839e-07 1.45608C-5.744e-07 1.10678 0.115177 0.819124 0.345532 0.593108C0.575888 0.367091 0.869068 0.254081 1.22507 0.254081C1.58108 0.254081 1.87426 0.367091 2.10461 0.593108L7.88445 6.26407C8.01009 6.38735 8.0993 6.52091 8.15208 6.66473C8.20485 6.80856 8.23082 6.96267 8.22998 7.12704Z"
                    fill="#999999"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className="btn btn-close" aria-label="close">
            <svg width="26" height="26" viewBox="0 0 32 32" fill="none">
              <title>Close</title>
              <g id="system-uicons:cross">
                <path
                  id="Vector"
                  d="M23.619 23.6209L8.38086 8.38281M23.619 8.38281L8.38086 23.6209"
                  stroke={
                    theme === "Light"
                      ? "#2a2a2a"
                      : theme === "Dark"
                      ? "#ffffff"
                      : window.matchMedia("(prefers-color-scheme: light)")
                          .matches
                      ? "#2a2a2a"
                      : "#ffffff"
                  }
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NetworkError;
