import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserProfilePic } from "../../Utils/helpers";
import "../../assets/css/profileAvatarView.scss";

function ProfileAvatarView({ user, close }) {
  const theme = useSelector((state) => state.theme);
  useEffect(() => {
    console.log("User:-", user);
  }, [user]);

  return (
    <div className="profileAvatarView">
      <div className="profile-details">
        <div className="profile-detail">
          <div className="profile-image-wrapper">
            <div className="profile-image">
              <img
                src={getUserProfilePic(user?.profile)}
                alt="profile avatar"
              />
            </div>
            <span className="name">{user?.name}</span>
          </div>
          <div
            className="btn btn-close"
            role="button"
            tabIndex="0"
            aria-label="Close"
            onClick={close}
          >
            <svg width="18" height="18" viewBox="0 0 25 25" fill="none">
              <title>Close</title>
              <g id="iconoir:cancel">
                <path
                  id="Vector"
                  d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                  stroke={
                    theme === "Light"
                      ? "#2a2a2a"
                      : theme === "Dark"
                      ? "#ffffff"
                      : window.matchMedia("(prefers-color-scheme: light)")
                          .matches
                      ? "#2a2a2a"
                      : "#ffffff"
                  }
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="avatar-image-wrapper">
          <div className="avatar-image">
            <img src={getUserProfilePic(user?.profile)} alt="avatar" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileAvatarView;
