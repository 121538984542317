import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { handleToast } from "../../Redux/actions";
import "../../assets/css/messageCtaNotification.scss";

const Toast = ({ type, message, onClose }) => {
  return (
    // <div className="toast">
    //   <div className="toast-message">{message}</div>
    //   <button className="close-button" onClick={onClose}>
    //     <svg width="15" height="15" viewBox="0 0 30 30" fill="none">
    //       <title>Close</title>
    //       <g id="Frame 2595">
    //         <path
    //           id="Shape"
    //           d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
    //           fill="#FFFFFF"
    //         ></path>
    //       </g>
    //     </svg>
    //   </button>
    // </div>

    <div className="messageCtaNotification">
      <div className="messageCtaNotification-wrapper">
        <span className="info">{message}</span>

        {type !== "info" && <button className="btn btn-undo">Undo</button>}

        <div className="close" onClick={onClose}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <title>Close</title>
            <g clipPath="url(#clip0_2101_44699)">
              <path
                d="M9 7.50024L14.2502 2.25L15.75 3.74976L10.4998 9L15.75 14.2502L14.2502 15.75L9 10.4998L3.74976 15.75L2.25 14.2502L7.50024 9L2.25 3.74976L3.74976 2.25L9 7.50024Z"
                fill="#FFFFFF"
              />
            </g>
            <defs>
              <clipPath id="clip0_2101_44699">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    // {/* <RepostedNotification /> */}
  );
};

const MessageCtaNotification = ({ type, message }) => {
  const dispatch = useDispatch();
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    const newToast = { id: Date.now(), type, message };
    setToasts((prevToasts) => [...prevToasts, newToast]);
    setTimeout(() => {
      removeToast(newToast.id);
    }, 3000);
  }, [message]);

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    dispatch(handleToast(null));
  };

  return (
    <div className="messageCtaNotification-container">
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          type={toast.type}
          message={toast.message}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </div>
  );
};

export default MessageCtaNotification;
