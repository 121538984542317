import { React, useState, useEffect } from "react";
import { ERROR } from "../../../Utils/enums";
import axiosRequest from "../../../Middleware/api";
import { useDispatch, useSelector } from "react-redux";
import {
  handleToast,
  setAnimationDirection,
  setExceptContactType,
  setSelectedSetting,
  setSettings,
} from "../../../Redux/actions";
import "../../../assets/css/lastSeen.scss";

function LastSeenAndOnline() {
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings);

  const [isMounted, setIsMounted] = useState(true);

  const handleBackButton = () => {
    setIsMounted(false);
    dispatch(setAnimationDirection("right"));
    dispatch(setSelectedSetting("privacy"));
  };

  useEffect(() => {
    const element = document.querySelector(".lastSeen");

    const handleAnimationEnd = () => {
      if (!isMounted) {
      }
    };

    if (isMounted) {
      element.classList.add("open");
      element.classList.remove("close");
      element.removeEventListener("animationend", handleAnimationEnd);
    } else {
      element.classList.add("close");
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      element.classList.remove("open", "close");
      element.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [isMounted]);

  const PrivacySettings = (key, value) => {
    const data = { [key]: value };
    axiosRequest
      .put("/user/setting/privacy", data)
      .then((response) => {
        dispatch(setSettings(response?.data?.data));
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  return (
    <div className="lastSeen">
      <div className="lastSeen-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => handleBackButton()}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Last seen and online</span>
          </div>
        </header>

        <div className="lastSeen-ctas-wrapper">
          <div className="lastSeen-cta">
            <div className="lastSeen-info">
              <div className="lastSeen-heading">
                <span className="heading">Who can see my last seen</span>
              </div>
              <ul className="lastSeen-list">
                <li className="lastSeen-listItem">
                  <div className="group-check">
                    <input
                      id="everyone"
                      type="radio"
                      aria-label="Check this box"
                      checked={settings?.lastSeen === 0}
                      onChange={() => PrivacySettings("lastSeen", 0)}
                    />

                    <div
                      className="checkmark"
                      role="radio"
                      tabIndex="0"
                      onClick={() => PrivacySettings("lastSeen", 0)}
                    >
                      <div className="offRadio radio-cmn"></div>
                      <div className="onRadio radio-cmn"></div>
                    </div>
                  </div>
                  <label className="description" htmlFor="everyone">
                    Everyone
                  </label>
                </li>

                <li className="lastSeen-listItem">
                  <div className="group-check">
                    <input
                      id="myContacts"
                      type="radio"
                      aria-label="Check this box"
                      checked={settings?.lastSeen === 1}
                      onChange={() => PrivacySettings("lastSeen", 1)}
                    />

                    <div
                      className="checkmark"
                      role="radio"
                      tabIndex="0"
                      onClick={() => PrivacySettings("lastSeen", 1)}
                    >
                      <div className="offRadio radio-cmn"></div>
                      <div className="onRadio radio-cmn"></div>
                    </div>
                  </div>
                  <label className="description" htmlFor="myContacts">
                    My contacts
                  </label>
                </li>

                <li
                  className="lastSeen-listItem"
                  onClick={() => {
                    PrivacySettings("lastSeen", 2);
                    dispatch(setSelectedSetting("exceptcontact"));
                    dispatch(setAnimationDirection("right"));
                    dispatch(setExceptContactType("lastseen"));
                  }}
                >
                  <div className="group-check">
                    <input
                      id="myContactExcept"
                      type="radio"
                      aria-label="Check this box"
                      checked={settings?.lastSeen === 2}
                      // onChange={() => PrivacySettings("lastSeen", 2)}
                    />

                    <div
                      className="checkmark"
                      role="radio"
                      tabIndex="0"
                      onClick={() => PrivacySettings("lastSeen", 2)}
                    >
                      <div className="offRadio radio-cmn"></div>
                      <div className="onRadio radio-cmn"></div>
                    </div>
                  </div>
                  <label className="description" htmlFor="myContactExcept">
                    My contacts except...
                  </label>
                </li>

                <li className="lastSeen-listItem">
                  <div className="group-check">
                    <input
                      id="nobody"
                      type="radio"
                      aria-label="Check this box"
                      checked={settings?.lastSeen === 3}
                      onChange={() => PrivacySettings("lastSeen", 3)}
                    />

                    <div
                      className="checkmark"
                      role="radio"
                      tabIndex="0"
                      onClick={() => PrivacySettings("lastSeen", 3)}
                    >
                      <div className="offRadio radio-cmn"></div>
                      <div className="onRadio radio-cmn"></div>
                    </div>
                  </div>
                  <label className="description" htmlFor="nobody">
                    Nobody
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <div className="online-cta">
            <div className="online-info">
              <div className="online-heading">
                <span className="heading">Who can see when I’m online</span>
              </div>

              <ul className="online-list">
                <li className="online-listItem">
                  <div className="group-check">
                    <input
                      id="online-everyone"
                      type="radio"
                      aria-label="Check this box"
                      checked={settings?.OnlineSeen === 0}
                      onChange={() => PrivacySettings("OnlineSeen", 0)}
                    />
                    {/* <span
                      className="checkmark"
                      onClick={() => PrivacySettings("OnlineSeen", 0)}
                    /> */}
                    <div
                      className="checkmark"
                      role="radio"
                      tabIndex="0"
                      onClick={() => PrivacySettings("OnlineSeen", 0)}
                    >
                      <div className="offRadio radio-cmn"></div>
                      <div className="onRadio radio-cmn"></div>
                    </div>
                  </div>
                  <label className="description" htmlFor="online-everyone">
                    Everyone
                  </label>
                </li>

                <li className="online-listItem">
                  <div className="group-check">
                    <input
                      id="sameAsLastSeen"
                      type="radio"
                      aria-label="Check this box"
                      checked={settings?.OnlineSeen === 1}
                      onChange={() => PrivacySettings("OnlineSeen", 1)}
                    />
                    {/* <span
                      className="checkmark"
                      onClick={() => PrivacySettings("OnlineSeen", 1)}
                    /> */}
                    <div
                      className="checkmark"
                      role="radio"
                      tabIndex="0"
                      onClick={() => PrivacySettings("OnlineSeen", 1)}
                    >
                      <div className="offRadio radio-cmn"></div>
                      <div className="onRadio radio-cmn"></div>
                    </div>
                  </div>
                  <label className="description" htmlFor="sameAsLastSeen">
                    Same as last seen
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <div className="lastSeen-note">
            <p className="note">
              If you don't share when you were last seen or online, you won't be
              able to see when other people were last seen or online.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LastSeenAndOnline;
