import React, { useEffect, useState, useRef } from "react";
import WhyPost from "./WhyPost";
import MenuPopup from "./MenuPopup";
import RepostPost from "./RepostPost";
import TeparsPost from "./TeparsPost";
import ReportPost from "./ReportPost";
import PostLikeUser from "./PostLikes";
import Spinner from "../Common/Spinner";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ERROR, COMMENT_ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  setTepars,
  handleToast,
  setPostDetails,
  setTogglePostAction,
  setTeparsPage,
  setTeparsTotalCount,
  setTeparsLoad,
} from "../../Redux/actions";
import "../../assets/css/teparsView.scss";

const NoPostsView = ({ svg, title }) => (
  <div className="default">
    <div className="wrapper">
      <div className="logo">
        <div className="no-post">
          <div className="no-post-image">{svg}</div>
          <span className="title">{title}</span>
        </div>
      </div>
    </div>
  </div>
);

function TeparsView({
  postContainerRef,
  selectedMenu,
  selectedTeparsType,
  sound,
  setSound,
  setSelectedMenu,
  teparsVideoThumb,
  progress,
  selected,
  handleCancelUpload,
}) {
  const dispatch = useDispatch();

  const UserID = localStorage.getItem("user_id");

  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const scrollToTop = useSelector((state) => state.scrollToTop);
  const postCreateProgress = useSelector((state) => state.postCreateProgress);
  const teparsPage = useSelector((state) => state.teparsPage);
  const totalCount = useSelector((state) => state.totalCount);
  const teparsProgress = useSelector((state) => state.teparsProgress);
  const teparsLoad = useSelector((state) => state.teparsLoad);

  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [postWhy, setPostWhy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postMenu, setPostMenu] = useState(false);
  const [postLikes, setPostLikes] = useState(false);
  const [selectedPost, setSelectedPost] = useState();
  const [selectPostID, setSelectPostID] = useState();
  const [yourRepost, setYourRepost] = useState(false);
  const [reportMenu, setReportMenu] = useState(false);
  const [yourRepostData, setYourRepostData] = useState([]);
  const [selectedPostData, setSelectedPostData] = useState(null);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  useEffect(() => {
    dispatch(setTeparsPage(1));
    // setAllDataLoaded(false);
    fetchTepars(1);
    if (postContainerRef.current) {
      postContainerRef.current.scrollTo(0, 0);
    }
  }, [
    selectedMenu,
    selected,
    selectedTeparsType === "archived",
    selectedTeparsType === "saved",
    selectedTeparsType === null,
  ]);

  const fetchTepars = async (page) => {
    var url;
    if (selectedMenu === "profile" && selectedTeparsType === null) {
      if (selected === "repost") {
        url = `post/repost/${UserID}?page=`;
      } else {
        url = `post/getuserpost/${UserID}?page=`;
      }
    } else if (
      selectedMenu === "profile" &&
      (selectedTeparsType === "privacy" ||
        selectedTeparsType === "help" ||
        selectedTeparsType === "updateProfile" ||
        selectedTeparsType === "theme")
    ) {
      url = `post/getuserpost/${UserID}?page=`;
    } else if (
      selectedMenu === "profile" &&
      selectedTeparsType === "archived"
    ) {
      url = `post/archive?page=`;
    } else if (selectedMenu === "profile" && selectedTeparsType === "saved") {
      url = `post/save?page=`;
    } else {
      url = `post/tepars?page=`;
    }

    setLoading(true);
    if (page === 1) dispatch(setTeparsLoad(true));

    try {
      const res = await axiosRequest.get(`${url}${page}`);

      const fetchedData = res.data?.data || [];

      if (!fetchedData.length) {
        if (page === 1) {
          dispatch(setTepars([]));
        } else {
          setAllDataLoaded(true);
        }
      }

      setCount(res.data.totalCount);
      dispatch(setTeparsTotalCount(res.data.totalCount || 0));
      setTotalPages(res.data.totalPages);

      if (fetchedData.length) {
        const updatedTepars =
          page === 1 ? fetchedData : [...Tepars, ...fetchedData];
        dispatch(setTepars(updatedTepars));
        dispatch(setTeparsPage(page + 1));
        const newIsLiked = {};
        const newIsAnimating = {};
        res.data.data.forEach((post) => {
          newIsLiked[post._id] = post.selfLike || false;
          newIsAnimating[post._id] = false;
        });
      }

      setLoading(false);
      dispatch(setTeparsLoad(false));
    } catch (error) {
      if (error?.response?.status !== 401) {
        dispatch(handleToast(ERROR));
      }
      setLoading(false);
      dispatch(setTeparsLoad(false));
    }
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = postContainerRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      totalCount !== Tepars?.length &&
      !allDataLoaded
    ) {
      fetchTepars(teparsPage);
    }
  };

  useEffect(() => {
    if (totalPages > 1 && count) fetchTepars(teparsPage);
  }, [count]);

  useEffect(() => {
    if (Tepars?.length <= 2) {
      if (totalCount > Tepars?.length) {
        fetchTepars(teparsPage);
      }
    }
  }, [totalCount]);

  const handleRepostModal = (post) => {
    dispatch(setPostDetails(post));
    dispatch(setTogglePostAction("RePost"));
  };

  const repostPopup = (post) => {
    if (selectedTeparsType === "archived") {
      dispatch(handleToast(COMMENT_ERROR));
    } else {
      setYourRepost(true);
      setYourRepostData(post);
    }
  };

  const whySeenPost = (post) => {
    setPostMenu(false);
    setPostWhy(true);
    setSelectedPostData(post);
  };

  useEffect(() => {
    if (!postContainerRef.current) return;
    if (scrollToTop) {
      postContainerRef.current.scrollTop = 0;
    }
  }, [scrollToTop]);

  return (
    <>
      {teparsLoad ? (
        <div className="teparsView">
          <div className="teparsView-wrapper">
            {Array.from({ length: 3 }).map((posts, index) => (
              <SkeletonTheme
                baseColor={
                  theme === "Light"
                    ? ""
                    : theme === "Dark"
                    ? "#202020"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? ""
                    : "#202020"
                }
                highlightColor={
                  theme === "Light"
                    ? ""
                    : theme === "Dark"
                    ? "#2A2A2A"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? ""
                    : "#2A2A2A"
                }
              >
                <div className="post-wrapper">
                  <article className="post">
                    <div className="header">
                      <div className="profile">
                        <div className="profile-image">
                          <Skeleton width={50} height={53} circle={true} />
                        </div>
                        <div className="profile-info">
                          <div className="profile-info-wrapper">
                            <div className="profile-info-name">
                              <span className="name">
                                <Skeleton
                                  width={150}
                                  height={20}
                                  borderRadius={50}
                                />
                              </span>
                            </div>
                            <div className="profile-details">
                              <p className="profile-details-time">
                                <Skeleton
                                  width={50}
                                  height={5}
                                  borderRadius={50}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="post-info-wrapper">
                      <Skeleton height={350} borderRadius={10} />
                    </div>
                  </article>
                </div>
              </SkeletonTheme>
            ))}
          </div>
        </div>
      ) : Tepars?.length > 0 ? (
        <div
          className="teparsView"
          onScroll={handleScroll}
          ref={postContainerRef}
        >
          <div className="teparsView-wrapper">
            {postCreateProgress && (
              <div className="upload-progress">
                <div className="upload-info">
                  <div className="upload-info-wrapper">
                    <div className="profile-image">
                      {teparsVideoThumb === null ? (
                        <Spinner />
                      ) : (
                        <img src={teparsVideoThumb} alt="profile" />
                      )}
                    </div>
                    <span className="upload-progress-text">
                      Post upload in progress...
                    </span>
                  </div>
                  <div
                    className="btn btn-close"
                    aria-label="Close"
                    role="button"
                    onClick={handleCancelUpload}
                  >
                    <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
                      <title>Close</title>
                      <g id="Frame 2595">
                        <path
                          id="Shape"
                          d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                          fill={
                            theme === "Light"
                              ? "#2a2a2a"
                              : theme === "Dark"
                              ? "#ffffff"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#2a2a2a"
                              : "#ffffff"
                          }
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="upload-progress-range">
                  <div
                    className="selected-color"
                    style={{ width: `${teparsProgress}%` }}
                    // style={{
                    //   width: `${
                    //     teparsProgress < 100 ? teparsProgress : progress
                    //   }%`,
                    // }}
                  />
                </div>
              </div>
            )}
            {/* UpToDate */}
            {/* <div className="uptodate-post">
              <div className="done-image">
                // <img src={tick} alt="you're all caught up" />
                <svg width="72" height="69" viewBox="0 0 72 69" fill="none">
                    <g id="solar:camera-linear">
                    <path id="Vector" d="M8 35.8L25.8182 54L64 15"   stroke={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        } strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    </svg>
              </div>
              <p className="done-text">You're all caught up</p>
              <p className="done-info">
                You've seen all new posts from the past 3 days.
              </p>
            </div> */}
            {Tepars?.map((post, index) => (
              <TeparsPost
                key={index}
                post={post}
                setPostMenu={setPostMenu}
                setSelectedPost={setSelectedPost}
                setPostLikes={setPostLikes}
                whySeenPost={whySeenPost}
                repostPopup={repostPopup}
                handleRepostModal={handleRepostModal}
                setSelectPostID={setSelectPostID}
                sound={sound}
                setSound={setSound}
                selectedTeparsType={selectedTeparsType}
                setSelectedMenu={setSelectedMenu}
              />
            ))}
          </div>
          {loading && (
            <div className="teparsView-spinner">
              <Spinner />
            </div>
          )}
          {postMenu && selectedTeparsType !== "archived" && (
            <MenuPopup
              onClose={() => setPostMenu(false)}
              post={selectedPost}
              whySeenPost={(e) => whySeenPost(e)}
              repostPost={(e) => handleRepostModal(e)}
              setReportMenu={setReportMenu}
              selectedTeparsType={selectedTeparsType}
            />
          )}
          {postLikes && (
            <PostLikeUser
              onClose={setPostLikes}
              selectPostID={selectPostID}
              setSelectPostID={setSelectPostID}
              setSelectedMenu={setSelectedMenu}
            />
          )}
          {reportMenu && (
            <ReportPost
              post={selectedPost}
              reportMenu={reportMenu}
              setReportMenu={setReportMenu}
            />
          )}
          {postWhy && (
            <WhyPost onClose={setPostWhy} userId={selectedPostData?._id} />
          )}
          {yourRepost && (
            <RepostPost onClose={setYourRepost} postData={yourRepostData} />
          )}
        </div>
      ) : selectedMenu === "profile" && selectedTeparsType === "archived" ? (
        <NoPostsView
          svg={
            <svg width="58" height="58" viewBox="0 0 75 71" fill="none">
              <path
                d="M9.89638 30.235C10.3436 29.7647 10.7092 29.3666 11.0953 28.981C12.076 28.006 13.548 27.9621 14.4785 28.8697C15.4091 29.7773 15.442 31.2335 14.4566 32.2367C12.5567 34.1784 10.635 36.1007 8.69121 38.0036C7.59275 39.0758 6.01411 38.9583 5.14476 37.7027C3.5112 35.3373 1.92157 32.9437 0.355481 30.5328C0.193954 30.2775 0.0844433 29.9928 0.0332221 29.6951C-0.0179991 29.3974 -0.00992474 29.0926 0.0569826 28.798C0.12389 28.5034 0.248316 28.2249 0.423133 27.9785C0.597951 27.732 0.819726 27.5224 1.07576 27.3617C1.57307 27.0319 2.17848 26.9062 2.76617 27.0107C3.35386 27.1153 3.87864 27.4419 4.23147 27.9229C4.52805 28.3085 4.79012 28.7223 5.13692 29.2208C5.51039 27.7709 5.78814 26.4291 6.20085 25.1311C9.79281 13.823 17.1635 6.05755 28.3379 2.05253C36.7145 -0.943004 45.0988 -0.615391 53.32 2.74537C67.4713 8.5311 76.3657 23.0244 74.8278 38.2325C73.4155 52.2399 66.0151 62.1968 53.0831 67.6611C43.6191 71.6599 33.9856 71.3354 24.6314 66.9087C19.6962 64.5794 15.6366 61.1308 12.382 56.7418C11.4217 55.4486 11.5362 54.077 12.6755 53.2149C13.8147 52.3527 15.158 52.6537 16.1388 53.9469C20.9108 60.2421 27.1987 64.1154 34.9993 65.2832C43.3288 66.5372 51.0321 64.7173 57.8645 59.7185C68.27 52.1051 72.6796 39.0492 69.1221 26.6438C65.8158 15.1163 55.6111 6.35694 43.6771 5.01201C32.3661 3.74232 23.0826 7.6768 15.8814 16.4236C12.6645 20.3283 10.7642 24.8882 9.85558 29.8682C9.85736 29.9915 9.87102 30.1143 9.89638 30.235Z"
                fill={
                  theme === "Light"
                    ? "#2a2a2a"
                    : theme === "Dark"
                    ? "#ffffff"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? "#2a2a2a"
                    : "#ffffff"
                }
              />
              <path
                d="M36.3529 25.8148C36.3529 22.4713 36.4141 19.1262 36.3294 15.7827C36.2839 13.9283 37.4937 12.9439 38.6832 12.9612C40.1567 12.9831 41.0543 14.0114 41.0543 15.7545C41.0543 21.9713 41.0506 28.1891 41.0433 34.408C41.0433 34.9566 41.1877 35.3077 41.6396 35.6463C45.6757 38.6706 49.6971 41.7137 53.7039 44.7756C54.1818 45.1306 54.5693 45.5929 54.8353 46.1252C55.2558 47.0454 54.8933 48.1034 54.1103 48.7242C53.7128 49.0525 53.2154 49.2362 52.6997 49.2453C52.184 49.2544 51.6805 49.0884 51.2716 48.7743C49.7463 47.6771 48.2508 46.5328 46.7443 45.4057C43.7518 43.1673 40.7813 40.9038 37.7621 38.6999C36.7578 37.9663 36.3215 37.0869 36.3404 35.8438C36.3859 32.5035 36.3529 29.1521 36.3529 25.8148Z"
                fill={
                  theme === "Light"
                    ? "#2a2a2a"
                    : theme === "Dark"
                    ? "#ffffff"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? "#2a2a2a"
                    : "#ffffff"
                }
              />
            </svg>
          }
          title="No archived posts yet"
        />
      ) : selectedMenu === "profile" && selectedTeparsType === "saved" ? (
        <NoPostsView
          svg={
            <svg width="58" height="58" viewBox="0 0 54 70" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.09667 0C3.65143 0 0 3.33749 0 7.40055V67.9422C0.000192745 68.3092 0.107704 68.6695 0.311402 68.9857C0.5151 69.302 0.807581 69.5627 1.15856 69.7409C1.50954 69.9191 1.90626 70.0084 2.30765 69.9994C2.70903 69.9904 3.10049 69.8835 3.4415 69.6898L27 56.3323L50.5585 69.6898C50.8995 69.8835 51.291 69.9904 51.6924 69.9994C52.0937 70.0084 52.4905 69.9191 52.8414 69.7409C53.1924 69.5627 53.4849 69.302 53.6886 68.9857C53.8923 68.6694 53.9998 68.3092 54 67.9422V7.40055C54 3.33749 50.3486 0 45.9033 0H8.09667ZM8.09667 4.11817H45.9033C47.933 4.11817 49.4945 5.54542 49.4945 7.40055V64.2495L28.1804 52.1685C27.8254 51.9689 27.4168 51.8632 27 51.8632C26.5832 51.8632 26.1746 51.9689 25.8196 52.1685L4.50554 64.2495V7.40055C4.50554 5.54542 6.06705 4.11817 8.09667 4.11817Z"
                fill={
                  theme === "Light"
                    ? "#2a2a2a"
                    : theme === "Dark"
                    ? "#ffffff"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? "#2a2a2a"
                    : "#ffffff"
                }
              />
            </svg>
          }
          title="No saved posts yet"
        />
      ) : selectedMenu === "profile" && selected === "repost" ? (
        <NoPostsView
          svg={
            <svg width="55" height="55" viewBox="0 0 71 71" fill="none">
              <path
                d="M57.164 31.7275C56.7173 28.2996 55.5294 25.0087 53.6822 22.0812C47.7104 12.5437 36.7014 7.97514 25.793 10.4697C15.1061 12.9098 7.1246 21.9925 5.9544 33.0434C4.32418 48.4111 17.3573 61.9937 32.866 61.1355C38.309 60.8834 43.5332 58.9282 47.7938 55.5485C48.0846 55.3003 48.4018 55.0844 48.7397 54.9049C49.8898 54.3671 51.2929 54.7247 51.9226 55.7345C52.6413 56.8788 52.4458 57.9344 51.4338 58.7954C47.9326 61.7871 43.778 63.9244 39.3006 65.0375C19.0508 70.1438 -0.224335 54.2498 1.06086 33.5183C1.95792 18.8973 13.4988 6.82227 28.1334 5.19739C44.5564 3.37513 59.1852 14.3516 61.9339 30.5975C62.0834 31.4729 62.4112 31.7161 63.2191 31.6703C64.0809 31.6216 64.9449 31.6216 65.8067 31.6703C68.311 31.8162 69.5243 33.9589 68.2966 36.133C66.3914 39.4914 64.4612 42.8346 62.506 46.1626C61.1518 48.4712 58.5987 48.5312 57.233 46.2513C55.2242 42.8985 53.2595 39.5201 51.3389 36.1158C50.1083 33.9245 51.3676 31.8248 53.892 31.7218C54.9271 31.6932 55.9593 31.7275 57.164 31.7275Z"
                fill={
                  theme === "Light"
                    ? "#2a2a2a"
                    : theme === "Dark"
                    ? "#ffffff"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? "#2a2a2a"
                    : "#ffffff"
                }
              />
              <path
                d="M21.9855 35.1347C21.9855 31.0896 21.9855 27.0416 21.9855 22.9965C21.9855 21.4574 22.4427 21.1885 23.7912 21.9638C30.9505 26.0661 38.104 30.1808 45.2519 34.3079C46.5198 35.0403 46.517 35.5638 45.2519 36.3105C38.0983 40.43 30.9399 44.5409 23.7768 48.6432C22.4571 49.4013 21.997 49.1181 21.9941 47.5704C21.9798 43.4166 21.9855 39.2771 21.9855 35.1347Z"
                fill={
                  theme === "Light"
                    ? "#2a2a2a"
                    : theme === "Dark"
                    ? "#ffffff"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? "#2a2a2a"
                    : "#ffffff"
                }
              />
            </svg>
          }
          title="No reposts yet"
        />
      ) : (
        <NoPostsView
          svg={
            <svg width="58" height="58" viewBox="0 0 72 69" fill="none">
              <path
                d="M36 45.9336C40.9706 45.9336 45 42.128 45 37.4336C45 32.7392 40.9706 28.9336 36 28.9336C31.0294 28.9336 27 32.7392 27 37.4336C27 42.128 31.0294 45.9336 36 45.9336Z"
                stroke={
                  theme === "Light"
                    ? "#2a2a2a"
                    : theme === "Dark"
                    ? "#ffffff"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? "#2a2a2a"
                    : "#ffffff"
                }
                strokeWidth="2.25"
              />
              <path
                d="M29.334 60.1017H42.666C52.029 60.1017 56.712 60.1017 60.075 58.0192C61.5261 57.121 62.7758 55.9618 63.753 54.6079C66 51.4912 66 47.1477 66 38.4664C66 29.7822 66 25.4415 63.753 22.3249C62.7759 20.9708 61.5261 19.8117 60.075 18.9135C57.915 17.5734 55.209 17.0945 51.066 16.9245C49.089 16.9245 47.388 15.5362 47.001 13.737C46.7052 12.4192 45.9368 11.2381 44.8255 10.3936C43.7143 9.54898 42.3284 9.09265 40.902 9.10169H31.098C28.134 9.10169 25.581 11.0425 24.999 13.737C24.612 15.5362 22.911 16.9245 20.934 16.9245C16.794 17.0945 14.088 17.5762 11.925 18.9135C10.4749 19.8119 9.22612 20.971 8.25 22.3249C6 25.4415 6 29.7822 6 38.4664C6 47.1477 6 51.4884 8.247 54.6079C9.219 55.9565 10.467 57.1154 11.925 58.0192C15.288 60.1017 19.971 60.1017 29.334 60.1017Z"
                stroke={
                  theme === "Light"
                    ? "#2a2a2a"
                    : theme === "Dark"
                    ? "#ffffff"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? "#2a2a2a"
                    : "#ffffff"
                }
                strokeWidth="2.25"
              />
              <path
                d="M57 28.9336H54"
                stroke={
                  theme === "Light"
                    ? "#2a2a2a"
                    : theme === "Dark"
                    ? "#ffffff"
                    : window.matchMedia("(prefers-color-scheme: light)").matches
                    ? "#2a2a2a"
                    : "#ffffff"
                }
                strokeWidth="2.25"
                strokeLinecap="round"
              />
            </svg>
          }
          title="No posts yet"
        />
      )}
    </>
  );
}

export default TeparsView;
