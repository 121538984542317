import React from "react";
import { useSelector } from "react-redux";
import "../../assets/css/spinner.scss";

function Spinner({ triming }) {
  const theme = useSelector((state) => state.theme);
  const authSync = useSelector((state) => state.authSync);

  return (
    <div className="spinner" style={{ width: triming ? "1.5rem" : "" }}>
      <svg
        aria-label="Loading..."
        className="xemfg65 xa4qsjk x1ka1v4i xbv57ra"
        role="img"
        viewBox="0 0 100 100"
        fill={
          authSync === "login"
            ? "#000000"
            : theme === "Light"
            ? "#000000"
            : theme === "Dark"
            ? "#ffffff"
            : window.matchMedia("(prefers-color-scheme: light)").matches
            ? "#000000"
            : "#ffffff"
        }
      >
        <rect
          className="x1i210e2"
          height="6"
          opacity="0"
          rx="3"
          ry="3"
          transform="rotate(-90 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
        <rect
          className="x1i210e2"
          height="6"
          opacity="0.08333333333333333"
          rx="3"
          ry="3"
          transform="rotate(-60 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
        <rect
          className="x1i210e2"
          height="6"
          opacity="0.16666666666666666"
          rx="3"
          ry="3"
          transform="rotate(-30 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
        <rect
          className="x1i210e2"
          height="6"
          opacity="0.25"
          rx="3"
          ry="3"
          transform="rotate(0 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
        <rect
          className="x1i210e2"
          height="6"
          opacity="0.3333333333333333"
          rx="3"
          ry="3"
          transform="rotate(30 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
        <rect
          className="x1i210e2"
          height="6"
          opacity="0.4166666666666667"
          rx="3"
          ry="3"
          transform="rotate(60 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
        <rect
          className="x1i210e2"
          height="6"
          opacity="0.5"
          rx="3"
          ry="3"
          transform="rotate(90 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
        <rect
          className="x1i210e2"
          height="6"
          opacity="0.5833333333333334"
          rx="3"
          ry="3"
          transform="rotate(120 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
        <rect
          className="x1i210e2"
          height="6"
          opacity="0.6666666666666666"
          rx="3"
          ry="3"
          transform="rotate(150 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
        <rect
          className="x1i210e2"
          height="6"
          opacity="0.75"
          rx="3"
          ry="3"
          transform="rotate(180 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
        <rect
          className="x1i210e2"
          height="6"
          opacity="0.8333333333333334"
          rx="3"
          ry="3"
          transform="rotate(210 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
        <rect
          className="x1i210e2"
          height="6"
          opacity="0.9166666666666666"
          rx="3"
          ry="3"
          transform="rotate(240 50 50)"
          width="25"
          x="72"
          y="47"
        ></rect>
      </svg>
    </div>
  );
}

export default Spinner;
