import { useState } from "react";
import media1 from "../../assets/image/media1.webp";

const Medias = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const handleLabelClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="medias-wrapper">
      <div className="medias">
        <span className="date">Today</span>
        <div className="media-container">
          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />
            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>
        </div>
      </div>

      <div className="medias">
        <span className="date">Today</span>
        <div className="media-container">
          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />
            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>
        </div>
      </div>

      <div className="medias">
        <span className="date">Today</span>
        <div className="media-container">
          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />
            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>
        </div>
      </div>

      <div className="medias">
        <span className="date">Today</span>
        <div className="media-container">
          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />
            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>
        </div>
      </div>

      <div className="medias">
        <span className="date">Today</span>
        <div className="media-container">
          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />
            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>
        </div>
      </div>

      <div className="medias">
        <span className="date">Today</span>
        <div className="media-container">
          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />
            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>
        </div>
      </div>

      <div className="medias">
        <span className="date">Today</span>
        <div className="media-container">
          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />
            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>

          <div className="media" tabIndex="0" onClick={handleLabelClick}>
            <img src={media1} alt="profile" />

            <div className="group-check">
              <input
                type="checkbox"
                id="myCheckbox"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>
        </div>
      </div>

      <div className="total-medias">
        <span className="info">87 photos , 55 videos</span>
      </div>
    </div>
  );
};
export { Medias };
