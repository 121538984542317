import { React, useState, useEffect } from "react";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import { useDispatch, useSelector } from "react-redux";
import {
  getDefaultMessageTimerValue,
  getLastseenNOnlineValue,
  getProfilePhotoValue,
} from "../../Utils/helpers";
import FocusTrapping from "./FocusTrapping";
import {
  handleToast,
  setSelectedSetting,
  setAnimationDirection,
  setSettings,
} from "../../Redux/actions";
import "../../assets/css/privacySettings.scss";

function PrivacySettings() {
  const dispatch = useDispatch();
  const animationDirection = useSelector((state) => state.animationDirection);
  const settings = useSelector((state) => state.settings);

  const [isMounted, setIsMounted] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const handleBackButton = () => {
    dispatch(setAnimationDirection("right"));
    dispatch(setSelectedSetting(""));
    setIsMounted(false);
  };

  const handleSelectPrivacy = (privacy) => {
    dispatch(setSelectedSetting(privacy));
    // dispatch(setAnimationDirection("left"));
    setIsMounted(false);
  };

  useEffect(() => {
    const element = document.querySelector(".privacySettings");

    const handleAnimationEnd = () => {
      if (!isMounted) {
      }
    };

    if (isMounted) {
      element.classList.add(`open-${animationDirection}`);
      element.classList.remove(`close-${animationDirection}`);
      element.removeEventListener("animationend", handleAnimationEnd);
    } else {
      element.classList.add(`close-${animationDirection}`);
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      element.classList.remove(
        `open-${animationDirection}`,
        `close-${animationDirection}`
      );
      element.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [isMounted, animationDirection]);

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  const PrivacySettings = (key, value) => {
    const data = { [key]: value };
    axiosRequest
      .put("/user/setting/privacy", data)
      .then((response) => {
        dispatch(setSettings(response?.data?.data));
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  return (
    <>
      <FocusTrapping onClose={handleBackButton}>
        <div className="privacySettings">
          <div className="privacySettings-wrapper">
            <header className="header">
              <div className="back">
                <div
                  className="btn btn-back"
                  aria-label="Back"
                  role="button"
                  tabIndex="0"
                  onClick={() => handleBackButton()}
                >
                  <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                    <title>Back</title>
                    <g id="Group 18522">
                      <g id="Group 18522_2">
                        <path
                          id="Vector"
                          d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                          stroke="white"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="text">Privacy</span>
              </div>
            </header>

            <div className="privacySettings-cta">
              <div className="personal-info">
                <div className="privacy-heading">
                  <span className="heading">
                    Who can see my personal information
                  </span>
                </div>

                <div
                  className="privacy-setting read-receipts"
                  onClick={handleCheckmarkClick}
                >
                  <label className="info" htmlFor="readreceipt-check">
                    <span className="title">Read receipts</span>
                    <p className="sub-title">
                      If you turned off, you won't send or receive read
                      receipts. Read receipts are always sent for group chats.
                    </p>
                  </label>

                  {/* <div className="group-check">
                    <input
                      id="readreceipt-check"
                      type="checkbox"
                      aria-label="Check this box"
                      checked={settings?.ReadReceipt}
                      onChange={() =>
                        PrivacySettings("ReadReceipt", !settings?.ReadReceipt)
                      }
                    />
                    <span
                      className="checkmark"
                      onClick={() =>
                        PrivacySettings("ReadReceipt", !settings?.ReadReceipt)
                      }
                    />
                  </div> */}

                  <div className="toggle-switch reposting-switch">
                    <div
                      className={`off ${settings?.ReadReceipt ? "toggled" : ""}`}
                      onClick={() =>
                        PrivacySettings("ReadReceipt", !settings?.ReadReceipt)
                      }
                      role="button"
                      tabIndex="0"
                      aria-label="Turn On Reposting"
                    >
                      <div className="on" />
                    </div>
                  </div>
                </div>

                <div
                  className="privacy-setting last-seen"
                  tabIndex="0"
                  onClick={() => handleSelectPrivacy("lastseenandonline")}
                >
                  <div className="info">
                    <span className="title">Last seen and online</span>
                    <p className="sub-title">
                      {getLastseenNOnlineValue(settings)}
                    </p>
                  </div>

                  <div className="more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More Info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>

                <div
                  className="privacy-setting profile-photo"
                  tabIndex="0"
                  onClick={() => handleSelectPrivacy("profilephoto")}
                >
                  <div className="info">
                    <span className="title">Profile photo</span>
                    <p className="sub-title">
                      {getProfilePhotoValue(settings)}
                    </p>
                  </div>

                  <div className="more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More Info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>

                {/* <div
                className="privacy-setting about"
                tabIndex="0"
                onClick={() => handleSelectPrivacy("about")}
              >
                <div className="info">
                  <span className="title">About</span>
                  <p className="sub-title">Everyone</p>
                </div>

                <div className="more">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <title>More Info</title>
                    <path
                      id="Vector"
                      d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                      fill="#C5C5C5"
                    />
                  </svg>
                </div>
              </div> */}

                {/* <div
                className="privacy-setting sound"
                onClick={handleCheckmarkClick}
              >
                <div className="info">
                  <span className="title">Sounds</span>
                  <p className="sub-title">
                    If turned off you won't send or receive Read receipts. Read
                    receipts are always sent for group chats.
                  </p>
                </div>

                <div className="group-check">
                  <input
                    type="checkbox"
                    id="sounds-check"
                    aria-label="Check this box"
                    checked={isChecked}
                  />
                  <span className="checkmark" onClick={handleCheckmarkClick} />
                </div>
              </div> */}
              </div>

              <div className="disappearing-messages">
                <div className="privacy-heading">
                  <span className="heading">Disappearing messages</span>
                </div>

                <div
                  className="privacy-setting message-timer"
                  tabIndex="0"
                  onClick={() => handleSelectPrivacy("disappearingmsg")}
                >
                  <div className="info">
                    <span className="title">Default message timer</span>
                    {/* <p className="sub-title">
                      {getDefaultMessageTimerValue(settings)}
                    </p> */}

                    <p className="sub-title">
                      Start new chats with disappearing message set to your
                      timer.
                    </p>
                  </div>

                  <div className="last-seen-more">
                    <div className="last-seen-more-info">
                      {getDefaultMessageTimerValue(settings)}
                    </div>
                    <div className="more">
                      <svg
                        width="10"
                        height="16"
                        viewBox="0 0 10 16"
                        fill="none"
                      >
                        <title>More Info</title>
                        <path
                          id="Vector"
                          d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                          fill="#C5C5C5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="blocked-contacts"
                onClick={() => handleSelectPrivacy("blockedcontacts")}
              >
                <div className="privacy-setting block-contacts" tabIndex="0">
                  <div className="info">
                    <span className="title">Blocked contacts</span>
                    <p className="sub-title">{settings?.BlockUser?.length}</p>
                  </div>

                  <div className="more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More Info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FocusTrapping>
    </>
  );
}

export default PrivacySettings;
