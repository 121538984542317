import React, { useRef } from "react";
import "../../assets/css/sharemenu.scss";

function Sharemenu({
  ddref,
  menuPosition,
  isFromReceiver,
  onReplyClick,
  deleted,
  onDeleteClick,
  onStarClick,
  message,
}) {
  const DDyref = useRef(null);
  const { x, y } = menuPosition;
  const menuStyle = isFromReceiver
    ? { top: `${y}px`, left: `${x}px` }
    : { top: `${y}px`, right: `${window.innerWidth - x}px` };

  return (
    <div ref={DDyref} className="sharemenu" style={menuStyle}>
      <ul
        onContextMenu={(e) => console.log(e.pageX, e.pageY, 31)}
        ref={ddref}
        className="menu-list"
      >
        {!isFromReceiver && !deleted && (
          <li onClick={() => alert("MessageInfo")}>MessageInfo</li>
        )}
        {!deleted && <li onClick={() => onReplyClick()}>Reply</li>}
        {!deleted && <li onClick={() => alert("React")}>React</li>}
        {!deleted && <li onClick={() => alert("Forward")}>Forward</li>}
        {!deleted && (
          <li onClick={() => onStarClick()}>
            {message?.isStarMessage ? "Unstar" : "Star"}
          </li>
        )}
        {!deleted && <li onClick={() => alert("Report")}>Report</li>}
        <li onClick={() => onDeleteClick()}>Delete</li>
      </ul>
    </div>
  );
}

export default Sharemenu;

// import React from "react";
// import "../../assets/css/sharemenu.scss";

// function Sharemenu({
//   showMenu,
//   menuPosition,
//   isFromReceiver,
//   onReplyClick,
//   deleted,
//   onDeleteClick,
//   onStarClick,
//   message,
// }) {
//   // Style for the menu based on its visibility and position
//   const menuStyle = {
//     // display: showMenu ? "block" : "none",
//     top: `${menuPosition.top}px`,
//     left: `${menuPosition.left}px`,
//     position: "absolute", // Ensure the menu is positioned absolutely
//     zIndex: 1000, // High z-index to ensure it's on top of other elements
//   };

//   return (
//     <div className="sharemenu" style={menuStyle}>
//       <ul className="menu-list">
//         {!isFromReceiver && !deleted && (
//           <li onClick={() => alert("MessageInfo")}>MessageInfo</li>
//         )}
//         {!deleted && <li onClick={onReplyClick}>Reply</li>}
//         {!deleted && <li onClick={() => alert("React")}>React</li>}
//         {!deleted && <li onClick={() => alert("Forward")}>Forward</li>}
//         {!deleted && (
//           <li onClick={onStarClick}>
//             {message?.isStarMessage ? "Unstar" : "Star"}
//           </li>
//         )}
//         {!deleted && <li onClick={() => alert("Report")}>Report</li>}
//         <li onClick={onDeleteClick}>Delete</li>
//       </ul>
//     </div>
//   );
// }

// export default Sharemenu;
