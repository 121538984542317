import React, { useEffect, useRef, useState } from "react";
import Spinner from "../Common/Spinner";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import NotificationItem from "./NotificationItem";
import { useDispatch, useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  handleToast,
  setNotification,
  setNotifications,
} from "../../Redux/actions";
import {
  getNotifyNoDataText,
  getSelectedUserDetails,
} from "../../Utils/helpers";
import "../../assets/css/notification.scss";

function Notification({
  setSelectedMenu,
  handlePostClick,
  handleDeleteNotification,
}) {
  const dispatch = useDispatch();

  const notificationContainerRef = useRef(null);

  const theme = useSelector((state) => state.theme);
  const notifications = useSelector((state) => state.notifications);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notifyLoading, setNotifyLoading] = useState(false);
  const [selectedButton, setSelectedButton] = useState("all");

  const fetchNotifications = (pageNumber, typeValue) => {
    const params = { type: typeValue };
    setLoading(true);
    if (pageNumber === 1) {
      setNotifyLoading(true);
    }
    axiosRequest
      .get(`notification/web?page=${pageNumber}`, { params })
      .then((res) => {
        setCount(res.data.totalCount);
        setTotalPages(res.data.totalPages);
        if (res?.data?.totalPages === 0 && res?.data?.totalCount === 0) {
          dispatch(setNotifications([]));
        } else {
          if (pageNumber === 1) {
            dispatch(setNotifications(res.data.data));
          } else {
            let updatedNotifications = [...notifications, ...res?.data?.data];
            dispatch(setNotifications(updatedNotifications));
          }
        }
        setPage(pageNumber + 1);
        setLoading(false);
        setTimeout(() => {
          setNotifyLoading(false);
        }, 1000);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
        setNotifyLoading(false);
      });
  };

  useEffect(() => {
    fetchNotifications(1, "all");
  }, []);

  useEffect(() => {
    if (totalPages > 1 && count) fetchNotifications(page, selectedButton);
  }, [count]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      notificationContainerRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      count !== notifications?.length &&
      page <= totalPages
    ) {
      fetchNotifications(page, selectedButton);
    }
  };

  const handleButtonChange = (btnVal) => {
    setPage(1);
    setCount();
    setSelectedButton(btnVal);
    fetchNotifications(1, btnVal);
    if (notificationContainerRef.current) {
      notificationContainerRef.current.scrollTo(0, 0);
    }
  };

  return (
    <div className="notification">
      <div className="notification-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => dispatch(setNotification(false))}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Notifications</span>
          </div>
        </header>

        <div className="wrapper">
          <div className="notification-cta">
            <button
              className={`btn btn-all btn-cta ${
                selectedButton === "all" && `active`
              }`}
              onClick={() => handleButtonChange("all")}
            >
              All
            </button>
            <button
              className={`btn btn-all btn-cta ${
                selectedButton === "like" && `active`
              }`}
              onClick={() => handleButtonChange("like")}
            >
              Teps
            </button>
            <button
              className={`btn btn-all btn-cta ${
                selectedButton === "comments" && `active`
              }`}
              onClick={() => handleButtonChange("comments")}
            >
              Comments
            </button>
            <button
              className={`btn btn-all btn-cta ${
                selectedButton === "newUser" && `active`
              }`}
              onClick={() => handleButtonChange("newUser")}
            >
              New users
            </button>
            <button
              className={`btn btn-all btn-cta ${
                selectedButton === "repost" && `active`
              }`}
              onClick={() => handleButtonChange("repost")}
            >
              Reposts
            </button>
          </div>

          <div
            ref={notificationContainerRef}
            className="notification-userList"
            role="list"
            onScroll={handleScroll}
          >
            {/* {notifications?.length > 0 ? (
              notifications?.map((notify, i) =>
                notifyLoading ? (
                  <SkeletonTheme
                    baseColor={
                      theme === "Light"
                        ? ""
                        : theme === "Dark"
                        ? "#202020"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? ""
                        : "#202020"
                    }
                    highlightColor={
                      theme === "Light"
                        ? ""
                        : theme === "Dark"
                        ? "#2A2A2A"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? ""
                        : "#2A2A2A"
                    }
                  >
                    <div className="notification-posts-info" role="listitem">
                      <div className="profile-image">
                        <Skeleton width={50} height={50} circle={true} />
                      </div>
                      <div className="profile-info">
                        <div className="profile-name">
                          <p className="notification-user-info">
                            <span className="notification-user-name">
                              <Skeleton
                                width={200}
                                height={18}
                                borderRadius={50}
                              />
                            </span>
                          </p>
                          <div className="notification-time">
                            <span className="time">
                              <Skeleton
                                width={100}
                                height={17}
                                borderRadius={50}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="profile-cta">
                          <div className="notification-post-image">
                            <Skeleton width={60} height={60} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SkeletonTheme>
                ) : (
                  <NotificationItem
                    notify={notify}
                    getSelectedUserDetails={getSelectedUserDetails}
                    handleDeleteNotification={handleDeleteNotification}
                    handlePostClick={handlePostClick}
                  />
                )
              )
            ) : (
              <div className="notification-default">
                <div className="notification-default-info">
                  <span className="default-text">
                    {getNotifyNoDataText(selectedButton)}
                  </span>
                </div>
              </div>
            )} */}

            {notifyLoading ? (
              Array.from({ length: 15 }).map((notify, index) => (
                <SkeletonTheme
                  baseColor={
                    theme === "Light"
                      ? ""
                      : theme === "Dark"
                      ? "#202020"
                      : window.matchMedia("(prefers-color-scheme: light)")
                          .matches
                      ? ""
                      : "#202020"
                  }
                  highlightColor={
                    theme === "Light"
                      ? ""
                      : theme === "Dark"
                      ? "#2A2A2A"
                      : window.matchMedia("(prefers-color-scheme: light)")
                          .matches
                      ? ""
                      : "#2A2A2A"
                  }
                >
                  <div className="notification-posts-info" role="listitem">
                    <div className="profile-image">
                      <Skeleton width={50} height={50} circle={true} />
                    </div>
                    <div className="profile-info">
                      <div className="profile-name">
                        <p className="notification-user-info">
                          <span className="notification-user-name">
                            <Skeleton
                              width={200}
                              height={18}
                              borderRadius={50}
                            />
                          </span>
                        </p>
                        <div className="notification-time">
                          <span className="time">
                            <Skeleton
                              width={100}
                              height={17}
                              borderRadius={50}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="profile-cta">
                        <div className="notification-post-image">
                          <Skeleton width={60} height={60} />
                        </div>
                      </div>
                    </div>
                  </div>
                </SkeletonTheme>
              ))
            ) : notifications?.length > 0 ? (
              notifications?.map((notify, i) => (
                <NotificationItem
                  key={i}
                  notify={notify}
                  getSelectedUserDetails={(userid) =>
                    getSelectedUserDetails(userid, dispatch, setSelectedMenu)
                  }
                  handleDeleteNotification={handleDeleteNotification}
                  handlePostClick={handlePostClick}
                />
              ))
            ) : (
              <div className="notification-default">
                <div className="notification-default-info">
                  <span className="default-text">
                    {getNotifyNoDataText(selectedButton)}
                  </span>
                </div>
              </div>
            )}

            {loading && page > 2 && <Spinner />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
