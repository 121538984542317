import React, { useState, useEffect } from "react";
import { pdfjs } from "react-pdf";
import Spinner from "../Common/Spinner";
import { IMAGE, VIDEO } from "../../Utils/enums";
import { getMediaType } from "../../Utils/helpers";
import transDelete from "../../assets/image/transDelete.png";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PopupTeparsImage = ({
  i,
  index,
  imagesList,
  media,
  handleSelect,
  handleDelete,
}) => {
  const [thumbImage, setThumbImage] = useState([]);
  const [loader, setLoader] = useState(false);
  const fileType = getMediaType([media]);

  useEffect(() => {
    let video;

    if (fileType && fileType === VIDEO) {
      setLoader(true);
      video = document.createElement("video");

      const captureThumbnail = () => {
        const canvas = document.createElement("canvas");
        canvas.width = 50;
        canvas.height = 50;
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const src = canvas.toDataURL("image/png");
        if (src) setLoader(false);
        setThumbImage(src);
        video.pause();
      };

      if (media) {
        video.src = URL.createObjectURL(media);

        video.onloadedmetadata = () => {
          setTimeout(() => {
            captureThumbnail();
          }, 500);
          video.play();
        };

        return () => {
          video.removeEventListener("loadedmetadata", captureThumbnail);
          URL.revokeObjectURL(video.src);
          video.pause();
        };
      }
    } else {
      video = null;
    }
  }, [fileType, media]);

  return (
    <>
      <div
        key={i}
        className={`media ${imagesList.length >= 1 && index === i && "active"}`}
        onClick={() => {
          handleSelect(media, i);
        }}
        // tabIndex={index === i ? 0 : -1}
        tabIndex="0"
      >
        {loader ? (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        ) : (
          <>
            <img
              id={i}
              src={
                fileType && fileType === VIDEO && thumbImage
                  ? thumbImage
                  : fileType && fileType === IMAGE
                  ? URL.createObjectURL(media)
                  : ""
              }
              alt="media"
            />
            <div className="mediaDelete">
              <img
                src={transDelete}
                alt="delete"
                onClick={() => handleDelete(media, i)}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export { PopupTeparsImage };
