import React, { useEffect, useState, useRef } from "react";
import Spinner from "../Common/Spinner";
import ProfileCrop from "./ProfileCrop";
import { useDispatch, useSelector } from "react-redux";
import { uploadProfileFile } from "../../Connection/AWSConfigure";
import "../../assets/css/adjustProfileImage.scss";

function AdjustProfileImage({
  updateUser,
  profilePhoto,
  setProfilePhoto,
  setlocalstream,
  setProfilePhotoMenu,
  takeOrUploadPhoto,
  setTakeOrUploadPhoto,
  setHaveCamaraPermissions,
}) {
  const dispatch = useDispatch();

  const loader = useSelector((state) => state.loader);

  const [zoom, setZoom] = useState();
  const [loading, setLoading] = useState(false);
  const [cropedimages, setCropdeImages] = useState();


  const fileInputRef = useRef(null);

  const handleFileSelected = async (e) => {
    const selectedFile = URL.createObjectURL(e.target?.files[0]);
    setProfilePhoto(selectedFile);
    setProfilePhotoMenu("adjust");
    setTakeOrUploadPhoto('uploadPhoto');
  };

  const handleImageClick = (e) => {
    e.preventDefault();
    fileInputRef?.current?.click();
  };

  const retakes = () => {
    setProfilePhotoMenu("take");
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        setlocalstream(stream);
        setHaveCamaraPermissions((prevState) => !prevState.havePermissions);
      })
      .catch((err) => {
        console.log(`${err.name}: ${err.message}`);
      });
  };

  const UpdateProfile = () => {
    uploadProfileFile(cropedimages, updateUser, setProfilePhotoMenu, dispatch);
  };

  let setCropImage = async (url, i) => {
    let blob = await fetch(url).then((r) => r.blob());
    const { v4 } = require("uuid");
    const uuid = v4();
    const fileName = uuid + ".jpg";
    const fileType = "image/jpg";
    const lastModified = new Date().getTime();
    const file = new File([blob], fileName, {
      lastModified: lastModified,
      name: profilePhoto.name,
      size: blob.size,
      type: fileType,
      webkitRelativePath: "",
    });
    setCropdeImages(file);
  };

  const setZoomValue = (value) => {
    var getCrop = zoom;
    getCrop = value;
    setZoom(getCrop);
  };

  const loadBackToCrop = () => {
    setLoading(true);

    var timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    loadBackToCrop();
  }, []);

  useEffect(() => {}, [profilePhoto]);
  useEffect(() => { }, [profilePhoto]);

  return (
    <div className="adjustProfileImage">
      {loader ? (
        <Spinner />
      ) : (
        <div className="dialog">
          <div className="header">
            <div className="adjust-heading">
              <div
                className="btn btn-close"
                aria-label="close"
                tabIndex="0"
                onClick={() => { setProfilePhotoMenu(null); setTakeOrUploadPhoto(null); }}
              >
                <svg width="20" height="20" viewBox="0 0 25 25" fill="none">
                  <title>Close</title>
                  <g id="iconoir:cancel">
                    <path
                      id="Vector"
                      d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                      stroke="#ffffff"
                      strokeWidth="2.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <h4>Drag the image to adjust</h4>
            </div>

            {/* Retake || Upload */}
            {takeOrUploadPhoto == 'takePhoto' ?
              <div className="retake-heading" onClick={() => retakes()}>
                <div className="retake" aria-label="Retake" tabIndex="0">
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none">
                    <title>Retake</title>
                    <path
                      d="M9.76367 16.9994C9.38125 16.8232 9.16211 16.4794 9.16641 16.0669C9.1707 15.6673 9.36836 15.358 9.74219 15.1603C9.87969 15.0872 10.043 15.0744 11.5254 15.0529C13.1195 15.0314 13.1668 15.0271 13.5879 14.9154C14.5117 14.6705 15.4227 14.0775 16.0156 13.347C16.3938 12.8787 16.7504 12.1783 16.9051 11.5982C17.0082 11.2072 17.0211 11.0955 17.0211 10.4165C17.0211 9.73764 17.0082 9.62162 16.9094 9.2478C16.4969 7.73529 15.4012 6.54936 13.9359 6.03803C13.1926 5.77592 13.184 5.77592 8.61641 5.77592C6.32617 5.77592 4.45703 5.78451 4.45703 5.7974C4.45703 5.81029 4.65898 6.12397 4.9082 6.49779C5.43242 7.28412 5.49688 7.46889 5.38516 7.84701C5.17891 8.5517 4.43555 8.81381 3.77383 8.4185C3.69219 8.36694 3.07773 7.64076 2.28281 6.64389C0.989453 5.02826 0.933594 4.95092 0.933594 4.77475C0.933594 4.67592 0.967969 4.54701 1.01094 4.48256C1.04961 4.4224 1.65977 3.65756 2.36445 2.7767C3.33984 1.56069 3.69219 1.15248 3.83398 1.06654C4.42266 0.722795 5.19609 1.01928 5.38086 1.66381C5.49688 2.06772 5.43672 2.23959 4.9082 3.03451C4.65898 3.40834 4.45703 3.72201 4.45703 3.7349C4.45703 3.7435 6.44219 3.76069 8.87422 3.76928C13.2742 3.78217 13.2871 3.78217 13.7168 3.881C15.5602 4.2935 16.9824 5.26029 17.9793 6.77279C18.6797 7.83412 19.0105 8.89975 19.0535 10.2232C19.075 10.8462 19.0621 11.031 18.9848 11.4865C18.5594 13.9744 16.8535 15.9681 14.4816 16.7501C13.6438 17.0294 13.4848 17.0466 11.6328 17.0638C10.0473 17.0767 9.92266 17.0724 9.76367 16.9994Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <span className="text">Retake</span>
              </div>
              :
              <>
                <div className="retake-heading" onClick={(e) => handleImageClick(e)}>
                  <div className="retake" aria-label="Retake" tabIndex="0">
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none">
                      <title>Retake</title>
                      <path
                        d="M9.76367 16.9994C9.38125 16.8232 9.16211 16.4794 9.16641 16.0669C9.1707 15.6673 9.36836 15.358 9.74219 15.1603C9.87969 15.0872 10.043 15.0744 11.5254 15.0529C13.1195 15.0314 13.1668 15.0271 13.5879 14.9154C14.5117 14.6705 15.4227 14.0775 16.0156 13.347C16.3938 12.8787 16.7504 12.1783 16.9051 11.5982C17.0082 11.2072 17.0211 11.0955 17.0211 10.4165C17.0211 9.73764 17.0082 9.62162 16.9094 9.2478C16.4969 7.73529 15.4012 6.54936 13.9359 6.03803C13.1926 5.77592 13.184 5.77592 8.61641 5.77592C6.32617 5.77592 4.45703 5.78451 4.45703 5.7974C4.45703 5.81029 4.65898 6.12397 4.9082 6.49779C5.43242 7.28412 5.49688 7.46889 5.38516 7.84701C5.17891 8.5517 4.43555 8.81381 3.77383 8.4185C3.69219 8.36694 3.07773 7.64076 2.28281 6.64389C0.989453 5.02826 0.933594 4.95092 0.933594 4.77475C0.933594 4.67592 0.967969 4.54701 1.01094 4.48256C1.04961 4.4224 1.65977 3.65756 2.36445 2.7767C3.33984 1.56069 3.69219 1.15248 3.83398 1.06654C4.42266 0.722795 5.19609 1.01928 5.38086 1.66381C5.49688 2.06772 5.43672 2.23959 4.9082 3.03451C4.65898 3.40834 4.45703 3.72201 4.45703 3.7349C4.45703 3.7435 6.44219 3.76069 8.87422 3.76928C13.2742 3.78217 13.2871 3.78217 13.7168 3.881C15.5602 4.2935 16.9824 5.26029 17.9793 6.77279C18.6797 7.83412 19.0105 8.89975 19.0535 10.2232C19.075 10.8462 19.0621 11.031 18.9848 11.4865C18.5594 13.9744 16.8535 15.9681 14.4816 16.7501C13.6438 17.0294 13.4848 17.0466 11.6328 17.0638C10.0473 17.0767 9.92266 17.0724 9.76367 16.9994Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <span className="text">Upload</span>
                </div>
                <input
                  ref={fileInputRef}
                  accept="image/*"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileSelected}
                />
              </>
            }
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <div className="photo-container">
              <ProfileCrop
                image={profilePhoto}
                setCropImage={setCropImage}
                index={0}
                zoom={zoom}
                setZoomValue={setZoomValue}
              />
            </div>
          )}

          <div className="extra-space">
            <div
              className="done-icon"
              tabIndex="0"
              onClick={() => UpdateProfile()}
            >
              <svg width="16" height="16" viewBox="0 0 34 25" fill="none">
                <path
                  d="M2 13.2L11.5455 23L32 2"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdjustProfileImage;
