import React from "react";
import { REPORT_VIOLENT_OPTION } from "../../../Utils/enums";

const Violence = ({
  selectedReportOption,
  setSelectedReportOption,
  SubmitReport,
}) => {
  const handleReportOptionChange = (option) => {
    setSelectedReportOption(option);
  };
  return (
    <div className="reportPost-info">
      <div className="why-report-list" role="list">
        <p className="title">Why are you reporting this post?</p>
        {REPORT_VIOLENT_OPTION.map((option, i) => (
          <div
            tabIndex="0"
            className="report-reason nudity"
            onClick={() => {
              handleReportOptionChange(option);
            }}
          >
            <div className="group-check">
              <input
                type="radio"
                id={`myCheckbox${i}`}
                aria-label="Check this box"
                checked={selectedReportOption === option}
              />
              <div className="checkmark" role="radio" tabIndex="0">
                <div className="offRadio radio-cmn"></div>
                <div className="onRadio radio-cmn"></div>
              </div>
            </div>
            <p className="report-reason-title">{option}</p>
          </div>
        ))}
        <div className="nudity-guidelines">
          <p className="title">
            Violence and dangerous organisations guidelines
          </p>

          <div className="we-remove">
            <span className="subtitle">We remove:</span>
            <ul className="remove-list-wrapper">
              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">
                  Photos or videos of extreme graphic violence.
                </p>
              </li>

              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">
                  Posts that encourage violence or attacks on anyone based on
                  their religious, ethnic or sexual background.
                </p>
              </li>

              <li className="remove-list">
                <div className="circle" aria-hidden="true" />
                <p className="info">
                  Specific threats of physical harm, theft, vandalism or
                  financial harm.
                </p>
              </li>
            </ul>
          </div>

          <button className="btn btn-submit" onClick={() => SubmitReport()}>
            Submit Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default Violence;
