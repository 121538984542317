import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import SocketContext from "../Contexts/SocketContext";
import gsap from "gsap";
import AWS from "aws-sdk";
import axios from "axios";
import WaveSurfer from "wavesurfer.js";

import InputEmoji from "react-input-emoji";
import Default from "../component/Default";
import axiosRequest from "../Middleware/api";
import ViewMedia from "../component/ViewMedia";
import Map from "../component/ChatComponent/Map";
import PollChat from "../component/Poll/PollChat";
import MainMenu from "../component/Menu/MainMenu";
import ViewPost from "../component/Tepars/ViewPost";
import Image from "../component/ChatComponent/Image";
import Audio from "../component/ChatComponent/Audio";
import GroupInfo from "../component/Group/GroupInfo";
import Videos from "../component/ChatComponent/Video";
import TakePhoto from "../component/Camera/TakePhoto";
import Settings from "../component/Settings/Settings";
import ViewStory from "../component/Tepars/ViewStory";
import MyGroups from "../component/Settings/MyGroups";
import { useDispatch, useSelector } from "react-redux";
import Groups from "../component/ChatComponent/Groups";
import TeparsView from "../component/Tepars/TeparsView";
import Contact from "../component/ChatComponent/Contact";
import Message from "../component/ChatComponent/Message";
import Documents from "../component/ChatComponent/Documents";
import UploadTepears from "../component/Tepars/UploadTepears";
import Headerprofile from "../component/Header/Headerprofile";
import MediaLinksDocs from "../component/Group/MediaLinksDocs";
import UpdateProfile from "../component/Settings/UpdateProfile";
import UploadMedia from "../component/ChatComponent/UploadMedia";
import VideoPlayer from "../component/ChatComponent/VideoPlayer";
import Notification from "../component/Notification/Notification";
import SendContact from "../component/ChatComponent/SendContacts";
import ViewContacts from "../component/ChatComponent/ViewContacts";
import EditPostPrivacy from "../component/Tepars/EditPostPrivacy";
import FileShareMenu from "../component/ChatComponent/FileShareMenu";
import ViewNotificationPost from "../component/Notification/ViewPost";
import MessageReply from "../component/ChatComponent/ChatReply/Reply";
import NewChatContact from "../component/Menu/subMenu/NewChatContact";
import ChatReply from "../component/ChatComponent/ChatReply/ChatReply";
import ForwardMessage from "../component/ChatComponent/ForwardMessage";
import ProfileAvatarView from "../component/Settings/ProfileAvatarView";
import { DeletedMessage } from "../component/ChatComponent/DeletedMessage";
import SocialLinkPreview from "../component/ChatComponent/SocialLinkPreview";
import DeleteNotification from "../component/Notification/DeleteNotification";
import MessageCtaNotification from "../component/Common/MessageCtaNotification";
import {
  uploadFile,
  uploadThumbFile,
  uploadTeparsThumbFile,
} from "../Connection/AWSConfigure";
import {
  setTepars,
  handleToast,
  setPostDetails,
  setNotification,
  setNotifications,
  setSettingOptions,
  setTeparsProgress,
  setSelectedSetting,
  setViewProfilePhoto,
  updateStory,
  deleteStory,
  updateMyStatus,
  deleteMyStatus,
  updateSeenUsers,
  setTogglePostAction,
  setPostCreateProgress,
  setToggeleViewOtherProfile,
  setSelfContact,
} from "../Redux/actions";
import {
  TEXT,
  AUDIO,
  VIDEO,
  IMAGE,
  PAGES,
  LIKED,
  ERROR,
  UNLIKE,
  CONTACT,
  DOCLINK,
  DOCUMENT,
  LOCATION,
  URL_REGX,
  SOCIAL_API,
  REPLY_TEXT,
  AWS_REGION,
  CONTACT_ALL,
  SENDER_TEXT,
  SENDER_AUDIO,
  SENDER_IMAGE,
  SENDER_VIDEO,
  AWS_S3_BUCKET,
  SENDER_DELETED,
  SENDER_CONTACT,
  CONTACT_EXCEPT,
  SOCIAL_API_KEY,
  SENDER_DOCUMENT,
  ONLY_SHARE_WITH,
  RECEIVER_DELETED,
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY,
  AWS_COGNITO_IDENTITY_POOL_ID,
} from "../Utils/enums";
import {
  joinUserID,
  splitUserID,
  RInboxModel,
  getMediaType,
  RMessageModel,
  getMessageType,
  getVideoDuration,
  RMessageMediaModel,
  handleCompressFile,
  getTimetoDaytoDate,
  ManageTeparsPrivacy,
  RMessageContactModel,
  captureVideoThumbnail,
  handleCompressThumbFile,
  generateThumbnail,
  ManageTeparsPrivacyValue,
} from "../Utils/helpers";
import {
  CameraAlertAllow,
  CameraAlertDenied,
} from "../component/Camera/CameraAlert";

import ComingSoon from "../component/Common/ComingSoon";
import {
  postImagePath,
  postVideoPath,
} from "../ServerConfiguration/awsConfiguration";
import ViewOtherProfile from "../component/OtherUser/ViewOtherProfile";
import { fetchFile } from "@ffmpeg/util";
import { FFmpeg } from "@ffmpeg/ffmpeg";

function MainChatApp({ sendMessage, deleteMessage }) {
  const dispatch = useDispatch();

  const socket = useContext(SocketContext);
  const UserID = localStorage.getItem("user_id");
  const inputRef = useRef(null);
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const sharemenuRef = useRef(null);
  const viewContactRef = useRef(null);
  const sendContactRef = useRef(null);
  const activeStreamsRef = useRef([]);
  const postContainerRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const sharemenubuttonRef = useRef(null);
  const messageContainerRef = useRef(null);
  const recordingsContainerRef = useRef({ playing: () => false });

  const userId = localStorage.getItem("user_id");
  const ownerId = localStorage.getItem("owner_id");

  const toast = useSelector((state) => state.toast);
  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const Messages = useSelector((state) => state.messages);
  const selfUser = useSelector((state) => state.selfContact);
  const togglePost = useSelector((state) => state.togglePost);
  const postDetails = useSelector((state) => state.postDetails);
  const loggedInUser = useSelector((state) => state.selfContact);
  const notification = useSelector((state) => state.notification);
  const notifications = useSelector((state) => state.notifications);
  const inboxContacts = useSelector((state) => state.inboxContacts);
  const chatBoxContact = useSelector((state) => state.chatBoxContact);
  const settingOptions = useSelector((state) => state.settingOptions);
  const selectedSetting = useSelector((state) => state.selectedSetting);
  const viewProfilePhoto = useSelector((state) => state.viewProfilePhoto);
  const postCreateProgress = useSelector((state) => state.postCreateProgress);
  const toggleViewOtherProfile = useSelector(
    (state) => state.toggleViewOtherProfile
  );

  let permission = false;
  let currentDate = null;

  const chatBoxId = splitUserID(chatBoxContact?.contact?.user?._id);

  const mimeType = "audio/m4a";
  const [ids, setIds] = useState();
  const [error, setError] = useState(null);
  const [audio, setAudio] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [message, setMessage] = useState("");
  const [stream, setStream] = useState(null);
  const [playing, setPlay] = useState(false);
  const [tapped, setTapped] = useState(true);
  const [forward, setForward] = useState(false);
  const [pipVideo, setPIPVideo] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [audioUrls, setAudioUrls] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [timeInSecs, setTimeInSecs] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [urlPreview, setUrlPreview] = useState(null);
  const [localstream, setlocalstream] = useState({});
  const [pipVideoId, setPIPVideoId] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const [checkaudio, setCheckAudio] = useState(false);
  const [closeAlert, setcloseAlert] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [pdfPagesCount, setPdfPagesCount] = useState(0);
  const [showForward, setShowForward] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [closeCamera, setCloseCamera] = useState(false);
  const [wallpaper, setWallpaper] = useState("#F4FEFF");
  const [progressCount, setProgressCount] = useState(0);
  const [forwardMessage, setForwardMessage] = useState();
  const [isOpenFileMenu, setisOpenFileMenu] = useState();
  const [showViewMedia, setShowViewMedia] = useState({});
  const [selectedEmoji, setSelectedEmoji] = useState([]);
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const [viewContacts, setViewContacts] = useState(false);
  const [viewContactType, setViewContactType] = useState();
  const [tappedMessage, setTappedMessage] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [AskPermission, setAskPermission] = useState(false);
  const [pipVideoPoster, setPIPVideoPoster] = useState(null);
  const [userProfileData, setUserProfileData] = useState("");
  const [closeViewMedia, setcloseViewMedia] = useState(false);
  const [viewAllContact, setViewAllContact] = useState(false);
  const [audioElapsedTime, setAudioElapsedTime] = useState(0);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [openChatContact, setOpenChatContact] = useState(false);
  const [selectUploadMedia, setSelectUploadMedia] = useState([]);
  const [isContentVisible, setIsContentVisible] = useState(true);
  const [totalRecordingTime, setTotalRecordingTime] = useState(0);
  const [recordingStartTime, setRecordingStartTime] = useState(0);
  const [pictureInPicture, setPictureInPicture] = useState(false);
  const [selectedContactName, setSelectedContactName] = useState();
  const [userProfileToggle, setUserProfileToggle] = useState(false);
  const [uploadTepearsToggle, setUploadTepearsToggle] = useState(false);
  const [haveCamaraPermissions, setHaveCamaraPermissions] = useState({});
  const [senderMessageType, setSenderMessageType] = useState(SENDER_TEXT);
  const [userMediaLinkDocsToggle, setUserMediaLinkDocsToggle] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("tepars");
  const [selectedTeparsType, setSelectedTeparsType] = useState(null);
  const [sound, setSound] = useState(true);
  const [story, setStory] = useState();
  const [viewStory, setViewStory] = useState(false);
  const [teparsVideoThumb, setTeparsVideoThumb] = useState(null);
  const [progress, setProgress] = useState(0);
  const [post, setPost] = useState("");
  const [viewNotifyPost, setViewNotifyPost] = useState(false);
  const [postDetail, setPostDetail] = useState();
  const [showDeleteNotification, setShowDeleteNotification] = useState(false);
  const [notificationId, setNotificationId] = useState(null);
  const [comingSoon, setComingSoon] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    getTimetoDaytoDate(Messages[0]?.timestamp, "chat")
  );
  const [showAnimation, setShowAnimation] = useState(false);
  const [getDescription, setGetDescription] = useState("");
  const [getVisibility, setGetVisibility] = useState("120");
  const [privacy, setPrivacy] = useState(ManageTeparsPrivacyValue(CONTACT_ALL));
  const [getRePosting, setGetRePosting] = useState(true);
  const [getOffComment, setGetOffComment] = useState(false);
  const [getCanReplyPost, setGetCanReplyPost] = useState(false);
  const [sharedGroups, setSharedGroups] = useState([]);
  const [getSelectedLocation, setGetSelectedLocation] = useState("");
  const [exceptContact, setExceptContact] = useState([]);
  const [onlyShareContact, setOnlyShareContact] = useState([]);
  const [cropAspectForUpload, setCropAspectForUpload] = useState(1);
  const [cancelUpload, setCancelUpload] = useState(null);
  const [mediaList, setMediaList] = useState({ medias: [], mainProgress: 0 });
  const [selected, setSelected] = useState("media");
  const [ffmpeg, setFfmpeg] = useState(null);
  const [isStopped, setIsStopped] = useState(false);

  useEffect(() => {
    if (socket) {
      socket.on("webStory", handleWebStory);
      socket.on("newStory", handleNewStory);
      socket.on("deletedStory", handleDeleteStory);
      socket.on("seenStory", handleSeenStory);
    }
  }, [socket]);

  const handleWebStory = (data) => {
    const storyUserId = data?.webUserId?._id;
    const processedStory = generateThumbnail(data);
    dispatch(updateMyStatus({ userId: storyUserId, newStory: processedStory }));
  };

  const handleNewStory = (data) => {
    const storyUserId = data?.webUserId?._id;
    const processedStory = generateThumbnail(data);
    dispatch(updateStory({ userId: storyUserId, newStory: processedStory }));
  };

  const handleDeleteStory = (data) => {
    const storyUserId = data?.userId;
    if (storyUserId === UserID) {
      dispatch(deleteMyStatus(data._id));
    } else {
      dispatch(deleteStory(data._id));
    }
  };

  const handleSeenStory = (data) => {
    const { _id, seenUsers } = data;
    dispatch(updateSeenUsers({ _id, seenUsers }));
  };

  useEffect(() => {
    if (settingOptions === true || selectedSetting !== "") {
      dispatch(setSettingOptions(false));
      dispatch(setSelectedSetting(""));
    }
    if (notification) {
      dispatch(setNotification(false));
    }
    if (togglePost) {
      dispatch(setTogglePostAction(null));
      dispatch(setPostDetails(null));
    }
    if (viewProfilePhoto) {
      dispatch(setViewProfilePhoto(null));
    }
    if (postCreateProgress) {
      dispatch(setPostCreateProgress(false));
    }
    if (toggleViewOtherProfile) {
      dispatch(setToggeleViewOtherProfile(false));
    }
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef?.current?.focus]);

  useEffect(() => {
    scrollToBottom();
  }, [Messages]);
  // }, [messages, messageContainerRef?.current?.scrollTop]);

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  //this code is for scrollEnable and close menu when user click on outside content
  const OpenFileShareMenu = async () => {
    setisOpenFileMenu((prev) => !prev);
    setScrollEnabled((prev) => !prev);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (!scrollEnabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (!scrollEnabled) {
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, [scrollEnabled]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isOpenFileMenu &&
        sharemenuRef.current &&
        !sharemenuRef.current.contains(e.target) &&
        sharemenubuttonRef.current &&
        !sharemenubuttonRef.current.contains(e.target)
      ) {
        setisOpenFileMenu(false);
        setScrollEnabled(true);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpenFileMenu]);
  //scroll event end-here

  const handleCloseAlert = () => {
    setcloseAlert(true);
  };

  const checkPermissions = async () => {
    await navigator.mediaDevices
      .getUserMedia({ camera: true })
      .then((stream) => {
        setHaveCamaraPermissions(
          (prevState) => prevState.haveCamaraPermissions
        );
      })
      .catch((err) => {
        console.log(`${err.name}: ${err.message}`);
      });
  };

  const getMicrophonePermissions = async () => {
    if ("MediaRecorder" in window) {
      try {
        var streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        permission = true;
        setAudio(true);
        setStream(streamData);
        activeStreamsRef.current.push(streamData);
        return streamData;
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const revokeMicrophonePermissions = async () => {
    if (stream && stream.getTracks) {
      activeStreamsRef.current.forEach((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      });
    }
  };

  const createMessageAtRealm = async (msgObj) => {
    const inboxChatAvailable = inboxContacts?.find((inbox) => {
      return inbox?._id === chatBoxContact?.contact?.user?._id + "_user";
    });
    if (inboxChatAvailable) {
      // addMessage(msgObj, inboxChatAvailable, 0);
    } else {
      const inboxId = joinUserID(chatBoxId, ownerId) + "_user";
      const inboxObj = RInboxModel(
        inboxId,
        ownerId,
        chatBoxContact?.contact,
        msgObj
      );
      // addInboxContact(inboxObj);
    }
  };

  const handleButtonClick = async () => {
    const msgObj = RMessageModel(
      loggedInUser?._id,
      ownerId,
      message,
      senderMessageType,
      chatBoxId,
      [],
      "",
      ids
    );

    if (checkaudio && audio) {
      handleDeleteAudio();
      handleSendAudioMessage(recordedBlobs[0]);
    } else if (audio) {
      handleDeleteAudio();
      handleSendAudioMessage(recordedBlobs[0]);
    } else {
      createMessageAtRealm(msgObj);
      const trimmedMessage = message.trim();
      if (trimmedMessage || selectedEmoji.length > 0) {
        const emojis = selectedEmoji.join(" ");
        const fullMessage = `${trimmedMessage} ${emojis}`.trim(); // Concatenate text and emoji
        sendMessage(msgObj, fullMessage, ids);
        setTapped(false);
        setTappedMessage(null);
        setSenderMessageType(SENDER_TEXT);
        setMessage("");
        setUrlPreview(false);
        setSelectedEmoji([]);
        const messageInputDiv = document.querySelector(".messageInput");
        if (messageInputDiv) {
          messageInputDiv.innerText = "";
        }
      }
    }
  };

  const handleSendContactMessage = async () => {
    const contactsMsgObj = [];

    const msgObj = RMessageModel(
      loggedInUser?._id,
      ownerId,
      "Contact",
      SENDER_CONTACT,
      chatBoxId,
      contactsMsgObj,
      "",
      ids
    );

    for (let i = 0; i < viewContacts.length; i++) {
      const RMessageContactObj = RMessageContactModel(
        ownerId,
        viewContacts[i]?.name,
        viewContacts[i]?.user?.phone,
        viewContacts[i]?.user?.countryCode,
        viewContacts[i]?.user?.profilePic,
        viewContacts[i]?.user?._id,
        msgObj?._id
      );
      contactsMsgObj.push(RMessageContactObj);
    }

    createMessageAtRealm(msgObj);
    sendMessage(msgObj, "", ids);
    setViewAllContact(false);
  };

  const handleSendMediaMessage = async () => {
    setSelectedMedia();
    var file;
    var thumb;
    var duration;
    var messageType;
    var mediaFileType;
    var RMessageMediaObj;

    for (var i = 0; i < selectedMedia.length; i++) {
      const fileType = getMediaType([selectedMedia[i]]);
      if (fileType && fileType === IMAGE) {
        mediaFileType = IMAGE;
        messageType = SENDER_IMAGE;
      } else if (fileType && fileType === VIDEO) {
        mediaFileType = VIDEO;
        messageType = SENDER_VIDEO;
      } else if (fileType && fileType[0] === DOCUMENT) {
        mediaFileType = DOCUMENT;
        messageType = SENDER_DOCUMENT;
      } else {
        return null;
      }

      if (mediaFileType === IMAGE) {
        duration = 0;
        file = await handleCompressFile(selectedMedia[i]);
        thumb = await handleCompressThumbFile(selectedMedia[i]);
      } else {
        file = selectedMedia[i];
      }

      if (mediaFileType === VIDEO) {
        duration = await getVideoDuration(selectedMedia[i]);
      }

      const { v4 } = require("uuid");
      const uuid = v4();
      const extension = file.type.split("/")[1];

      const docUrl =
        uuid +
        DOCLINK +
        pdfPagesCount +
        PAGES +
        DOCLINK +
        file?.name.split(".")[1] +
        DOCLINK +
        file?.name;

      const name = uuid + "." + extension;
      const mediaName = mediaFileType === DOCUMENT ? docUrl : name;
      const thumbName =
        mediaFileType === VIDEO || mediaFileType === IMAGE ? uuid + ".jpg" : "";

      const msgObj = RMessageModel(
        loggedInUser?._id,
        ownerId,
        mediaFileType,
        messageType,
        chatBoxId,
        [],
        []
      );

      RMessageMediaObj = RMessageMediaModel(
        ownerId,
        file?.size,
        duration,
        mediaName,
        msgObj?._id,
        thumbName
      );

      const appendMedia = {
        ...msgObj,
        media: RMessageMediaObj,
      };

      createMessageAtRealm(appendMedia);

      uploadFile(
        file,
        mediaName,
        RMessageMediaObj?._id,
        appendMedia,
        docUrl,
        sendMessage,
        setProgressCount,
        UpdateMediaMessageAtRealm
      );

      if (mediaFileType === IMAGE) {
        uploadThumbFile(thumb, thumbName, mediaFileType);
      }

      if (mediaFileType === VIDEO) {
        var src = await captureVideoThumbnail(selectedMedia[i]);
        uploadThumbFile(src, thumbName, mediaFileType);
      }
    }
  };

  const handleSendLiveImageMessage = async (fileValue) => {
    var file;
    var thumb;
    var RMessageMediaObj;

    file = await handleCompressFile(fileValue);
    thumb = await handleCompressThumbFile(fileValue);

    const { v4 } = require("uuid");
    const uuid = v4();
    const extension = file.type.split("/")[1];
    const mediaName = uuid + "." + extension;
    const thumbName = uuid + "." + extension;

    const msgObj = RMessageModel(
      loggedInUser?._id,
      ownerId,
      IMAGE,
      SENDER_IMAGE,
      chatBoxId,
      [],
      []
    );

    RMessageMediaObj = RMessageMediaModel(
      ownerId,
      file?.size,
      0,
      mediaName,
      msgObj?._id
    );

    const appendMedia = {
      ...msgObj,
      media: RMessageMediaObj,
    };

    createMessageAtRealm(appendMedia);

    uploadFile(
      file,
      mediaName,
      RMessageMediaObj?._id,
      appendMedia,
      "",
      sendMessage,
      setProgressCount,
      UpdateMediaMessageAtRealm
    );

    uploadThumbFile(thumb, thumbName, IMAGE);
  };

  const handleSendAudioMessage = async (file) => {
    const { v4 } = require("uuid");
    const uuid = v4();
    const extension = file.type.split("/")[1];
    const mediaName = uuid + "." + extension;

    const msgObj = RMessageModel(
      loggedInUser?._id,
      ownerId,
      "Audio",
      SENDER_AUDIO,
      chatBoxId,
      [],
      []
    );

    const RMessageMediaObj = RMessageMediaModel(
      ownerId,
      file?.size,
      totalRecordingTime,
      mediaName,
      msgObj?._id
    );

    const appendMedia = {
      ...msgObj,
      media: RMessageMediaObj,
    };

    createMessageAtRealm(appendMedia);

    uploadFile(
      file,
      mediaName,
      RMessageMediaObj?._id,
      appendMedia,
      "",
      sendMessage,
      setProgressCount,
      UpdateMediaMessageAtRealm
    );
  };

  const UpdateMediaMessageAtRealm = async (id, status) => {
    // realm.write(() => {
    //   realm.create(
    //     "RMessageMediaModel",
    //     {
    //       _id: id,
    //       mediaStatus: status,
    //     },
    //     "modified"
    //   );
    // });
  };

  const fetchMetadata = async (url) => {
    try {
      const response = await axios.get(`${SOCIAL_API}${url}${SOCIAL_API_KEY}`);
      setMetadata(response.data);
    } catch (error) {
      setMetadata(null);
      setError(true);
    }
  };

  const checkIsLink = (val) => {
    const isLink = URL_REGX.test(val);
    const url = val.split(".");
    const lastStr = url[url.length - 1];
    if (isLink && url.length >= 2 && lastStr.length >= 2) {
      setUrlPreview(isLink);
      fetchMetadata(val);
    } else {
      setUrlPreview(null);
    }
  };

  const toggleVisibility = () => {
    setIsContentVisible(!isContentVisible);
    if (isPlayingAudio) {
      toggleAudioPlayback();
    }
  };

  const startRecording = async () => {
    try {
      const streamData = await getMicrophonePermissions();
      if (permission) {
        setRecordingStartTime(new Date().getTime());
        startTimer(totalRecordingTime);
        const mediaRecorder = new MediaRecorder(streamData, { type: mimeType });
        mediaRecorderRef.current = mediaRecorder;

        let localAudioChunks = audioChunks;
        mediaRecorderRef.current.ondataavailable = (event) => {
          if (typeof event.data === "undefined") return;
          if (event.data.size === 0) return;
          localAudioChunks.push(event.data);
        };
        setAudioChunks(localAudioChunks);

        mediaRecorderRef.current.start();
        await animateBars();
      }
    } catch (err) {
      console.log("errorrr", err.message);
    }
  };

  const resetRecorder = () => {
    setAudioUrls([]);
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state === "recording") {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current.onstop = () => {
        mediaRecorderRef.current = null;
      };
    }
    setTotalRecordingTime(0);
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.onstop = () => {
        permission = false;
        const audioBlob = new Blob(audioChunks, { type: mimeType });
        setTotalRecordingTime(totalRecordingTime + timeInSecs); // Update total recording time

        const recordingDuration = Math.floor(
          (new Date().getTime() - recordingStartTime) / 1000
        );
        setTotalRecordingTime(totalRecordingTime + recordingDuration);

        let url = URL.createObjectURL(audioBlob);
        let urls = [];
        urls = audioUrls.length > 0 ? [audioUrls[0], url] : [url];
        let proms = urls.map((uri) => fetch(uri).then((r) => r.blob()));
        Promise.all(proms).then((blobs) => {
          let blob = new Blob([blobs[0], blobs[1]]),
            audioUrl = URL.createObjectURL(blob);

          const fileName = AUDIO + ".m4a";
          const lastModified = new Date().getTime();
          const file = new File([blob], fileName, {
            lastModified: lastModified,
            lastModifiedDate: new Date(lastModified),
            name: fileName,
            size: blob.size,
            type: mimeType,
            webkitRelativePath: "",
          });
          setRecordedBlobs([file]);

          const mergedWaveSurfer = WaveSurfer.create({
            container: recordingsContainerRef.current,
            progressColor: "#1891A2",
            waveColor: "#939499",
            height: 15,
            width: 200,
            barGap: 1.5,
            barWidth: 1.5,
            barRadius: 2,
            normalize: true,
            url: audioUrl,
          });
          mergedWaveSurfer.load(audioUrl);
          mergedWaveSurfer.on("ready", () => {
            recordingsContainerRef.current = mergedWaveSurfer;
          });
          mergedWaveSurfer.on("finish", () => {
            setPlay(false);
            mergedWaveSurfer.setTime(0);
          });

          // time get weves wise
          const formatTime = (seconds) => {
            const minutes = Math.floor(seconds / 60);
            const secondsRemainder = Math.round(seconds) % 60;
            const paddedSeconds = `0${secondsRemainder}`.slice(-2);
            return `${minutes}:${paddedSeconds}`;
          };

          const timeEl = document.querySelector("#time");
          const durationEl = document.querySelector("#duration");
          mergedWaveSurfer.on(
            "timeupdate",
            (currentTime) => (timeEl.textContent = formatTime(currentTime))
          );
          mergedWaveSurfer.on(
            "decode",
            (duration) => (durationEl.textContent = formatTime(duration))
          );

          setAudioUrl(audioUrl);
          setAudioUrls([audioUrl]);
        });

        setAudioChunks([]);
      };
    }

    cancelAnimationFrame(animationRef.current);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const animateBars = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const streamData = await getMicrophonePermissions();
    const source = audioContext.createMediaStreamSource(streamData);
    source.connect(analyser);

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const barWidth = 2;
    const barGap = 2;
    const barMaxHeight = canvas.height * 0.8;
    const barMinHeight = canvas.height * 0.04;
    const initialBarCount = Math.floor(canvas.width / (barWidth + barGap));
    const maxBarCount = Math.ceil(canvas.width / (barWidth + barGap));
    let currentBarCount = initialBarCount;
    let isFirstIteration = true;

    const drawBars = () => {
      analyser.getByteFrequencyData(dataArray);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      currentBarCount = 80;
      for (let i = 0; i < currentBarCount; i++) {
        const barIndex = Math.floor(i * (bufferLength / currentBarCount));
        function mapRange(value, min1, max1, min2, max2) {
          return ((value - min1) * (max2 - min2)) / (max1 - min1) + min2;
        }
        const barHeight = mapRange(
          dataArray[barIndex],
          0,
          255,
          barMinHeight,
          barMaxHeight
        );

        // const barHeight = gsap.utils.mapRange(
        //   0,
        //   255,
        //   barMinHeight,
        //   barMaxHeight,
        //   dataArray[barIndex]
        // );
        const x = canvas.width - barWidth - i * (barWidth + barGap);
        const y = (canvas.height - barHeight) / 2;

        ctx.fillStyle = "#1891a2";
        ctx.fillRect(x, y, barWidth, barHeight);
      }

      if (isFirstIteration) {
        currentBarCount++;
        if (currentBarCount > maxBarCount) {
          currentBarCount = maxBarCount;
          isFirstIteration = true;
        }
      } else {
        currentBarCount--;
        if (currentBarCount <= 0) {
          isFirstIteration = false;
          currentBarCount = maxBarCount;
        }
      }

      setTimeout(() => {
        requestAnimationFrame(drawBars);
      }, 30);
    };

    setInterval(() => {
      drawBars();
    }, 30);

    audioContext.resume().then(() => {
      drawBars();
    });
  };

  function showrecording() {
    if (checkaudio === false) {
      stopRecording();
    }
  }

  // Audio Timer = stop and pause ----------------

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(() => {
        setTimeInSecs((prevTimeInSecs) => prevTimeInSecs + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isRunning]);

  useEffect(() => {
    if (isRunning) {
      setMinutes(Math.floor(timeInSecs / 60));
      setSeconds(timeInSecs % 60);
    } else {
      setMinutes(0);
      setSeconds(0);
    }
  }, [isRunning, timeInSecs, setIsRunning]);

  const startTimer = (secs) => {
    setTimeInSecs(secs);
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const toggleTimer = () => {
    if (isRunning) {
      stopTimer();
    } else {
      startTimer(timeInSecs);
    }
  };

  const toggleAudioPlayback = () => {
    if (isPlayingAudio) {
      setIsPlayingAudio(false);
      stopTimer();
    } else {
      setIsPlayingAudio(true);
      startTimer(audioElapsedTime);
    }
  };

  const handleDeleteAudio = () => {
    stopTimer();
    setCheckAudio(false);
    revokeMicrophonePermissions();
    resetRecorder();
    setAudio(false);
  };

  const handlePlayPause = () => {
    setPlay(!playing);
    recordingsContainerRef.current.playPause();
  };

  // for prevent from select entire screen text on press "a" key
  useEffect(() => {
    const handleGlobalKeyDown = (e) => {
      if (e.ctrlKey && e.key === "a") {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleGlobalKeyDown);
    return () => {
      document.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, []);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      messageContainerRef.current;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    const topIndex = Math.floor((scrollPercentage / 100) * Messages.length);
    if (Messages.length > 0 && topIndex >= 0 && topIndex < Messages.length) {
      setToastMessage(
        getTimetoDaytoDate(Messages[topIndex]?.timestamp, "chat")
      );
    }
  };

  const handlePostClick = (postVal) => {
    setPost(postVal);

    const postId = postVal?.postId
      ? postVal?.postId?._id
      : postVal?.commentsId?.postId?._id;

    axiosRequest
      .get(`post/${postId}`)
      .then((res) => {
        if (res?.data?.data) {
          setPostDetail(res?.data?.data);
          setViewNotifyPost(true);
        } else {
          setViewNotifyPost(false);
          dispatch(handleToast(res?.data?.message));
        }
      })
      .catch((error) => {
        setViewNotifyPost(false);
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const handleDeleteNotification = (id) => {
    setNotificationId(id);
    setShowDeleteNotification(true);
  };

  const deleteNotification = () => {
    axiosRequest
      .delete(`/notification/delete/${notificationId}`)
      .then(() => {
        setShowDeleteNotification(false);
        const notification = notifications?.filter(
          (notification) => notification?._id !== notificationId
        );
        dispatch(setNotifications(notification));
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setShowDeleteNotification(false);
      });
  };

  const toggleLike = (postId) => {
    axiosRequest
      .put(`post/like/${postId}`)
      .then((res) => {
        if (res?.data?.message === LIKED || res?.data?.message === UNLIKE) {
          if (res?.data?.message === LIKED) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps + 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          if (res?.data?.message === UNLIKE) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps - 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          const postData = viewNotifyPost ? postDetail : postDetails;
          let newpost = postData;
          const newObj = {
            userId: {
              _id: selfUser?._id,
              name: "You",
              profile: selfUser?.profile,
              phone: selfUser?.phone,
              countryCode: selfUser?.countryCode,
              contactStatus: selfUser?.isUserVerified,
            },
          };
          if (postData?.selfLike) {
            newpost.TotalLike -= 1;
            newpost.selfLike = false;
            newpost.likeUser = newpost?.likeUser?.filter(
              (item) => item?.userId?._id !== userId
            );
          } else {
            newpost.TotalLike += 1;
            newpost.selfLike = true;
            newpost.likeUser = [...(newpost?.likeUser || []), newObj];
          }
          const findPost = Tepars.map((Post) =>
            Post?._id === postId ? newpost : Post
          );
          dispatch(setTepars(findPost));
          if (newpost?.selfLike) {
            setShowAnimation(true);
            setTimeout(() => {
              setShowAnimation(false);
            }, 1000);
          }
        } else {
          dispatch(handleToast(res?.data?.message));
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  // Create post section..

  useEffect(() => {
    const loadFFmpeg = async () => {
      try {
        const ffmpegInstance = new FFmpeg();
        await ffmpegInstance.load();
        setFfmpeg(ffmpegInstance);
      } catch (error) {
        console.error("Error loading FFmpeg:", error);
      }
    };

    loadFFmpeg();
  }, []);

  const resetUploadStates = useCallback(() => {
    setProgress(0);
    setMediaList({ medias: [], mainProgress: 0 });
    setTeparsVideoThumb(null);
    setCancelUpload(null);
    setIsStopped(false);
    dispatch(setTeparsProgress(0));
    dispatch(setPostCreateProgress(false));
  }, [dispatch]);

  const HandlePostUpload = async (imgAndVideoMedia) => {
    if (!imgAndVideoMedia?.length) return;

    resetUploadStates();
    setIsStopped(false);

    function areAllVideos(arr) {
      return arr?.every((item) => item?.type.split("/")[0] === "video");
    }

    let currentUpload = null;
    let isCancelled = false;
    const cancelSignal = { onCancel: null };

    const cancelUploadFunc = () => {
      isCancelled = true;
      if (currentUpload) {
        currentUpload.abort();
      }
      if (cancelSignal.onCancel) {
        cancelSignal.onCancel();
      }
      dispatch(setPostCreateProgress(false));
    };

    setCancelUpload(() => cancelUploadFunc);

    const uploadMediaToS3 = async (file, mediaType) => {
      const UPLOAD_PATH = mediaType === IMAGE ? postImagePath : postVideoPath;
      const key = UPLOAD_PATH + file.name;

      AWS.config.update({
        region: AWS_REGION,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: AWS_COGNITO_IDENTITY_POOL_ID,
        }),
      });
      const s3 = new AWS.S3({
        params: { Bucket: AWS_S3_BUCKET },
      });

      const params = { Bucket: AWS_S3_BUCKET, Key: key, Body: file };

      setSharedGroups([]);
      setPrivacy(ManageTeparsPrivacyValue(CONTACT_ALL));
      // setUploadTepearsToggle(false);
      // dispatch(setPostCreateProgress(true));

      return new Promise((resolve, reject) => {
        const upload = s3.upload(params);
        currentUpload = upload;

        upload.on("httpUploadProgress", (evt) => {
          if (isCancelled) {
            upload.abort();
            reject(new Error("Upload cancelled"));
            return;
          }

          const uploadProgress = parseInt((evt.loaded * 100) / evt.total);
          const finalProgress = 90 + (uploadProgress * 10) / 100;
          dispatch(setTeparsProgress(finalProgress));
        });

        upload.send((err, data) => {
          if (err) {
            // reject(err);
            reject(new Error(`Failed to upload file: ${err.message}`)); // Properly reject the promise
            console.error("Error uploading file to S3:", err);
          } else {
            resolve(data);
          }
        });
      });
    };

    const compressVideo = async (file, index, totalVideos) => {
      if (!ffmpeg || isStopped) {
        return;
      }

      try {
        await ffmpeg.writeFile(file.name, await fetchFile(file));

        ffmpeg.on("progress", ({ progress }) => {
          if (isStopped) {
            ffmpeg.terminate();
            dispatch(setTeparsProgress(0));
            return;
          }
          const videoProgress = Math.round(progress * 100);
          const compressionProgress = Math.round(
            (index * 100 + videoProgress) / totalVideos
          );
          const finalProgress = (compressionProgress * 90) / 100;
          if (finalProgress <= 100) dispatch(setTeparsProgress(finalProgress));
        });

        await ffmpeg.exec([
          "-i",
          file.name,
          "-c:v",
          "libx264",
          "-crf",
          "28",
          "-preset",
          "veryfast",
          "-c:a",
          "aac",
          "-b:a",
          "128k",
          "output.mp4",
        ]);

        if (isStopped) return;

        const data = await ffmpeg.readFile("output.mp4");
        const compressedBlob = new Blob([data.buffer], { type: "video/mp4" });
        const compressedFile = new File([compressedBlob], `${file.name}`, {
          type: "video/mp4",
        });

        if (compressedFile.size < file.size) {
          return compressedFile;
        } else {
          return file;
        }
      } catch (error) {
        console.error("Error during video compression:", error);
        return file;
      }
    };

    try {
      setUploadTepearsToggle(false);
      dispatch(setPostCreateProgress(true));
      const mediaArray = [];
      const mediasArray = [];
      const uploadPromises = [];

      const firstFile = imgAndVideoMedia[0];
      if (firstFile) {
        try {
          if (getMediaType([firstFile]) === VIDEO) {
            const src = await captureVideoThumbnail(firstFile);
            setTeparsVideoThumb(src);
          } else {
            const image = URL.createObjectURL(firstFile);
            setTeparsVideoThumb(image);
          }
        } catch (error) {
          console.error("Error creating thumbnail:", error);
        }
      }

      const videoFiles = imgAndVideoMedia.filter(
        (file) => getMediaType([file]) === VIDEO
      );
      const totalVideos = videoFiles.length;

      for (let i = 0; i < imgAndVideoMedia?.length; i++) {
        if (isCancelled) {
          return;
        }
        let file = imgAndVideoMedia[i];
        const fileType = getMediaType([file]);
        const mediaType = fileType === IMAGE ? IMAGE : VIDEO;

        if (mediaType === VIDEO) {
          // dispatch(setTeparsProgress(0));
          try {
            const videoIndex = videoFiles.findIndex(
              (vf) => vf.name === file.name
            );
            file = await compressVideo(file, videoIndex, totalVideos);
            imgAndVideoMedia[i] = file;
          } catch (error) {
            console.error("Error compressing video:", error);
          }
        }

        const duration = mediaType === VIDEO ? await getVideoDuration(file) : 0;

        const mediaObj = {
          fileSize: file.size,
          mediaLength: duration,
          mediaUrl: file.name,
          mediaType: fileType,
          ratio:
            imgAndVideoMedia?.length > 1
              ? areAllVideos(imgAndVideoMedia) && 1 / 1
              : cropAspectForUpload,
          progress: 0,
        };

        let medias = {
          size: file?.size,
          duration: duration?.toString(),
          thumbnail: file?.name,
          aspectRatio:
            imgAndVideoMedia?.length > 1
              ? areAllVideos(imgAndVideoMedia)
                ? 1 / 1
                : 1 / 1
              : cropAspectForUpload,
          type: fileType,
          url: file?.name,
        };

        if (mediaType === VIDEO) {
          const src = await captureVideoThumbnail(file);
          const mediaThumbNailName = file?.name?.split(".")[0] + ".jpg";
          uploadPromises.push(
            uploadTeparsThumbFile(src, mediaThumbNailName, cancelSignal)
          );
          medias = { ...medias, thumbnail: mediaThumbNailName };
        }

        mediaArray.push(mediaObj);
        mediasArray.push(medias);

        // Trigger download for compressed video
        // if (mediaType === VIDEO) {
        //   console.log("file:-", file);
        //   const url = URL.createObjectURL(file);
        //   console.log("url:-", url);
        //   const a = document.createElement("a");
        //   a.href = url;
        //   a.download = `compressed_${file.name}`;
        //   document.body.appendChild(a);
        //   a.click();
        //   document.body.removeChild(a);
        // }
      }

      setMediaList((prevState) => ({
        ...prevState,
        medias: mediaArray,
      }));

      if (mediaArray?.length === imgAndVideoMedia?.length) {
        for (let j = 0; j < mediaArray.length; j++) {
          if (isCancelled) {
            return;
          }

          const file =
            mediaArray[j].mediaUrl === imgAndVideoMedia[j].name
              ? imgAndVideoMedia[j]
              : new File([imgAndVideoMedia[j]], mediaArray[j].mediaUrl, {
                  type: imgAndVideoMedia[j].type,
                });
          const fileType = getMediaType([file]);
          const mediaType = fileType === IMAGE ? IMAGE : VIDEO;

          uploadPromises.push(uploadMediaToS3(file, mediaType));
        }

        try {
          await Promise.all(uploadPromises);
          if (!isCancelled) {
            await createPost(mediasArray);
          }
        } catch (error) {
          if (
            error.code === "RequestAbortedError" ||
            error.message === "Upload cancelled"
          ) {
            return;
          }
          throw error;
        }
      }
    } catch (error) {
      if (
        error.code === "RequestAbortedError" ||
        error.message === "Upload cancelled"
      ) {
      } else if (error?.response?.status !== 401) {
        dispatch(handleToast(ERROR));
      }
      dispatch(setTeparsProgress(0));
      setProgress(0);
    } finally {
      setCancelUpload(null);
      dispatch(setTeparsProgress(0));
      setProgress(0);
    }
  };

  const createPost = async (medias) => {
    const postData = {
      location: {
        longitude: getSelectedLocation?.geometry?.location?.lng,
        name: getSelectedLocation?.name,
        latitude: getSelectedLocation?.geometry?.location?.lat,
      },
      description: getDescription,
      privacy: ManageTeparsPrivacy(privacy, sharedGroups?.length, ""),
      group: sharedGroups,
      contact:
        privacy === CONTACT_EXCEPT
          ? exceptContact
          : privacy === ONLY_SHARE_WITH
          ? onlyShareContact
          : [],
      tagUser: [],
      media: medias,
      expireAt: getVisibility,
      canReply: getCanReplyPost,
      turnOffComment: getOffComment,
      settingRepost: getRePosting,
    };

    axiosRequest
      .post(`post/create`, postData)
      .then((res) => {
        const updatedUser = {
          ...selfUser,
          post: selfUser?.post + 1,
        };
        const updatedPost = {
          ...res?.data?.data,
          isFirstTime: true,
        };
        dispatch(setSelfContact(updatedUser));
        dispatch(setTepars([updatedPost, ...Tepars]));
        dispatch(setPostCreateProgress(false));
        dispatch(setTeparsProgress(0));
        setTeparsVideoThumb(null);
        setGetDescription("");
        setGetSelectedLocation("");
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        dispatch(setPostCreateProgress(false));
        dispatch(setTeparsProgress(0));
        setTeparsVideoThumb(null);
      });
  };

  const handleCancelUpload = useCallback(() => {
    if (cancelUpload) {
      cancelUpload();
    }
    if (ffmpeg) {
      ffmpeg.terminate();
      // Reinitialize FFmpeg after termination
      const loadFFmpeg = async () => {
        try {
          const ffmpegInstance = new FFmpeg();
          await ffmpegInstance.load();
          setFfmpeg(ffmpegInstance);
        } catch (error) {
          console.error("Error reloading FFmpeg:", error);
        }
      };
      loadFFmpeg();
    }
    setIsStopped(true);
    resetUploadStates();
  }, [cancelUpload, ffmpeg, resetUploadStates]);

  const CalculateProgress = (currentMedia, currentprogress) => {
    setMediaList((prevState) => {
      const updatedMedias = prevState?.medias.map((media) => {
        if (media?.mediaUrl === currentMedia) {
          const oldProgress = media?.progress;
          return {
            ...media,
            progress: media?.progress + (currentprogress - oldProgress),
          };
        }
        return media;
      });

      const updatedProgress = updatedMedias?.reduce(
        (sum, media) => sum + media?.progress,
        0
      );
      const overallProgress =
        (updatedProgress / (updatedMedias.length * 100)) * 100;

      setProgress(overallProgress);

      return {
        ...prevState,
        medias: updatedMedias,
        mainProgress: updatedProgress,
      };
    });
  };

  return (
    <>
      {!closeAlert && AskPermission === true && (
        <CameraAlertAllow handleCloseAlert={handleCloseAlert} />
      )}
      {!closeAlert &&
        haveCamaraPermissions === false &&
        AskPermission === false && (
          <CameraAlertDenied handleCloseAlert={handleCloseAlert} />
        )}
      {showViewMedia.show === true && closeViewMedia === false ? (
        <ViewMedia
          mediaUrl={showViewMedia.url}
          setShowViewMedia={setShowViewMedia}
          setcloseViewMedia={setcloseViewMedia}
        />
      ) : (
        <div
          className={`chatpage ${
            userProfileToggle || userMediaLinkDocsToggle ? "userProfile" : ""
          }`}
        >
          {/* aside */}
          <aside className="aside-wrapper">
            {openChatContact && (
              <NewChatContact setOpenChatContact={setOpenChatContact} />
            )}
            {settingOptions && (
              <Settings
                postContainerRef={postContainerRef}
                setWallpaper={setWallpaper}
                setSelectedMenu={setSelectedMenu}
                setSelectedTeparsType={setSelectedTeparsType}
              />
            )}
            {selectedSetting === "updateProfile" && (
              <UpdateProfile
                setAskPermission={setAskPermission}
                setcloseAlert={setcloseAlert}
                setCloseCamera={setCloseCamera}
                setlocalstream={setlocalstream}
                localstream={localstream}
              />
            )}
            {selectedSetting === "myGroups" && <MyGroups />}
            {notification && (
              <Notification
                setSelectedMenu={setSelectedMenu}
                handlePostClick={(post) => handlePostClick(post)}
                handleDeleteNotification={(id) => handleDeleteNotification(id)}
              />
            )}
            <MainMenu
              postContainerRef={postContainerRef}
              selectedMenu={selectedMenu}
              selected={selected}
              setSelected={setSelected}
              setSelectedMenu={setSelectedMenu}
              setOpenChatContact={setOpenChatContact}
              onCloseProfile={setUserProfileToggle}
              onCloseMediaLinkDocs={setUserMediaLinkDocsToggle}
              openTepearsToggle={setUploadTepearsToggle}
              setUploadMedia={setSelectUploadMedia}
              setSelectedTeparsType={setSelectedTeparsType}
              openViewStory={setViewStory}
              setStory={setStory}
              setComingSoon={setComingSoon}
            />

            {/* <ScreenLock /> */}
            {/* <CreateGroup /> */}
            {/* <GroupMention /> */}
            {/* <NewGroup /> */}
            {/* <SelectGroupMention /> */}
            {/* <Archived /> */}
            {/* <Notifications /> */}
          </aside>

          {/* chat-content */}
          {/* <div className="content" style={{ backgroundColor: wallpaper }}> */}
          <div className="content">
            {selectedMenu === "chat" && (
              <>
                {chatBoxId ? (
                  <>
                    <Headerprofile
                      setUserProfileToggle={setUserProfileToggle}
                      getUserProfileData={setUserProfileData}
                      checkMediaToggle={userMediaLinkDocsToggle}
                      setUserMediaToggle={setUserMediaLinkDocsToggle}
                    />
                    {closeCamera === false && haveCamaraPermissions === true ? (
                      <TakePhoto
                        localstream={localstream}
                        setCloseCamera={setCloseCamera}
                        onImageSend={(e) => handleSendLiveImageMessage(e)}
                      />
                    ) : (
                      <>
                        {pictureInPicture && (
                          <VideoPlayer
                            video={pipVideo}
                            poster={pipVideoPoster}
                            getPIPVideoId={setPIPVideoId}
                            onClose={setPictureInPicture}
                          />
                        )}
                        {selectedMedia ? (
                          <UploadMedia
                            media={selectedMedia}
                            setPdfPagesCount={setPdfPagesCount}
                            onMediaSend={sendMessage}
                            onClose={setSelectedMedia}
                            onSendMedia={() => handleSendMediaMessage()}
                          />
                        ) : (
                          <>
                            <main
                              ref={messageContainerRef}
                              className="content-message"
                              onScroll={handleScroll}
                            >
                              <div className="message-date">
                                <p className="time">{toastMessage}</p>
                              </div>
                              {/* <VideoLink /> */}
                              <PollChat />
                              <div className="message">
                                {Messages?.map((message, index) => {
                                  // console.log(message, "All Messages...");
                                  const messageDate = new Date(
                                    message.date
                                  ).toDateString();
                                  const renderDateHeader =
                                    currentDate !== messageDate;
                                  currentDate = messageDate;
                                  if (message) {
                                    if (
                                      // For Receiver
                                      (message?.from === chatBoxId &&
                                        message?.to === userId) ||
                                      // For Sender
                                      (message?.from === userId &&
                                        message?.to === chatBoxId)
                                    ) {
                                      if (getMessageType(message) === TEXT) {
                                        return (
                                          <>
                                            {renderDateHeader && (
                                              <div className="message-date">
                                                {/* <p className="time">Sat, Jul 26</p> */}
                                                <p className="time">
                                                  {getTimetoDaytoDate(
                                                    message.timestamp,
                                                    "chat"
                                                  )}
                                                </p>
                                              </div>
                                            )}
                                            {message?.type === SENDER_DELETED ||
                                            message?.type ===
                                              RECEIVER_DELETED ? (
                                              <DeletedMessage
                                                message={message}
                                                forward={forward}
                                              />
                                            ) : (
                                              <Message
                                                key={index}
                                                message={message}
                                                tapped={setTapped}
                                                getTappedMessage={
                                                  setTappedMessage
                                                }
                                                getIds={setIds}
                                                getSenderMessageType={
                                                  setSenderMessageType
                                                }
                                                urlPreview={urlPreview}
                                                metadata={metadata}
                                                forward={forward}
                                                deleteMessage={deleteMessage}
                                              />
                                            )}
                                          </>
                                        );
                                      } else if (
                                        getMessageType(message) === LOCATION
                                      ) {
                                        return (
                                          <>
                                            {message?.SenderDeleted ||
                                            message?.ReceiverDeleted ? (
                                              <DeletedMessage
                                                message={message}
                                                forward={forward}
                                              />
                                            ) : (
                                              <Map
                                                key={index}
                                                message={message}
                                                tapped={setTapped}
                                                getTappedMessage={
                                                  setTappedMessage
                                                }
                                                getIds={setIds}
                                                getSenderMessageType={
                                                  setSenderMessageType
                                                }
                                                forward={forward}
                                                deleteMessage={deleteMessage}
                                              />
                                            )}
                                          </>
                                        );
                                      } else if (
                                        getMessageType(message) === CONTACT
                                      ) {
                                        return (
                                          <>
                                            {message?.SenderDeleted ||
                                            message?.ReceiverDeleted ? (
                                              <DeletedMessage
                                                message={message}
                                                forward={forward}
                                              />
                                            ) : (
                                              <Contact
                                                key={index}
                                                message={message}
                                                tapped={setTapped}
                                                getTappedMessage={
                                                  setTappedMessage
                                                }
                                                getSenderMessageType={
                                                  setSenderMessageType
                                                }
                                                getIds={setIds}
                                                viewAllContact={
                                                  setViewAllContact
                                                }
                                                getViewContacts={
                                                  setViewContacts
                                                }
                                                showGroups={setShowGroups}
                                                showForward={setShowForward}
                                                setForwardMessage={
                                                  setForwardMessage
                                                }
                                                setViewContactType={
                                                  setViewContactType
                                                }
                                                forward={forward}
                                                deleteMessage={deleteMessage}
                                              />
                                            )}
                                          </>
                                        );
                                      } else if (
                                        getMessageType(message) === IMAGE
                                      ) {
                                        return (
                                          <>
                                            {message?.SenderDeleted ||
                                            message?.ReceiverDeleted ? (
                                              <DeletedMessage
                                                message={message}
                                                forward={forward}
                                              />
                                            ) : (
                                              <Image
                                                key={index}
                                                message={message}
                                                progressCount={progressCount}
                                                setShowViewMedia={
                                                  setShowViewMedia
                                                }
                                                setcloseViewMedia={
                                                  setcloseViewMedia
                                                }
                                                tapped={setTapped}
                                                getTappedMessage={
                                                  setTappedMessage
                                                }
                                                getIds={setIds}
                                                getSenderMessageType={
                                                  setSenderMessageType
                                                }
                                                UpdateMediaStatusAtRealm={
                                                  UpdateMediaMessageAtRealm
                                                }
                                                forward={forward}
                                                deleteMessage={deleteMessage}
                                              />
                                            )}
                                          </>
                                        );
                                      } else if (
                                        getMessageType(message) === VIDEO
                                      ) {
                                        return (
                                          <>
                                            {message?.SenderDeleted ||
                                            message?.ReceiverDeleted ? (
                                              <DeletedMessage
                                                message={message}
                                                forward={forward}
                                              />
                                            ) : (
                                              <Videos
                                                key={index}
                                                message={message}
                                                setShowViewMedia={
                                                  setShowViewMedia
                                                }
                                                setcloseViewMedia={
                                                  setcloseViewMedia
                                                }
                                                show={showViewMedia}
                                                tapped={setTapped}
                                                getTappedMessage={
                                                  setTappedMessage
                                                }
                                                showForward={setShowForward}
                                                setForwardMessage={
                                                  setForwardMessage
                                                }
                                                isPIP={pictureInPicture}
                                                handlePIP={setPictureInPicture}
                                                getPIPVideo={setPIPVideo}
                                                getPIPVideoPoster={
                                                  setPIPVideoPoster
                                                }
                                                getPIPVideoId={setPIPVideoId}
                                                pipId={pipVideoId}
                                                getIds={setIds}
                                                getSenderMessageType={
                                                  setSenderMessageType
                                                }
                                                forward={forward}
                                                deleteMessage={deleteMessage}
                                              />
                                            )}
                                          </>
                                        );
                                      } else if (
                                        getMessageType(message) === DOCUMENT
                                      ) {
                                        return (
                                          <>
                                            {message?.SenderDeleted ||
                                            message?.ReceiverDeleted ? (
                                              <DeletedMessage
                                                message={message}
                                                forward={forward}
                                              />
                                            ) : (
                                              <Documents
                                                key={index}
                                                message={message}
                                                tapped={setTapped}
                                                getTappedMessage={
                                                  setTappedMessage
                                                }
                                                showForward={setShowForward}
                                                setForwardMessage={
                                                  setForwardMessage
                                                }
                                                getIds={setIds}
                                                getSenderMessageType={
                                                  setSenderMessageType
                                                }
                                                UpdateMediaStatusAtRealm={
                                                  UpdateMediaMessageAtRealm
                                                }
                                                forward={forward}
                                                deleteMessage={deleteMessage}
                                              />
                                            )}
                                          </>
                                        );
                                      } else if (
                                        getMessageType(message) === AUDIO
                                      ) {
                                        return (
                                          <>
                                            {message?.SenderDeleted ||
                                            message?.ReceiverDeleted ? (
                                              <DeletedMessage
                                                message={message}
                                                forward={forward}
                                              />
                                            ) : (
                                              <Audio
                                                key={index}
                                                message={message}
                                                tapped={setTapped}
                                                getTappedMessage={
                                                  setTappedMessage
                                                }
                                                getSenderMessageType={
                                                  setSenderMessageType
                                                }
                                                getIds={setIds}
                                                showForward={setShowForward}
                                                setForwardMessage={
                                                  setForwardMessage
                                                }
                                                forward={forward}
                                                deleteMessage={deleteMessage}
                                              />
                                            )}
                                          </>
                                        );
                                      } else if (
                                        getMessageType(message) === REPLY_TEXT
                                      ) {
                                        return (
                                          <ChatReply
                                            message={message}
                                            messages={Messages}
                                            forward={forward}
                                          />
                                        );
                                      }
                                    }
                                  }
                                  return null;
                                })}
                              </div>
                            </main>
                            <footer className="message-box">
                              {tapped && (
                                <MessageReply
                                  onClose={setTapped}
                                  tappedMessage={tappedMessage}
                                  clearTappedMessage={setTappedMessage}
                                  resetTappedMessageType={setSenderMessageType}
                                />
                              )}
                              {urlPreview && metadata && (
                                <SocialLinkPreview
                                  metaData={metadata}
                                  showUrlPreview={setUrlPreview}
                                />
                              )}
                              <div className="message-container">
                                <div
                                  className="emoji"
                                  onClick={() => setShowPicker(!showPicker)}
                                />
                                {audio ? (
                                  <div
                                    className={`message-wrapper2 ${
                                      audio ? "translate" : ""
                                    }`}
                                  >
                                    <div className="btns">
                                      <button
                                        className="btn delete"
                                        onClick={handleDeleteAudio}
                                      >
                                        <svg
                                          width="35"
                                          height="35"
                                          viewBox="0 0 35 35"
                                          fill="none"
                                        >
                                          <title>Delete</title>
                                          <path
                                            d="M8.74935 27.7083C8.74935 29.3125 10.0618 30.625 11.666 30.625H23.3327C24.9368 30.625 26.2493 29.3125 26.2493 27.7083V10.2083H8.74935V27.7083ZM27.7077 5.83333H22.6035L21.1452 4.375H13.8535L12.3952 5.83333H7.29102V8.75H27.7077V5.83333Z"
                                            fill="#E72915"
                                          />
                                        </svg>
                                      </button>
                                      {checkaudio === false && (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <button
                                            className="btn btn-pause"
                                            onClick={() => {
                                              toggleTimer();
                                              setCheckAudio(!checkaudio);
                                              showrecording();
                                            }}
                                          >
                                            <svg
                                              width="25"
                                              height="25"
                                              viewBox="0 0 35 35"
                                              fill="none"
                                            >
                                              <rect
                                                x="-1.1413"
                                                y="1.1413"
                                                width="32.7174"
                                                height="32.7174"
                                                rx="16.3587"
                                                transform="matrix(-1 0 0 1 32.7174 0)"
                                                stroke="#1891A2"
                                                strokeWidth="2.28261"
                                              />
                                              <path
                                                d="M14.2032 23.2742C15.1099 23.2742 15.8518 22.5324 15.8518 21.6257V13.3829C15.8518 12.4762 15.1099 11.7344 14.2032 11.7344C13.2965 11.7344 12.5547 12.4762 12.5547 13.3829V21.6257C12.5547 22.5324 13.2965 23.2742 14.2032 23.2742ZM19.1489 13.3829V21.6257C19.1489 22.5324 19.8907 23.2742 20.7974 23.2742C21.7041 23.2742 22.446 22.5324 22.446 21.6257V13.3829C22.446 12.4762 21.7041 11.7344 20.7974 11.7344C19.8907 11.7344 19.1489 12.4762 19.1489 13.3829Z"
                                                fill="#1891A2"
                                              />
                                            </svg>
                                          </button>
                                          <div className="canve_waves">
                                            <canvas
                                              ref={canvasRef}
                                              width={200}
                                              height={30}
                                            />
                                          </div>
                                          <span id="countdown">
                                            {minutes < 10 ? "0" : ""}
                                            {minutes}:{seconds < 10 ? "0" : ""}
                                            {seconds}
                                          </span>
                                        </div>
                                      )}

                                      {checkaudio === true && (
                                        <div className="canvasAudio couser_parent">
                                          <button
                                            className="btn-play"
                                            id="htmlPlayButton"
                                            onClick={() => {
                                              toggleVisibility();
                                              toggleAudioPlayback();
                                            }}
                                          >
                                            {!playing && (
                                              <svg
                                                width="35"
                                                height="35"
                                                viewBox="0 0 35 35"
                                                fill="none"
                                                onClick={() =>
                                                  handlePlayPause()
                                                }
                                              >
                                                <path
                                                  d="M14.334 12.75L22.2506 17.5L14.334 22.25V12.75Z"
                                                  fill="#1891A2"
                                                  stroke="#1891A2"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <rect
                                                  x="-1.1413"
                                                  y="1.1413"
                                                  width="32.7174"
                                                  height="32.7174"
                                                  rx="16.3587"
                                                  transform="matrix(-1 0 0 1 32.7174 0)"
                                                  stroke="#1891A2"
                                                  strokeWidth="2.28261"
                                                />
                                              </svg>
                                            )}
                                            {playing && (
                                              <svg
                                                width="35"
                                                height="35"
                                                viewBox="0 0 35 35"
                                                fill="none"
                                                onClick={() =>
                                                  handlePlayPause()
                                                }
                                              >
                                                <rect
                                                  x="-1.1413"
                                                  y="1.1413"
                                                  width="32.7174"
                                                  height="32.7174"
                                                  rx="16.3587"
                                                  transform="matrix(-1 0 0 1 32.7174 0)"
                                                  stroke="#1891A2"
                                                  strokeWidth="2.28261"
                                                />
                                                <path
                                                  d="M14.2032 23.2742C15.1099 23.2742 15.8518 22.5324 15.8518 21.6257V13.3829C15.8518 12.4762 15.1099 11.7344 14.2032 11.7344C13.2965 11.7344 12.5547 12.4762 12.5547 13.3829V21.6257C12.5547 22.5324 13.2965 23.2742 14.2032 23.2742ZM19.1489 13.3829V21.6257C19.1489 22.5324 19.8907 23.2742 20.7974 23.2742C21.7041 23.2742 22.446 22.5324 22.446 21.6257V13.3829C22.446 12.4762 21.7041 11.7344 20.7974 11.7344C19.8907 11.7344 19.1489 12.4762 19.1489 13.3829Z"
                                                  fill="#1891A2"
                                                />
                                              </svg>
                                            )}
                                          </button>
                                          <div
                                            id="waveform"
                                            className={`recorded-audio-waveform ${
                                              recordedBlobs.length
                                                ? "visible"
                                                : ""
                                            }`}
                                            style={{ margin: "1rem 0" }}
                                            ref={recordingsContainerRef}
                                          />
                                          {/* <span>{`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`}</span> */}
                                          <div
                                            id="duration"
                                            className={
                                              !isContentVisible
                                                ? "audio-duration-visibility"
                                                : ""
                                            }
                                          >
                                            0:00
                                          </div>
                                          <div
                                            id="time"
                                            className={
                                              isContentVisible
                                                ? "audio-duration-visibility"
                                                : ""
                                            }
                                          >
                                            00:00
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="message-wrapper">
                                    <div className="input-wrapper">
                                      <InputEmoji
                                        ref={inputRef}
                                        value={message}
                                        onChange={(e) => {
                                          setMessage(e);
                                          sendMessage("", true, "");
                                          checkIsLink(e);
                                          setTimeout(() => {
                                            sendMessage("", false, "");
                                          }, 3000);
                                        }}
                                        cleanOnEnter
                                        onEnter={() => {
                                          sendMessage("", false, "");
                                          handleButtonClick();
                                        }}
                                        keepOpened={true}
                                        placeholder="Type a message"
                                        perLine={10}
                                        theme={
                                          theme === "Light"
                                            ? "light"
                                            : theme === "Dark"
                                            ? "dark"
                                            : window.matchMedia(
                                                "(prefers-color-scheme: light)"
                                              ).matches
                                            ? "light"
                                            : "dark"
                                        }
                                      />
                                    </div>
                                    <div className="btns">
                                      <button
                                        className="btn btn-files"
                                        ref={sharemenubuttonRef}
                                        onClick={async () => {
                                          await checkPermissions();
                                          await OpenFileShareMenu();
                                        }}
                                      >
                                        <svg
                                          width="10"
                                          height="18"
                                          viewBox="0 0 11 19"
                                          fill="none"
                                        >
                                          <title>Attach</title>
                                          <path
                                            d="M1.01298 13.5115C1.41103 13.5115 1.73149 13.1772 1.73149 12.7619L1.73149 5.25615C1.73149 3.18353 3.34056 1.50151 5.33082 1.50151C7.32108 1.50151 8.93015 3.18353 8.93015 5.25615L8.93015 14.2645C8.93015 15.5067 7.96538 16.5166 6.77123 16.5166C5.57707 16.5166 4.61231 15.5067 4.61231 14.2645L4.61231 6.7552C4.61231 6.33997 4.93614 6.00568 5.33082 6.00568C5.7255 6.00568 6.04934 6.33997 6.04934 6.7552L6.04934 12.7619C6.04934 13.1772 6.37318 13.5115 6.76786 13.5115C7.16591 13.5115 7.48637 13.1772 7.48637 12.7619L7.48637 6.7552C7.48637 5.50951 6.5216 4.50311 5.32745 4.50311C4.13329 4.50311 3.16852 5.50951 3.16852 6.7552L3.16852 14.261C3.1719 16.3336 4.78435 18.0156 6.77123 18.0156C8.75811 18.0156 10.3672 16.3336 10.3672 14.261L10.3672 5.25264C10.3672 2.34956 8.11044 -0.00105648 5.33082 -0.00105623C2.55121 -0.00105599 0.294458 2.35308 0.294458 5.25264L0.294459 12.7619C0.294459 13.1772 0.614924 13.5115 1.01298 13.5115Z"
                                            fill="#1891A2"
                                          />
                                        </svg>

                                        <FileShareMenu
                                          isOpen={isOpenFileMenu}
                                          ref={sharemenuRef}
                                          setHaveCamaraPermissions={
                                            setHaveCamaraPermissions
                                          }
                                          setAskPermission={setAskPermission}
                                          setcloseAlert={setcloseAlert}
                                          setCloseCamera={setCloseCamera}
                                          setlocalstream={setlocalstream}
                                          localstream={localstream}
                                          onClose={() =>
                                            setisOpenFileMenu(false)
                                          }
                                          showContact={setShowContact}
                                          handleFileShareMenu={
                                            OpenFileShareMenu
                                          }
                                          getSelectedMedia={setSelectedMedia}
                                          onMediaSend={sendMessage}
                                        />
                                      </button>
                                      <button
                                        className="btn btn-audio"
                                        onClick={async () => {
                                          await getMicrophonePermissions();
                                          await startRecording();
                                        }}
                                      >
                                        <svg
                                          width="13"
                                          height="18"
                                          viewBox="0 0 14 19"
                                          fill="none"
                                        >
                                          <path
                                            d="M7.04532 11.379C6.25914 11.379 5.59089 11.1024 5.04057 10.5492C4.49025 9.9961 4.21509 9.32442 4.21509 8.53421V2.84474C4.21509 2.05453 4.49025 1.38286 5.04057 0.829715C5.59089 0.276572 6.25914 0 7.04532 0C7.8315 0 8.49974 0.276572 9.05007 0.829715C9.60039 1.38286 9.87555 2.05453 9.87555 2.84474V8.53421C9.87555 9.32442 9.60039 9.9961 9.05007 10.5492C8.49974 11.1024 7.8315 11.379 7.04532 11.379ZM7.04532 18.0167C6.77802 18.0167 6.5538 17.9256 6.37267 17.7436C6.19153 17.5615 6.10128 17.3365 6.10191 17.0684V15.1008C4.63962 14.8954 3.39746 14.279 2.37544 13.2517C1.35341 12.2245 0.732327 10.9918 0.512198 9.55358C0.465027 9.28491 0.535784 9.04785 0.724466 8.8424C0.913148 8.63694 1.16472 8.53421 1.47919 8.53421C1.69932 8.53421 1.89587 8.61734 2.06883 8.7836C2.24178 8.94986 2.35185 9.15121 2.39902 9.38764C2.60343 10.4939 3.13803 11.4185 4.00282 12.1613C4.86761 12.904 5.88178 13.2754 7.04532 13.2754C8.20886 13.2754 9.22303 12.904 10.0878 12.1613C10.9526 11.4185 11.4872 10.4939 11.6916 9.38764C11.7388 9.15057 11.8526 8.94892 12.0331 8.78266C12.2136 8.6164 12.4143 8.53358 12.635 8.53421C12.9338 8.53421 13.1775 8.63694 13.3662 8.8424C13.5549 9.04785 13.6256 9.28491 13.5784 9.55358C13.3583 10.9918 12.7372 12.2245 11.7152 13.2517C10.6932 14.279 9.45102 14.8954 7.98873 15.1008V17.0684C7.98873 17.3371 7.89816 17.5625 7.71703 17.7445C7.53589 17.9266 7.31199 18.0173 7.04532 18.0167ZM7.04532 9.48246C7.31262 9.48246 7.53684 9.39143 7.71797 9.20937C7.89911 9.0273 7.98936 8.80225 7.98873 8.53421V2.84474C7.98873 2.57607 7.89816 2.3507 7.71703 2.16864C7.53589 1.98658 7.31199 1.89586 7.04532 1.89649C6.77802 1.89649 6.5538 1.98752 6.37267 2.16959C6.19153 2.35165 6.10128 2.5767 6.10191 2.84474V8.53421C6.10191 8.80289 6.19248 9.02825 6.37361 9.21032C6.55475 9.39238 6.77865 9.48309 7.04532 9.48246Z"
                                            fill="#1891A2"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <div
                                  role="button"
                                  className="send-message"
                                  onClick={handleButtonClick}
                                  tabIndex="0"
                                >
                                  <svg
                                    width="45"
                                    height="45"
                                    viewBox="0 0 46 46"
                                    fill="none"
                                  >
                                    <rect
                                      width="45"
                                      height="45"
                                      rx="23"
                                      transform="matrix(-1 0 0 1 46 0)"
                                      fill="#1891A2"
                                    />
                                    <path
                                      d="M18.8886 31.6163L26.34 24.3652C27.22 23.5088 27.22 22.1075 26.34 21.2512L18.8886 14"
                                      stroke="white"
                                      strokeWidth="1.5"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </footer>
                            {/* <ForwardCta /> */}
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Default MenuType={selectedMenu} />
                  // <UnkownContact />
                )}
              </>
            )}
            {selectedMenu === "tepars" && (
              <TeparsView
                postContainerRef={postContainerRef}
                selectedMenu={selectedMenu}
                selectedTeparsType={selectedTeparsType}
                sound={sound}
                setSound={setSound}
                setSelectedMenu={setSelectedMenu}
                teparsVideoThumb={teparsVideoThumb}
                progress={progress}
                handleCancelUpload={handleCancelUpload}
              />
            )}
            {selectedMenu === "profile" && (
              <TeparsView
                postContainerRef={postContainerRef}
                selectedMenu={selectedMenu}
                selectedTeparsType={selectedTeparsType}
                sound={sound}
                setSound={setSound}
                setSelectedMenu={setSelectedMenu}
                teparsVideoThumb={teparsVideoThumb}
                progress={progress}
                selected={selected}
                handleCancelUpload={handleCancelUpload}
              />
            )}
          </div>

          {/* main-info */}
          {/* <div className="info-hide"> */}
          {userProfileToggle && (
            <GroupInfo
              onClose={setUserProfileToggle}
              openMediaLinksDocs={setUserMediaLinkDocsToggle}
              userProfileData={userProfileData}
            />
            // <SearchMessages />
            // <MessageInfo />
            // <GroupSettings />
            // <DisappearingMessage />
            // <EncryptionInfo />
            // <StarredMessages />
            // <InviteGroupLink />
            // <GroupChatSettings />
            // <PollDetails />
          )}
          {userMediaLinkDocsToggle && (
            <MediaLinksDocs onClose={setUserMediaLinkDocsToggle} />
          )}
          {/* </div> */}
        </div>
      )}
      {showContact && (
        <SendContact
          ref={sendContactRef}
          onClose={setShowContact}
          onContactSend={setViewAllContact}
          selectedContacts={setViewContacts}
          SelectedContactName={setSelectedContactName}
          setViewContactType={setViewContactType}
        />
      )}
      {viewAllContact && (
        <ViewContacts
          ref={viewContactRef}
          onClose={setViewAllContact}
          contacts={viewContacts}
          selectedContactName={selectedContactName}
          onContactSend={() => handleSendContactMessage()}
          viewContactType={viewContactType}
        />
      )}
      {showGroups && <Groups onClose={setShowGroups} />}
      {showForward && (
        <ForwardMessage
          onClose={setShowForward}
          forwaredMessage={forwardMessage}
        />
      )}
      {viewNotifyPost && (
        <ViewNotificationPost
          post={post}
          postDetail={postDetail}
          setSelectedMenu={setSelectedMenu}
          toggleSelfLike={(id) => toggleLike(id)}
          onClose={() => setViewNotifyPost(false)}
          showAnimation={showAnimation}
          setShowAnimation={setShowAnimation}
        />
      )}
      {uploadTepearsToggle && (
        <UploadTepears
          onClose={setUploadTepearsToggle}
          setUploadMedia={selectUploadMedia}
          getDescription={getDescription}
          setGetDescription={setGetDescription}
          getVisibility={getVisibility}
          setGetVisibility={setGetVisibility}
          privacy={privacy}
          setPrivacy={setPrivacy}
          getRePosting={getRePosting}
          setGetRePosting={setGetRePosting}
          getOffComment={getOffComment}
          setGetOffComment={setGetOffComment}
          getCanReplyPost={getCanReplyPost}
          setGetCanReplyPost={setGetCanReplyPost}
          sharedGroups={sharedGroups}
          setSharedGroups={setSharedGroups}
          getSelectedLocation={getSelectedLocation}
          setGetSelectedLocation={setGetSelectedLocation}
          exceptContact={exceptContact}
          setExceptContact={setExceptContact}
          onlyShareContact={onlyShareContact}
          setOnlyShareContact={setOnlyShareContact}
          setCropAspectForUpload={setCropAspectForUpload}
          HandlePostUpload={(imgAndVideoMedia) =>
            HandlePostUpload(imgAndVideoMedia)
          }
        />
      )}
      {(togglePost === "EditPost" || togglePost === "RePost") && (
        <EditPostPrivacy onClose={() => dispatch(setTogglePostAction(null))} />
      )}
      {togglePost === "ViewPost" && (
        <ViewPost
          setSelectedMenu={setSelectedMenu}
          toggleSelfLike={(id) => toggleLike(id)}
          showAnimation={showAnimation}
          setShowAnimation={setShowAnimation}
        />
      )}

      {viewStory && <ViewStory story={story} onClose={setViewStory} />}
      {toast && <MessageCtaNotification type={"info"} message={toast} />}
      {showDeleteNotification && (
        <DeleteNotification
          deleteNotification={() => deleteNotification()}
          closeNotification={() => setShowDeleteNotification(false)}
        />
      )}
      {comingSoon && <ComingSoon onClose={() => setComingSoon(false)} />}
      {viewProfilePhoto && (
        <ProfileAvatarView
          user={viewProfilePhoto}
          close={() => dispatch(setViewProfilePhoto(null))}
        />
      )}
      {/* <SetDevicePassword /> */}
      {/* <EditMessage /> */}
      {/* <ForwardMessage /> */}
      {/* <ReportContact /> */}
      {/* <MessageNotSent /> */}
      {/* <CreatePoll /> */}
      {toggleViewOtherProfile && <ViewOtherProfile />}
    </>
  );
}

export default MainChatApp;
