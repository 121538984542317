import React, { useEffect, useRef, useState } from 'react'
import { getTepars } from "../../Utils/helpers";

function SliderPopupVideo({
    index,
    mediaIndexFinal,
    media,
    handleLoadedMetadata,
    handlePlayPauseToggle,
    disableRightClick,
    setIsPaused,
}) {

    const videoRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [objectFit, setObjectFit] = useState('contain');

    const handleLoadedData = () => {
        setIsLoading(false);
        if (videoRef.current) {
            const { videoWidth, videoHeight } = videoRef.current;
            setObjectFit(getObjectFit(videoWidth, videoHeight));
        }
    };

    const getObjectFit = (width, height) => {
        if (width === height) {
            return "contain";
        } else if (width < height) {
            return "cover";
        } else {
            return "contain";
        }
    };

    useEffect(() => {
        if (videoRef.current && videoRef.current.readyState === 4) {
            setIsLoading(false);
            videoRef.current.play().catch(error => console.error("Autoplay failed:", error));
        }
    }, []);

    return (
        <>
            <video
                ref={videoRef}
                loop={true}
                id={`${media._id}-${index}`}
                className="upload-video"
                src={getTepars(media)}
                autoPlay={index === mediaIndexFinal}
                controls={false}
                width="100%"
                height="100%"
                onLoadedData={handleLoadedData}
                onLoadedMetadata={(e) => handleLoadedMetadata(e, media, index)}
                onPlay={() => setIsPaused(prev => ({ ...prev, [media._id]: false }))}
                onPause={() => setIsPaused(prev => ({ ...prev, [media._id]: true }))}
                onClick={() => handlePlayPauseToggle(media._id, index)}
                onContextMenu={disableRightClick}
                style={{
                    opacity: isLoading ? 0 : 1,
                    transition: 'opacity 150ms ease-in-out',
                    objectFit: objectFit
                }}
            />
        </>
    )
}
export default SliderPopupVideo;