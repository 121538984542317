import React from "react";
import "../../assets/css/deleteNotification.scss";

const DeleteNotification = ({ deleteNotification, closeNotification }) => {
  return (
    <div className="delete-notification">
      <div className="dialog">
        <div className="dialog-info">
          <h4 className="title">Notification</h4>
        </div>
        <p className="description">
          Are you sure you want to delete this notification?
        </p>

        <div className="delete-notification-cta">
          <button className="btn btn-cancel" onClick={closeNotification}>
            Cancel
          </button>

          <button
            className="btn btn-delete active"
            onClick={deleteNotification}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteNotification;
