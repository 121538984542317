import { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../Tepars/Crop";

const ProfileCrop = ({ image, setCropImage, index, zoom, setZoomValue }) => {
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );

      setCropImage(croppedImage, index);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image]);

  useEffect(() => {
    if (
      !isNaN(croppedAreaPixels?.width) &&
      !isNaN(croppedAreaPixels?.height) &&
      !isNaN(croppedAreaPixels?.x) &&
      !isNaN(croppedAreaPixels?.y)
    ) {
      showCroppedImage();
    }
    return () => {};
  }, [croppedAreaPixels]);

  return (
    <div className="container">
      <div className="crop-container">
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          zoomSpeed={1}
          maxZoom={3}
          zoomWithScroll={true}
          showGrid={true}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoomValue}
          onRotationChange={setRotation}
          objectFit={"contain"}
          cropShape={"round"}
        />
      </div>
    </div>
  );
};

export default ProfileCrop;
