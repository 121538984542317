import React, { useEffect } from "react";

const TeparsDelete = ({ postId, confirmDelete, cancelDelete }) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        confirmDelete(postId);
      }
    };

    // Attach the event listener when the component is mounted
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [postId, confirmDelete]);
  return (
    <div className="deleteTepars">
      <div className="dialog-info">
        <h4 className="title">Delete post?</h4>
      </div>
      <p className="description">Are you sure you want to delete this post?</p>

      <div className="discardPost-cta">
        <button className="btn btn-cancel" onClick={cancelDelete}>
          Cancel
        </button>

        <button
          className="btn btn-delete active"
          onClick={() => confirmDelete(postId)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default TeparsDelete;
