import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import car from "../../assets/image/car.png";
import { getUserProfilePic, splitUserID } from "../../Utils/helpers";
import "../../assets/css/forwardMessage.scss";

function ForwardMessage({ onClose, forwaredMessage }) {
  const Contacts = useSelector((state) => state.savedContacts);
  const [searchValue, setSearchValue] = useState("");
  const [contactList, setContactList] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  const [selectedContactName, setSelectedContactName] = useState([]);

  useEffect(() => {
    setContactList(Contacts);
  }, [Contacts]);

  const handleSearchInputChange = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = Contacts?.filter((contact) => {
        return (
          contact?.name.toLowerCase().startsWith(keyword.toLowerCase()) ||
          contact?.User?.phone.toString().startsWith(keyword.toString())
        );
      });
      setContactList(results);
    } else {
      setContactList(Contacts);
    }
    setSearchValue(keyword);
  };

  const handleChange = (e, contact) => {
    var _id = splitUserID(contact?._id);
    if (e.target.checked) {
      setSelectedContact([
        ...selectedContact,
        {
          _id: _id,
          countryCode: contact?.countryCode,
          contactName: contact?.name,
          contactNumber: contact?.phone,
          profilePic: getUserProfilePic(contact?.profilePic),
        },
      ]);

      setSelectedContactName([
        selectedContactName.length > 0
          ? contact?.name + ", "
          : contact?.name + " ",
        ...selectedContactName,
      ]);
    }
    if (!e.target.checked) {
      const nameList = selectedContactName.filter((name) => {
        return name.replace(",", "").trim() !== contact?.name.toString();
      });
      const contactList = selectedContact.filter((name) => {
        return name?.contactName !== contact?.name;
      });
      setSelectedContactName(nameList);
      setSelectedContact(contactList);
    }
  };

  const onSendContact = () => {
    onClose(false);
  };

  const getSelectedName = () => {
    var selectedName = selectedContactName?.slice(0, 3);
    var formattedName = [];
    for (let i = 0; i < selectedName?.length; i++) {
      let str = selectedName[i];
      formattedName.push(" " + str.replace(",", "").trim());
    }
    return formattedName;
  };

  return (
    <div className="forwardMessage">
      <div className="forwardMessage-container">
        <header className="forward-to">
          <div className="forward-to-container">
            <h2 className="title">Forward message to</h2>
            <button className="btn btn-close" onClick={() => onClose(false)}>
              <svg width="24" height="24" viewBox="0 0 30 29" fill="none">
                <title>Close</title>
                <path
                  d="M24.072 5.50734L24.1924 5.61254C24.7728 6.17362 24.8091 7.06152 24.3012 7.66346L24.1924 7.77985L17.242 14.4985L24.1924 21.2172C24.7728 21.7783 24.8091 22.6662 24.3012 23.2681L24.1924 23.3845C23.612 23.9456 22.6934 23.9806 22.0707 23.4897L21.9503 23.3845L15 16.6658L8.04966 23.3845C7.46923 23.9456 6.55071 23.9806 5.92802 23.4897L5.80761 23.3845C5.22718 22.8234 5.19091 21.9355 5.69878 21.3336L5.80761 21.2172L12.758 14.4985L5.80761 7.77985C5.22718 7.21877 5.19091 6.33086 5.69878 5.72893L5.80761 5.61254C6.38804 5.05146 7.30656 5.01639 7.92925 5.50734L8.04966 5.61254L15 12.3312L21.9503 5.61254C22.5308 5.05146 23.4493 5.01639 24.072 5.50734L24.1924 5.61254L24.072 5.50734Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </header>
        <div className="forward-search">
          
          <div className="search-bar">
            <svg
              width="12"
              height="12"
              viewBox="0 0 16 16"
              fill="none"
              className="search"
            >
              <path
                d="M6.3833 12.7666C7.76123 12.7666 9.04785 12.3184 10.0938 11.5713L14.0283 15.5059C14.2109 15.6885 14.4517 15.7798 14.7007 15.7798C15.2402 15.7798 15.6304 15.3647 15.6304 14.8335C15.6304 14.5845 15.5474 14.3521 15.3647 14.1694L11.4551 10.2515C12.2769 9.17236 12.7666 7.83594 12.7666 6.3833C12.7666 2.87207 9.89453 0 6.3833 0C2.86377 0 0 2.87207 0 6.3833C0 9.89453 2.86377 12.7666 6.3833 12.7666ZM6.3833 11.3887C3.63574 11.3887 1.37793 9.12256 1.37793 6.3833C1.37793 3.64404 3.63574 1.37793 6.3833 1.37793C9.12256 1.37793 11.3887 3.64404 11.3887 6.3833C11.3887 9.12256 9.12256 11.3887 6.3833 11.3887Z"
                fill="#8E8E93"
              />
            </svg>

            <svg
              width="12"
              height="12"
              viewBox="0 0 22 22"
              fill="none"
              className="back"
              style={{ display: "none" }}
            >
              <path
                d="M10.4997 1.39844L1.39844 10.998L10.4997 20.5984M20.5984 10.998H1.68644"
                stroke="#1891A2"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <input
              type="search"
              placeholder="Search contact"
              value={searchValue}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <div className="forward-contacts">
          {contactList && contactList?.length > 0 ? (
            contactList?.map((contact, i) => (
              <label htmlFor={i}>
                <div key={i} className="profile" tabIndex="0">
                  <div className="profile-image">
                    <img
                      className="profile-avatar"
                      src={getUserProfilePic(contact?.User?.profilePic)}
                      alt="profile thumbnail"
                    />
                  </div>
                  <div className="profile-info">
                    <div className="profile-info-name">
                      <p className="profile-name">{contact?.name}</p>
                      <p className="profile-info-message">
                        {contact?.user.bio}
                      </p>
                    </div>
                    <div className="profile-check">
                      <input
                        type="checkbox"
                        name="group"
                        id={i}
                        aria-label="Check this box"
                        value=""
                        onChange={(e) => handleChange(e, contact?.User)}
                      />
                      <span className="checkmark" />
                    </div>
                  </div>
                </div>
              </label>
            ))
          ) : (
            <div className="contact-notFound">
              {/* <span>No chats, contacts or messages found</span> */}
              <span>No contacts found</span>
            </div>
          )}
        </div>
        <div className="forward-message-media">
          <div className="forward-media">
            <div className="media">
              <img className="selected-media" src={car} alt="forwared media" />
              <div className="media-type">
                <svg width="16" height="16" viewBox="0 0 30 29" fill="none">
                  <title>Cancel</title>
                  <path
                    d="M24.072 5.50734L24.1924 5.61254C24.7728 6.17362 24.8091 7.06152 24.3012 7.66346L24.1924 7.77985L17.242 14.4985L24.1924 21.2172C24.7728 21.7783 24.8091 22.6662 24.3012 23.2681L24.1924 23.3845C23.612 23.9456 22.6934 23.9806 22.0707 23.4897L21.9503 23.3845L15 16.6658L8.04966 23.3845C7.46923 23.9456 6.55071 23.9806 5.92802 23.4897L5.80761 23.3845C5.22718 22.8234 5.19091 21.9355 5.69878 21.3336L5.80761 21.2172L12.758 14.4985L5.80761 7.77985C5.22718 7.21877 5.19091 6.33086 5.69878 5.72893L5.80761 5.61254C6.38804 5.05146 7.30656 5.01639 7.92925 5.50734L8.04966 5.61254L15 12.3312L21.9503 5.61254C22.5308 5.05146 23.4493 5.01639 24.072 5.50734L24.1924 5.61254L24.072 5.50734Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div className="input-wrapper">
              <p
                placeholder="Type Message.."
                className="messageInput"
                contentEditable
              />
            </div>
          </div>

          {selectedContactName.length > 0 && (
            <div className="forward-message">
              <div className="forward-message-names">
                <span className="name">
                  {selectedContactName?.length > 2
                    ? getSelectedName() + " ..."
                    : selectedContactName}
                </span>
              </div>
              <div
                role="button"
                className="send-message"
                onClick={() => onSendContact()}
                tabIndex="0"
              >
                <svg width="40" height="40" viewBox="0 0 46 46" fill="none">
                  <rect
                    width="46"
                    height="46"
                    rx="23"
                    transform="matrix(-1 0 0 1 46 0)"
                    fill="#1891A2"
                  />
                  <path
                    d="M19.6903 33.2L28.2425 24.8854C29.2525 23.9034 29.2525 22.2966 28.2425 21.3146L19.6903 13"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForwardMessage;
