import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SENDER_TEXT,
  MESSAGE_TYPE,
  SENDER_AUDIO,
  SENDER_VIDEO,
  SENDER_IMAGE,
  RECEIVER_TEXT,
  RECEIVER_AUDIO,
  RECEIVER_IMAGE,
  RECEIVER_VIDEO,
  SENDER_CONTACT,
  SENDER_DOCUMENT,
  RECEIVER_CONTACT,
  RECEIVER_DOCUMENT,
} from "../../../Utils/enums";
import {
  TN_CHAT_IMAGE_BASE_URL,
  TN_THUMB_VIDEO_IMAGE_BASE_URL,
} from "../../../Utils/helpers";
import "../../../assets/css/replyMedia.scss";

function ReplyMedia({
  onClose,
  tappedMessage,
  clearTappedMessage,
  resetTappedMessageType,
}) {
  const dispatch = useDispatch();
  const [media, setMedia] = useState();
  const userId = localStorage.getItem("user_id");
  const chatBoxContact = useSelector((state) => state.chatBoxContact);
  const MessageMedia = useSelector((state) => state.messageMedia);
  const MessageContacts = useSelector((state) => state.messageContacts);
  const messageType = MESSAGE_TYPE.find((type) => tappedMessage?.type === type);
  const [contacts, setContacts] = useState([]);
  const userName =
    tappedMessage?.type === messageType && tappedMessage?.senderID === userId
      ? "You"
      : chatBoxContact?.contact?.name;

  useEffect(() => {
    const media = MessageMedia?.find((media) => {
      return media?.messageId === tappedMessage?._id;
    });
    setMedia(media);
  }, []);
  // useEffect(() => {
  //   const getContacts = async () => {
  //     var messageIds = [];
  //     const contacts = MessageContacts?.filter((contact) => {
  //       return contact?.messageId === tappedMessage?._id;
  //     });
  //     contacts?.map((contact) =>
  //       messageIds.push(`${contact?.number}-${contact?.owner_id}`)
  //     );
  //     const MsgContacts = await fetchMessageContactsByIds(dispatch, messageIds);
  //     setContacts(MsgContacts);
  //   };
  //   getContacts();
  // }, [tappedMessage, MessageContacts]);

  const getContacts = () => {
    if (contacts?.length === 1) {
      return "Contact: " + contacts[0]?.name;
    } else {
      return contacts?.length + " Contacts";
    }
  };

  const handleClose = () => {
    onClose(false);
    clearTappedMessage(null);
    resetTappedMessageType(SENDER_TEXT);
  };
  return (
    <>
      {/* Text */}
      {(messageType === RECEIVER_TEXT || messageType === SENDER_TEXT) && (
        <div className="reply">
          <div className="wrapper">
            <div className="info">
              <p className="sender">{userName}</p>
              <div className="description">
                <p>{tappedMessage?.message}</p>
              </div>
            </div>
            <div
              className="btn btn-cancel"
              onClick={() => handleClose()}
              tabIndex="0"
              aria-label="Cancel"
              role="button"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <title>Cancel</title>
                <path
                  d="M3 16L9.5 9.5L16 16M16 3L9.49876 9.5L3 3"
                  stroke="#999999"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
      {/* Audio */}
      {(messageType === RECEIVER_AUDIO || messageType === SENDER_AUDIO) && (
        <div className="reply">
          <div className="wrapper">
            <div className="info">
              <p className="sender">{userName}</p>
              <div className="description">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
                  <path
                    d="M1.27053 6.35263H3.17632C3.51328 6.35263 3.83644 6.48649 4.07471 6.72476C4.31298 6.96303 4.44684 7.28619 4.44684 7.62316V10.7995C4.44684 11.1364 4.31298 11.4596 4.07471 11.6979C3.83644 11.9361 3.51328 12.07 3.17632 12.07H1.27053C0.933562 12.07 0.610398 11.9361 0.372128 11.6979C0.133859 11.4596 0 11.1364 0 10.7995V6.35263C0 2.84407 2.84407 0 6.35263 0C9.86119 0 12.7053 2.84407 12.7053 6.35263V10.7995C12.7053 11.1364 12.5714 11.4596 12.3331 11.6979C12.0949 11.9361 11.7717 12.07 11.4347 12.07H9.52894C9.19198 12.07 8.86882 11.9361 8.63055 11.6979C8.39228 11.4596 8.25842 11.1364 8.25842 10.7995V7.62316C8.25842 7.28619 8.39228 6.96303 8.63055 6.72476C8.86882 6.48649 9.19198 6.35263 9.52894 6.35263H11.4347C11.4347 5.00477 10.8993 3.71212 9.94622 2.75904C8.99314 1.80596 7.70049 1.27053 6.35263 1.27053C5.00477 1.27053 3.71212 1.80596 2.75904 2.75904C1.80596 3.71212 1.27053 5.00477 1.27053 6.35263Z"
                    fill="#8E8E93"
                  />
                </svg>
                <span>Audio</span>
              </div>
            </div>
            <div
              className="btn btn-cancel"
              onClick={() => handleClose()}
              tabIndex="0"
              role="button"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <title>Cancel</title>
                <path
                  d="M3 16L9.5 9.5L16 16M16 3L9.49876 9.5L3 3"
                  stroke="#999999"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
      {/* Video */}
      {(messageType === RECEIVER_VIDEO || messageType === SENDER_VIDEO) && (
        <div className="reply">
          <div className="wrapper">
            <div className="info">
              <p className="sender">{userName}</p>
              <div className="description">
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none">
                  <g clipPath="url(#clip0_2101_50811)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.9602 3.00658L9.91927 4.98366V2.70866C9.91927 2.565 9.85781 2.42722 9.74842 2.32564C9.63902 2.22406 9.49065 2.16699 9.33594 2.16699H1.16927C1.01456 2.16699 0.866188 2.22406 0.756792 2.32564C0.647396 2.42722 0.585938 2.565 0.585938 2.70866V10.292C0.585938 10.4356 0.647396 10.5734 0.756792 10.675C0.866188 10.7766 1.01456 10.8337 1.16927 10.8337H9.33594C9.49065 10.8337 9.63902 10.7766 9.74842 10.675C9.85781 10.5734 9.91927 10.4356 9.91927 10.292V8.01699L12.9602 9.99407C13.0039 10.0225 13.0552 10.0393 13.1085 10.0426C13.1618 10.0458 13.215 10.0354 13.2624 10.0125C13.3097 9.98958 13.3494 9.95504 13.3771 9.91263C13.4047 9.87022 13.4193 9.82158 13.4193 9.77199V3.22866C13.4193 3.17907 13.4047 3.13043 13.3771 3.08802C13.3494 3.04561 13.3097 3.01107 13.2624 2.98816C13.215 2.96525 13.1618 2.95485 13.1085 2.9581C13.0552 2.96134 13.0039 2.97811 12.9602 3.00658Z"
                      fill="#999999"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2101_50811">
                      <rect width="14" height="13" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Video</span>
              </div>
            </div>
            <div className="document" onClick={() => onClose(false)}>
              <img
                src={
                  TN_THUMB_VIDEO_IMAGE_BASE_URL + tappedMessage?.media?.thumbUrl
                }
                className="document-reply"
                alt="document-img"
              />
              <div className="cancel" tabIndex="0" role="button">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <title>Cancel</title>
                  <path
                    d="M3 16L9.5 9.5L16 16M16 3L9.49876 9.5L3 3"
                    stroke="#ffffff"
                    strokeWidth="2.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Contact */}
      {(messageType === RECEIVER_CONTACT || messageType === SENDER_CONTACT) && (
        <div className="reply">
          <div className="wrapper">
            <div className="info">
              <p className="sender">{userName}</p>
              <div className="description">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.76712 0C2.58193 0 0 2.58193 0 5.76712C0 6.94275 0.351741 8.03621 0.95573 8.94799C1.15809 8.77562 1.43038 8.57248 1.77842 8.37082C2.63589 7.87401 3.9404 7.39383 5.7668 7.39383C7.59319 7.39383 8.89786 7.87401 9.75546 8.3708C10.1036 8.57249 10.376 8.77568 10.5785 8.94806C11.1825 8.03627 11.5342 6.94279 11.5342 5.76712C11.5342 2.58193 8.9523 0 5.76712 0ZM9.95574 9.73143C9.79363 9.59087 9.56185 9.41431 9.25421 9.2361C8.54156 8.82328 7.40885 8.39383 5.7668 8.39383C4.12475 8.39383 2.99222 8.82328 2.27974 9.23608C1.97219 9.41428 1.74049 9.59082 1.57844 9.73137C2.62958 10.8416 4.11744 11.5342 5.76712 11.5342C7.41676 11.5342 8.9046 10.8417 9.95574 9.73143ZM5.7668 1.32115C5.13023 1.32115 4.52113 1.57754 4.0731 2.03168C3.6253 2.48559 3.37492 3.0998 3.37492 3.73884C3.37492 4.37788 3.6253 4.99209 4.0731 5.446C4.52113 5.90014 5.13023 6.15653 5.7668 6.15653C6.40337 6.15653 7.01247 5.90014 7.4605 5.446C7.9083 4.99209 8.15868 4.37788 8.15868 3.73884C8.15868 3.0998 7.9083 2.48559 7.4605 2.03168C7.01247 1.57754 6.40337 1.32115 5.7668 1.32115ZM4.78498 2.73398C5.04655 2.46885 5.39985 2.32115 5.7668 2.32115C6.13375 2.32115 6.48705 2.46885 6.74862 2.73398C7.01041 2.99934 7.15868 3.36068 7.15868 3.73884C7.15868 4.11701 7.01041 4.47834 6.74862 4.7437C6.48705 5.00883 6.13375 5.15653 5.7668 5.15653C5.39985 5.15653 5.04655 5.00883 4.78498 4.7437C4.52319 4.47834 4.37492 4.11701 4.37492 3.73884C4.37492 3.36068 4.52319 2.99934 4.78498 2.73398Z"
                    fill="#999999"
                  />
                </svg>
                <span>{getContacts()}</span>
              </div>
            </div>
            <div className="document" onClick={() => onClose(false)}>
              <img
                src={tappedMessage?.contact[0]?.profilePic}
                className="document-reply"
                alt="document-img"
              />
              <div className="cancel" tabIndex="0" role="button">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <title>Cancel</title>
                  <path
                    d="M3 16L9.5 9.5L16 16M16 3L9.49876 9.5L3 3"
                    stroke="#ffffff"
                    strokeWidth="2.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Image */}
      {(messageType === RECEIVER_IMAGE || messageType === SENDER_IMAGE) && (
        <div className="reply">
          <div className="wrapper">
            <div className="info">
              <p className="sender">{userName}</p>
              <div className="description">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.567691 0H11.5415C11.8163 0 12.1092 0.244372 12.1092 0.634222V8.46931L10.5656 6.56037C10.1098 5.98755 9.25914 5.93994 8.74338 6.4611L7.7046 7.51073L8.05998 7.86244L7.7046 7.51073C7.61237 7.60392 7.46258 7.59764 7.37825 7.49539L4.81579 4.3883C4.33182 3.80146 3.4396 3.7941 2.94609 4.37224L0 7.7483V0.634222C0 0.244372 0.292929 0 0.567691 0ZM2.02548 12.0738H10.0837C10.0087 12.079 9.93291 12.0816 9.85653 12.0816H2.25269C2.17631 12.0816 2.10054 12.079 2.02548 12.0738ZM12.1092 10.0597V10.4396C12.1092 10.8294 11.8163 11.0738 11.5415 11.0738H0.567691C0.292929 11.0738 0 10.8294 0 10.4396V9.26921L3.70157 5.02742L3.70161 5.02745L3.70614 5.02211C3.79598 4.9162 3.95579 4.91722 4.04431 5.02455L6.60677 8.13164C7.0672 8.68992 7.90589 8.72895 8.41537 8.21415L9.45415 7.16452C9.54756 7.07013 9.69983 7.07791 9.78338 7.18343L9.78336 7.18344L9.7866 7.18745L12.1092 10.0597ZM7.207 2.96824C7.207 2.25536 7.78302 1.67383 8.49804 1.67383C9.21307 1.67383 9.78908 2.25536 9.78908 2.96824C9.78908 3.68112 9.21307 4.26265 8.49804 4.26265C7.78302 4.26265 7.207 3.68112 7.207 2.96824ZM8.49804 2.67383C8.33931 2.67383 8.207 2.80363 8.207 2.96824C8.207 3.13285 8.33931 3.26265 8.49804 3.26265C8.65677 3.26265 8.78908 3.13285 8.78908 2.96824C8.78908 2.80363 8.65677 2.67383 8.49804 2.67383Z"
                    fill="#999999"
                  />
                </svg>

                <span>Photo</span>
              </div>
            </div>
            <div className="document" onClick={() => onClose(false)}>
              <img
                src={TN_CHAT_IMAGE_BASE_URL + media?.mediaUrl}
                className="document-reply"
                alt="document-img"
              />
              <div className="cancel" tabIndex="0" role="button">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <title>Cancel</title>
                  <path
                    d="M3 16L9.5 9.5L16 16M16 3L9.49876 9.5L3 3"
                    stroke="#ffffff"
                    strokeWidth="2.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Document */}
      {(messageType === RECEIVER_DOCUMENT ||
        messageType === SENDER_DOCUMENT) && (
        <div className="reply">
          <div className="wrapper">
            <div className="info">
              <p className="sender">{userName}</p>
              <div className="description">
                <svg width="10" height="13" viewBox="0 0 10 13" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.79695 0V3.84858C4.79695 4.12472 5.02081 4.34858 5.29695 4.34858H9.14668V11.0962C9.14668 11.6206 8.72156 12.0457 8.19715 12.0457H0.949526C0.425117 12.0457 0 11.6206 0 11.0962V0.949525C0 0.425117 0.425117 0 0.949525 0H4.79695ZM5.79695 0V3.34858H9.14668V3.33092L5.81576 0H5.79695Z"
                    fill="#999999"
                  />
                </svg>
                {/* <span>{media?.mediaUrl?.split(DOCLINK)[3]}</span> */}
                <span>PDF</span>
              </div>
            </div>
            <div
              className="btn btn-cancel"
              onClick={() => handleClose()}
              tabIndex="0"
              role="button"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <title>Cancel</title>
                <path
                  d="M3 16L9.5 9.5L16 16M16 3L9.49876 9.5L3 3"
                  stroke="#999999"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ReplyMedia;
