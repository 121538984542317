const chat_image_thumb_size = "400x400";
const stories_image_thumb_size = "250x100";
const view_profile_image_thumb_size = "400x400";
const profile_image_thumb_size = "400x400";
const story_image_size = "1920x1920";
const story_thumb_size = "100x100";

const post_grid_size = "400x400";
export const storyVideoPath = "/uploads/stories/video/";
export const view_profile_image_base_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/fit-in/${view_profile_image_thumb_size}/`;
export const profile_image_base_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/fit-in/${profile_image_thumb_size}/`;
export const group_profile_image_base_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/fit-in/${profile_image_thumb_size}/`;
export const chat_thumb_image_base_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/fit-in/${chat_image_thumb_size}/`;
export const stories_image_thumb_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/fit-in/${stories_image_thumb_size}/`;
export const story_image_base_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/fit-in/${story_image_size}/`;
export const story_thumb_base_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/fit-in/${story_thumb_size}/`;
export const story_video_thumb_base_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/fit-in/${story_thumb_size}/`;

export const story_video_thumb_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/fit-in/`;
export const story_video_base_url = `${process.env.REACT_APP_CLOUDFRONT_BUCKET_VIDEO}${storyVideoPath}`;
export const post_video_url = `${process.env.REACT_APP_CLOUDFRONT_BUCKET_VIDEO}/uploads/posts/video/`;
export const post_first_video_url = `${process.env.REACT_APP_CLOUDFRONT_BUCKET_VIDEO}/uploads/posts/input-video/`;
export const post_image__url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/uploads/posts/image/`;
export const post_video_thumb_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/uploads/posts/video/thumb/`;
export const post_image_grid_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/uploads/posts/image/${post_grid_size}/`;
export const post_video_thumb_grid_url = `${process.env.REACT_APP_CLOUDFRONT_IMAGE}/uploads/posts/video/thumb/${post_grid_size}/`;
export const basicPath = `/uploads/`;
// export const placeholderImage = `uploads/tapnot.png`;
export const placeholderImage = `uploads/placeholder/TepnotProfilePH.png`;
export const groupPlaceholderImage = `uploads/placeholder/TepnotGroupPH.png`;
export const postImagePath = `uploads/posts/image/`;
export const postVideoPath = `uploads/posts/input-video/`;
export const postVideoThumb = `uploads/posts/video/thumb/`;
export const userProfileImage = "uploads/profile/";
export const groupProfilePath = "uploads/group/profile/";
export const chatImagePath = `uploads/chat/image/`;
export const chatDocumentPath = "/uploads/chat/document/";
export const chatThumbImagePath = "uploads/chat/image/thumb/";
export const chatVideoPath = "uploads/chat/video/";
export const chatThumbVideoImagePath = "uploads/chat/video/thumb/";
export const chatAudioFilePath = "uploads/chat/audio/";
export const chatStickerPath = "uploads/chat/sticker/";
export const storyImagePath = "uploads/stories/image/";
export const storyThumbVideoImagePath = "uploads/stories/video/thumb/";
